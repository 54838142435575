import {ListTable, Modal, ModalContent, ModalTitle} from "@components";
import {
  faArrowCircleDown,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {TUpDown, IPostRecipeItem} from "@data";
import React from "react";
import {useSetRecoilState} from "recoil";
import {COLOR} from "utils/styleUtils";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  listData?: IPostRecipeItem[];
  post_no: number;
  close?: () => void;
  refresh?: () => void;
}

export function PostRecipeListModal({
  listData,
  post_no,
  isVisible,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);

  function setClose() {
    close?.();
  }

  async function changePostRecipeOrdering({
    no,
    type,
  }: {
    no: number;
    type: TUpDown;
  }) {
    try {
      const {success, message} = await adminAPI.post.changePostRecipeOrdering({
        no,
        post_no,
        type,
      });
      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changePostRecipeOrdering error", error);
    }
  }

  return (
    <>
      <Modal isVisible={isVisible} width={"700px"} close={setClose}>
        <ModalTitle title="상품순서" isClose close={setClose} />
        <ModalContent>
          <ListTable>
            <thead>
              <tr>
                <th>이미지</th>
                <th>업체명</th>
                <th>상품명</th>
                <th>순번</th>
              </tr>
            </thead>
            <tbody>
              {(listData || []).map((item, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={item.product?.item_img}
                      className={styles.logoImg}
                    />
                  </td>
                  <td>{item.product?.partners?.partner_name}</td>
                  <td>
                    {item.product?.item_name}
                    {(item.product?.partners?.hide_yn === "Y" ||
                      item.product?.view_yn === "N") && (
                      <span className="text-12" style={{color: COLOR.red}}>
                        <br />
                        (미노출상품)
                      </span>
                    )}
                  </td>
                  <td>
                    <div className="row" style={{minWidth: "50px"}}>
                      <div className="col">
                        {(item.ordering || 0) > 1 && (
                          <FontAwesomeIcon
                            icon={faArrowCircleUp}
                            onClick={() => {
                              if (item.no) {
                                changePostRecipeOrdering({
                                  no: item.no,
                                  type: "up",
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                      <div className="col">{item.ordering || "-"}</div>
                      <div className="col">
                        <FontAwesomeIcon
                          icon={faArrowCircleDown}
                          onClick={() => {
                            if (item.no) {
                              changePostRecipeOrdering({
                                no: item.no,
                                type: "down",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </ListTable>
        </ModalContent>
      </Modal>
    </>
  );
}
