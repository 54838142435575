import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
  Button,
  CheckBox,
} from "@components";
import {PartnerDetailModal} from "@pages";
import {IPage, TypeYN, IDBPartner} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";

export default function PartnerPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [list, setList] = useState<IDBPartner[]>();
  const [paging, setPaging] = useState<IPage>();
  const [searchText, setSearchText] = useState("");
  const [detailData, setDetailData] = useState<IDBPartner>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.partner.apiGetPartnerList(
        {
          page,
          searchtxt: searchText,
        },
      );
      if (success) {
        setList(data.result);
        setPaging(data.page);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  async function toggleHideYn({no, hide_yn}: {no: number; hide_yn: TypeYN}) {
    try {
      const {data, success, message} = await adminAPI.partner.apiUpdatePartner({
        no,
        hide_yn,
      });
      if (success) {
        getList({page: paging?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("toggleHideYn error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="업체관리">
        <SearchFilter
          left={[
            <InputBox
              key={0}
              type={"text"}
              placeholder="검색"
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => setIsDetailOpen(true)}
            />,
          ]}
        />
        <Content>
          <ListTable paging={paging} getList={getList}>
            <thead>
              <tr>
                <th>업체코드</th>
                <th>업체명</th>
                <th>사업자등록번호</th>
                <th>대표자명</th>
                <th>전화번호</th>
                <th>담당자</th>
                <th>수수료</th>
                <th>기타</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {(list || []).length > 0 ? (
                <>
                  {list?.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        setDetailData(item);
                        setIsDetailOpen(true);
                      }}>
                      <td>{item.partner_code}</td>
                      <td className="break-spaces">{item.partner_name}</td>
                      <td>{utils.bizNoFormatter(item.biz_regno || "")}</td>
                      <td className="break-spaces">{item.biz_repsname}</td>
                      <td>{utils.formatPhoneNumber(item.biz_tel || "")}</td>
                      <td className="break-spaces">
                        {item.biz_manager}
                        {!!item.biz_phone && (
                          <>
                            <br />(
                            {utils.formatPhoneNumber(item.biz_phone || "")})
                          </>
                        )}
                      </td>
                      <td>{item.biz_commission || 0}%</td>
                      <td className="break-spaces">{item.biz_memo}</td>
                      <td onClick={utils.handleClickBubble}>
                        <CheckBox
                          checked={item?.hide_yn === "N"}
                          text={item?.hide_yn === "N" ? "노출" : "숨김"}
                          isToggle
                          onChange={e => {
                            if (!item.no) {
                              return;
                            }
                            toggleHideYn({
                              no: item.no,
                              hide_yn: item.hide_yn === "N" ? "Y" : "N",
                            });
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={9} className="text-center">
                    데이터가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <PartnerDetailModal
        isVisible={isDetailOpen}
        data={detailData}
        setData={setDetailData}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
        }}
        reloadData={getList}
      />
    </>
  );
}
