import React from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  InputBox,
  FileUploadButton,
  TextAreaBox,
  CheckBox,
} from "@components";
import styles from "./style.module.scss";
import {IDBNotice} from "@data";
import {styleUtils} from "@utils";

interface IProps {
  isVisible?: boolean;
  data?: IDBNotice;
  setData?: (data?: IDBNotice) => void;
  save?: () => void;
  deleteNotice?: ({no}: {no: number}) => void;
  close?: () => void;
}

export function NoticeDetailModal({
  isVisible,
  data = {},
  setData,
  save,
  deleteNotice,
  close,
}: IProps) {
  function setClose() {
    setData?.(undefined);
    close?.();
  }

  function setDelete() {
    if (!data.no) {
      return;
    }
    deleteNotice?.({no: data.no});
  }

  return (
    <Modal
      isVisible={isVisible}
      className={styles.modal}
      width={"500px"}
      close={setClose}>
      <ModalTitle title="공지상세" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              이미지
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadNoticeImg"}
                  fileUpload={({files}) =>
                    setData?.({...data, img: files?.[0]})
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">{!!data?.img && <img src={data?.img} />}</div>
        </div>
        <div className="row">
          <div className="col title">제목</div>
          <div className="col">
            <InputBox
              value={data?.title}
              onValue={value => setData?.({...data, title: value})}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">내용</div>
          <div className="col">
            <TextAreaBox
              placeholder="내용"
              value={data?.content}
              onValue={value => setData?.({...data, content: value})}
              height={300}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">노출여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.hide_yn === "N"}
                text={data?.hide_yn === "N" ? "노출" : "숨김"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    hide_yn: data?.hide_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          {!!data.no && (
            <Button
              text="삭제"
              onClick={setDelete}
              backgroundColor={styleUtils.COLOR.red400}
            />
          )}
        </div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
