import React, {useState} from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  TextAreaBox,
} from "@components";
import {styleUtils, utils} from "@utils";
import styles from "./style.module.scss";
import moment from "moment";
import {IMemberQnaListResponse} from "@data";

interface IProps {
  detailData: IMemberQnaListResponse;
  updateDetail?: ({answer}: {answer: string}) => void;
  close?: () => void;
}

export function MemberQnaDetailModal({
  detailData,
  updateDetail,
  close,
}: IProps) {
  const [answer, setAnswer] = useState(detailData.answer || "");

  function update() {
    updateDetail?.({answer});
  }

  return (
    <Modal close={close} isVisible={!!detailData} width={"500px"}>
      <ModalTitle close={close} isClose title="답변등록" />
      <ModalContent>
        <div className={styles.member}>
          <span>
            {detailData?.member?.name} ({detailData?.member?.nickname})
          </span>
          <div>
            {utils.formatPhoneNumber(detailData?.member?.phone_number || "")}
            {detailData?.member?.phone_number !== detailData.phone_number && (
              <>
                <br />
                {utils.formatPhoneNumber(detailData.phone_number || "")}
              </>
            )}
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.title}>{detailData?.title}</div>
          <div className={styles.content}>{detailData?.content}</div>
        </div>
        <div className={styles.answerContainer}>
          {!!detailData?.answerDt && (
            <div className={styles.title}>
              <span>{detailData.admin?.name}</span>
              <span>
                {moment(detailData?.answerDt).format("YYYY-MM-DD HH:mm")}
              </span>
            </div>
          )}
          <div className={styles.content}>
            <TextAreaBox value={answer} onValue={setAnswer} height={250} />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button
            text="저장"
            backgroundColor={styleUtils.COLOR.blueDark}
            fontColor={styleUtils.COLOR.white}
            onClick={update}
          />
        </div>
        <div>
          <Button text="닫기" onClick={close} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
