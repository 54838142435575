import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ChartData} from "chart.js";
import {Content, InputBox, ListTable, SearchFilter} from "@components";
import {IDashboardMemberChartKeys, IDashBoardResponse} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Bar} from "react-chartjs-2";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import styles from "./style.module.scss";

const memberChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    plugins: {
      legend: {display: false},
      tooltip: {
        callbacks: {
          title: () => null,
        },
      },
    },
    scales: {
      x: {stacked: false},
      y: {stacked: false},
    },
  },
  maintainAspectRatio: false,
};

const memberChartLabelName: {
  value: {key: IDashboardMemberChartKeys; title: string}[];
  title: string;
  text?: string;
  isChart?: boolean;
}[] = [
  {
    value: [
      {key: "member_1_1", title: "미로그인"},
      {key: "member_1_2", title: "로그인"},
      {key: "member_1_3", title: "비회원"},
    ],
    title: "접촉고객",
    text: undefined,
    isChart: false,
  },
  {
    value: [
      {key: "member_2_1", title: "경험고객"},
      {key: "member_2_2_1", title: "이탈고객(1개월)"},
      {key: "member_2_2_2", title: "이탈고객(2개월)"},
      {key: "member_2_2_3", title: "이탈고객(3개월)"},
    ],
    title: "경험고객",
    text: undefined,
    isChart: true,
  },
  {
    value: [
      {key: "member_3_1", title: "재구매고객"},
      {key: "member_3_2_1", title: "이탈고객(1개월)"},
      {key: "member_3_2_2", title: "이탈고객(2개월)"},
      {key: "member_3_2_3", title: "이탈고객(3개월)"},
    ],
    title: "재구매고객",
    text: undefined,
    isChart: true,
  },
  {
    value: [
      {key: "member_4_1", title: "로열티고객"},
      {key: "member_4_2_1", title: "이탈고객(1개월)"},
      {key: "member_4_2_2", title: "이탈고객(2개월)"},
      {key: "member_4_2_3", title: "이탈고객(3개월)"},
    ],
    title: "로열티고객",
    text: undefined,
    isChart: true,
  },
  {
    value: [{key: "member_5", title: "탈퇴고객"}],
    title: "탈퇴고객",
    text: undefined,
    isChart: true,
  },
];

type TMemberChartBottomData = {
  [key in IDashboardMemberChartKeys]?: {
    member_count?: number;
    percent?: number;
  };
};

export function DashBoardHomeView() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchDate, setSearchDate] = useState(moment().format("YYYY-MM-DD"));
  const [dashboardData, setDashboardData] = useState<IDashBoardResponse>();
  const [memberChartBottomData, setMemberChartBottomData] =
    useState<TMemberChartBottomData>({});
  const [memberChartData, setMemberChartData] = useState<ChartData<any>>();

  async function getData() {
    try {
      const {data, success, message} = await adminAPI.dashboard.getData({
        date: searchDate,
      });
      if (success) {
        setDashboardData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  function convertMemberChartData() {
    try {
      if (!dashboardData) {
        return;
      }

      const chart_data_member = dashboardData?.chart_data_member;

      const total_member_count = Number(
        chart_data_member?.total_member_count || 0,
      );

      const datasetLen = memberChartLabelName.filter(
        item => !!item.isChart,
      ).length;

      const datasets = [
        {
          label: "고객수",
          data: Array(datasetLen).fill(0),
        },
        {
          label: "이탈고객(1개월)",
          data: Array(datasetLen).fill(0),
        },
        {
          label: "이탈고객(2개월)",
          data: Array(datasetLen).fill(0),
        },
        {
          label: "이탈고객(3개월)",
          data: Array(datasetLen).fill(0),
        },
      ];

      const bottomData: TMemberChartBottomData = {};

      let datasetIndex = 0;
      const labelName = memberChartLabelName.map(label => {
        const value = label.value.reduce(
          (acc, keys) => acc + Number(chart_data_member?.[keys.key] || 0),
          0,
        );
        label.value.forEach(keys => {
          const _value = Number(chart_data_member?.[keys.key] || 0);
          bottomData[keys.key] = {
            member_count: _value,
            percent: (_value / value) * 100,
          };

          if (label.isChart) {
            if (keys["title"] === datasets[1].label) {
              datasets[1].data[datasetIndex] += _value;
            } else if (keys["title"] === datasets[2].label) {
              datasets[2].data[datasetIndex] += _value;
            } else if (keys["title"] === datasets[3].label) {
              datasets[3].data[datasetIndex] += _value;
            } else {
              datasets[0].data[datasetIndex] += _value;
            }
          }
        });
        if (label.isChart) {
          datasetIndex++;
        }

        const percent = ((value / total_member_count) * 100).toFixed(0);
        const text = [label.title, `${value.toLocaleString()}명(${percent}%)`];

        return {...label, text};
      });

      const chartDataMember = {
        datasets,
        labels: labelName
          .filter(item => !!item.isChart)
          .map(_label => _label.text),
      };

      setMemberChartData(chartDataMember);
      setMemberChartBottomData(bottomData);
    } catch (error) {
      console.error("convertMemberChartData error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [searchDate]);

  useEffect(() => {
    if (dashboardData) {
      convertMemberChartData();
    }
  }, [dashboardData]);

  return (
    <>
      <SearchFilter
        left={[
          <InputBox
            key={0}
            type={"date"}
            placeholder="연도 입력"
            rightContent={
              <div style={{display: "flex"}} onClick={getData}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            }
            value={searchDate}
            onValue={setSearchDate}
            onEnter={getData}
          />,
        ]}
      />
      <Content>
        <div style={{flex: 1}}>
          <div>
            <div className="row top">
              <div className="col">
                <div className={styles.item}>
                  <div className={styles.title}>매출액</div>
                  <div className={styles.content}>
                    <span className="text-12">₩ </span>
                    {(dashboardData?.sale?.sale_price || 0).toLocaleString()}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className={styles.item}>
                  <div className={styles.title}>주문수</div>
                  <div className={styles.content}>
                    {(dashboardData?.sale?.sale_count || 0).toLocaleString()}
                    <span className="text-12"> 건</span>
                  </div>
                  <div className={styles.subContent}>
                    새벽{" "}
                    {(
                      dashboardData?.sale?.sale_dawn_count || 0
                    ).toLocaleString()}{" "}
                    일반{" "}
                    {(
                      dashboardData?.sale?.sale_common_count || 0
                    ).toLocaleString()}{" "}
                    도서산간{" "}
                    {(
                      dashboardData?.sale?.sale_plus_count || 0
                    ).toLocaleString()}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className={styles.item}>
                  <div className={styles.title}>출고 주문수</div>
                  <div className={styles.content}>
                    {(
                      dashboardData?.sale_output?.sale_output_count || 0
                    ).toLocaleString()}
                    <span className="text-12"> 건</span>
                  </div>
                  <div className={styles.subContent}>
                    새벽{" "}
                    {(
                      dashboardData?.sale_output?.sale_output_dawn_count || 0
                    ).toLocaleString()}{" "}
                    일반{" "}
                    {(
                      dashboardData?.sale_output?.sale_output_common_count || 0
                    ).toLocaleString()}{" "}
                    도서산간{" "}
                    {(
                      dashboardData?.sale_output?.sale_output_dawn_count || 0
                    ).toLocaleString()}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className={styles.item}>
                  <div className={styles.title}>가입자</div>
                  <div className={styles.content}>
                    {(dashboardData?.join_member_count || 0).toLocaleString()}
                    <span className="text-12"> 명</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!!memberChartData && (
            <div className={styles.memberChartContainer}>
              <Bar options={memberChartOptions} data={memberChartData} />
            </div>
          )}
          <div style={{marginTop: "24px"}}>
            <ListTable>
              <tbody className={styles.memberChartTbody}>
                <tr className={styles.memberChartThead}>
                  <td rowSpan={3}>정의</td>
                  <td>접촉고객</td>
                  <td>경험고객</td>
                  <td>재구매고객</td>
                  <td>로열티고객</td>
                  <td>탈퇴고객</td>
                </tr>
                <tr className={styles.title}>
                  <td>앱내 접속이력 있는 고객</td>
                  <td>구매이력 1회</td>
                  <td>구매이력 2~3회</td>
                  <td>구매이력 4회이상</td>
                  <td>탈퇴회원</td>
                </tr>
                <tr>
                  <td>
                    <div>
                      미로그인 메이크샵기고객:{" "}
                      {(
                        memberChartBottomData?.member_1_1?.member_count || 0
                      ).toLocaleString()}
                      명 (
                      {(
                        memberChartBottomData?.member_1_1?.percent || 0
                      ).toFixed(0)}
                      %)
                    </div>
                    <div>
                      로그인:{" "}
                      {(
                        memberChartBottomData?.member_1_2?.member_count || 0
                      ).toLocaleString()}
                      명 (
                      {(
                        memberChartBottomData?.member_1_2?.percent || 0
                      ).toFixed(0)}
                      %)
                    </div>
                    <div>
                      비회원:{" "}
                      {(
                        memberChartBottomData?.member_1_3?.member_count || 0
                      ).toLocaleString()}
                      명 (
                      {(
                        memberChartBottomData?.member_1_3?.percent || 0
                      ).toFixed(0)}
                      %)
                    </div>
                  </td>
                  <td>
                    <div>
                      총 :{" "}
                      {(
                        dashboardData?.chart_data_member?.member_2 || 0
                      ).toLocaleString()}
                      명
                    </div>
                    <div>
                      유효회원수:{" "}
                      {(
                        memberChartBottomData?.member_2_1?.member_count || 0
                      ).toLocaleString()}
                      명 (
                      {(
                        memberChartBottomData?.member_2_1?.percent || 0
                      ).toFixed(0)}
                      %)
                    </div>
                    <div>
                      이탈고객:
                      <div>
                        - 1개월 :
                        {(
                          memberChartBottomData?.member_2_2_1?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_2_2_1?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                      <div>
                        - 2개월 :
                        {(
                          memberChartBottomData?.member_2_2_2?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_2_2_2?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                      <div>
                        - 3개월 :
                        {(
                          memberChartBottomData?.member_2_2_3?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_2_2_3?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      총 :{" "}
                      {(
                        dashboardData?.chart_data_member?.member_3 || 0
                      ).toLocaleString()}
                      명
                    </div>
                    <div>
                      유효회원수:{" "}
                      {(
                        memberChartBottomData?.member_3_1?.member_count || 0
                      ).toLocaleString()}
                      명 (
                      {(
                        memberChartBottomData?.member_3_1?.percent || 0
                      ).toFixed(0)}
                      %)
                    </div>
                    <div>
                      이탈고객:
                      <div>
                        - 1개월 :
                        {(
                          memberChartBottomData?.member_3_2_1?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_3_2_1?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                      <div>
                        - 2개월 :
                        {(
                          memberChartBottomData?.member_3_2_2?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_3_2_2?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                      <div>
                        - 3개월 :
                        {(
                          memberChartBottomData?.member_3_2_3?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_3_2_3?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      총 :{" "}
                      {(
                        dashboardData?.chart_data_member?.member_4 || 0
                      ).toLocaleString()}
                      명
                    </div>
                    <div>
                      유효회원수:{" "}
                      {(
                        memberChartBottomData?.member_4_1?.member_count || 0
                      ).toLocaleString()}
                      명 (
                      {(
                        memberChartBottomData?.member_4_1?.percent || 0
                      ).toFixed(0)}
                      %)
                    </div>
                    <div>
                      이탈고객:
                      <div>
                        - 1개월 :
                        {(
                          memberChartBottomData?.member_4_2_1?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_4_2_1?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                      <div>
                        - 2개월 :
                        {(
                          memberChartBottomData?.member_4_2_2?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_4_2_2?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                      <div>
                        - 3개월 :
                        {(
                          memberChartBottomData?.member_4_2_3?.member_count || 0
                        ).toLocaleString()}
                        명 (
                        {(
                          memberChartBottomData?.member_4_2_3?.percent || 0
                        ).toFixed(0)}
                        %)
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      총 :{" "}
                      {(
                        dashboardData?.chart_data_member?.member_5 || 0
                      ).toLocaleString()}
                      명
                    </div>
                    <div>
                      유효회원수:{" "}
                      {(
                        memberChartBottomData?.member_5?.member_count || 0
                      ).toLocaleString()}
                      명 (
                      {(memberChartBottomData?.member_5?.percent || 0).toFixed(
                        0,
                      )}
                      %)
                    </div>
                  </td>
                </tr>
              </tbody>
            </ListTable>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <div className="text-11">
                <div>
                  <div>- 앱 미접속 고객 제외</div>
                  <div>- 구매횟수는 메이크샵 구매횟수 포함</div>
                  <div>
                    - 이탈고객: 구매횟수 1회 이상이며 최근 1달 내 구매이력이
                    없는 고객
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}
