import {APP_NAVIGATE} from "@assets";
import {
  Button,
  CheckBox,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
  TextAreaBox,
} from "@components";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {
  IDBDeliveryDateRecommandItem,
  IDBProduct,
  IDeliveryDateResponse,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {encryptQuery} from "utils/commonUtil";
import {ZOOTOPIA_LINK_URL} from "utils/constant";
import {COLOR} from "utils/styleUtils";
import styles from "./style.module.scss";

interface IProps {
  isVisible?: boolean;
  data?: IDeliveryDateResponse;
  close?: () => void;
  refresh?: () => void;
  itemList?: IDBProduct[];
}

interface IRecommandItemList extends IDBDeliveryDateRecommandItem {
  title: string;
  item_no: number;
  item_name?: string;
}

export function DeliveryDateDetailModal({
  isVisible,
  data = {},
  close,
  refresh,
  itemList = [],
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [deliveryDate, setDeliveryDate] = useState<moment.Moment>();
  const [isMilk, setIsMilk] = useState(false);
  const [tempItemNo, setTempItemNo] = useState<number>();
  const [tempTitle, setTempTitle] = useState<string>();
  const [recommandItemList, setRecommandItemList] = useState<
    IRecommandItemList[]
  >([]);

  function setClose() {
    setDeliveryDate(undefined);
    setIsMilk(false);
    close?.();
  }

  async function setSave() {
    try {
      if (!deliveryDate || !moment(deliveryDate).isValid()) {
        return alert("출고일을 입력해주세요");
      }
      if (!confirm("저장하시겠습니까?")) {
        return;
      }

      const {success, message} = data?.no
        ? await adminAPI.deliveryDate.updateDate({
            no: data?.no,
            delivery_datetime: moment(deliveryDate).format("YYYY-MM-DD"),
            milk_yn: isMilk ? "Y" : "N",
            recommandItemList,
          })
        : await adminAPI.deliveryDate.createDate({
            delivery_datetime: moment(deliveryDate).format("YYYY-MM-DD"),
            milk_yn: isMilk ? "Y" : "N",
            recommandItemList,
          });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("setSave error", error);
    }
  }

  async function deleteDate() {
    try {
      if (!data?.no) {
        return;
      }
      if (!confirm("삭제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.deliveryDate.deleteDate({
        no: data.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("deleteDate error", error);
    }
  }

  function setTempToRecommand() {
    try {
      if (!tempItemNo || isNaN(Number(tempItemNo))) {
        return alert("상품을 선택해주세요");
      }
      if (!tempTitle) {
        return alert("타이틀을 입력해주세요");
      }
      const item_name = (itemList || []).find(
        item => item.no === tempItemNo,
      )?.item_name;
      setRecommandItemList([
        ...(recommandItemList || []),
        {item_no: tempItemNo, title: tempTitle, item_name},
      ]);
      setTempItemNo(undefined);
      setTempTitle(undefined);
    } catch (error) {
      console.error("setTempToRecommand error", error);
    }
  }

  useEffect(() => {
    if (data) {
      setDeliveryDate(moment(data.delivery_datetime));
      setIsMilk(data.milk_yn === "Y");
      setRecommandItemList(
        (data.recommand || [])
          .filter(recommand => !!recommand.item_no && !!recommand.title)
          .map(recommand => {
            const item_name = (itemList || []).find(
              item => item.no === recommand.item_no,
            )?.item_name;
            return {...recommand, item_name};
          }) as IRecommandItemList[],
      );
    } else {
      setIsMilk(false);
    }
  }, [data]);

  return (
    <Modal
      isVisible={isVisible}
      width={"500px"}
      close={setClose}
      className={styles.modal}>
      <ModalTitle title="상세페이지" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">출고일</div>
          <div className="col">
            <InputBox
              placeholder="출고일"
              type="date"
              value={deliveryDate?.format("YYYY-MM-DD")}
              onValue={value =>
                setDeliveryDate?.(
                  moment(value).isValid() ? moment(value) : moment(),
                )
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">또또우유출고</div>
          <div className="col">
            <div>
              <CheckBox
                checked={isMilk}
                text={isMilk ? "Yes" : "No"}
                isToggle
                onChange={e => setIsMilk(!isMilk)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">추천상품추가</div>
          <div className="col">
            <div className="row">
              <div style={{flex: 1}}>
                <SelectBox
                  list={(itemList || []).map(item => ({
                    label: String(item.item_name),
                    value: String(item.no),
                  }))}
                  value={String(tempItemNo)}
                  singleSelect={value => setTempItemNo(Number(value))}
                  placeholder="상품선택"
                />
                <TextAreaBox
                  value={tempTitle}
                  onValue={setTempTitle}
                  placeholder="추천내용 입력"
                />
              </div>
              <div>
                <Button
                  text="추가"
                  backgroundColor={COLOR.success}
                  onClick={setTempToRecommand}
                  fontSize={12}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">추천문자 미리보기</div>
          <div>
            [주토피아] 내일은 정기구독 결제되는 날
            <br />
            M월 D일 도착 정기구독 결제는 nn원이에요!
            <br />
            내일 오전 8시 59분까지 함께 받기 상품을 추가하면 배송비 없이 받을 수
            있어요
            <br />
            <br />
            이번 회차 추천 아이템
            <br />
            -----------------------------
            <br />
            {(recommandItemList || []).map((recommand, index) => (
              <div key={index} className={styles.row}>
                <div className="col preWrap">
                  ▶ {recommand.title}
                  <br />
                  {recommand.item_name}
                  <br />
                  {`${ZOOTOPIA_LINK_URL}/${
                    APP_NAVIGATE.PRODUCT_DETAIL
                  }?k=${encryptQuery({
                    query: `${recommand.item_no}`,
                  })}`}
                </div>
                <div className="col">
                  <div
                    className={styles.minusIcon}
                    onClick={() => {
                      if (!confirm("항목을 제거하시겠습니까?")) {
                        return;
                      }
                      setRecommandItemList(
                        (recommandItemList || []).filter(
                          (_recommand, _index) => index !== _index,
                        ),
                      );
                    }}>
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          {!!data?.no && (
            <Button
              text="삭제"
              onClick={deleteDate}
              backgroundColor={styleUtils.COLOR.danger}
              fontColor={styleUtils.COLOR.white}
            />
          )}
        </div>
        <div>
          <Button
            text="저장"
            onClick={setSave}
            backgroundColor={styleUtils.COLOR.success}
            fontColor={styleUtils.COLOR.white}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}
