import React, {useEffect, useState} from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  InputBox,
  FileUploadButton,
  CheckBox,
  SelectBox,
} from "@components";
import styles from "./style.module.scss";
import {IDBEventPopup, IDeeplinkContentNoListResponse} from "@data";
import {adminAPI, styleUtils} from "@utils";
import moment from "moment";
import {deeplinkArr} from "utils/constant";
import {states} from "@recoils";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible?: boolean;
  data?: IDBEventPopup;
  setData?: (data?: IDBEventPopup) => void;
  save?: () => void;
  deleteEventPopup?: ({no}: {no: number}) => void;
  close?: () => void;
}

export function EventPopupDetailModal({
  isVisible,
  data = {},
  setData,
  save,
  deleteEventPopup,
  close,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [targetList, setTargetList] =
    useState<IDeeplinkContentNoListResponse[]>();

  function setClose() {
    setData?.(undefined);
    close?.();
  }

  function setDelete() {
    if (!data.no) {
      return;
    }
    deleteEventPopup?.({no: data.no});
  }

  async function getContentNoList() {
    try {
      setTargetList(undefined);
      if (!data.deeplink) {
        return;
      }
      const {
        data: apiData,
        success,
        message,
      } = await adminAPI.marketing.getContentNoList({
        type: data.deeplink,
        hide_yn: "N",
      });
      if (success) {
        setTargetList(apiData.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getContentNoList error", error);
    }
  }

  useEffect(() => {
    const pushType = (deeplinkArr || []).find(
      item => item.value === data.deeplink,
    );
    if (pushType?.requireno) {
      getContentNoList();
    } else {
      setTargetList(undefined);
    }
  }, [data.deeplink]);

  return (
    <Modal
      isVisible={isVisible}
      className={styles.modal}
      width={"500px"}
      close={setClose}>
      <ModalTitle title="팝업상세" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              이미지
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadNoticeImg"}
                  fileUpload={({files}) =>
                    setData?.({...data, img_url: files?.[0]})
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            {!!data?.img_url && <img src={data?.img_url} />}
          </div>
        </div>
        <div className="row">
          <div className="col title">제목</div>
          <div className="col">
            <InputBox
              value={data?.title}
              onValue={value => setData?.({...data, title: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">오픈일자</div>
          <div className="col">
            <InputBox
              type={"date"}
              value={
                data?.openDate
                  ? moment(data?.openDate).format("YYYY-MM-DD")
                  : ""
              }
              onValue={value =>
                setData?.({...data, openDate: moment(value).toDate()})
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">종료일자</div>
          <div className="col">
            <InputBox
              type={"date"}
              value={
                data?.closeDate
                  ? moment(data?.closeDate).format("YYYY-MM-DD")
                  : ""
              }
              onValue={value =>
                setData?.({...data, closeDate: moment(value).toDate()})
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">딥링크</div>
          <div className="col">
            <SelectBox
              list={[{label: "미선택", value: ""}, ...(deeplinkArr || [])]}
              value={data.deeplink}
              singleSelect={value => setData?.({...data, deeplink: value})}
            />
          </div>
        </div>
        <>
          {(targetList || []).length > 0 && (
            <div className="row">
              <div className="col title">딥링크타겟</div>
              <div className="col">
                <SelectBox
                  list={(targetList || []).map(item => ({
                    value: String(item.no),
                    label: String(item.title || ""),
                  }))}
                  value={String(data.target_no)}
                  singleSelect={value =>
                    setData?.({...data, target_no: Number(value)})
                  }
                />
              </div>
            </div>
          )}
        </>
        <div className="row">
          <div className="col title">외부링크</div>
          <div className="col">
            <InputBox
              value={data?.link_url}
              onValue={value => setData?.({...data, link_url: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">노출여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.hide_yn === "N"}
                text={data?.hide_yn === "N" ? "노출" : "숨김"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    hide_yn: data?.hide_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          {!!data.no && (
            <Button
              text="삭제"
              onClick={setDelete}
              backgroundColor={styleUtils.COLOR.red400}
            />
          )}
        </div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
