import {ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {
  ResultPagingResponse,
  IOrderDetail,
  IMemberQnaListResponse,
} from "@data";
import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";
import {MemberQnaDetailModal} from "pages/MemberQnaPage/MemberQnaDetailModal";

interface IProps {
  member_no: number;
}

export function MemberQnaTab({member_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] = useState<
    ResultPagingResponse<{
      list?: IMemberQnaListResponse[];
      count?: {allCnt?: number; notCnt?: number};
    }>
  >();
  const [detailData, setDetailData] = useState<IOrderDetail>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} =
        await adminAPI.member.apiGetMemberQnAList({
          page,
          member_no,
          limit: 15,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} =
        await adminAPI.member.apiGetMemberQnADetail({
          no,
        });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function updateDetail({answer}: {answer: string}) {
    try {
      if (!detailData || !detailData.no) {
        return;
      }

      const {success, message} = await adminAPI.member.apiUpdateMemberQnaAnswer(
        {
          no: detailData.no,
          admin_no: 1,
          answer,
        },
      );
      if (success) {
        setDetailData(undefined);
        getList({page: listData?.page?.cur || 1});
        setAlertModal({
          isVisible: true,
          title: message || "답변등록 완료되었습니다.",
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("updateDetail error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row">
        <div className={styles.title}>
          총 {(listData?.page?.maxcnt || 0).toLocaleString()}개
        </div>
      </div>
      <div className="row">
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>구분</th>
              <th>제목</th>
              <th>문의일시</th>
              <th>답변여부</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result.list || []).map((item, index) => {
              const isComplete = !!item.answer;

              return (
                <tr
                  key={index}
                  onClick={() => !!item.no && getDetail({no: item.no})}>
                  <td>{item.group?.title}</td>
                  <td>{item.title}</td>
                  <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                  <td>
                    <div
                      className={[
                        styles.badge,
                        ...[isComplete && styles.active],
                      ].join(" ")}>
                      {isComplete ? "완료" : "미완료"}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </div>
      {!!detailData && (
        <MemberQnaDetailModal
          detailData={detailData}
          close={() => setDetailData(undefined)}
          updateDetail={updateDetail}
        />
      )}
    </>
  );
}
