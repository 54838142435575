import {
  Button,
  ITabList,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  TabList,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {
  IDBBadgeList,
  IDBDmbList,
  IDBItemCategory,
  IDBItemType,
  IDBMaterialList,
  IDBPartner,
  IItemDetail,
} from "@data";
import React, {useEffect, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
  InfoTab,
  MaterialTab,
  StockTab,
  DetailTab,
  ReviewTab,
  DmbTab,
  SubscribeMemberTab,
} from "./detailModalTab";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  detailData?: IItemDetail;
  close?: () => void;
  refresh?: (item_no?: number) => void;
}

type TTabs =
  | "info"
  | "material"
  | "stock"
  | "detail"
  | "review"
  | "dmb"
  | "subscribeMember";
interface ITabListLevel extends ITabList<TTabs> {
  level: number[];
}
const tabs: ITabListLevel[] = [
  {tab: "info", title: "정보", level: [1, 2]},
  {tab: "material", title: "원재료", level: [1, 2]},
  {tab: "dmb", title: "영양소", level: [1, 2]},
  {tab: "stock", title: "재고", level: [1]},
  {tab: "detail", title: "상세", level: [1, 2]},
  {tab: "review", title: "리뷰", level: [1]},
  {tab: "subscribeMember", title: "정기구독", level: [1]},
];

export function ItemDetailModal({
  isVisible,
  detailData,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const adminData = useRecoilValue(states.adminDataState);
  const [tab, setTab] = useState<TTabs>("info");
  const [partnerList, setPartnerList] = useState<IDBPartner[]>([]);
  const [itemTypeList, setItemTypeList] = useState<IDBItemType[]>([]);
  const [itemCategoryList, setItemCategoryList] = useState<IDBItemCategory[]>(
    [],
  );
  const [itemBadgeList, setItemBadgeList] = useState<IDBBadgeList[]>([]);
  const [itemMaterialList, setItemMaterialList] = useState<IDBMaterialList[]>(
    [],
  );
  const [dmbList, setDmbList] = useState<IDBDmbList[]>();

  const dmbItemTypeNoList = (detailData?.type_list || []).filter(item =>
    [1, 5].includes(item),
  );
  const isDmbTabVisible = dmbItemTypeNoList.length > 0;

  function setClose() {
    setTab("info");
    close?.();
  }

  async function getPartnerList() {
    try {
      const {data, success, message} =
        await adminAPI.partner.apiGetAllPartners();
      if (success) {
        setPartnerList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPartnerList error", error);
    }
  }

  async function getItemTypeList() {
    try {
      const {data, success, message} = await adminAPI.item.getItemTypes();
      if (success) {
        setItemTypeList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPartnerList error", error);
    }
  }

  async function getItemCategoryList() {
    try {
      const {data, success, message} = await adminAPI.item.getItemCategory({});
      if (success) {
        setItemCategoryList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPartnerList error", error);
    }
  }

  async function getItemBadgeList() {
    try {
      const {data, success, message} = await adminAPI.item.getBadgeList();
      if (success) {
        setItemBadgeList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPartnerList error", error);
    }
  }

  async function getItemMaterialList() {
    try {
      const {data, success, message} = await adminAPI.item.getMaterialList();
      if (success) {
        setItemMaterialList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPartnerList error", error);
    }
  }

  async function getDmbList() {
    try {
      const {data, success, message} = await adminAPI.item.getDmbList({
        default_yn: "Y",
      });
      if (success) {
        setDmbList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDmbList error", error);
    }
  }

  async function cloneItem() {
    try {
      if (!detailData?.no) {
        return;
      }
      if (!confirm("제품을 복제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.item.cloneItem({
        item_no: detailData?.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("cloneItem error", error);
    }
  }

  useEffect(() => {
    getPartnerList();
    getItemTypeList();
    getItemCategoryList();
    getItemBadgeList();
    getItemMaterialList();
    getDmbList();
  }, []);

  return (
    <Modal
      isVisible={isVisible}
      close={setClose}
      width={"90vw"}
      height={"100vh"}
      positionTop={"0"}
      positionRight={"0"}
      borderRadius={0}
      animate={"right"}>
      <ModalTitle
        close={setClose}
        isClose
        title={`상품관리${detailData ? ` - ${detailData?.item_name}` : ""}`}
      />
      <ModalContent>
        <>
          {!!detailData?.no && (
            <div className="row">
              <div className="col">
                <TabList
                  tabList={tabs.filter(_tab => {
                    const isFilterLevel =
                      !!adminData?.level &&
                      _tab.level.includes(adminData?.level);
                    const isFilterTab =
                      _tab.tab === "dmb" ? isDmbTabVisible : true;
                    const isVisible = !!isFilterLevel && !!isFilterTab;
                    return isVisible;
                  })}
                  setTab={setTab}
                  activeTab={tab}
                />
              </div>
              <div style={{marginLeft: "6px"}}>
                <Button
                  backgroundColor={styleUtils.COLOR.warning}
                  text="상품복제"
                  onClick={cloneItem}
                />
              </div>
            </div>
          )}

          {tab === "info" && (
            <InfoTab
              detailData={detailData}
              partnerList={partnerList}
              itemTypeList={itemTypeList}
              itemCategoryList={itemCategoryList}
              itemBadgeList={itemBadgeList}
              refresh={item_no => {
                refresh?.(item_no);
              }}
            />
          )}
          {tab === "material" && !!detailData?.no && (
            <MaterialTab
              item_no={detailData.no}
              item_partner_no={detailData.item_partner_no}
              itemMaterialList={itemMaterialList}
              getItemMaterialList={getItemMaterialList}
            />
          )}
          {tab === "stock" && !!detailData?.no && (
            <StockTab item_no={detailData.no} />
          )}
          {tab === "detail" && !!detailData?.no && (
            <DetailTab
              item_no={detailData.no}
              item_partner_no={detailData.item_partner_no}
            />
          )}
          {tab === "review" && !!detailData?.no && (
            <ReviewTab item_no={detailData.no} />
          )}
          {tab === "dmb" && !!detailData?.no && !!isDmbTabVisible && (
            <DmbTab
              item_no={detailData.no}
              item_partner_no={detailData?.item_partner_no}
              dmb_list={dmbList}
              type_list={dmbItemTypeNoList}
              itemTypeList={itemTypeList.filter(
                item => !!item.no && !!dmbItemTypeNoList.includes(item.no),
              )}
            />
          )}
          {tab === "subscribeMember" && !!detailData?.no && (
            <SubscribeMemberTab item_no={detailData.no} />
          )}
        </>
      </ModalContent>
    </Modal>
  );
}
