import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {IMemberListResponse} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
}

export function MemberPointAddMultiModal({isVisible, close, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [point, setPoint] = useState(0);
  const [title, setTitle] = useState("");
  const [memberList, setMemberList] = useState<IMemberListResponse[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchMemberList, setSearchMemberList] = useState<
    IMemberListResponse[]
  >([]);

  function setClose({isRefresh = false}: {isRefresh?: boolean} = {}) {
    setPoint(0);
    setTitle("");
    close?.();
    if (isRefresh) {
      refresh?.();
    }
  }

  async function getMemberData() {
    try {
      if (!searchText) {
        return alert("검색어를 입력해주세요.");
      }
      const {data, success, message} = await adminAPI.member.getList({
        searchtxt: searchText,
      });
      if (success) {
        setSearchMemberList(data.result.list);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getMemberData error", error);
    }
  }

  async function save() {
    try {
      if (point === 0) {
        return alert("포인트금액을 입력해주세요.");
      }
      if (!title) {
        return alert("포인트 지급사유를 입력해주세요.");
      }
      const member_arr = [
        ...new Set(memberList.map(_item => _item.no).filter(utils.isDefined)),
      ];

      if (member_arr.length === 0) {
        return alert("회원을 선택해주세요.");
      }

      if (
        !confirm(
          `${member_arr.length}명의 회원에게 쿠폰을 지급하시겠습니까? 회원들에게 푸시가 발송됩니다.`,
        )
      ) {
        return;
      }

      const {success, message} = await adminAPI.member.addPointSaveMulti({
        member_arr,
        point,
        title,
      });
      setAlertModal({isVisible: true, title: message});
      if (success) {
        setClose({isRefresh: true});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      setAlertModal({isVisible: true, title: "오류발생"});
    }
  }

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="포인트지급" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">회원검색</div>
          <div className="col">
            <InputBox
              value={searchText}
              onValue={setSearchText}
              rightContent={
                <div style={{display: "flex"}} onClick={getMemberData}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              onEnter={getMemberData}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              {searchMemberList.map(_item => (
                <div
                  className={styles.badge}
                  key={_.uniqueId()}
                  onClick={() =>
                    setMemberList([
                      ...memberList.filter(_member => _member.no !== _item.no),
                      _item,
                    ])
                  }>
                  {_item.name}{" "}
                  {utils.formatPhoneNumber(_item.phone_number || "")}
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col title">지급받을회원목록</div>
          <div className="col">
            <div>
              {memberList.map(_item => (
                <div className={styles.badge} key={_.uniqueId()}>
                  {_item.name}{" "}
                  {utils.formatPhoneNumber(_item.phone_number || "")}{" "}
                  <span
                    className={styles.remove}
                    onClick={() =>
                      setMemberList([
                        ...memberList.filter(
                          _member => _member.no !== _item.no,
                        ),
                      ])
                    }>
                    X
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">포인트</div>
          <div className="col">
            <InputBox
              value={point}
              type="number"
              onValue={value => setPoint(Number(value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">타이틀</div>
          <div className="col">
            <InputBox
              value={title}
              onValue={setTitle}
              placeholder={"포인트 지급 사유를 입력해주세요."}
            />
          </div>
        </div>
        <div className="row">
          <div className={["col", "flex-end"].join(" ")}>
            포인트를 마이너스로 지급 시 차감됩니다.
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="지급하기" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
