import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  member_no: number;
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
}

export function CardAddModal({member_no, isVisible, close, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [cardNumber, setCardNumber] = useState("");
  const [validThru, setValidThru] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [password, setPassword] = useState("");

  function setClose() {
    setCardNumber("");
    setValidThru("");
    setBirthDay("");
    setPassword("");
    close?.();
  }

  async function addCard() {
    try {
      if (!cardNumber || !validThru || !birthDay || !password) {
        return setAlertModal({
          isVisible: true,
          title: "입력값을 확인해주세요",
        });
      }
      const {success, message} = await adminAPI.payment.addCardSave({
        member_no,
        cardNumber,
        validThru,
        birthDay,
        password,
      });
      setAlertModal({
        isVisible: true,
        title: message || "카드등록 오류",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("addCard error", error);
    }
  }

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="신규카드등록" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">카드번호(-생략가능)</div>
          <div className="col">
            <InputBox
              value={cardNumber}
              onValue={setCardNumber}
              placeholder="1234-1234-1234-1234"
              maxLength={20}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">유효기간(MM/YY)</div>
          <div className="col">
            <InputBox
              value={validThru}
              onValue={setValidThru}
              placeholder="12/31 (/ 문자 필수)"
              maxLength={5}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">생년월일(YY/MM/DD)</div>
          <div className="col">
            <InputBox
              value={birthDay}
              onValue={setBirthDay}
              placeholder="90/01/01 (/ 문자 생략 가능)"
              maxLength={8}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">카드비밀번호 앞 2자리</div>
          <div className="col">
            <InputBox
              value={password}
              onValue={setPassword}
              placeholder="00"
              maxLength={2}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="등록" onClick={addCard} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
