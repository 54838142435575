import {
  Button,
  Content,
  ContentLayout,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYNWithEmpty,
  IFrequencyList,
  IMemberResponse,
} from "@data";
import _ from "lodash";
import moment from "moment";
import {MemberDetailModal} from "pages/MemberPage/MemberDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {formatPhoneNumber} from "utils/utils";
import styles from "./style.module.scss";

export default function FrequencyPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [successYn, setSuccessYn] = useState<TypeYNWithEmpty>("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IFrequencyList[]>>();
  const [memberDetail, setMemberDetail] = useState<IMemberResponse>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.event.getFrequencyList({
        page,
        searchtxt: searchText,
        successYn,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getMemberDetail(no: number) {
    try {
      const {data, success, message} = await adminAPI.member.getDetail({
        member_no: no,
      });
      if (success) {
        setMemberDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function setConfirmDelivery({
    no,
    frequency_no,
    step_no,
    member_no,
  }: {
    no: number;
    frequency_no: number;
    step_no: number;
    member_no: number;
  }) {
    if (!confirm("발송완료처리 하시겠습니까?")) {
      return;
    }
    try {
      const {data, success, message} = await adminAPI.event.setDeliveryConfirm({
        no,
        frequency_no,
        step_no,
        member_no,
        confirm_yn: "Y",
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("setConfirmDelivery error", error);
    }
  }

  useEffect(() => {
    getList();
  }, [successYn]);

  return (
    <>
      <ContentLayout title="프리퀀시">
        <SearchFilter
          left={[
            <SelectBox
              key={0}
              value={successYn}
              placeholder="성공여부"
              list={
                [
                  {label: "전체", value: ""},
                  {label: "성공", value: "Y"},
                  {label: "실패", value: "N"},
                ] as {label: string; value: TypeYNWithEmpty}[]
              }
              singleSelect={value => setSuccessYn(value as TypeYNWithEmpty)}
            />,
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>회원정보</th>
                <th>누적금액</th>
                <th>리워드신청내역</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.member?.name} ({item.member?.nickname})<br />
                      {utils.formatPhoneNumber(item.member?.phone_number || "")}
                      <br />
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="상세"
                          onClick={() =>
                            !!item.member?.no &&
                            getMemberDetail(item.member?.no)
                          }
                          backgroundColor={styleUtils.COLOR.success}
                          fontColor={styleUtils.COLOR.white}
                          paddingVertical={1}
                          paddingHorizontal={6}
                          fontSize={12}
                        />
                      </div>
                    </td>
                    <td>
                      {Number(item.frequency_price || 0).toLocaleString()}
                    </td>
                    <td className="text-left">
                      {(item.reward || []).map(_reward => {
                        const reward = ((): JSX.Element => {
                          if (_reward.step?.reward_type === "delivery") {
                            return (
                              <div style={{paddingLeft: "12px"}}>
                                <div className="row top">
                                  <div className="col">
                                    <table className={styles.rewardTable}>
                                      <tr>
                                        <th>주소</th>
                                        <td>
                                          {_reward.road_address}{" "}
                                          {_reward.detail_address}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>우편번호</th>
                                        <td>{_reward.zonecode}</td>
                                      </tr>
                                      <tr>
                                        <th>수령인</th>
                                        <td>
                                          {_reward.sender_name} (
                                          {formatPhoneNumber(
                                            _reward.sender_phone || "",
                                          )}
                                          )
                                        </td>
                                      </tr>
                                      {_reward.entrance_yn === "Y" &&
                                        !!_reward.entrance_memo && (
                                          <tr>
                                            <th>공동현관</th>
                                            <td>{_reward.entrance_memo}</td>
                                          </tr>
                                        )}
                                      <tr>
                                        <th>메모</th>
                                        <td>{_reward.memo}</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div className="col">
                                    <div>
                                      {_reward.confirm_yn === "N" && (
                                        <Button
                                          text="발송처리"
                                          backgroundColor={
                                            styleUtils.COLOR.success
                                          }
                                          fontColor={styleUtils.COLOR.white}
                                          fontSize={12}
                                          onClick={() => {
                                            if (
                                              _reward.no &&
                                              _reward.frequency_no &&
                                              _reward.step_no &&
                                              _reward.member_no
                                            )
                                              setConfirmDelivery({
                                                no: _reward.no,
                                                frequency_no:
                                                  _reward.frequency_no,
                                                step_no: _reward.step_no,
                                                member_no: _reward.member_no,
                                              });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (_reward.step?.reward_type === "point") {
                            const point = (_reward.reward || []).reduce(
                              (acc, cur) => {
                                const cur_point = !isNaN(Number(cur.point || 0))
                                  ? Number(cur.point || 0)
                                  : 0;
                                return acc + cur_point;
                              },
                              0,
                            );
                            return <span>{point.toLocaleString()}원 지급</span>;
                          } else if (_reward.step?.reward_type === "item") {
                            // TODO
                          } else if (_reward.step?.reward_type === "coupon") {
                            // TODO
                          }
                          return <div></div>;
                        })();
                        return (
                          <div key={_.uniqueId()}>
                            {getFrequencyItem(_reward.step?.icon)} /{" "}
                            {moment(_reward.createdAt).format("YYYY-MM-DD")}{" "}
                            신청 / {reward}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <MemberDetailModal
        isVisible={!!memberDetail}
        close={() => setMemberDetail(undefined)}
        member={memberDetail}
      />
    </>
  );
}

function getFrequencyItem(icon?: string) {
  switch (icon) {
    case "coupon":
      return "쿠폰";
    case "gift":
      return "상품";
    case "bowl":
      return "보울";
    case "point":
      return "포인트";
  }
  return "";
}
