import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IPostRequest} from "@data";
import moment from "moment";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  post: IPostRequest;
  close?: () => void;
  refresh?: () => void;
}

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:mm";

export function PostOpenDateSettingModal({
  post,
  isVisible,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [openDate, setOpenDate] = useState(
    moment(post.open_date || moment()).format(DATE_FORMAT),
  );
  const [openTime, setOpenTime] = useState(
    moment(post.open_date || moment()).format(TIME_FORMAT),
  );

  function setClose() {
    close?.();
  }

  async function save() {
    try {
      const date = `${openDate} ${openTime}:00`;
      if (!post.no) {
        return;
      }
      if (!moment(date).isValid()) {
        return setAlertModal({
          isVisible: true,
          title: "날짜를 확인해주세요.",
        });
      }
      const {success, message} = await adminAPI.post.setOpenDate({
        no: post.no,
        open_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        refresh?.();
        setClose();
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  return (
    <>
      <Modal isVisible={isVisible} close={setClose}>
        <ModalTitle title="노출일자 수정" isClose close={setClose} />
        <ModalContent>
          <div className="row">
            <div className="col title">날짜</div>
            <div className="col">
              <InputBox
                value={openDate}
                type="date"
                onValue={value =>
                  setOpenDate(moment(value).format(DATE_FORMAT))
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">시간</div>
            <div className="col">
              <InputBox value={openTime} type="time" onValue={setOpenTime} />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div></div>
          <div>
            <Button
              text="저장"
              onClick={save}
              backgroundColor={styleUtils.COLOR.success}
              fontColor={styleUtils.COLOR.white}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
