import {
  Button,
  InputBox,
  ListTable,
  Modal,
  ModalContent,
  ModalTitle,
  RadioBox,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {styleUtils, utils} from "@utils";
import {
  ORDER_PAYMENT_TYPE,
  ORDER_STATUS,
  ORDER_STATUS_COLOR,
  ORDER_STATUS_TEXT_COLOR,
  TDBOrderStatus,
  IDBOrderDetail,
  IOrderDetail,
  TDeliveryEntranceType,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {adminAPI} from "utils";
import {OrderDeliveryChangeModal} from "./OrderDeliveryChangeModal";
import {OrderDetailChangeModal} from "./OrderDetailChangeModal";
import {OrderEstimateChangeModal} from "./OrderEstimateChangeModal";
import {OrderFinalPriceModal} from "./OrderFinalPriceModal";
import {OrderGiftModal} from "./OrderGiftModal";
import {OrderRefundModal} from "./OrderRefundModal";
import styles from "./style.module.scss";
import {defaultEntranceList} from "utils/constant";

interface IProps {
  data?: IOrderDetail;
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
}

const TRACKER_CARRIER_ID: {[key: string]: string} = {
  CJ: "kr.cjlogistics",
  롯데: "kr.lotte",
  한진택배: "kr.hanjin",
};

const initEntranceType = defaultEntranceList[0]?.entrance_type;

export function OrderDetailModal({data, isVisible, close, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [claimText, setClaimText] = useState<string>();
  const [claimImportant, setClaimImportant] = useState<string>("1");
  const [isEstimateDateChangeVisible, setIsEstimateDateChangeVisible] =
    useState(false);
  const [isOrderGiftVisible, setIsOrderGiftVisible] = useState(false);
  const [isRefundModal, setIsRefundModal] = useState(false);
  const [isOrderFinalPriceVisible, setIsOrderFinalPriceVisible] =
    useState(false);
  const [entranceType, setEntranceType] =
    useState<TDeliveryEntranceType>(initEntranceType);
  const [entranceMemo, setEntranceMemo] = useState("");
  const [excelMemo, setExcelMemo] = useState("");
  const [orderDetail, setOrderDetail] = useState<IDBOrderDetail>();
  const [isOrderDeliveryChangeModal, setIsOrderDeliveryChangeModal] =
    useState(false);

  const deliveryTypeBadge =
    data?.delivery_type === "새벽"
      ? styles.dawn
      : data?.delivery_type === "도서산간"
      ? styles.plus
      : "";

  const statusColor = data?.status ? ORDER_STATUS_COLOR[data.status] : "";
  const statusTextColor = data?.status
    ? ORDER_STATUS_TEXT_COLOR[data.status]
    : "";

  async function saveClaim() {
    try {
      if (!data || !data.no || !data.member_no) {
        return;
      }
      if (isNaN(Number(claimImportant)) || !claimText) {
        return setAlertModal({
          title: "클레임내용을 입력해주세요",
          isVisible: true,
        });
      }

      const {message} = await adminAPI.order.apiSaveOrderClaim({
        member_no: data.member_no,
        order_no: data.no,
        content: claimText,
        important: Number(claimImportant),
      });
      setAlertModal({title: message, isVisible: true});
    } catch (error) {
      console.error("saveClaim error", error);
    }
  }

  async function updateStatus({status}: {status: TDBOrderStatus}) {
    try {
      if (!data || !data.no) {
        return;
      }
      if (
        !confirm("주문상태를 변경하시겠습니까? 변경 후 취소가 불가능합니다.")
      ) {
        return;
      }
      const {success, message} = await adminAPI.order.apiUpdateOrderStatus({
        no: data.no,
        status,
      });
      setAlertModal({title: message, isVisible: true});
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("updateStatus error", error);
    }
  }

  async function cancelOrder({type}: {type: "cancel" | "refund"}) {
    try {
      if (!data || !data.no) {
        return;
      }
      if (
        !confirm(
          `주문${
            type === "cancel" ? "취소" : "환불"
          }를 하시겠습니까? 확인을 누르시면 즉시 결제가 취소됩니다.\n${
            type === "cancel"
              ? "재고가 복구됩니다!"
              : "재고가 복구되지 않습니다!"
          }`,
        )
      ) {
        return;
      }
      const {success, message} = await adminAPI.order.apiCancelOrder({
        order_no: data.no,
        type,
      });
      setAlertModal({title: message, isVisible: true});
      if (success) {
        refresh?.();
        setIsRefundModal(false);
      }
    } catch (error) {
      console.error("cancelOrder error", error);
    }
  }

  async function cancelOrderPoint(point: number) {
    try {
      if (!data || !data.no) {
        return;
      }
      if (
        !confirm(
          `${point.toLocaleString()}원을 적립금지급하고 주문을 취소하시겠습니까? 카드결제취소는 이뤄지지 않습니다.`,
        )
      ) {
        return;
      }
      const {success, message} = await adminAPI.order.apiCancelOrderPoint({
        order_no: data.no,
        point,
      });
      setAlertModal({title: message, isVisible: true});
      if (success) {
        refresh?.();
        setIsRefundModal(false);
      }
    } catch (error) {
      console.error("cancelOrderPoint error", error);
    }
  }

  async function saveEntranceMemo() {
    try {
      if (!data || !data.no) {
        return;
      }
      if (!confirm("공동현관비밀번호를 수정하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.order.updateOrderEntrance({
        order_no: data.no,
        entrance_type: entranceType,
        entrance_memo: entranceMemo,
      });
      setAlertModal({title: message, isVisible: true});
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("saveEntranceMemo error", error);
    }
  }

  async function saveExcelMemo() {
    try {
      if (!data || !data.no) {
        return;
      }
      if (!confirm("냉매제추가메모를 저장하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.order.updateOrderExcelMemo({
        order_no: data.no,
        excel_memo: excelMemo,
      });
      setAlertModal({title: message, isVisible: true});
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("saveExcelMemo error", error);
    }
  }

  async function deleteOrderGift({no, item_no}: {no: number; item_no: number}) {
    try {
      if (!data || !data.no) {
        return;
      }
      if (
        !confirm(
          "지급하신 사은품을 제거하시겠습니까? 재고 차감했던 사은품의 경우, 재고복구가 됩니다.",
        )
      ) {
        return;
      }
      const {success, message} = await adminAPI.order.deleteOrderGift({
        order_no: data.no,
        order_detail_no: no,
        item_no,
      });
      setAlertModal({title: message, isVisible: true});
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("deleteGift error", error);
    }
  }

  function openMemberOrderPopup() {
    if (data?.member_no) {
      window.open(
        `/pop/member-order?member_no=${data.member_no}`,
        "_blank",
        "height=800, width=600",
      );
    }
  }

  function openClaimPopup() {
    if (data?.member_no) {
      window.open(
        `/pop/member-claim?member_no=${data.member_no}`,
        "_blank",
        "height=800, width=600",
      );
    }
  }

  function openEstimateDateChange() {
    setIsEstimateDateChangeVisible(true);
  }

  function openOrderGiftModal() {
    setIsOrderGiftVisible(true);
  }

  function openOrderFinalPriceModal() {
    setIsOrderFinalPriceVisible(true);
  }

  function popupOpen({url}: {url: string}) {
    window.open(url, "_blank", "height=800, width=600");
  }

  async function addCartList() {
    try {
      if (!data?.member_no) {
        return;
      }
      if (
        !confirm("해당 주문의 상품목록을 고객님의 장바구니에 다시 넣겠습니까?")
      ) {
        return;
      }
      const item_list = (data?.detail || [])
        .filter(
          item =>
            item.cancel_yn === "N" &&
            item.refund_yn === "N" &&
            (item.origin_price || 0) > 0 &&
            item.item_no &&
            Number(item.count) > 0,
        )
        .map(item => ({
          item_no: item.item_no as number,
          count: item.count as number,
        }));

      const {message} = await adminAPI.member.addCartSave({
        member_no: data?.member_no,
        item_list,
        subscribe_yn: "N",
      });
      setAlertModal({title: message, isVisible: true});
    } catch (error) {
      console.error("addCartList error", error);
    }
  }

  useEffect(() => {
    const claim = data?.claim?.[0];
    setClaimText(claim?.content || "");
    setClaimImportant(String(claim?.important || "1"));
    setEntranceType(data?.entrance_type || initEntranceType);
    setEntranceMemo(data?.entrance_memo || "");
    setExcelMemo(data?.excel_memo || "");
  }, [data]);

  return (
    <>
      <Modal
        isVisible={isVisible}
        close={close}
        width={"90vw"}
        mobileWidth={"100vw"}
        height={"100vh"}
        positionTop={"0"}
        positionRight={"0"}
        borderRadius={0}
        animate={"right"}>
        <ModalTitle title="주문상세" close={close} isClose />
        <ModalContent>
          <div className="row">
            <div className={styles.modalCol}>
              <div className={styles.item}>
                <div className="row">
                  <div className={styles.title}>주문정보</div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    주문시간
                  </div>
                  <div className="col">
                    {moment(data?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                    <span
                      className={[styles.badge, deliveryTypeBadge].join(" ")}
                      style={{marginLeft: 6}}>
                      {data?.delivery_type}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    주문번호
                  </div>
                  <div className="col">{data?.order_number}</div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    출고일
                  </div>
                  <div className="col">
                    {moment(data?.estimate_output_date).format("YYYY-MM-DD")}
                    {moment(data?.estimate_output_date).diff(moment(), "days") >
                      0 && (
                      <>
                        {" "}
                        (D-
                        {moment(data?.estimate_output_date)
                          .startOf("day")
                          .diff(moment().startOf("day"), "days")}
                        )
                      </>
                    )}
                    {/** 주문상태가 주문완료 일때만 수정 가능 */}
                    {data?.status === "1" && (
                      <div style={{display: "inline-block"}}>
                        <Button
                          onClick={openEstimateDateChange}
                          text={"변경하기"}
                          fontSize={12}
                          marginHorizontal={8}
                          backgroundColor={styleUtils.COLOR.green}
                          fontColor={styleUtils.COLOR.white}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    도착예정일
                  </div>
                  <div className="col">
                    {moment(data?.estimate_delivery_date).format("YYYY-MM-DD")}
                    {moment(data?.estimate_delivery_date).diff(
                      moment(),
                      "days",
                    ) > 0 && (
                      <>
                        {" "}
                        (D-
                        {moment(data?.estimate_delivery_date)
                          .startOf("day")
                          .diff(moment().startOf("day"), "days")}
                        )
                      </>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    고객명
                  </div>
                  <div className="col">
                    {data?.member?.name} ({data?.member?.nickname})
                    <div style={{display: "inline-block"}}>
                      <Button
                        onClick={openMemberOrderPopup}
                        text={"주문내역"}
                        fontSize={12}
                        marginHorizontal={8}
                        backgroundColor={styleUtils.COLOR.green}
                        fontColor={styleUtils.COLOR.white}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    주문상태
                  </div>
                  <div className="col">
                    <span
                      className={styles.badge}
                      style={{
                        background: statusColor,
                        color: statusTextColor,
                      }}>
                      {data?.status ? ORDER_STATUS[data.status] : ""}
                    </span>
                  </div>
                </div>
                {!!data?.status && !["2", "7"].includes(data?.status) && (
                  <div className="row">
                    <div className={["col", styles.itemTitle].join(" ")}>
                      주문상태변경
                    </div>
                    <div className={["col", styles.colWrap].join(" ")}>
                      {(["3", "4", "5", "6", "2", "7"] as TDBOrderStatus[])
                        .filter(
                          value =>
                            !isNaN(Number(data?.status)) &&
                            Number(value) > Number(data?.status),
                        )
                        .map((value, index) => (
                          <div key={index}>
                            <Button
                              text={ORDER_STATUS[value]}
                              backgroundColor={ORDER_STATUS_COLOR[value]}
                              fontColor={ORDER_STATUS_TEXT_COLOR[value]}
                              fontSize={12}
                              marginHorizontal={2}
                              marginVertical={1}
                              onClick={() => {
                                switch (value) {
                                  case "2": {
                                    cancelOrder({type: "cancel"});
                                    break;
                                  }
                                  case "3":
                                  case "4":
                                  case "5":
                                  case "6": {
                                    updateStatus({status: value});
                                    break;
                                  }
                                  case "7": {
                                    setIsRefundModal(true);
                                    break;
                                  }
                                }
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {data?.status === "2" && (
                  <div className="row">
                    <div className={["col", styles.itemTitle].join(" ")}>
                      취소사유
                    </div>
                    <div className="text-13">
                      {data?.outReason?.map(item => item.title).join(", ")}
                      <br />
                      <span className="text-12">
                        (
                        {moment(data?.outReason?.[0]?.createdAt).format(
                          "YYYY-MM-DD HH:mm:ss",
                        )}
                        )
                      </span>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    배송주소
                  </div>
                  <div className="col">
                    <div style={{flex: 1}}>
                      {data?.road_address}
                      <br />({data?.jibun_address})
                      <br />
                      {data?.detail_address}
                    </div>
                    {data?.status === "1" && (
                      <div>
                        <Button
                          text="배송지변경"
                          fontSize={12}
                          backgroundColor={styleUtils.COLOR.lightGreenColor}
                          paddingVertical={3}
                          onClick={() => setIsOrderDeliveryChangeModal(true)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    공동현관
                  </div>
                  <div className="col">
                    <div>
                      <div>
                        <RadioBox
                          list={defaultEntranceList.map(item => ({
                            text: item.title,
                            value: item.entrance_type,
                          }))}
                          value={entranceType || ""}
                          setValue={value =>
                            setEntranceType(value as TDeliveryEntranceType)
                          }
                          containerStyle={{flexWrap: "wrap"}}
                        />
                      </div>
                      <div>
                        <InputBox
                          value={entranceMemo}
                          onValue={setEntranceMemo}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      backgroundColor={styleUtils.COLOR.lightGreenColor}
                      text="저장"
                      fontSize={12}
                      paddingVertical={3}
                      onClick={saveEntranceMemo}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    냉매제 추가 기입
                    <br />
                    (5번메모)
                  </div>
                  <div className="col">
                    <InputBox value={excelMemo} onValue={setExcelMemo} />
                  </div>
                  <div>
                    <Button
                      backgroundColor={styleUtils.COLOR.lightGreenColor}
                      text="저장"
                      fontSize={12}
                      paddingVertical={3}
                      onClick={saveExcelMemo}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    배송요청사항
                  </div>
                  <div className="col">{data?.memo}</div>
                </div>
                {(data?.delivery || []).length > 0 && (
                  <div className="row">
                    <div className={["col", styles.itemTitle].join(" ")}>
                      송장번호
                    </div>
                    <div className="col">
                      {(data?.delivery || []).map((item, i) => (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "space-between",
                          }}>
                          <span>
                            {item.courie_company} {item.courier_no}
                          </span>
                          {!!item.courie_company &&
                            Object.keys(TRACKER_CARRIER_ID).includes(
                              item.courie_company,
                            ) && (
                              <div>
                                <Button
                                  text="배송상태조회"
                                  fontSize={12}
                                  onClick={() =>
                                    popupOpen({
                                      url: `https://tracker.delivery/#/${
                                        TRACKER_CARRIER_ID[
                                          item.courie_company || ""
                                        ]
                                      }/${item.courier_no}`,
                                    })
                                  }
                                />
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    수령인
                  </div>
                  <div className="col">{data?.sender_name}</div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    연락처
                  </div>
                  <div className="col">
                    {utils.formatPhoneNumber(data?.sender_phone || "")}
                  </div>
                </div>
              </div>
              <div className={styles.item}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}>
                  <div className={styles.title}>결제정보</div>
                  <div>
                    <Button
                      text="최종금액수정"
                      onClick={openOrderFinalPriceModal}
                      fontSize={12}
                      backgroundColor={styleUtils.COLOR.lightGreenColor}
                    />
                  </div>
                </div>
                <div className="row">
                  <ListTable>
                    <tbody>
                      <tr>
                        <td>총상품금액</td>
                        <td className={["text-right"].join(" ")}>
                          {(data?.total_price || 0).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td>배송비</td>
                        <td className={["text-right"].join(" ")}>
                          {(data?.delivery_price || 0).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td>총할인금액</td>
                        <td className={["text-right"].join(" ")}>
                          <span className={styles.minus}>
                            {(data?.discount_price || 0).toLocaleString()}
                          </span>
                          <div style={{fontSize: 12}}>
                            {(data?.discount || [])
                              .sort((a, b) => (a.type === "coupon" ? -1 : 1))
                              .map((item, i) => (
                                <div key={i}>
                                  -{" "}
                                  {item.type === "point"
                                    ? "포인트사용"
                                    : "쿠폰할인"}{" "}
                                  {!!item.coupon && (
                                    <span>
                                      [{item.coupon.couponDetail?.coupon_num}]{" "}
                                      {item.coupon.couponDetail?.title}{" "}
                                    </span>
                                  )}
                                  {(item.price || 0).toLocaleString()}
                                </div>
                              ))}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>총주문금액</td>
                        <td className={["text-right"].join(" ")}>
                          {(data?.order_price || 0).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td>총결제금액</td>
                        <td className={["text-right"].join(" ")}>
                          <span className="text-bold">
                            {(data?.payment_price || 0).toLocaleString()}
                          </span>
                          <div style={{fontSize: 12}}>
                            {(data?.payment || []).map((item, i) => (
                              <div key={i} style={{marginTop: "6px"}}>
                                - {!!item.type && ORDER_PAYMENT_TYPE[item.type]}{" "}
                                {item.easypay_type
                                  ? `(${item.easypay_type}) `
                                  : ""}
                                | {item.card_name} |{" "}
                                {utils.formatCardNo(item.card_no || "")}
                                <br />
                                <span>[{item.pay_id}]</span>{" "}
                                <span
                                  className={[
                                    "text-bold",
                                    (item.payment_price || 0) < 0 &&
                                      styles.minus,
                                  ].join(" ")}>
                                  {(item.payment_price || 0).toLocaleString()}
                                </span>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>최종금액</td>
                        <td className={["text-right"].join(" ")}>
                          <span className="text-bold text-16">
                            {(data?.final_price || 0).toLocaleString()}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>적립예정포인트</td>
                        <td className={["text-right"].join(" ")}>
                          <span className="text-bold text-16">
                            {(
                              (((data?.final_price || 0) -
                                (data?.delivery_price || 0)) *
                                (data?.point_percent || 0)) /
                              100
                            ).toLocaleString()}
                          </span>
                          <span className="text-12">
                            ({data?.point_percent || 0}%)
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </ListTable>
                </div>
              </div>
            </div>
            <div className={styles.modalCol}>
              <div className={styles.item}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}>
                  <div className={styles.title}>
                    상세내역{" "}
                    {data?.member?.gift_apply_yn === "Y" && `(체험제품신청)`}
                  </div>
                  <div>
                    {data?.status === "1" && (
                      <div
                        style={{display: "inline-block", marginRight: "4px"}}>
                        <Button
                          text="증정품지급"
                          onClick={openOrderGiftModal}
                          fontSize={12}
                          backgroundColor={styleUtils.COLOR.lightGreenColor}
                        />
                      </div>
                    )}
                    <div style={{display: "inline-block"}}>
                      <Button
                        text="장바구니넣기"
                        onClick={addCartList}
                        fontSize={12}
                        backgroundColor={styleUtils.COLOR.baige}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <ListTable>
                    <thead>
                      <tr>
                        <th>업체명</th>
                        <th>상품명</th>
                        <th>개당가격</th>
                        <th>수량</th>
                        <th>총금액</th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize: 12}}>
                      {(data?.detail || []).map((item, i) => (
                        <tr key={i}>
                          <td>{item.product?.partners?.partner_name}</td>
                          <td>
                            {item.item_name}
                            <br />
                            <span>({item.product?.teamfresh_code})</span>
                          </td>
                          <td className={"text-right"}>
                            {(item.unit_price || 0).toLocaleString()}
                          </td>
                          <td className="row" style={{alignItems: "center"}}>
                            <div className="text-right" style={{flex: 1}}>
                              {(
                                (item.count || 0) *
                                (item.cancel_yn === "Y" ||
                                item.refund_yn === "Y"
                                  ? -1
                                  : 1)
                              ).toLocaleString()}
                            </div>
                            {data?.status === "1" && (
                              <Button
                                text="변경"
                                fontSize={10}
                                paddingVertical={2}
                                paddingHorizontal={2}
                                marginHorizontal={4}
                                onClick={() => setOrderDetail(item)}
                              />
                            )}
                          </td>
                          <td className={"text-right"}>
                            {(item.total_price || 0) > 0 ? (
                              (
                                (item.total_price || 0) *
                                (item.cancel_yn === "Y" ||
                                item.refund_yn === "Y"
                                  ? -1
                                  : 1)
                              ).toLocaleString()
                            ) : (
                              <div>
                                <span>
                                  사은품
                                  {item.stock_yn === "N" && "(재고미차감)"}
                                </span>
                                {item.cancel_yn === "N" &&
                                  item.refund_yn === "N" && (
                                    <div
                                      style={{
                                        display: "inline-block",
                                        marginLeft: "4px",
                                      }}>
                                      <Button
                                        fontSize={10}
                                        text="제거"
                                        paddingVertical={1}
                                        paddingHorizontal={2}
                                        backgroundColor={
                                          styleUtils.COLOR.warning
                                        }
                                        onClick={() => {
                                          if (item.no && item.item_no) {
                                            deleteOrderGift({
                                              no: item.no,
                                              item_no: item.item_no,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr className="text-bold">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className={"text-right"}>
                          {(data?.detail || [])
                            .reduce(
                              (acc, cur) =>
                                acc +
                                (cur.count || 0) *
                                  (cur.cancel_yn === "Y" ||
                                  cur.refund_yn === "Y"
                                    ? -1
                                    : 1),
                              0,
                            )
                            .toLocaleString()}
                        </td>
                        <td className={"text-right"}>
                          {(data?.detail || [])
                            .reduce(
                              (acc, cur) =>
                                acc +
                                (cur.total_price || 0) *
                                  (cur.cancel_yn === "Y" ||
                                  cur.refund_yn === "Y"
                                    ? -1
                                    : 1),
                              0,
                            )
                            .toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </ListTable>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.title}>아이알러지</div>
                <div className="row">
                  <ListTable>
                    <thead>
                      <tr>
                        <th>아이명</th>
                        <th>생일</th>
                        <th>몸무게</th>
                        <th>아이종류</th>
                        <th>질병</th>
                        <th>알러지</th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize: 12}}>
                      {(data?.pet || []).map((item, i) => (
                        <tr key={i}>
                          <td>{item.name}</td>
                          <td>
                            {moment(item.birthday).isValid() &&
                              moment(item.birthday).format("YYYY-MM-DD")}
                          </td>
                          <td>{item.weight || 0} kg</td>
                          <td>
                            ({item.kind?.kind_name}) {item.type?.type_name}
                          </td>
                          <td className="text-left">
                            <div
                              className={["break-spaces"].join(", ")}
                              style={{maxWidth: "200px"}}>
                              {(item.diseaseList || []).map((disease, j) => (
                                <span
                                  className={styles.badge}
                                  style={{margin: "1px"}}
                                  key={j}>
                                  {disease?.disease?.title}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td className="text-left">
                            <div
                              className={["break-spaces"].join(", ")}
                              style={{maxWidth: "200px"}}>
                              {(item.allergyList || []).map((allergy, j) => (
                                <span
                                  className={styles.badge}
                                  style={{margin: "1px"}}
                                  key={j}>
                                  {allergy?.allergy?.title}
                                </span>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ListTable>
                </div>
              </div>
              <div className={styles.item}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                  }}>
                  <div className={styles.title}>클레임</div>
                  <div>
                    <Button
                      text="조회"
                      onClick={openClaimPopup}
                      fontSize={12}
                      backgroundColor={styleUtils.COLOR.lightGreenColor}
                    />
                  </div>
                </div>
                <div className="row">
                  <TextAreaBox
                    value={claimText}
                    onValue={setClaimText}
                    height={150}
                  />
                </div>
                <div className="row">
                  <div style={{fontSize: "14px", marginRight: "16px"}}>
                    중요도
                  </div>
                  <div className="col">
                    <RadioBox
                      list={[
                        {value: "1", text: "높음"},
                        {value: "2", text: "중간"},
                        {value: "3", text: "낮음"},
                      ]}
                      setValue={setClaimImportant}
                      value={claimImportant}
                    />
                  </div>
                  <div>
                    <div>
                      <Button
                        text="저장"
                        onClick={saveClaim}
                        fontSize={12}
                        backgroundColor={styleUtils.COLOR.lightGreenColor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
      {!!data?.no && (
        <OrderGiftModal
          isVisible={isOrderGiftVisible}
          order_no={data.no}
          close={() => setIsOrderGiftVisible(false)}
          refresh={refresh}
        />
      )}
      {!!data?.no && !!data.estimate_output_date && (
        <OrderEstimateChangeModal
          isVisible={isEstimateDateChangeVisible}
          close={() => setIsEstimateDateChangeVisible(false)}
          order_no={data.no}
          estimate_output_date={data.estimate_output_date}
          refresh={refresh}
        />
      )}
      <OrderRefundModal
        isVisible={isRefundModal}
        close={() => setIsRefundModal(false)}
        cardCancle={() => cancelOrder({type: "refund"})}
        pointCancle={cancelOrderPoint}
      />
      {!!data?.no && (
        <OrderFinalPriceModal
          isVisible={isOrderFinalPriceVisible}
          order_no={data.no}
          payment_price={data?.payment_price || 0}
          final_price={data?.final_price || 0}
          close={() => setIsOrderFinalPriceVisible(false)}
          refresh={refresh}
        />
      )}
      {!!orderDetail && (
        <OrderDetailChangeModal
          isVisible={!!orderDetail}
          orderDetail={orderDetail}
          close={() => setOrderDetail(undefined)}
          refresh={() => {
            setOrderDetail(undefined);
            refresh?.();
          }}
        />
      )}
      {!!isOrderDeliveryChangeModal && !!data?.member_no && !!data?.no && (
        <OrderDeliveryChangeModal
          isVisible={!!isOrderDeliveryChangeModal}
          member_no={data?.member_no}
          order_no={data?.no}
          prev_delivery_address_no={data?.delivery_address_no}
          delivery_type={data.delivery_type}
          close={() => setIsOrderDeliveryChangeModal(false)}
          refresh={() => {
            setIsOrderDeliveryChangeModal(false);
            refresh?.();
          }}
        />
      )}
    </>
  );
}
