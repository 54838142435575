import {
  Button,
  CheckBox,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {IProductWithStock} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  order_no: number;
  close?: () => void;
  refresh?: () => void;
}

export function OrderGiftModal({isVisible, order_no, close, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [itemList, setItemList] = useState<IProductWithStock[]>([]);
  const [itemNo, setItemNo] = useState<number>();
  const [count, setCount] = useState(1);
  const [isStock, setIsStock] = useState(true);

  async function save() {
    try {
      if (!itemNo) {
        return alert("상품을 선택해주세요");
      }

      if (!confirm("상품을 무료로 증정하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.order.apiCreateOrderGift({
        order_no,
        item_no: itemNo,
        count,
        isStock,
      });
      setAlertModal({isVisible: true, title: message});
      if (success) {
        close?.();
        refresh?.();
      }
    } catch (error) {
      setAlertModal({isVisible: true, title: "오류발생"});
    }
  }

  async function getItemList() {
    try {
      const {data, success, message} = await adminAPI.item.getItemWithStock({});
      if (success) {
        setItemList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  useEffect(() => {
    setItemNo(undefined);
    setCount(1);
    setIsStock(true);
  }, [isVisible]);

  useEffect(() => {
    getItemList();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={close}>
      <ModalTitle title="증정품등록" isClose close={close} />
      <ModalContent>
        <div className="row">
          <div className="col title">상품선택</div>
          <div className="col">
            <SelectBox
              list={[
                {item_name: "상품선택"} as IProductWithStock,
                ...itemList,
              ].map(item => ({
                value: String(item.no),
                label: `${item.view_yn === "N" ? "[미노출상품]" : ""}${
                  item.partners?.partner_name
                    ? `[${item.partners.partner_name}] `
                    : ""
                }${item.item_name} ${
                  !!item.stock || !!item.stock_date
                    ? `(재고: ${item.stock}${
                        item.stock_date
                          ? ` / 입고예정 : ${moment(item.stock_date).format(
                              "MM/DD",
                            )}`
                          : ""
                      })`
                    : ""
                }`,
              }))}
              value={String(itemNo || "")}
              singleSelect={value => {
                setItemNo(value ? Number(value) : undefined);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">수량</div>
          <div className="col">
            <InputBox
              value={count}
              type="number"
              onValue={value => setCount(Number(value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">재고차감</div>
          <div className="col">
            <div>
              <CheckBox
                checked={isStock}
                text={isStock ? "차감" : "안함"}
                isToggle
                onChange={e => setIsStock(e.target.checked)}
              />
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
