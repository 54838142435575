import React, {InputHTMLAttributes, useRef} from "react";
import {adminAPI, axiosUtil, styleUtils} from "@utils";
import Button from ".";
import styles from "./style.module.scss";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  height?: number;
  text?: string;
  fileUpload?: ({
    files,
    thumbList,
  }: {
    files: string[];
    thumbList?: string[];
  }) => void;
  callback?: (data?: any) => void;
  uploadUrl?: string;
  type?: "image" | "excel";
  backgroundColor?: styleUtils.COLOR;
  fontColor?: styleUtils.COLOR;
  isThumbnail?: boolean;
  isMessageModal?: boolean;
}

export default function FileUploadButton({
  text,
  fileUpload,
  callback,
  uploadUrl,
  height,
  type = "image",
  backgroundColor,
  fontColor,
  isThumbnail = false,
  isMessageModal = true,
  ...rest
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const setLoading = useSetRecoilState(states.loadingState);
  const ref = useRef<HTMLInputElement>(null);

  function fileSelect() {
    if (ref.current) {
      ref.current.files = null;
    }
    ref.current?.click();
  }

  async function uploadFile(event: InputHTMLAttributes<HTMLInputElement>) {
    try {
      if (!event) {
        throw Error("event is null");
      }
      if (!uploadUrl) {
        throw Error("uploadUrl is null");
      }

      const files = ref.current?.files;
      if (!files || files.length === 0) {
        throw Error("files is null");
      }

      setLoading(true);
      let callBackData: any;
      if (type === "image") {
        const imageList = await axiosUtil.imageUpload({
          files,
          endPoint: uploadUrl,
        });

        if (!imageList || imageList?.length === 0) {
          throw Error("사진업로드 실패");
        }
        const {data: thumbList = []} = !isThumbnail
          ? {data: [] as string[]}
          : await adminAPI.utils.uploadImgThumb({url_list: imageList});

        fileUpload?.({files: imageList, thumbList});
      } else if (type === "excel") {
        const {data, message} = await axiosUtil.fileUpload({
          files,
          endPoint: uploadUrl,
        });
        if (isMessageModal) {
          setAlertModal({isVisible: true, title: message});
        }
        callBackData = data;
      }
      ref.current.files = null;
      ref.current.value = "";
      callback?.(callBackData);
      setLoading(false);
    } catch (error) {
      console.log("FileUploadButton uploadFile error", error);
      setLoading(false);
    }
  }

  return (
    <div className={styles.inputLabel} onClick={fileSelect}>
      <Button
        text={text}
        fontSize={12}
        fontWeight={500}
        height={height}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
      />
      <input
        {...rest}
        ref={ref}
        type="file"
        onChange={uploadFile}
        accept={
          type === "image"
            ? "image/*"
            : type === "excel"
            ? "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "*"
        }
      />
    </div>
  );
}
