import {
  Button,
  CheckBox,
  InputBox,
  ListTable,
  Modal,
  ModalContent,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IDBItemsOption} from "@data";
import _ from "lodash";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  item_no: number;
  optionList?: IDBItemsOption[];
  close?: () => void;
  refresh?: () => void;
}

export function OptionListModal({
  isVisible,
  item_no,
  optionList,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [optionName, setOptionName] = useState("");

  function setClose() {
    close?.();
    setOptionName("");
  }

  async function save() {
    try {
      if (!optionName) {
        return setAlertModal({
          isVisible: true,
          title: "옵션명을 입력해주세요.",
        });
      }

      const {success, message} = await adminAPI.item.createItemOption({
        item_no,
        option_name: optionName,
      });
      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function deleteOption({no}: {no: number}) {
    try {
      if (!confirm("옵션을 삭제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.item.deleteItemOption({
        item_no,
        no,
      });
      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  return (
    <>
      <Modal isVisible={isVisible} width="500px" close={setClose}>
        <ModalTitle close={setClose} isClose title="샘플팩관리" />
        <ModalContent>
          <div className="row">
            <div className="col">
              <InputBox
                value={optionName}
                onValue={setOptionName}
                rightContent={<Button text="추가" onClick={save} />}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ListTable>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>옵션명</th>
                    <th>삭제</th>
                  </tr>
                </thead>
                <tbody>
                  {(optionList || []).map(item => (
                    <tr key={_.uniqueId()}>
                      <td>{item.no}</td>
                      <td>{item.option_name}</td>
                      <td>
                        <div>
                          <Button
                            backgroundColor={styleUtils.COLOR.danger}
                            fontColor={styleUtils.COLOR.white}
                            text="삭제"
                            onClick={() => {
                              if (item.no) {
                                deleteOption({no: item.no});
                              }
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ListTable>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
