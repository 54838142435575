import {
  Button,
  Content,
  ContentLayout,
  InputBox,
  SearchFilter,
  SelectBox,
} from "@components";
import {IReOrderCountResponse, TOrderListDateType} from "@data";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "./style.module.scss";
import {adminAPI} from "@utils";

const DATE_FORMAT = "YYYY-MM-DD";

export default function PartnerPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [startDate, setStartDate] = useState(
    moment().add(-7, "day").format(DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(moment().format(DATE_FORMAT));
  const [data, setData] = useState<IReOrderCountResponse>({
    all_count: 0,
    result: [],
  });

  async function getData() {
    try {
      const {data, success, message} = await adminAPI.order.getReOrderCount({
        start_date: startDate,
        end_date: endDate,
      });
      if (success) {
        setData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  return (
    <>
      <ContentLayout title="일자별 재구매현황">
        <SearchFilter
          left={[
            <div key={0} className={styles.row} style={{marginBottom: 0}}>
              <div className={styles.filterTitle}>주문검색날짜</div>
              <div className={styles.col}>
                <div className={styles.row}>
                  <div className="col">
                    <InputBox
                      type={"date"}
                      placeholder="시작일"
                      value={startDate}
                      onValue={setStartDate}
                      onEnter={getData}
                    />
                  </div>
                  <div className="col">~</div>
                  <div className="col">
                    <InputBox
                      type={"date"}
                      placeholder="종료일"
                      value={endDate}
                      onValue={setEndDate}
                      onEnter={getData}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.col}>
                <div className={[styles.row, styles.dateBtn].join(" ")}>
                  <div className="col">
                    <Button
                      text="오늘"
                      fontSize={12}
                      marginHorizontal={2}
                      onClick={() => {
                        setStartDate(moment().format(DATE_FORMAT));
                        setEndDate(moment().format(DATE_FORMAT));
                      }}
                    />
                  </div>
                  <div className="col">
                    <Button
                      text="2일"
                      fontSize={12}
                      marginHorizontal={2}
                      onClick={() => {
                        setStartDate(
                          moment().add(-1, "days").format(DATE_FORMAT),
                        );
                        setEndDate(moment().format(DATE_FORMAT));
                      }}
                    />
                  </div>
                  <div className="col">
                    <Button
                      text="7일"
                      fontSize={12}
                      marginHorizontal={2}
                      onClick={() => {
                        setStartDate(
                          moment().add(-7, "days").format(DATE_FORMAT),
                        );
                        setEndDate(moment().format(DATE_FORMAT));
                      }}
                    />
                  </div>
                  <div className="col">
                    <Button
                      text="한달"
                      fontSize={12}
                      marginHorizontal={2}
                      onClick={() => {
                        setStartDate(
                          moment().add(-1, "month").format(DATE_FORMAT),
                        );
                        setEndDate(moment().format(DATE_FORMAT));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>,
          ]}
        />
        <Content>
          <div>
            <div>
              <div className={["row", styles.topSection].join(" ")}>
                <div className={[styles.col, styles.item].join(" ")}>
                  <div>구매회원</div>
                  <div>{(data.all_member_count || 0).toLocaleString()}</div>
                </div>
                <div className={[styles.col, styles.item].join(" ")}>
                  <div>기간내 재구매회원</div>
                  <div>{(data.all_count || 0).toLocaleString()}</div>
                </div>
                {/* <div className={[styles.col, styles.desc].join(" ")}>
                  *{" "}
                  {data.start_date ? moment(data.start_date).format("M/D") : ""}{" "}
                  이전 구매횟수
                </div> */}
              </div>
            </div>
            <div>
              <div className="row">
                {[...new Array(data.max_count || 0)].map((i, index) => {
                  const order_cnt = index + 1;
                  const item = (data.result || []).find(
                    _item => _item.order_cnt === order_cnt,
                  );
                  return (
                    <div
                      key={index}
                      className={[styles.col, styles.item].join(" ")}>
                      <div>
                        {Number(order_cnt || 0).toLocaleString()}회 구매자
                      </div>
                      <div>{(item?.cnt || 0).toLocaleString()}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Content>
      </ContentLayout>
    </>
  );
}
