import {Button, ListTable, Modal, ModalContent, ModalTitle} from "@components";
import {
  faArrowCircleDown,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {TUpDown, IPostDetail} from "@data";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";
import {PostDetailModal} from "./PostDetailModal";

interface IProps {
  isVisible: boolean;
  data?: IPostDetail[];
  post_no: number;
  close?: () => void;
  refresh?: () => void;
}

export function PostDetailListModal({
  data,
  post_no,
  isVisible,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [isDetailModal, setIsDetailModal] = useState(false);
  const [postDetail, setPostDetail] = useState<IPostDetail>();

  function setClose() {
    close?.();
    setIsDetailModal(false);
  }

  async function getPostDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.post.getPostDetail({
        post_no,
        no,
      });
      if (success) {
        setPostDetail(data);
        setIsDetailModal(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPostDetail error", error);
    }
  }

  async function deletePostDetail({no}: {no: number}) {
    try {
      if (!confirm("삭제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.post.deletePostDetail({
        no,
        post_no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("deletePostDetail error", error);
    }
  }

  async function changePostDetailOrdering({
    no,
    type,
  }: {
    no: number;
    type: TUpDown;
  }) {
    try {
      const {success, message} = await adminAPI.post.changePostDetailOrdering({
        no,
        post_no,
        type,
      });
      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changePostDetailOrdering error", error);
    }
  }

  return (
    <>
      <Modal isVisible={isVisible} width={"700px"} close={setClose}>
        <ModalTitle title="홈섹션상세" isClose close={setClose} />
        <ModalContent>
          <div>
            <div className="row flex-end">
              <div>
                <Button
                  text="추가"
                  onClick={() => setIsDetailModal(true)}
                  backgroundColor={styleUtils.COLOR.success}
                  fontColor={styleUtils.COLOR.white}
                />
              </div>
            </div>
            <ListTable>
              <thead>
                <tr>
                  <th>타이틀</th>
                  <th>내용</th>
                  <th>순번</th>
                  <th>수정</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {(data || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item.title}</td>
                    <td>
                      <div
                        className="text-ellipsis"
                        style={{maxWidth: "200px"}}>
                        {item.content}
                      </div>
                    </td>
                    <td>
                      <div className="row" style={{minWidth: "50px"}}>
                        <div className="col">
                          {(item.ordering || 0) > 1 && (
                            <FontAwesomeIcon
                              icon={faArrowCircleUp}
                              onClick={() => {
                                if (item.no) {
                                  changePostDetailOrdering({
                                    no: item.no,
                                    type: "up",
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className="col">{item.ordering || "-"}</div>
                        <div className="col">
                          <FontAwesomeIcon
                            icon={faArrowCircleDown}
                            onClick={() => {
                              if (item.no) {
                                changePostDetailOrdering({
                                  no: item.no,
                                  type: "down",
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td onClick={e => utils.handleClickBubble(e)}>
                      <div>
                        <Button
                          text="수정"
                          backgroundColor={styleUtils.COLOR.warning}
                          onClick={() => {
                            if (item.no) {
                              getPostDetail({
                                no: item.no,
                              });
                            }
                          }}
                        />
                      </div>
                    </td>
                    <td onClick={e => utils.handleClickBubble(e)}>
                      <div>
                        <Button
                          text="삭제"
                          backgroundColor={styleUtils.COLOR.danger}
                          fontColor={styleUtils.COLOR.white}
                          onClick={() => {
                            if (item.no) {
                              deletePostDetail({
                                no: item.no,
                              });
                            }
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ListTable>
          </div>
        </ModalContent>
      </Modal>
      {!!isDetailModal && (
        <PostDetailModal
          isVisible={isDetailModal}
          data={postDetail}
          post_no={post_no}
          close={() => {
            setIsDetailModal(false);
            setPostDetail(undefined);
          }}
          refresh={refresh}
        />
      )}
    </>
  );
}
