import {IButtonProps} from "@data";
import React from "react";
import styles from "./style.module.scss";

export default function Button({
  isMainBtn,
  onClick,
  text,
  icon,
  height,
  fontSize = 16,
  fontWeight = 400,
  backgroundColor,
  fontColor,
  borderRadius = 4,
  marginHorizontal,
  marginVertical,
  paddingHorizontal,
  paddingVertical,
}: IButtonProps) {
  return (
    <div
      onClick={onClick}
      className={[
        styles.button,
        ...[isMainBtn ? styles.greenBtn : undefined],
      ].join(" ")}
      style={{
        ...(height ? {height: `${height}px`} : undefined),
        fontSize: `${fontSize}px`,
        fontWeight: `${fontWeight}`,
        borderRadius: `${borderRadius}px`,
        ...(backgroundColor ? {backgroundColor} : undefined),
        ...(fontColor ? {color: fontColor} : undefined),
        ...(marginHorizontal
          ? {
              marginLeft: `${marginHorizontal}px`,
              marginRight: `${marginHorizontal}px`,
            }
          : undefined),
        ...(marginVertical
          ? {
              marginTop: `${marginVertical}px`,
              marginBottom: `${marginVertical}px`,
            }
          : undefined),
        ...(paddingVertical
          ? {
              paddingTop: `${paddingVertical}px`,
              paddingBottom: `${paddingVertical}px`,
            }
          : undefined),
        ...(paddingHorizontal
          ? {
              paddingLeft: `${paddingHorizontal}px`,
              paddingRight: `${paddingHorizontal}px`,
            }
          : undefined),
      }}>
      {!!text && <span>{text}</span>}
      {icon}
    </div>
  );
}
