import React from "react";
import styles from "./style.module.scss";

interface IProps {
  children: JSX.Element;
}

export function PopupLayout({children}: IProps) {
  return <div className={styles.popupContainer}>{children}</div>;
}
