import {faUser} from "@fortawesome/free-regular-svg-icons";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown} from "@components";
import {useLogin} from "@hook";
import React from "react";
import styles from "./style.module.scss";
import {useRecoilValue} from "recoil";
import {states} from "@recoils";

interface IProps {
  isOpen: boolean;
  toggleMenu?: () => void;
}

export function Header({isOpen, toggleMenu}: IProps) {
  const {logout} = useLogin();
  const adminData = useRecoilValue(states.adminDataState);

  return (
    <div
      className={[styles.header, isOpen ? styles.open : styles.close].join(
        " ",
      )}>
      <div className={styles.headerLeft}>
        <FontAwesomeIcon
          icon={faBars}
          className={styles.menuToggle}
          onClick={toggleMenu}
        />
      </div>
      <div className={styles.headerRight}>
        <Dropdown
          content={
            <>
              <FontAwesomeIcon icon={faUser} className={styles.icon} />
              <span className={styles.title}>
                {adminData?.nickname || adminData?.name || "관리자"}님
              </span>
            </>
          }
          options={[{label: "로그아웃", value: "logout", onClick: logout}]}
        />
      </div>
    </div>
  );
}
