import {Modal, ModalContent, ModalTitle} from "@components";
import {IDeeplinkType, IMarketingLogDetailResponse} from "@data";
import moment from "moment";
import React from "react";
import {convertMarketingSelect} from ".";
import {constant} from "@utils";

interface IProps {
  isVisible?: boolean;
  data: IMarketingLogDetailResponse;
  close?: () => void;
  deeplinkArr?: IDeeplinkType[];
}

export function MarketingDetailModal({
  isVisible,
  data,
  close,
  deeplinkArr,
}: IProps) {
  const filter_json = data.filter_json || {};
  const deeplink = (deeplinkArr || []).find(item => item.value === data.type);

  return (
    <Modal isVisible={isVisible} close={close} width={"500px"}>
      <ModalTitle title="상세보기" isClose close={close} />
      <ModalContent>
        <>
          {!!filter_json.test && (
            <div className="row">
              <div className="col title">테스트발송</div>
            </div>
          )}
          {(!!filter_json.createdAt_sdate || !!filter_json.createdAt_edate) && (
            <div className="row">
              <div className="col title">가입일자</div>
              <div className="col">
                {!!filter_json.createdAt_sdate &&
                  moment(filter_json.createdAt_sdate).isValid() &&
                  moment(filter_json.createdAt_sdate).format("YYYY-MM-DD")}
                ~
                {!!filter_json.createdAt_edate &&
                  moment(filter_json.createdAt_edate).isValid() &&
                  moment(filter_json.createdAt_edate).format("YYYY-MM-DD")}
              </div>
            </div>
          )}
          {(!!filter_json.order_sdate || !!filter_json.order_edate) && (
            <div className="row">
              <div className="col title">구매(주문)일자</div>
              <div className="col">
                {!!filter_json.order_sdate &&
                  moment(filter_json.order_sdate).isValid() &&
                  moment(filter_json.order_sdate).format("YYYY-MM-DD")}
                ~
                {!!filter_json.order_edate &&
                  moment(filter_json.order_edate).isValid() &&
                  moment(filter_json.order_edate).format("YYYY-MM-DD")}
              </div>
            </div>
          )}
          {(!!filter_json.last_order_sdate ||
            !!filter_json.last_order_edate) && (
            <div className="row">
              <div className="col title">마지막 구매(주문)일자</div>
              <div className="col">
                {!!filter_json.last_order_sdate &&
                  moment(filter_json.last_order_sdate).format("YYYY-MM-DD")}
                ~
                {!!filter_json.last_order_edate &&
                  moment(filter_json.last_order_edate).format("YYYY-MM-DD")}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col title">구매횟수</div>
            <div className="col">
              {filter_json.partner_name} {filter_json.item_name}{" "}
              {(filter_json.orderCnt || 0).toLocaleString()}건{" "}
              {!!filter_json.orderCnt_select &&
                convertMarketingSelect[filter_json.orderCnt_select]}
            </div>
          </div>
          <div className="row">
            <div className="col title">주문건</div>
            <div className="col">
              {(filter_json.orderAllCnt || 0).toLocaleString()}건{" "}
              {!!filter_json.orderAllCnt_select &&
                convertMarketingSelect[filter_json.orderAllCnt_select]}
            </div>
          </div>
          {!!filter_json.use_coupon_no && !!filter_json.coupon_data && (
            <div className="row">
              <div className="col title">특정쿠폰 사용자</div>
              <div className="col">
                [{filter_json.coupon_data.coupon_num}]{" "}
                {filter_json.coupon_data.title}
              </div>
            </div>
          )}
          {(filter_json.use_not_coupon_no || []).length > 0 &&
            (filter_json.use_not_coupon_data || []).length > 0 && (
              <div className="row">
                <div className="col title">특정쿠폰 미발행자 사용자</div>
                <div className="col">
                  <div>
                    {(filter_json.use_not_coupon_data || []).map((item, i) => (
                      <div key={i}>
                        [{item.coupon_num}] {item.title}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          {!!filter_json.expire_coupon_no &&
            !!filter_json.expire_coupon_data && (
              <div className="row">
                <div className="col title">특정쿠폰 오늘만료</div>
                <div className="col">
                  [{filter_json.expire_coupon_data.coupon_num}]{" "}
                  {filter_json.expire_coupon_data.title}
                </div>
              </div>
            )}
          {!!filter_json.cart_yn && (
            <div className="row">
              <div className="col title">장바구니상품</div>
              <div className="col">
                {filter_json.cart_yn === "Y" ? "있음" : "없음"}
              </div>
            </div>
          )}
          {!!filter_json.kind_name && (
            <div className="row">
              <div className="col title">아이종류</div>
              <div className="col">{filter_json.kind_name}</div>
            </div>
          )}
          {(filter_json.delivery_type || []).length > 0 && (
            <div className="row">
              <div className="col title">기본배송지구분</div>
              <div className="col">
                {(filter_json.delivery_type || []).map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </div>
            </div>
          )}
          {!!filter_json.subscription_yn && (
            <div className="row">
              <div className="col title">정기구독</div>
              <div className="col">
                {filter_json.subscription_yn === "Y"
                  ? "정기구독중"
                  : filter_json.subscription_yn === "N"
                  ? "구독안함"
                  : "선택안함"}
              </div>
            </div>
          )}
          {(filter_json.gradeList || []).length > 0 && (
            <div className="row">
              <div className="col title">등급</div>
              <div className="col">
                {(filter_json.gradeList || [])
                  .map(item => {
                    const grade_name = constant.GRADE_LIST.find(
                      _item => Number(_item.value) === Number(item),
                    )?.label;

                    return grade_name;
                  })
                  .join(", ")}
              </div>
            </div>
          )}
          {!!filter_json.push_yn && (
            <div className="row">
              <div className="col title">앱알림</div>
              <div className="col">
                {filter_json.push_yn === "Y"
                  ? "수신동의"
                  : filter_json.push_yn === "N"
                  ? "수신거부"
                  : "전체"}
              </div>
            </div>
          )}
          {!!filter_json.include_nonMember && (
            <div className="row">
              <div className="col title">비회원</div>
              <div className="col">
                {filter_json.include_nonMember === "Y"
                  ? "발송"
                  : filter_json.include_nonMember === "N"
                  ? "미발송"
                  : "선택안함"}
              </div>
            </div>
          )}

          <hr />

          {!!data.type && (
            <div className="row">
              <div className="col title">딥링크</div>
              <div className="col">
                {deeplink?.label}
                {!!data.target_title && ` [${data.target_title}]`}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col title">타이틀</div>
            <div className="col">{data.title}</div>
          </div>
          <div className="row">
            <div className="col title">내용</div>
            <div className="col preline">{data.content}</div>
          </div>

          <hr />

          {!!data.coupon_no && (
            <>
              <div className="row">
                <div className="col title">쿠폰발행</div>
                <div className="col">
                  [{data.coupon?.coupon_num}] {data.coupon?.title}
                </div>
              </div>
              <div className="row">
                <div className="col title">만료일</div>
                <div className="col">
                  {moment(data.coupon_limit_date).format("YYYY-MM-DD HH:mm:ss")}{" "}
                  (
                  {data.coupon_immediate_yn === "Y"
                    ? "즉시발행"
                    : "푸시클릭 시 발행"}
                  )
                </div>
              </div>
              {data.coupon_immediate_yn === "N" && (
                <>
                  <div className="row">
                    <div className="col title">팝업타이틀</div>
                    <div className="col">{data.popup_title}</div>
                  </div>
                  <div className="row">
                    <div className="col title">팝업내용</div>
                    <div className="col">{data.popup_content}</div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      </ModalContent>
    </Modal>
  );
}
