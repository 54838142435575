import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {ISubscribeItem} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
  subscribeItem: ISubscribeItem;
}

export function SubscribeItemEditModal({
  isVisible,
  close,
  refresh,
  subscribeItem,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [count, setCount] = useState<number>(0);

  function setClose() {
    close?.();
    setCount(0);
  }

  async function changeSubscribeItemCount() {
    try {
      if (!subscribeItem.subscribe_no || !subscribeItem.item_no) {
        return;
      }
      if (!confirm("정기구독 상품 수량을 변경하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.member.editSubscribeItemCount({
        subscribe_no: subscribeItem.subscribe_no,
        count,
        item_no: subscribeItem.item_no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류발생",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("changeSubscribeItemCount error", error);
    }
  }

  useEffect(() => {
    setCount(subscribeItem.count || 0);
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="정기배송수정" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>상품</div>
          <div className="col">
            [{subscribeItem.product?.partners?.partner_name}]{" "}
            {subscribeItem.product?.item_name}
          </div>
        </div>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>수량</div>
          <div className="col">
            <InputBox
              type="number"
              value={count}
              onValue={value => setCount(Number(value))}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button
            text="수정"
            onClick={changeSubscribeItemCount}
            backgroundColor={styleUtils.COLOR.warning}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}
