import React, {useEffect, useState} from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  InputBox,
  TextAreaBox,
  FileUploadButton,
  SelectBox,
  CheckBox,
} from "@components";
import {adminAPI, styleUtils} from "@utils";
import {IBanner, IDeeplinkContentNoListResponse, TBannerUrl} from "@data";
import styles from "./style.module.scss";
import moment from "moment";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import _ from "lodash";

interface IProps {
  isVisible?: boolean;
  data?: IBanner;
  setData?: (data?: IBanner) => void;
  photoList?: string[];
  setPhotoList?: (data?: string[]) => void;
  save?: () => void;
  deleteBanner?: () => void;
  close?: () => void;
}

const urlArr: {label: string; value: TBannerUrl}[] = [
  {label: "이벤트상세", value: "event"},
  {label: "로그인", value: "login"},
  {label: "아이정보입력", value: "petinfo"},
  {label: "출석체크이벤트", value: "attendance"},
  {label: "공지사항", value: "noticeDetail"},
];

export function BannerModal({
  isVisible,
  data = {},
  setData,
  photoList = [],
  setPhotoList,
  save,
  deleteBanner,
  close,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [targetList, setTargetList] =
    useState<IDeeplinkContentNoListResponse[]>();

  function setClose() {
    setData?.(undefined);
    close?.();
  }

  function setSave() {
    save?.();
    setClose();
  }

  function setDelete() {
    if (!confirm("배너를 정말 삭제하시겠습니까?")) {
      return;
    }
    deleteBanner?.();
    setClose();
  }

  async function getContentNoList() {
    try {
      setTargetList(undefined);
      if (!data?.url || !["noticeDetail"].includes(data?.url || "")) {
        return;
      }
      const {
        data: apiData,
        success,
        message,
      } = await adminAPI.marketing.getContentNoList({
        type: data?.url,
        hide_yn: "N",
      });
      if (success) {
        setTargetList(apiData.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getContentNoList error", error);
    }
  }

  useEffect(() => {
    getContentNoList();
  }, [data.url]);

  return (
    <Modal
      isVisible={isVisible}
      width={"600px"}
      close={setClose}
      className={styles.modal}>
      <ModalTitle title="상세" isClose close={setClose} />
      <ModalContent>
        <div className="row top">
          <div className="col title">
            <div className="buttonCol">
              홈화면 이미지
              <br />
              (비율 375x340)
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  width={32}
                  uploadUrl={"/admin/utils/uploadBannerImg"}
                  fileUpload={({files}) =>
                    setData?.({...data, big_img_url: files?.[0]})
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            {!!data?.big_img_url && <img src={data?.big_img_url} />}
          </div>
        </div>
        <div className="row top">
          <div className="col title">
            <div className="buttonCol">
              카테고리 이미지 <br />
              비율 375x183)
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  width={32}
                  uploadUrl={"/admin/utils/uploadBannerImg"}
                  fileUpload={({files}) =>
                    setData?.({...data, thumbnail_img: files?.[0]})
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            {!!data?.thumbnail_img && <img src={data?.thumbnail_img} />}
          </div>
        </div>
        <div className="row">
          <div className="col title">제목</div>
          <div className="col">
            <div className="row">
              <div className="col">
                <InputBox
                  placeholder="제목"
                  value={data?.title}
                  onValue={value => setData?.({...data, title: value})}
                />
              </div>
              <div className="col">
                <InputBox
                  placeholder="굵은제목"
                  value={data?.bold_title}
                  style={{fontWeight: 700}}
                  onValue={value => setData?.({...data, bold_title: value})}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row top">
          <div className="col title">소제목</div>
          <div className="col">
            <TextAreaBox
              placeholder="소제목"
              value={data?.sub_title || ""}
              onValue={value => setData?.({...data, sub_title: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">url</div>
          <div className="col">
            <SelectBox
              list={urlArr}
              value={data.url}
              singleSelect={value =>
                setData?.({...data, url: value as TBannerUrl})
              }
            />
          </div>
        </div>
        <>
          {(targetList || []).length > 0 && (
            <div className="row">
              <div className="col title">딥링크타겟</div>
              <div className="col">
                <SelectBox
                  list={(targetList || []).map(item => ({
                    value: String(item.no),
                    label: String(item.title || ""),
                  }))}
                  value={String(data.target_no)}
                  singleSelect={value =>
                    setData?.({...data, target_no: Number(value)})
                  }
                />
              </div>
            </div>
          )}
        </>
        <div className="row">
          <div className="col title">외부url</div>
          <div className="col">
            <InputBox
              placeholder="웹링크. 입력 시 위의 url은 무시됩니다."
              value={data?.external_url}
              onValue={value => setData?.({...data, external_url: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">상시노출</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.always_open_yn === "Y"}
                text={data?.always_open_yn === "Y" ? "O" : "X"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    always_open_yn: data?.always_open_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
        {data.always_open_yn === "Y" ? (
          <div className="row">
            <div className="col title">표기문구</div>
            <div className="col">
              <InputBox
                placeholder="상시노출"
                value={data?.always_open_title}
                onValue={value =>
                  setData?.({...data, always_open_title: value})
                }
              />
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col title">시작일</div>
              <div className="col">
                <InputBox
                  placeholder="시작일"
                  type="date"
                  value={moment(data?.openDate).format("YYYY-MM-DD")}
                  onValue={value =>
                    setData?.({...data, openDate: moment(value).toDate()})
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col title">종료일</div>
              <div className="col">
                <InputBox
                  placeholder="종료일"
                  type="date"
                  value={moment(data?.closeDate).format("YYYY-MM-DD")}
                  onValue={value =>
                    setData?.({...data, closeDate: moment(value).toDate()})
                  }
                />
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              <div>
                상세페이지 이미지
                <br />
                <span className="text-10">꼭 저장을 눌러주세요!</span>
              </div>
              <div>
                <FileUploadButton
                  multiple
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadBannerImg"}
                  fileUpload={({files}) =>
                    setPhotoList?.([...photoList, ...files])
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            <span className="text-12">
              이미지등록 시 상세페이지에서 업로드하신 이미지만 조회됩니다.
            </span>
          </div>
        </div>
        <div className={styles.imgRow}>
          {photoList.map(img_url => (
            <div key={_.uniqueId()}>
              <img src={img_url} className={styles.logoImg} />
              <div
                className={styles.deleteBtn}
                onClick={() =>
                  setPhotoList?.([
                    ...photoList.filter(item => item !== img_url),
                  ])
                }>
                X
              </div>
            </div>
          ))}
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          {!!data.no && (
            <Button
              text="삭제"
              onClick={setDelete}
              backgroundColor={styleUtils.COLOR.red500}
            />
          )}
        </div>
        <div>
          <Button text="저장" onClick={setSave} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
