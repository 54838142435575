import React, {useEffect, useRef, useState} from "react";
import styles from "./style.module.scss";

interface IDropdownItem {
  label?: string;
  value?: string;
  onClick?: () => void;
}

interface IProps {
  content: JSX.Element;
  options: IDropdownItem[];
}

export default function Dropdown({content, options = []}: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<any>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option: IDropdownItem) => {
    option.onClick?.();
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref} className={[styles.dropdown, styles.open].join(" ")}>
      <div className={styles.dropdownToggle} onClick={toggleDropdown}>
        {content}
      </div>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {options.map(option => (
            <li
              key={option.value}
              className={styles.dropdownMenuItem}
              onClick={() => handleOptionSelect(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
