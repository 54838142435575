import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  CheckBox,
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
} from "@components";
import {ResultPagingResponse, TypeYN, IDBFaq, IFaq} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {utils, adminAPI} from "utils";
import {FaqDetailModal} from "./FaqDetailModal";

export default function FaqPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState<ResultPagingResponse<IFaq[]>>();
  const [detailData, setDetailData] = useState<IDBFaq>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.faq.apiGetFaqList({
        page,
        searchtxt: searchText,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.faq.apiGetFaqDetail({no});
      if (success) {
        setDetailData(data);
        setIsDetailOpen(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function toggleHideYN({no, hide_yn}: {no: number; hide_yn: TypeYN}) {
    try {
      if (!no) {
        return;
      }

      const {success, message} = await adminAPI.faq.apiToggleHideYNFaq({
        no,
        hide_yn,
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function toggleTopYN({no, top_yn}: {no: number; top_yn: TypeYN}) {
    try {
      if (!no) {
        return;
      }

      const {success, message} = await adminAPI.faq.apiToggleTopYNFaq({
        no,
        top_yn,
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function saveFaq() {
    try {
      if (!detailData) {
        return;
      }
      if (!detailData.group_no) {
        setAlertModal({
          isVisible: true,
          title: "그룹을 선택해주세요.",
        });
        return;
      }

      const {success, message} = await (detailData?.no
        ? adminAPI.faq.apiUpdateFaq({
            ...detailData,
            hide_yn: detailData.hide_yn || "Y",
          })
        : adminAPI.faq.apiCreateFaq({...detailData}));
      if (success) {
        setIsDetailOpen(false);
        setDetailData(undefined);
        getList({page: listData?.page?.cur || 1});
        setAlertModal({
          isVisible: true,
          title: message || "답변등록 완료되었습니다.",
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("updateDetail error", error);
    }
  }

  async function deleteFaq() {
    try {
      if (!detailData?.no) {
        return;
      }

      const {success, message} = await adminAPI.faq.apiDeleteFaq({
        no: detailData.no,
      });
      if (success) {
        setIsDetailOpen(false);
        setDetailData(undefined);
        getList({page: listData?.page?.cur || 1});
        setAlertModal({
          isVisible: true,
          title: message || "삭제되었습니다.",
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("deleteFaq error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="자주묻는 질문">
        <SearchFilter
          left={[
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => {
                setDetailData(undefined);
                setIsDetailOpen(true);
              }}
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>그룹</th>
                <th>제목</th>
                <th>노출여부</th>
                <th>top</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail({no: item.no})}>
                    <td>{item.group?.title}</td>
                    <td>{item.title}</td>
                    <td onClick={utils.handleClickBubble}>
                      <CheckBox
                        checked={item.hide_yn === "N"}
                        text={item.hide_yn === "N" ? "노출" : "숨김"}
                        isToggle
                        onChange={e => {
                          if (!item.no) {
                            return;
                          }
                          toggleHideYN({
                            no: item.no,
                            hide_yn: item.hide_yn === "N" ? "Y" : "N",
                          });
                        }}
                      />
                    </td>
                    <td onClick={utils.handleClickBubble}>
                      <CheckBox
                        checked={item.top_yn === "Y"}
                        text={item.top_yn === "Y" ? "Yes" : "No"}
                        isToggle
                        onChange={e => {
                          if (!item.no) {
                            return;
                          }
                          toggleTopYN({
                            no: item.no,
                            top_yn: item.top_yn === "N" ? "Y" : "N",
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <FaqDetailModal
        isVisible={isDetailOpen}
        data={detailData}
        setData={setDetailData}
        save={saveFaq}
        deleteFaq={deleteFaq}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
        }}
      />
    </>
  );
}
