import {ListTable, Modal, ModalContent, ModalTitle} from "@components";
import {ResultPagingResponse, IMarketingLogMemberListResponse} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";

interface IProps {
  isVisible: boolean;
  marketing_no: number;
  close?: () => void;
}

export function MarketingClickListModal({
  isVisible,
  marketing_no,
  close,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<ResultPagingResponse<IMarketingLogMemberListResponse[]>>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      if (!marketing_no) {
        return;
      }
      const {data, success, message} =
        await adminAPI.marketing.apiGetMarketingLogMemberList({
          no: marketing_no,
          page,
        });
      if (success) {
        setListData(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  useEffect(() => {
    if (marketing_no) {
      getList();
    }
  }, [marketing_no]);

  return (
    <Modal isVisible={isVisible} close={close} width={"600px"}>
      <ModalTitle title="클릭회원" isClose close={close} />
      <ModalContent>
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>No</th>
              <th>회원명</th>
              <th>닉네임</th>
              <th>전화번호</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.member?.no}</td>
                  <td>{item.member?.name}</td>
                  <td>{item.member?.nickname}</td>
                  <td>
                    {utils.formatPhoneNumber(item.member?.phone_number || "")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </ModalContent>
    </Modal>
  );
}
