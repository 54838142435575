import React from "react";
import styles from "./style.module.scss";

interface IProps {
  left?: JSX.Element[];
  right?: JSX.Element[];
  bottom?: JSX.Element;
}

export default function SearchFilter({left, right, bottom}: IProps) {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        {(left || []).length > 0 && (
          <div className={styles.leftCol}>
            <div className={styles.row}>
              {(left || []).map((ele, i) => (
                <div key={i} className={styles.col}>
                  {ele}
                </div>
              ))}
            </div>
          </div>
        )}
        {(right || []).length > 0 && (
          <div className={styles.rightCol}>
            <div className={styles.row}>
              {(right || []).map((ele, i) => (
                <div key={i} className={styles.col}>
                  {ele}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {!!bottom && <div className={styles.bottom}>{bottom}</div>}
    </div>
  );
}
