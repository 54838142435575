import {
  Button,
  CheckBox,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {
  TCouponIssuanceType,
  TDBCouponGroup,
  TDBCouponType,
  IDBItemCategory,
  IDBPartner,
  IDBProduct,
  ICouponList,
  ICouponListRequest,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible?: boolean;
  data?: ICouponList;
  setData?: (data?: ICouponList) => void;
  refresh?: () => void;
  close?: () => void;
}

const couponIssuanceType = [
  {value: "signup", label: "최초 가입 시 발급 쿠폰"},
  {value: "addPetInfo", label: "아이정보 입력 시 발급 쿠폰"},
  {value: "birthday", label: "생일 쿠폰"},
] as {value: TCouponIssuanceType; label: string}[];

const couponGroup = [
  {value: "order", label: "주문서쿠폰"},
  {value: "item", label: "상품쿠폰"},
  {value: "subscribe", label: "정기구독쿠폰"},
] as {value: TDBCouponGroup; label: string}[];

const couponType = [
  {value: "price", label: "할인금액"},
  {value: "percent", label: "퍼센트"},
  {value: "delivery", label: "배달비쿠폰"},
] as {value: TDBCouponType; label: string}[];

export function CouponDetailModal({
  isVisible,
  data = {},
  setData,
  refresh,
  close,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);

  const [partnerList, setPartnerList] = useState<IDBPartner[]>([]);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [itemCategoryList, setItemCategoryList] = useState<IDBItemCategory[]>(
    [],
  );

  const [partnerNoList, setPartnerNoList] = useState<string[]>([]);
  const [itemNoList, setItemNoList] = useState<string[]>([]);
  const [itemCategoryNoList, setItemCategoryNoList] = useState<string[]>([]);

  function setClose() {
    setData?.(undefined);
    close?.();
  }

  async function setSave() {
    try {
      if (!data.title) {
        return setAlertModal({
          isVisible: true,
          title: "쿠폰명을 입력해주세요.",
        });
      }
      if (!data.coupon_num && data?.multiple_yn !== "Y") {
        return setAlertModal({
          isVisible: true,
          title: "쿠폰번호를 입력해주세요.",
        });
      }
      if (
        !moment(data.use_start_date).isValid() ||
        !moment(data.use_end_date).isValid()
      ) {
        return setAlertModal({
          isVisible: true,
          title: "쿠폰 사용기간을 확인해주세요.",
        });
      }

      const partnerList = partnerNoList.filter(item => !!item);
      const itemList = itemNoList.filter(item => !!item);
      const categoryList = itemCategoryNoList.filter(item => !!item);

      const params: ICouponListRequest = {
        no: data.no,
        partners: partnerList.length > 0 ? `|${partnerList.join("|")}|` : "",
        items: itemList.length > 0 ? `|${itemList.join("|")}|` : "",
        categorys: categoryList.length > 0 ? `|${categoryList.join("|")}|` : "",
        title: data.title,
        issuance_count: data.issuance_count,
        coupon_num: data.coupon_num,
        coupon_desc: data.coupon_desc || "",
        coupon_sub_desc: data.coupon_sub_desc || "",
        issuance_type: data.issuance_type,
        price: data.price || 0,
        max_price: data.max_price || 0,
        min_order_price: data.min_order_price || 0,
        budget: data.budget,
        use_start_date: data.use_start_date
          ? moment(data.use_start_date).format("YYYY-MM-DD")
          : "",
        use_end_date: data.use_end_date
          ? moment(data.use_end_date).format("YYYY-MM-DD")
          : "",
        type: data.type || "price",
        group: data.group || "order",
        addable_yn: data.addable_yn || "N",
        limit_count: data.limit_count,
        point_yn: data.point_yn || "N",
        return_yn: data.return_yn || "N",
        limit_yn: data.limit_yn || "N",
        useable_yn: data.useable_yn || "N",
        reservation_date: data.reservation_date
          ? moment(data.reservation_date).format("YYYY-MM-DD")
          : "",
        scene_yn: data.scene_yn || "N",
        scene_modal_text: data.scene_modal_text,
        multiple_yn: data.multiple_yn || "N",
      };

      const {success, message} = data.no
        ? await adminAPI.coupon.updateCoupon(params)
        : await adminAPI.coupon.createCoupon(params);
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("setSave error", error);
    }
  }

  async function setDelete() {
    if (!data.no) {
      return;
    }
    try {
      const {success, message} = await adminAPI.coupon.deleteCoupon({
        no: data.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("setDelete error", error);
    }
  }

  async function getPartnerList() {
    try {
      const {data, success, message} =
        await adminAPI.partner.apiGetAllPartners();
      if (success) {
        setPartnerList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPartnerList error", error);
    }
  }

  async function getItemList() {
    try {
      setItemNoList([]);

      const {data, success, message} = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getItemList error", error);
    }
  }

  async function getItemCategoryList() {
    try {
      setItemCategoryNoList([]);

      const {data, success, message} = await adminAPI.item.getItemCategory({});
      if (success) {
        setItemCategoryList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getItemCategoryList error", error);
    }
  }

  useEffect(() => {
    getPartnerList();
    getItemList();
    getItemCategoryList();
  }, []);

  useEffect(() => {
    if (data) {
      setPartnerNoList((data.partners || "").split("|"));
      setItemNoList((data.items || "").split("|"));
      setItemCategoryNoList((data.categorys || "").split("|"));
    } else {
      setPartnerNoList([]);
      setItemNoList([]);
      setItemCategoryNoList([]);
    }
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="상세페이지" isClose close={setClose} />
      <ModalContent>
        <div className="row top">
          <div className="col title">업체선택</div>
          <div className="col">
            <SelectBox
              multiple
              list={(partnerList || []).map(item => ({
                value: String(item.no),
                label: String(item.partner_name || ""),
              }))}
              value={partnerNoList}
              multipleSelect={setPartnerNoList}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">상품선택</div>
          <div className="col">
            <SelectBox
              multiple
              list={(itemList || [])
                .filter(item => {
                  if ((partnerNoList || []).length === 0) {
                    return true;
                  } else {
                    return !!(partnerNoList || []).find(
                      partnerNo =>
                        Number(partnerNo) === Number(item.item_partner_no || 0),
                    );
                  }
                })
                .map(item => ({
                  value: String(item.no),
                  label: String(item.item_name || ""),
                }))}
              value={itemNoList}
              multipleSelect={setItemNoList}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">상품카테고리</div>
          <div className="col">
            <SelectBox
              multiple
              list={(itemCategoryList || []).map(item => ({
                value: String(item.no),
                label: String(item.category || ""),
              }))}
              value={itemCategoryNoList}
              multipleSelect={setItemCategoryNoList}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">쿠폰명</div>
          <div className="col">
            <InputBox
              value={data.title}
              onValue={value => setData?.({...data, title: value})}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">쿠폰설명</div>
          <div className="col">
            <TextAreaBox
              value={data.coupon_desc}
              onValue={value => setData?.({...data, coupon_desc: value})}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">쿠폰설명2</div>
          <div className="col">
            <TextAreaBox
              value={data.coupon_sub_desc}
              onValue={value => setData?.({...data, coupon_sub_desc: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">쿠폰발급구분</div>
          <div className="col">
            <SelectBox
              list={[{value: "", label: "미선택"}, ...couponIssuanceType]}
              value={data.issuance_type}
              singleSelect={value =>
                setData?.({
                  ...data,
                  issuance_type: value as TCouponIssuanceType,
                })
              }
            />
          </div>
        </div>
        {data?.issuance_type ? (
          <div className="row">
            <div className="col title">발행수량</div>
            <div className="col">
              <InputBox
                type={"number"}
                value={String(data.issuance_count)}
                onValue={value =>
                  setData?.({...data, issuance_count: Number(value)})
                }
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          <div className="col title">쿠폰번호</div>
          <div className="col">
            <InputBox
              value={data.coupon_num}
              onValue={value => setData?.({...data, coupon_num: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">쿠폰구분</div>
          <div className="col">
            <SelectBox
              list={couponGroup}
              value={data.group || "order"}
              singleSelect={value =>
                setData?.({
                  ...data,
                  group: value as TDBCouponGroup,
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">할인구분</div>
          <div className="col">
            <SelectBox
              list={couponType}
              value={data.type || "price"}
              singleSelect={value =>
                setData?.({
                  ...data,
                  type: value as TDBCouponType,
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">할인금액</div>
          <div className="col">
            <InputBox
              type={"number"}
              value={String(data.price)}
              onValue={value => setData?.({...data, price: Number(value)})}
              rightContent={
                <span>
                  {!data.type
                    ? "원"
                    : ["percent"].includes(data.type)
                    ? "%"
                    : ["price", "delivery"].includes(data.type)
                    ? "원"
                    : ""}
                </span>
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">최대할인금액</div>
          <div className="col">
            <InputBox
              type={"number"}
              value={String(data.max_price)}
              onValue={value => setData?.({...data, max_price: Number(value)})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">최소주문금액</div>
          <div className="col">
            <InputBox
              type={"number"}
              value={String(data.min_order_price)}
              onValue={value =>
                setData?.({...data, min_order_price: Number(value)})
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">쿠폰시작일자</div>
          <div className="col">
            <InputBox
              type={"date"}
              value={
                data.use_start_date
                  ? moment(data.use_start_date).format("YYYY-MM-DD")
                  : ""
              }
              onValue={value =>
                setData?.({
                  ...data,
                  use_start_date: moment(value).toDate(),
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">쿠폰종료일자</div>
          <div className="col">
            <InputBox
              type={"date"}
              value={
                data.use_end_date
                  ? moment(data.use_end_date).format("YYYY-MM-DD")
                  : ""
              }
              onValue={value =>
                setData?.({
                  ...data,
                  use_end_date: moment(value).toDate(),
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">사용자등록여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.addable_yn === "Y"}
                text={data?.addable_yn === "Y" ? "가능" : "불가"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    addable_yn: data?.addable_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">적립금동시사용</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.point_yn === "Y"}
                text={data?.point_yn === "Y" ? "가능" : "불가"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    point_yn: data?.point_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">취소/반품시 복원</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.return_yn === "Y"}
                text={data?.return_yn === "Y" ? "가능" : "불가"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    return_yn: data?.return_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">발급 수 제한</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.limit_yn === "Y"}
                text={data?.limit_yn === "Y" ? "Yes" : "No"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    limit_yn: data?.limit_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
        {data?.limit_yn === "Y" ? (
          <div className="row top">
            <div className="col title">발급수량</div>
            <div className="col">
              <InputBox
                type={"number"}
                value={data.limit_count}
                onValue={value =>
                  setData?.({...data, limit_count: Number(value)})
                }
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row top">
          <div className="col title">쿠폰활성화</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.useable_yn === "Y"}
                text={data?.useable_yn === "Y" ? "Yes" : "No"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    useable_yn: data?.useable_yn === "N" ? "Y" : "N",
                  })
                }
              />
              {!!(data?.no && data?.useable_yn === "N") && (
                <>
                  <br />
                  <span className="text-12">
                    (기 발행된 쿠폰도 사용불가하며, 앱 내에 비노출됩니다.)
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">현장사용쿠폰여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.scene_yn === "Y"}
                text={data?.scene_yn === "Y" ? "Yes" : "No"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    scene_yn: data?.scene_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
        {data?.scene_yn === "Y" ? (
          <div className="row top">
            <div className="col title">모달내용</div>
            <div className="col">
              <TextAreaBox
                value={data.scene_modal_text}
                onValue={value => setData?.({...data, scene_modal_text: value})}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          <div className="col title">플친쿠폰여부</div>
          <div className="col">
            <div>
              <CheckBox
                checked={data?.multiple_yn === "Y"}
                text={data?.multiple_yn === "Y" ? "Yes" : "No"}
                isToggle
                onChange={e =>
                  setData?.({
                    ...data,
                    multiple_yn: data?.multiple_yn === "N" ? "Y" : "N",
                  })
                }
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col title">자동발행예약일</div>
          <div className="col">
            <InputBox
              type={"date"}
              value={
                data.reservation_date
                  ? moment(data.reservation_date).format("YYYY-MM-DD")
                  : ""
              }
              onValue={value =>
                setData?.({
                  ...data,
                  reservation_date: moment(value).toDate(),
                })
              }
            />
          </div>
        </div> */}
      </ModalContent>
      <ModalFooter>
        <div>
          {!!data.no && (
            <Button
              text="삭제"
              onClick={setDelete}
              backgroundColor={styleUtils.COLOR.red400}
            />
          )}
        </div>
        <div>
          <Button text="저장" onClick={setSave} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
