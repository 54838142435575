// ts filter undefined 제거
export function isDefined<T>(argument: T | undefined | null): argument is T {
  return argument !== undefined && argument !== null;
}

export function formatPhoneNumber(input: string) {
  let result = "";
  try {
    const cleanInput = input.replaceAll(/[^0-9]/g, "");
    const length = cleanInput.length;
    if (length === 8) {
      result = cleanInput.replace(/(\d{4})(\d{4})/, "$1-$2");
    } else if (cleanInput.startsWith("02") && (length === 9 || length === 10)) {
      result = cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-$2-$3");
    } else if (
      !cleanInput.startsWith("02") &&
      (length === 10 || length === 11)
    ) {
      result = cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3");
    } else {
      result = input;
    }
  } catch (error) {
    result = input;
  }
  return result;
}

export function bizNoFormatter(input: string) {
  let result = "";
  try {
    if (input.length == 10) {
      result = input.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
    } else {
      result = input;
    }
  } catch (error) {
    result = input;
  }
  return result;
}

export function numberFormatter(input: string | number) {
  let result = "";
  try {
    if (isNaN(Number(input))) {
      throw Error();
    } else {
      result = Number(input).toLocaleString("ko-KR");
    }
  } catch (error) {
    result = String(input || 0);
  }
  return result;
}

export function handleClickBubble(e: React.MouseEvent<HTMLElement>) {
  e.stopPropagation();
}

export function handleClickCapture(e: React.MouseEvent<HTMLElement>) {
  e.preventDefault();
}

export function handleClickBubbleAndCapture(e: React.MouseEvent<HTMLElement>) {
  handleClickBubble(e);
  handleClickCapture(e);
}

// 카드번호 포맷
export function formatCardNo(card_no: string) {
  if (!!card_no && typeof card_no === "string") {
    return `${card_no?.substring(0, 4)}-${card_no?.substring(
      4,
      6,
    )}**-****-${card_no?.substring(card_no.length - 4, card_no.length)}`;
  }
  return "";
}

export function getParameterObject({url}: {url: string}) {
  const params = url.split("?")[1]?.split("&") || [];
  const obj: {[key: string]: string} = {};

  for (let i = 0; i < params.length; i++) {
    const temp = params[i]?.split("=") || [];
    const key = temp[0];
    const value = temp.splice(1, temp.length).join("=");
    obj[key] = value;
  }
  return obj;
}

export function getParameter({url, key}: {url: string; key: string}) {
  const obj = getParameterObject({url});
  return obj[key];
}

export function strReplaceJustNumber(str?: string) {
  const isMinus = /^-/.test(str || "");
  const numStr = `${isMinus ? "-" : ""}${(str || "").replace(/[^0-9]/g, "")}`;
  return numStr;
}

export function getPercent({
  part,
  total,
  floatNum = 0,
}: {
  part?: number;
  total?: number;
  floatNum?: number;
}) {
  if (!total || isNaN(Number(total)) || Number(total) === 0) {
    return 0;
  }
  if (!part || isNaN(Number(part)) || Number(part) === 0) {
    return 0;
  }
  const percent = Number(
    Number((Number(part) / Number(total)) * 100).toFixed(floatNum),
  );
  return percent;
}
