import {Button, FileUploadButton, InputBox, TextAreaBox} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IProductDetailInterview} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";

interface IProps {
  item_no: number;
  detailData: IProductDetailInterview;
  refresh?: () => void;
}

export function InterviewItem({detailData, refresh, item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailInterview>({
    type: "interview",
    data_json: {
      title: "",
      content: "",
      img_url: "",
    },
  });

  const img_url = data.data_json?.thumbnail_img_url || data.data_json?.img_url;

  async function save() {
    try {
      const params = {
        ...data,
        data_json: {...data.data_json},
        item_no,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{flex: 1}}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              이미지
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadItemDetailImg"}
                  fileUpload={({files}) =>
                    setData?.({
                      ...data,
                      data_json: {...data.data_json, img_url: files[0]},
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={["col", styles.imgCol].join(" ")}>
            {!!img_url && <img src={img_url} className={styles.detailImg} />}
          </div>
        </div>
        <div className="row">
          <div className="col title">타이틀</div>
          <div className="col">
            <InputBox
              value={data.data_json?.title}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {...data.data_json, title: value},
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">내용</div>
          <div className="col">
            <TextAreaBox
              value={data.data_json?.content}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {...data.data_json, content: value},
                })
              }
              height={120}
            />
          </div>
        </div>
      </div>
    </>
  );
}
