import React, {
  InputHTMLAttributes,
  KeyboardEvent,
  CSSProperties,
  useState,
} from "react";
import styles from "./style.module.scss";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string | number | readonly string[] | undefined;
  onValue?: (value: string) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  textAlign?: "left" | "right" | "center";
  rightContent?: JSX.Element;
  rightContentStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  onEnter?: () => void;
  boxStyle?: CSSProperties;
}

export default function InputBox({
  textAlign,
  rightContent,
  rightContentStyle,
  inputStyle,
  onEnter,
  boxStyle = {},
  value,
  onValue,
  onChange,
  type,
  ...rest
}: IProps) {
  const [isFocus, setIsFocus] = useState(false);

  function setFocus() {
    setIsFocus(true);
  }
  function setBlur() {
    setIsFocus(false);
  }
  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      onEnter?.();
    }
  }

  return (
    <div
      className={[
        styles.box,
        ...[!!isFocus && styles.focus],
        ...[!!textAlign && styles.textAlign],
      ].join(" ")}
      style={{...boxStyle}}>
      <input
        {...rest}
        type={type}
        value={value || (type === "number" ? 0 : "")}
        onFocus={setFocus}
        onBlur={setBlur}
        onKeyDown={handleKeyDown}
        style={inputStyle}
        onChange={e => {
          onValue?.(e.target.value);
          onChange?.(e);
        }}
      />
      {!!rightContent && (
        <div className={styles.rightContainer} style={rightContentStyle}>
          {rightContent}
        </div>
      )}
    </div>
  );
}
