import {
  Button,
  CheckBox,
  FileUploadButton,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  TextAreaBox,
} from "@components";
import {IDBPartner} from "@data";
import React from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import styles from "./style.module.scss";

interface IProps {
  isVisible?: boolean;
  data?: IDBPartner;
  setData?: (data?: IDBPartner) => void;
  reloadData?: () => void;
  close?: () => void;
}

export default function PartnerDetailModal({
  isVisible,
  data = {},
  setData,
  reloadData,
  close,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);

  async function savePartner() {
    try {
      if (!data) {
        return;
      }

      const {success, message} = await (data?.no
        ? adminAPI.partner.apiUpdatePartner(data)
        : adminAPI.partner.apiCreatePartner(data));
      if (success) {
        setClose();
        reloadData?.();
        setAlertModal({
          isVisible: true,
          title: message || "저장완료",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("savePartner error", error);
    }
  }

  async function deletePartner() {
    try {
      if (!data || !data?.no) {
        return;
      }
      if (!confirm("업체를 삭제하시겠습니까? 삭제 후 복구가 불가능합니다."))
        return;

      const {success, message} = await adminAPI.partner.apiDeletePartner({
        no: data?.no,
      });
      if (success) {
        setClose();
        reloadData?.();
        setAlertModal({
          isVisible: true,
          title: message || "삭제완료",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("deletePartner error", error);
    }
  }

  function setClose() {
    setData?.(undefined);
    close?.();
  }

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="업체상세" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              로고
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadPartnerLogoImg"}
                  fileUpload={({files}) =>
                    setData?.({...data, logo_url: files?.[0]})
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            {!!data?.logo_url && (
              <img src={data?.logo_url} className={styles.logoImg} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col title">
            <div className="buttonCol">
              랜딩 로고
              <div>
                <FileUploadButton
                  text={"업로드"}
                  height={32}
                  uploadUrl={"/admin/utils/uploadPartnerLogoImg"}
                  fileUpload={({files}) =>
                    setData?.({...data, landing_img: files?.[0]})
                  }
                />
              </div>
            </div>
          </div>
          <div className="col">
            {!!data?.landing_img && (
              <img src={data?.landing_img} className={styles.logoImg} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col title">업체코드</div>
          <div className="col">
            <InputBox
              disabled={!!data.no}
              value={data?.partner_code}
              onValue={value => setData?.({...data, partner_code: value})}
              placeholder={"영문 두자리 입력"}
              maxLength={2}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">업체명</div>
          <div className="col">
            <InputBox
              value={data?.partner_name}
              onValue={value => setData?.({...data, partner_name: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">사업자등록번호</div>
          <div className="col">
            <InputBox
              value={data?.biz_regno}
              onValue={value => setData?.({...data, biz_regno: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">대표자명</div>
          <div className="col">
            <InputBox
              value={data?.biz_repsname}
              onValue={value => setData?.({...data, biz_repsname: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">우편번호</div>
          <div className="col">
            <InputBox
              value={data?.zip_code}
              onValue={value => setData?.({...data, zip_code: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">주소</div>
          <div className="col">
            <InputBox
              value={data?.addr}
              onValue={value => setData?.({...data, addr: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">상세주소</div>
          <div className="col">
            <InputBox
              value={data?.addr2}
              onValue={value => setData?.({...data, addr2: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">업종</div>
          <div className="col">
            <InputBox
              value={data?.biz_type}
              onValue={value => setData?.({...data, biz_type: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">업태</div>
          <div className="col">
            <InputBox
              value={data?.biz_items}
              onValue={value => setData?.({...data, biz_items: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">통신판매신고번호</div>
          <div className="col">
            <InputBox
              value={data?.biz_number}
              onValue={value => setData?.({...data, biz_number: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">전화번호</div>
          <div className="col">
            <InputBox
              value={data?.biz_tel}
              onValue={value => setData?.({...data, biz_tel: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">팩스번호</div>
          <div className="col">
            <InputBox
              value={data?.biz_fax}
              onValue={value => setData?.({...data, biz_fax: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">업체담당자</div>
          <div className="col">
            <InputBox
              value={data?.biz_manager}
              onValue={value => setData?.({...data, biz_manager: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">담당자전화번호</div>
          <div className="col">
            <InputBox
              value={data?.biz_phone}
              onValue={value => setData?.({...data, biz_phone: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">수수료(rate)</div>
          <div className="col">
            <InputBox
              value={String(data?.biz_commission || 0)}
              type={"number"}
              onValue={value =>
                setData?.({...data, biz_commission: Number(value || 0)})
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">기타</div>
          <div className="col">
            <InputBox
              value={data?.biz_memo}
              onValue={value => setData?.({...data, biz_memo: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">은행(ex.국민은행)</div>
          <div className="col">
            <InputBox
              value={data?.bank}
              onValue={value => setData?.({...data, bank: value})}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">계좌번호</div>
          <div className="col">
            <InputBox
              value={data?.account}
              onValue={value => setData?.({...data, account: value})}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">메모</div>
          <div className="col">
            <TextAreaBox
              value={data?.memo}
              onValue={value => setData?.({...data, memo: value})}
              height={100}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">쿠폰사용여부</div>
          <div className="col">
            <div className="col">
              <div>
                <CheckBox
                  isToggle
                  checked={data?.coupon_useable === "Y"}
                  text={data?.coupon_useable === "Y" ? "가능" : "불가"}
                  onChange={e =>
                    setData?.({
                      ...data,
                      coupon_useable: data?.coupon_useable === "Y" ? "N" : "Y",
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button
            text="삭제"
            onClick={deletePartner}
            backgroundColor={styleUtils.COLOR.red400}
          />
        </div>
        <div>
          <Button text="저장" onClick={savePartner} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
