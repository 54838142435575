import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {styleUtils} from "@utils";
import styles from "./style.module.scss";

interface IProps {
  loading: boolean;
  color?: string;
  size?: number;
}

export default function Loader({
  loading,
  color = styleUtils.COLOR.green,
  size = 100,
}: IProps) {
  if (!loading) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <ClipLoader color={color} loading={loading} size={size} />
    </div>
  );
}
