import {
  ContentLayout,
  SearchFilter,
  ListTable,
  Content,
  SelectBox,
  InputBox,
} from "@components";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import moment from "moment";
import {
  IOrderLogItemResponse,
  IOrderLogPartnerResponse,
  ResultPagingResponse,
  IDBPartner,
} from "@data";

type TSelectType = "partner" | "item";

export default function OrderLogPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [partnerList, setPartnerList] = useState<IDBPartner[]>([]);
  const [partnerData, setPartnerData] =
    useState<ResultPagingResponse<IOrderLogPartnerResponse[]>>();
  const [itemData, setItemData] =
    useState<ResultPagingResponse<IOrderLogItemResponse[]>>();
  const [type, setType] = useState<TSelectType>("item");
  const [partnerNo, setPartnerNo] = useState<number>();
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD"),
  );

  async function getPartnerList() {
    try {
      const {data, success, message} =
        await adminAPI.partner.apiGetAllPartners();
      if (success) {
        setPartnerList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("getPartnerList error", error);
    }
  }

  async function getPartnerData({page}: {page?: number} = {}) {
    try {
      const {success, data, message} =
        await adminAPI.orderLog.apiGetOrderLogPartner({
          page: page || 1,
          limit: 15,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        });
      if (success) {
        setPartnerData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("KpiPage getData error", error);
    }
  }

  async function getItemData({page}: {page?: number} = {}) {
    try {
      const {success, data, message} =
        await adminAPI.orderLog.apiGetOrderLogItem({
          page: page || 1,
          limit: 15,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          item_partner_no: partnerNo,
        });
      if (success) {
        setItemData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("KpiPage getData error", error);
    }
  }

  function getData() {
    if (type === "partner") {
      getPartnerData();
    } else if (type === "item") {
      getItemData();
    }
  }

  useEffect(() => {
    getData();
  }, [type, startDate, endDate, partnerNo]);

  useEffect(() => {
    getPartnerList();
  }, []);

  return (
    <>
      <ContentLayout title="판매량조회">
        <SearchFilter
          left={[
            <SelectBox
              key={0}
              value={type}
              list={[
                {label: "상품", value: "item"},
                {label: "업체별", value: "partner"},
              ]}
              singleSelect={e => setType(e as TSelectType)}
              boxStyle={{minWidth: "100px"}}
            />,
            <SelectBox
              key={1}
              list={[{no: "", partner_name: "업체선택"}, ...partnerList].map(
                item => ({
                  value: String(item.no),
                  label: String(item.partner_name || ""),
                }),
              )}
              value={String(partnerNo)}
              singleSelect={e => {
                const activeNo = Number(e);
                setPartnerNo(isNaN(activeNo) ? undefined : activeNo);
              }}
              boxStyle={{minWidth: "120px"}}
            />,
            <div key={2} className="row center" style={{marginBottom: 0}}>
              <div className="col">
                <InputBox
                  value={startDate}
                  type={"date"}
                  onValue={setStartDate}
                  onEnter={getData}
                />
              </div>
              <div className="col">~</div>
              <div className="col">
                <InputBox
                  value={endDate}
                  type={"date"}
                  onValue={setEndDate}
                  onEnter={getData}
                />
              </div>
            </div>,
          ]}
        />
        <Content>
          <>
            {type === "partner" && !!partnerData && (
              <PartnerView data={partnerData} getList={getPartnerData} />
            )}
            {type === "item" && !!itemData && (
              <ItemView data={itemData} getList={getItemData} />
            )}
          </>
        </Content>
      </ContentLayout>
    </>
  );
}

function PartnerView({
  data,
  getList,
}: {
  data: ResultPagingResponse<IOrderLogPartnerResponse[]>;
  getList: (data: {page: number}) => void;
}) {
  return (
    <ListTable paging={data.page} getList={getList}>
      <thead>
        <tr>
          <th>업체명</th>
          <th>판매수량</th>
          <th>총판매금액</th>
        </tr>
      </thead>
      <tbody>
        {(data.result || []).map((item, index) => (
          <tr key={index}>
            <td>{item.partner_name}</td>
            <td>{(item.order_count || 0).toLocaleString()}</td>
            <td>{(item.total_price || 0).toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </ListTable>
  );
}

function ItemView({
  data,
  getList,
}: {
  data: ResultPagingResponse<IOrderLogItemResponse[]>;
  getList: (data: {page: number}) => void;
}) {
  return (
    <ListTable paging={data.page} getList={getList}>
      <thead>
        <tr>
          <th>고유코드</th>
          <th>업체명</th>
          <th>상품명</th>
          <th>
            총판매금액
            <br />
            <span className="font-14">(판매수량)</span>
          </th>
          <th>
            일반판매금액
            <br />
            <span className="font-14">(판매수량)</span>
          </th>
          <th>
            구독판매금액
            <br />
            <span className="font-14">(판매수량)</span>
          </th>
          <th>
            함께담기금액
            <br />
            <span className="font-14">(판매수량)</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {(data.result || []).map((item, index) => (
          <>
            <tr key={`tr1_${index}`}>
              <td rowSpan={2}>{item.nosnos_code}</td>
              <td rowSpan={2}>{item.partner_name}</td>
              <td rowSpan={2}>{item.item_name}</td>
              <td>
                <span className="text-10">₩ </span>
                {(item.total_price || 0).toLocaleString()}
              </td>
              <td>
                <span className="text-10">₩ </span>
                {(item.common_total_price || 0).toLocaleString()}
              </td>
              <td>
                <span className="text-10">₩ </span>
                {(item.subscribe_total_price || 0).toLocaleString()}
              </td>
              <td>
                <span className="text-10">₩ </span>
                {(item.subscribe_temp_total_price || 0).toLocaleString()}
              </td>
            </tr>
            <tr key={`tr2_${index}`}>
              <td>
                {(item.order_count || 0).toLocaleString()}
                <span className="text-10"> ea</span>
              </td>
              <td>
                {(item.common_order_count || 0).toLocaleString()}
                <span className="text-10"> ea</span>
              </td>
              <td>
                {(item.subscribe_order_count || 0).toLocaleString()}
                <span className="text-10"> ea</span>
              </td>
              <td>
                {(item.subscribe_temp_order_count || 0).toLocaleString()}
                <span className="text-10"> ea</span>
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </ListTable>
  );
}
