import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import {ApiResponse} from "@data";
import {getCookie} from "./cookieUtil";
import envUtil from "./envUtil";

const axiosApi = axios.create({
  timeout: 20000,
  baseURL: envUtil.BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export const cookie_token_key = "token";

// Request 로깅 인터셉터
const requestLoggingIntercepter = (config: InternalAxiosRequestConfig) => {
  const token = getCookie({name: cookie_token_key});
  if (token && config.headers) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
};

// Response 로깅 인터셉터
const responseLoggingIntercepter = (response: AxiosResponse) => {
  return response;
};

export const isAxiosError = (value: any): value is AxiosError =>
  value?.isAxiosError;

// Error 로깅 인터셉터
const errorLoggingInterceptor = (error: any) => {
  if (isAxiosError(error) && error.response) {
    error.response = responseLoggingIntercepter(error.response);
  }
  throw error;
};

export function axiosPostUtil<T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
): Promise<R> {
  return axiosApi.post<T, R, D>(url, data, config);
}

export async function imageUpload({
  files,
  endPoint,
}: {
  files: File[] | FileList;
  endPoint: string;
}): Promise<string[] | undefined> {
  try {
    const imageList: string[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append("name", "files");
      formData.append("files", file);
      const {data} = await axiosPostUtil<any>(endPoint, formData);
      imageList.push(data.data.imglist[0]);
    }
    return imageList;
  } catch (error) {
    console.log("imageUpload error", error);
    return undefined;
  }
}

export async function fileUpload<T>({
  files,
  endPoint,
}: {
  files: File[] | FileList;
  endPoint: string;
}): Promise<ApiResponse<T | undefined>> {
  try {
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    const result = await axiosPostUtil<any>(endPoint, formData).then(
      value => value?.data,
    );
    return result;
  } catch (error) {
    console.log("fileUpload error", error);
    return {success: false, message: "오류발생", data: undefined};
  }
}

axiosApi.interceptors.request.use(
  requestLoggingIntercepter,
  errorLoggingInterceptor,
);

axiosApi.interceptors.response.use(
  responseLoggingIntercepter,
  errorLoggingInterceptor,
);
