import {
  Button,
  CheckBox,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IDBMaterialList} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible?: boolean;
  item_no: number;
  close?: () => void;
  refresh?: () => void;
  itemMaterialList?: IDBMaterialList[];
  getItemMaterialList?: () => void;
}

export function MaterialAddModal({
  isVisible,
  item_no,
  close,
  refresh,
  itemMaterialList,
  getItemMaterialList,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [materialNo, setMaterialNo] = useState<number>();
  const [title, setTitle] = useState("");
  const [outTitle, setOutTitle] = useState("");
  const [isMain, setIsMain] = useState(false);
  const [isDomestic, setIsDomestic] = useState(true);
  const [materialFromCountry, setMaterialFromCountry] = useState("");
  const [contentPercent, setContentPercent] = useState(0);

  function setClose() {
    close?.();
    setMaterialNo(undefined);
    setTitle("");
    setOutTitle("");
    setIsMain(false);
    setIsDomestic(false);
    setMaterialFromCountry("");
  }

  async function save() {
    try {
      if (!title || !outTitle) {
        return setAlertModal({
          isVisible: true,
          title: "타이틀을 입력해주세요.",
        });
      }
      if (contentPercent > 100 || contentPercent < 0) {
        return setAlertModal({
          isVisible: true,
          title: "함량은 0~100 사이의 값만 입력가능합니다.",
        });
      }

      const {success, message} = await adminAPI.item.createItemMaterial({
        item_no,
        material_no: materialNo,
        material_text: title,
        out_title: outTitle,
        domestic_yn: isDomestic ? "Y" : "N",
        from_country: materialFromCountry,
        main_yn: isMain ? "Y" : "N",
        content_percent: contentPercent,
      });
      if (success) {
        refresh?.();
        setClose();
        if (!materialNo) {
          getItemMaterialList?.();
        }
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    if (materialNo) {
      const materialData = (itemMaterialList || []).find(
        item => item.no === materialNo,
      );
      setTitle(materialData?.title || "");
      setOutTitle(materialData?.out_title || "");
    } else {
      setTitle("");
      setOutTitle("");
    }
  }, [materialNo]);

  return (
    <>
      <Modal isVisible={isVisible} width="500px" close={setClose}>
        <ModalTitle close={setClose} isClose title="원재료추가" />
        <ModalContent>
          <div className="row">
            <div className="col title">구분</div>
            <div className="col">
              <SelectBox
                placeholder="직접입력"
                list={[
                  {title: "직접입력", no: 0},
                  ...(itemMaterialList || []),
                ].map(item => ({
                  label: item.title || "",
                  value: String(item.no || 0),
                }))}
                value={String(materialNo)}
                singleSelect={value =>
                  setMaterialNo(
                    Number(value) && Number(value) > 0
                      ? Number(value)
                      : undefined,
                  )
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">상세페이지표기</div>
            <div className="col">
              <InputBox
                value={title}
                onValue={setTitle}
                disabled={!!materialNo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">바깥표기</div>
            <div className="col">
              <InputBox
                value={outTitle}
                onValue={setOutTitle}
                disabled={!!materialNo}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">함량(%)</div>
            <div className="col">
              <InputBox
                value={String(contentPercent)}
                type="number"
                onValue={value => {
                  if (!isNaN(Number(value))) {
                    setContentPercent(Number(value));
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">핵심원재료</div>
            <div className="col">
              <div>
                <CheckBox
                  isToggle
                  onChange={e => setIsMain(!isMain)}
                  checked={isMain}
                  text={isMain ? "O" : "X"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col title">국내산</div>
            <div className="col">
              <div>
                <CheckBox
                  isToggle
                  onChange={e => {
                    setIsDomestic(!isDomestic);
                    setMaterialFromCountry("");
                  }}
                  checked={isDomestic}
                  text={isDomestic ? "O" : "X"}
                />
              </div>
            </div>
          </div>
          <>
            {!isDomestic && (
              <div className="row">
                <div className="col title">원산지</div>
                <div className="col">
                  <InputBox
                    value={materialFromCountry}
                    onValue={setMaterialFromCountry}
                  />
                </div>
              </div>
            )}
          </>
        </ModalContent>
        <ModalFooter>
          <div></div>
          <div>
            <Button
              backgroundColor={styleUtils.COLOR.success}
              fontColor={styleUtils.COLOR.white}
              text="저장"
              onClick={save}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
