import {ListTable} from "@components";
import {IDBMemberGradeLog, ResultPagingResponse} from "@data";
import {states} from "@recoils";
import {adminAPI, constant} from "@utils";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  member_no: number;
}

export default function GradeLogTab({member_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const setLoading = useSetRecoilState(states.loadingState);
  const [listData, setListData] =
    useState<ResultPagingResponse<IDBMemberGradeLog[]>>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      setLoading(true);
      const {data, success, message} = await adminAPI.member.getGradeLog({
        page,
        member_no,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("gatData error", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row">
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>구분</th>
              <th>등급산정기간</th>
              <th>6개월누적결제금액</th>
              <th>등급</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => {
              const grade_name = constant.GRADE_LIST.find(
                grade => Number(grade.value) === item.grade_no,
              )?.label;
              return (
                <tr key={index}>
                  <td>{moment(item.createdAt).format("Y년 M월")}</td>
                  <td>
                    {moment(item.createdAt)
                      .add(-7, "month")
                      .startOf("month")
                      .format("YYYY-MM-DD")}
                    <br />~
                    {moment(item.createdAt)
                      .add(-1, "month")
                      .startOf("month")
                      .set("date", 25)
                      .format("YYYY-MM-DD")}
                  </td>
                  <td>
                    {(item.spend_amount || 0).toLocaleString()}원<br />
                    <span className="text-12">
                      ({(item.spend_cnt || 0).toLocaleString()}건)
                    </span>
                  </td>
                  <td>{grade_name}</td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </div>
    </>
  );
}
