import {Content, InputBox, ListTable, SearchFilter} from "@components";
import {IPaymentFailLogResponse, ResultPagingResponse} from "@data";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

export function PaymentFailLog() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const setLoading = useSetRecoilState(states.loadingState);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IPaymentFailLogResponse[]>>();

  async function getData({page = 1}: {page?: number} = {}) {
    try {
      setLoading(true);
      const {data, success, message} =
        await adminAPI.orderLog.apiGetPaymentFailLog({
          limit: 20,
          page,
          searchtxt: searchText,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("gatData error", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <SearchFilter
        left={[
          <InputBox
            key={1}
            value={searchText}
            onValue={setSearchText}
            onEnter={getData}
            rightContent={
              <div style={{display: "flex"}} onClick={() => getData()}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            }
          />,
        ]}
      />
      <Content>
        <ListTable paging={listData?.page} getList={getData}>
          <thead>
            <tr>
              <th>전화번호</th>
              <th>고객명 (닉네임)</th>
              <th>카드번호</th>
              <th>결제금액</th>
              <th>오류메시지</th>
              <th>결제시도일시</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => (
              <tr key={index}>
                <td>{utils.formatPhoneNumber(item.phone_number || "")}</td>
                <td>
                  {item.name || item.nickname ? (
                    <>
                      {item.name} ({item.nickname})
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {item.card_name} {utils.formatCardNo(item.card_no || "")}
                </td>
                <td>{Number(item.amt || 0).toLocaleString()}원</td>
                <td className="preWrap" style={{maxWidth: 200}}>
                  {item.result_msg}
                </td>
                <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      </Content>
    </>
  );
}
