import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IDBItemStockDate} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  data?: IDBItemStockDate;
  close?: () => void;
  refresh?: () => void;
  item_no: number;
}

export function StockDateDetailModal({
  isVisible,
  data,
  close,
  refresh,
  item_no,
}: IProps) {
  const adminData = useRecoilValue(states.adminDataState);
  const setAlertModal = useSetRecoilState(states.alertState);
  const [stockDate, setStockDate] = useState<moment.Moment>();
  const [limitDate, setLimitDate] = useState<moment.Moment>();
  const [madeDate, setMadeDate] = useState<moment.Moment>();
  const [count, setCount] = useState(0);
  const [memo, setMemo] = useState("");

  const admin_name = adminData?.name || adminData?.nickname || "";

  function setClose() {
    close?.();
    setStockDate(undefined);
    setLimitDate(undefined);
    setMadeDate(undefined);
    setCount(0);
    setMemo("");
  }

  async function save() {
    try {
      if (count <= 0) {
        return setAlertModal({
          isVisible: true,
          title: "수량을 정확히 입력해주세요.",
        });
      }
      if (!stockDate || moment(stockDate).isBefore(moment())) {
        return setAlertModal({
          isVisible: true,
          title: "입고일을 정확히 입력해주세요.",
        });
      }
      if (!confirm(`${data?.no ? "수정" : "등록"}하시겠습니까?`)) {
        return;
      }

      const params = {
        item_no,
        stock_date: moment(stockDate).format("YYYY-MM-DD"),
        limit_date: limitDate ? moment(limitDate).format("YYYY-MM-DD") : "",
        made_date: madeDate ? moment(madeDate).format("YYYY-MM-DD") : "",
        stock_count: count,
        admin_name,
        memo,
      };

      const {success, message} = data?.no
        ? await adminAPI.item.updateItemStockDate({no: data?.no, ...params})
        : await adminAPI.item.createItemStockDate(params);
      if (success) {
        setClose();
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setStockDate(
      !!data?.stock_date && moment(data?.stock_date).isValid()
        ? moment(data?.stock_date)
        : undefined,
    );
    setLimitDate(
      !!data?.limit_date && moment(data?.limit_date).isValid()
        ? moment(data?.limit_date)
        : undefined,
    );
    setMadeDate(
      !!data?.made_date && moment(data?.made_date).isValid()
        ? moment(data?.made_date)
        : undefined,
    );
    setCount(data?.stock_count || 0);
    setMemo(data?.memo || "");
  }, [data]);

  return (
    <>
      <Modal isVisible={isVisible} width="500px" close={setClose}>
        <ModalTitle close={setClose} isClose title="선입고일입력" />
        <ModalContent>
          <div className="row">
            <div className="col title">입고예정일</div>
            <div className="col">
              <InputBox
                type="date"
                value={
                  !!stockDate && moment(stockDate).isValid()
                    ? moment(stockDate).format("YYYY-MM-DD")
                    : ""
                }
                onValue={value => setStockDate(moment(value))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">수량</div>
            <div className="col">
              <InputBox
                type="number"
                value={String(count)}
                onValue={value => setCount(Number(value))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">제조일자</div>
            <div className="col">
              <InputBox
                type="date"
                value={
                  !!madeDate && moment(madeDate).isValid()
                    ? moment(madeDate).format("YYYY-MM-DD")
                    : ""
                }
                onValue={value => setMadeDate(moment(value))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">소비기한</div>
            <div className="col">
              <InputBox
                type="date"
                value={
                  !!limitDate && moment(limitDate).isValid()
                    ? moment(limitDate).format("YYYY-MM-DD")
                    : undefined
                }
                onValue={value => setLimitDate(moment(value))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">입고처리자</div>
            <div className="col">
              <InputBox disabled value={admin_name} />
            </div>
          </div>
          <div className="row top">
            <div className="col title">메모</div>
            <div className="col">
              <TextAreaBox value={memo} onValue={setMemo} height={150} />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div></div>
          <div>
            <Button
              backgroundColor={styleUtils.COLOR.success}
              fontColor={styleUtils.COLOR.white}
              text="저장"
              onClick={save}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
