import {
  Button,
  Content,
  ContentLayout,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYN,
  IMemberOutResponse,
  IMemberResponse,
} from "@data";
import moment from "moment";
import {MemberDetailModal} from "pages/MemberPage/MemberDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {COLOR} from "utils/styleUtils";
import styles from "./style.module.scss";

export default function MemberOutRequestPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<ResultPagingResponse<IMemberOutResponse[]>>();
  const [confirmYN, setConfirmYN] = useState<TypeYN>();
  const [memberDetail, setMemberDetail] = useState<IMemberResponse>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} =
        await adminAPI.member.getMemberOutRequestList({
          page,
          confirm_yn: confirmYN,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  async function getMemberDetail(no: number) {
    try {
      const {data, success, message} = await adminAPI.member.getDetail({
        member_no: no,
      });
      if (success) {
        setMemberDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function setConfirm({no}: {no: number}) {
    try {
      if (
        !confirm(
          "핸드폰번호로 매칭되지 않은 요청은 처리여부만 변경되며, 핸드폰번호가 매칭된 계정은 탈퇴처리됩니다.\n계정을 탈퇴처리하시겠습니까?",
        )
      ) {
        return;
      }
      const {success, message} =
        await adminAPI.member.setMemberOutRequestConfirm({no});
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("setConfirm error", error);
    }
  }

  useEffect(() => {
    getList();
  }, [confirmYN]);

  return (
    <>
      <ContentLayout title="계정삭제요청">
        <SearchFilter
          left={[
            <SelectBox
              key={0}
              boxStyle={{minWidth: "80px"}}
              list={[
                {label: "전체", value: ""},
                {label: "미처리", value: "N"},
                {label: "처리완료", value: "Y"},
              ]}
              value={confirmYN}
              singleSelect={value => {
                setConfirmYN(value as TypeYN);
              }}
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>고객조회</th>
                <th>핸드폰번호</th>
                <th>고객명</th>
                <th>고객상태</th>
                <th>사유</th>
                <th>요청일</th>
                <th>처리여부</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => (
                <tr key={index}>
                  <td>
                    {!isNaN(Number(item.member?.no)) && (
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="고객정보조회"
                          fontSize={12}
                          onClick={() => {
                            if (item.member?.no) {
                              getMemberDetail(item?.member?.no);
                            }
                          }}
                        />
                      </div>
                    )}
                  </td>
                  <td>{utils.formatPhoneNumber(item.phone_number || "")}</td>
                  <td>{item.name}</td>
                  <td>
                    {!!item.member && (
                      <div>
                        {item.member?.del_yn === "Y" ? (
                          <span
                            className={[styles.badge, styles.out].join(" ")}>
                            탈퇴
                          </span>
                        ) : (
                          <span
                            className={[styles.badge, styles.purchase].join(
                              " ",
                            )}>
                            정상
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                  <td>
                    <span className="preWrap">{item.reason}</span>
                  </td>
                  <td>
                    {moment(item.createdAt).isValid() ? (
                      <>
                        {moment(item.createdAt).format("YYYY-MM-DD")}
                        <br />
                        {moment(item.createdAt).format("HH:mm")}
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {item.confirm_yn === "Y" ? (
                      <span>처리완료</span>
                    ) : (
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="처리하기"
                          fontSize={12}
                          onClick={() => {
                            if (item.no) {
                              setConfirm({no: item.no});
                            }
                          }}
                          backgroundColor={COLOR.red}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <MemberDetailModal
        isVisible={!!memberDetail}
        close={() => setMemberDetail(undefined)}
        member={memberDetail}
        refresh={() => !!memberDetail?.no && getMemberDetail(memberDetail.no)}
      />
    </>
  );
}
