import {ChartData} from "chart.js";
import {Content, InputBox, SearchFilter} from "@components";
import {IDashBoardSignupResponse} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import styles from "./style.module.scss";

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
    },
    plugins: {
      legend: {display: false},
      tooltip: {
        callbacks: {
          title: () => null,
        },
      },
    },
    scales: {
      x: {stacked: false},
      y: {stacked: false},
    },
  },
  maintainAspectRatio: false,
};

export function DashBoardSignupView() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [startDate, setStartDate] = useState(
    moment().add(-7, "day").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dashboardData, setDashboardData] =
    useState<IDashBoardSignupResponse[]>();
  const [chartData, setChartData] = useState<ChartData<any>>();

  async function getData() {
    try {
      const {data, success, message} = await adminAPI.dashboard.getSignupData({
        start_date: startDate,
        end_date: endDate,
      });
      if (success) {
        setDashboardData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getData error", error);
    }
  }

  function convertChartData() {
    try {
      if (!dashboardData) {
        return;
      }
      const data = dashboardData.sort((a, b) => {
        return moment(a.date).diff(moment(b.date)) > 0 ? 1 : -1;
      });

      const labels = data.map(item => moment(item.date).format("M/D"));
      const datasets = [
        {
          label: "회원가입",
          data: data.map(item => Number(item.signup_cnt || 0)),
        },
        {
          label: "탈퇴",
          data: data.map(item => Number(item.out_cnt || 0)),
        },
      ];

      setChartData({
        labels,
        datasets,
      });
    } catch (error) {
      console.error("convertChartData error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (dashboardData) {
      convertChartData();
    }
  }, [dashboardData]);

  return (
    <>
      <SearchFilter
        left={[
          <div key={0} className="row center">
            <div className="col">
              <InputBox
                type={"date"}
                placeholder="시작일"
                value={startDate}
                onValue={setStartDate}
                onEnter={getData}
              />
            </div>
            <div className="col">~</div>
            <div className="col">
              <InputBox
                type={"date"}
                placeholder="종료일"
                value={endDate}
                onValue={setEndDate}
                onEnter={getData}
              />
            </div>
          </div>,
        ]}
      />
      <Content>
        <>
          {!!chartData && (
            <div className={styles.signupChartContainer}>
              <Line options={chartOptions} data={chartData} />
            </div>
          )}
        </>
      </Content>
    </>
  );
}
