import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  CheckBox,
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
} from "@components";
import {ResultPagingResponse, TypeYN, IDBHomeTabList} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {utils, adminAPI} from "@utils";
import {HomeTabDetailModal} from "./HomeTabDetailModal";
import styles from "./style.module.scss";

export default function HomeTabPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IDBHomeTabList[]>>();
  const [detailData, setDetailData] = useState<IDBHomeTabList>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.homeTab.apiGetHomeTabList(
        {
          page,
          searchtxt: searchText,
        },
      );
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} =
        await adminAPI.homeTab.apiGetHomeTabDetail({no});
      if (success) {
        setDetailData(data);
        setIsDetailOpen(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function saveHomeTab() {
    try {
      if (!detailData) {
        return;
      }
      if (!detailData.name) {
        setAlertModal({
          isVisible: true,
          title: "탭 타이틀을 입력해주세요",
        });
      }
      if (
        !(detailData.bold_title || detailData.gray_title || detailData.title)
      ) {
        setAlertModal({
          isVisible: true,
          title: "제목을 입력해주세요",
        });
      }

      const {success, message} = await (detailData?.no
        ? adminAPI.homeTab.apiUpdateHomeTab({
            ...detailData,
            hide_yn: detailData.hide_yn || "Y",
          })
        : adminAPI.homeTab.apiCreateHomeTab({
            ...detailData,
            hide_yn: detailData.hide_yn || "Y",
          }));
      if (success) {
        setDetailData(undefined);
        setIsDetailOpen(false);
        getList();
        setAlertModal({
          isVisible: true,
          title: message || "저장완료",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function toggleHideYN({no, hide_yn}: {no: number; hide_yn: TypeYN}) {
    try {
      if (!no) {
        return;
      }

      const {success, message} = await adminAPI.homeTab.apiUpdateHomeTab({
        no,
        hide_yn,
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="홈 탭관리">
        <SearchFilter
          left={[
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => setIsDetailOpen(true)}
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>번호</th>
                <th>탭타이틀</th>
                <th>제목</th>
                <th>순번</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail({no: item.no})}>
                    <td>{item.no}</td>
                    <td>{item.name}</td>
                    <td className={styles.contentTd}>
                      <div className={styles.grayTitle}>
                        {item.gray_title || ""}
                      </div>
                      <div>{item.title || ""}</div>
                      <div className={styles.boldTitle}>
                        {item.bold_title || ""}
                      </div>
                    </td>
                    <td>{item.ordering || "-"}</td>
                    <td onClick={e => utils.handleClickBubble(e)}>
                      <CheckBox
                        checked={item.hide_yn === "N"}
                        text={item.hide_yn === "N" ? "노출" : "숨김"}
                        isToggle
                        onChange={e => {
                          if (!item.no) {
                            return;
                          }
                          toggleHideYN({
                            no: item.no,
                            hide_yn: item.hide_yn === "N" ? "Y" : "N",
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <HomeTabDetailModal
        isVisible={isDetailOpen}
        data={detailData}
        setData={setDetailData}
        save={saveHomeTab}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
        }}
      />
    </>
  );
}
