import {Button, InputBox, SelectBox, TextAreaBox} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {
  TProductDetailInformationKeepType,
  TProductDetailInformationPackType,
  TProductDetailInformationPackType2,
  TProductDetailInformationWeightType,
  IDBPetKind,
  IProductDetailInformation,
  TProductDetailInformationFeed,
} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";

interface IProps {
  item_no: number;
  detailData: IProductDetailInformation;
  refresh?: () => void;
  petKindList?: IDBPetKind[];
}

const keepSelectList: {
  label: string;
  value: TProductDetailInformationKeepType;
  subcontent: string;
}[] = [
  {
    label: "냉장보관",
    value: "냉장보관",
    subcontent: "0~10°C",
  },
  {
    label: "냉동보관",
    value: "냉동보관",
    subcontent: "-18°C 이하",
  },
  {
    label: "실온보관",
    value: "실온보관",
    subcontent: "1~35°C",
  },
  {
    label: "온장보관",
    value: "온장보관",
    subcontent: "60°C 이상",
  },
];

export function InformationItem({
  detailData,
  refresh,
  item_no,
  petKindList,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailInformation>({
    type: "information",
    data_json: {
      data: [],
    },
  });

  async function save() {
    try {
      const pet_kind_list = (
        (
          (data.data_json?.data || []).find(
            item => item.title === "급여대상",
          ) as TProductDetailInformationFeed
        )?.content || []
      )
        .filter(item => utils.isDefined(item) && !isNaN(Number(item)))
        .map(item => Number(item));

      const params = {
        ...data,
        data_json: {...data.data_json},
        item_no,
        pet_kind_list,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{flex: 1}}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        {(data.data_json?.data || []).map((item, index) => {
          return (
            <div key={index}>
              <div className="row">
                <div className="col title">{item.title}</div>
                <div className="col">
                  {item.title === "급여대상" && (
                    <SelectBox
                      multiple
                      value={item.content}
                      list={(petKindList || []).map(pet => ({
                        label: pet.kind_name || "",
                        value: String(pet.no),
                      }))}
                      multipleSelect={values => {
                        setData?.({
                          ...data,
                          data_json: {
                            ...data.data_json,
                            data: (data.data_json?.data || []).map(_item =>
                              _item.title === "급여대상"
                                ? {...item, content: values}
                                : _item,
                            ),
                          },
                        });
                      }}
                    />
                  )}
                  {item.title === "포장타입" && (
                    <>
                      <SelectBox
                        value={item.content}
                        list={
                          [
                            {label: "냉동", value: "냉동"},
                            {label: "냉장", value: "냉장"},
                            {label: "상온", value: "상온"},
                          ] as {
                            label: string;
                            value: TProductDetailInformationPackType;
                          }[]
                        }
                        singleSelect={value => {
                          setData?.({
                            ...data,
                            data_json: {
                              ...data.data_json,
                              data: (data.data_json?.data || []).map(_item =>
                                _item.title === "포장타입"
                                  ? {
                                      ...item,
                                      content:
                                        value as TProductDetailInformationPackType,
                                    }
                                  : _item,
                              ),
                            },
                          });
                        }}
                      />
                      <SelectBox
                        value={item.subcontent}
                        list={
                          [
                            {label: "미선택", value: ""},
                            {label: "(종이포장)", value: "(종이포장)"},
                            {label: "(비닐포장)", value: "(비닐포장)"},
                          ] as {
                            label: string;
                            value: TProductDetailInformationPackType2;
                          }[]
                        }
                        singleSelect={value => {
                          setData?.({
                            ...data,
                            data_json: {
                              ...data.data_json,
                              data: (data.data_json?.data || []).map(_item =>
                                _item.title === "포장타입"
                                  ? {
                                      ...item,
                                      subcontent:
                                        value as TProductDetailInformationPackType2,
                                    }
                                  : _item,
                              ),
                            },
                          });
                        }}
                      />
                    </>
                  )}
                  {item.title === "판매단위" && (
                    <InputBox
                      value={item.content}
                      onValue={value =>
                        setData?.({
                          ...data,
                          data_json: {
                            ...data.data_json,
                            data: (data.data_json?.data || []).map(_item =>
                              _item.title === "판매단위"
                                ? {...item, content: value}
                                : _item,
                            ),
                          },
                        })
                      }
                    />
                  )}
                  {item.title === "중량/용량" && (
                    <>
                      <InputBox
                        value={item.content}
                        onValue={value =>
                          setData?.({
                            ...data,
                            data_json: {
                              ...data.data_json,
                              data: (data.data_json?.data || []).map(_item =>
                                _item.title === "중량/용량"
                                  ? {...item, content: value}
                                  : _item,
                              ),
                            },
                          })
                        }
                      />
                      <SelectBox
                        value={item.subcontent}
                        list={
                          [
                            {label: "g", value: "g"},
                            {label: "kg", value: "kg"},
                            {label: "ml", value: "ml"},
                          ] as {
                            label: string;
                            value: TProductDetailInformationWeightType;
                          }[]
                        }
                        singleSelect={value => {
                          setData?.({
                            ...data,
                            data_json: {
                              ...data.data_json,
                              data: (data.data_json?.data || []).map(_item =>
                                _item.title === "중량/용량"
                                  ? {
                                      ...item,
                                      subcontent:
                                        value as TProductDetailInformationWeightType,
                                    }
                                  : _item,
                              ),
                            },
                          });
                        }}
                      />
                    </>
                  )}
                  {item.title === "보관방법" && (
                    <div style={{flex: 1}}>
                      <div>
                        <SelectBox
                          value={item.content}
                          list={[
                            {label: "미선택", value: ""},
                            ...keepSelectList,
                          ]}
                          singleSelect={value => {
                            setData?.({
                              ...data,
                              data_json: {
                                ...data.data_json,
                                data: (data.data_json?.data || []).map(_item =>
                                  _item.title === "보관방법"
                                    ? {
                                        ...item,
                                        content:
                                          value as TProductDetailInformationKeepType,
                                        subcontent:
                                          keepSelectList.find(
                                            keep => keep.value === value,
                                          )?.subcontent || "",
                                      }
                                    : _item,
                                ),
                              },
                            });
                          }}
                        />
                      </div>
                      <div>
                        <TextAreaBox
                          value={item.subcontent}
                          onValue={value => {
                            setData?.({
                              ...data,
                              data_json: {
                                ...data.data_json,
                                data: (data.data_json?.data || []).map(_item =>
                                  _item.title === "보관방법"
                                    ? {...item, subcontent: value}
                                    : _item,
                                ),
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
