import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {TDeliveryType, IDBDeliveryAddress} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  order_no: number;
  member_no: number;
  prev_delivery_address_no?: number;
  delivery_type?: TDeliveryType;
  close?: () => void;
  refresh?: () => void;
}

export function OrderDeliveryChangeModal({
  isVisible,
  order_no,
  member_no,
  prev_delivery_address_no,
  delivery_type,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [deliveryAddressList, setDeliveryAddressList] = useState<
    IDBDeliveryAddress[]
  >([]);
  const [deliveryNo, setDeliveryNo] = useState(prev_delivery_address_no);

  const selectedDeliveryAddress = (deliveryAddressList.filter(
    item => item.no === deliveryNo,
  ) || [])?.[0];
  const selectedDeliveryType = (
    selectedDeliveryAddress
      ? selectedDeliveryAddress.dawn_delivery_yn === "Y"
        ? "새벽"
        : selectedDeliveryAddress.plus_delivery_yn === "Y"
        ? "도서산간"
        : "일반"
      : undefined
  ) as TDeliveryType | undefined;
  const isSameDeliveryType = delivery_type === selectedDeliveryType;

  async function getDeliveryAddressList() {
    try {
      const {data, success} = await adminAPI.member.getDeliveryAddressList({
        member_no,
      });
      if (success) {
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.error("getDeliveryAddressList error", error);
    }
  }

  async function saveAddress() {
    try {
      if (!deliveryNo || !order_no || !member_no) {
        return;
      }
      if (!confirm("배송지를 수정하시겠습니까?")) {
        return;
      }

      const {success, message} =
        await adminAPI.order.changeOrderDeliveryAddressNo({
          order_no,
          delivery_address_no: deliveryNo,
        });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        close?.();
        refresh?.();
      }
    } catch (error) {
      console.error("saveAddress error", error);
    }
  }

  useEffect(() => {
    getDeliveryAddressList();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={close}>
      <ModalTitle title="배송지변경" isClose close={close} />
      <ModalContent>
        <div className="row">
          <div className="col title">배송지</div>
          <div className="col">
            <SelectBox
              value={String(deliveryNo)}
              list={(deliveryAddressList || []).map(item => {
                const isDawn = item.dawn_delivery_yn === "Y";
                const isPlus = item.plus_delivery_yn === "Y";
                const deliveryType: TDeliveryType = isDawn
                  ? "새벽"
                  : isPlus
                  ? "도서산간"
                  : "일반";
                return {
                  label: `${
                    item.main_address_yn === "Y" ? "[주배송지] " : ""
                  }(${deliveryType}) ${item.road_address} ${
                    item.detail_address
                  }`,
                  value: String(item.no),
                };
              })}
              singleSelect={value => setDeliveryNo(Number(value))}
            />
          </div>
        </div>
        {!!selectedDeliveryAddress && (
          <div className="row">
            <div className="col">
              주소: {selectedDeliveryAddress.road_address}{" "}
              {selectedDeliveryAddress.detail_address}
              {!!selectedDeliveryAddress.jibun_address && (
                <>
                  <br />({selectedDeliveryAddress.jibun_address})
                </>
              )}
              <br />
              수령인: {selectedDeliveryAddress.sender_name}
              <br />
              휴대폰번호: {selectedDeliveryAddress.sender_phone}
              <br />
              {!!selectedDeliveryAddress.sub_phone && (
                <>
                  전화번호: {selectedDeliveryAddress.sub_phone}
                  <br />
                </>
              )}
              {selectedDeliveryAddress.entrance_yn === "Y" && (
                <>공동현관: {selectedDeliveryAddress.entrance_memo}</>
              )}
            </div>
          </div>
        )}
        {!isSameDeliveryType ? (
          <div className="row">
            <div className="col">
              <span style={{color: styleUtils.COLOR.danger}}>
                배송지 유형이 현재 주문건과 다릅니다.
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="저장" onClick={saveAddress} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
