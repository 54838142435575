import React from "react";
import styles from "./style.module.scss";

interface IProps {
  children?: JSX.Element | JSX.Element[];
}

export default function ModalFooter({children}: IProps) {
  return <div className={styles.footer}>{children}</div>;
}
