import React, {useEffect, useState} from "react";
import {Header} from "./Header";
import {Sidebar} from "./Sidebar";
import styles from "./style.module.scss";
import ivoryLogo_small from "../../assets/images/ivoryLogo_small.png";
import ivoryLogo from "../../assets/images/ivoryLogo.png";
import {useLocation} from "react-router-dom";
import {ICommonResponse} from "@data";
import {adminAPI, constant} from "@utils";

interface IProps {
  children: JSX.Element;
}

export default function Layout({children}: IProps) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(
    window.innerWidth > constant.MOBILE_WIDTH,
  );
  const [commonData, setCommonData] = useState<ICommonResponse>({});

  async function getCommonData() {
    try {
      const {data, success} = await adminAPI.admin.apiGetCommon();
      if (success) {
        setCommonData(data);
      }
    } catch (error) {
      console.error("getCommonData error", error);
    }
  }

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  function closeMenu() {
    setIsOpen(false);
  }

  useEffect(() => {
    getCommonData();

    if (window.innerWidth <= constant.MOBILE_WIDTH) {
      closeMenu();
    }
  }, [location]);

  return (
    <div className={styles.pane}>
      <Header isOpen={isOpen} toggleMenu={toggleMenu} />
      <div
        className={[styles.sidebar, isOpen ? styles.open : styles.close].join(
          " ",
        )}>
        <div className={styles.sidebarHeader}>
          <img src={isOpen ? ivoryLogo : ivoryLogo_small} />
        </div>
        <Sidebar
          itemQnaCount={commonData.item_qna_count}
          memberQnaCount={commonData.member_qna_count}
          frequnecyApplyCount={commonData.frequnecy_apply_count}
          memberOutRequestCount={commonData.member_out_request_count}
        />
      </div>
      <div className={styles.content}>
        <div
          className={[
            styles.sidebarPlaceholder,
            isOpen ? styles.open : styles.close,
          ].join(" ")}
        />
        <div className={styles.mainContent}>{children}</div>
      </div>
    </div>
  );
}
