import {Button, CheckBox, ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYN,
  IReviewDetailResponse,
  IReviewListResponse,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import StarImg from "../../../assets/images/Star.png";
import StarGreenImg from "../../../assets/images/StarGreen.png";
import styles from "../style.module.scss";
import {ADMIN_PASSWORD} from "utils/constant";
import {COLOR} from "utils/styleUtils";

interface IProps {
  member_no: number;
}

export function ReviewTab({member_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<
      ResultPagingResponse<{list?: IReviewListResponse[]; allCnt?: number}>
    >();
  const [detailData, setDetailData] = useState<IReviewDetailResponse>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.item.getReviewList({
        page,
        member_no,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.item.apiGetReviewDetail({
        no,
      });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function save({
    no,
    item_no,
    best_yn,
  }: {
    no: number;
    item_no: number;
    best_yn: TypeYN;
  }) {
    if (!confirm("수정하시겠습니까?")) {
      return;
    }

    try {
      const {success, message} = await adminAPI.item.apiUpdateRreviewBestYn({
        no,
        item_no,
        best_yn,
      });
      if (success) {
        setDetailData(undefined);
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function deleteReview(no: number) {
    const password = prompt("비밀번호를 입력해주세요.");
    if (password !== ADMIN_PASSWORD) {
      alert("비밀번호가 일치하지 않아요.");
      return;
    }

    try {
      const {success, message} = await adminAPI.item.deleteReview({
        no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });

      if (success) {
        setDetailData(undefined);
        getList({page: listData?.page?.cur || 1});
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className={["row", styles.reivewTab].join(" ")}>
      <div className={styles.row}>
        <div className={styles.col}>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>제품명</th>
                <th>별점</th>
                <th>베스트리뷰</th>
                <th>작성일</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result?.list || []).map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    if (!item.no) {
                      return;
                    }
                    getDetail({no: item.no});
                  }}>
                  <td className={styles.itemName}>
                    [{item.product?.partners?.partner_name}]{" "}
                    {item.product?.item_name}
                  </td>
                  <td>
                    <div className={styles.scoreContainer}>
                      {[...Array(5).keys()].map(starIndex => {
                        const isActive =
                          Number(item.score || 0) >= starIndex + 1;
                        return (
                          <img
                            key={starIndex}
                            src={isActive ? StarGreenImg : StarImg}
                          />
                        );
                      })}
                    </div>
                    {(item.photo_cnt || 0) > 0 && (
                      <span className="text-10">
                        (사진 {(item.photo_cnt || 0).toLocaleString()}
                        개)
                      </span>
                    )}
                  </td>
                  <td>
                    <CheckBox
                      checked={item.best_yn === "Y"}
                      text={item.best_yn === "Y" ? "O" : "X"}
                      isToggle
                      onChange={e => {
                        if (!item.no || !item.item_no) {
                          return;
                        }
                        save({
                          no: item.no,
                          item_no: item.item_no,
                          best_yn: item.best_yn === "Y" ? "N" : "Y",
                        });
                      }}
                    />
                  </td>
                  <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                </tr>
              ))}
            </tbody>
          </ListTable>
        </div>

        <div className={styles.detailContainer}>
          {!!detailData && (
            <>
              <div className={styles.member}>
                <div>
                  <span>
                    {detailData?.member?.name} ({detailData?.member?.nickname})
                  </span>
                  <div>
                    {utils.formatPhoneNumber(
                      detailData?.member?.phone_number || "",
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className={styles.scoreContainer}
                    style={{justifyContent: "flex-end"}}>
                    {[...Array(5).keys()].map(starIndex => {
                      const isActive =
                        Number(detailData.score || 0) >= starIndex + 1;
                      return (
                        <img
                          key={starIndex}
                          src={isActive ? StarGreenImg : StarImg}
                        />
                      );
                    })}
                  </div>
                  <div>
                    {moment(detailData.createdAt).format("YYYY-MM-DD HH:mm")}
                  </div>
                </div>
              </div>
              {(detailData.options || []).length > 0 && (
                <div className={styles.optionContainer}>
                  {(detailData.options || []).map((option, optionIndex) => (
                    <>
                      {optionIndex > 0 && (
                        <span
                          key={`dot_${optionIndex}`}
                          className={styles.dot}
                        />
                      )}
                      <div key={option.no} className={styles.item}>
                        <span className={styles.category}>
                          {option.category?.title}
                        </span>
                        <span className={styles.option}>
                          {option.option?.title}
                        </span>
                      </div>
                    </>
                  ))}
                </div>
              )}
              <div className={styles.contentContainer}>
                <div className={styles.content}>{detailData?.content}</div>
                <div className={styles.photoContainer}>
                  {(detailData.photos || []).map(photo => {
                    const img_url = photo.thumbnail_img_url || photo.img_url;
                    return <img key={photo.no} src={img_url} />;
                  })}
                </div>
              </div>
              <div style={{display: "flex", justifyContent: "flex-end"}}>
                <div>
                  <Button
                    text="삭제"
                    onClick={() => {
                      if (detailData?.no) {
                        deleteReview(detailData.no);
                      }
                    }}
                    backgroundColor={COLOR.danger}
                    fontColor={COLOR.white}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
