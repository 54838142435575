import React, {useEffect, useState} from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  TextAreaBox,
  RadioBox,
} from "@components";
import {styleUtils, utils} from "@utils";
import styles from "../style.module.scss";
import {IMemberClaim, TClaimType} from "@data";

interface IProps {
  visible: boolean;
  data?: IMemberClaim;
  save?: (data: {content: string; important: number; type: string}) => void;
  close?: () => void;
}

const TYPE_LIST: TClaimType[] = ["주문", "단순문의", "클레임"];
const DEFAULT_IMPORTANT = "1";
const DEFAULT_TYPE = TYPE_LIST[0];

export function ClaimDetailModal({visible, data, save, close}: IProps) {
  const [content, setContent] = useState<string>();
  const [claimImportant, setClaimImportant] =
    useState<string>(DEFAULT_IMPORTANT);
  const [type, setType] = useState<TClaimType>(DEFAULT_TYPE);

  function setSave() {
    try {
      if (isNaN(Number(claimImportant))) {
        return alert("중요도를 확인해주세요.");
      }
      if (!content) {
        return alert("내용을 입력해주세요");
      }
      save?.({content, important: Number(claimImportant), type});
    } catch (error) {
      console.error("setSave error", error);
    }
  }

  useEffect(() => {
    if (visible) {
      setContent(data?.content);
      setClaimImportant(
        data?.important ? String(data?.important) : DEFAULT_IMPORTANT,
      );
      setType(data?.type || DEFAULT_TYPE);
    } else {
      setContent(undefined);
      setClaimImportant(DEFAULT_IMPORTANT);
      setType(DEFAULT_TYPE);
    }
  }, [visible, data]);

  return (
    <Modal close={close} isVisible={visible} width={"500px"}>
      <ModalTitle close={close} isClose title="클레임상세" />
      <ModalContent>
        <div className="row">
          <div
            style={{
              fontSize: "14px",
              marginRight: "16px",
              display: "flex",
              alignItems: "center",
              minWidth: "80px",
            }}>
            구분
          </div>
          <div className="col">
            <RadioBox
              list={TYPE_LIST.map(item => ({text: item, value: item}))}
              setValue={value => setType(value as TClaimType)}
              value={type}
            />
          </div>
        </div>
        <div className="row">
          <div
            style={{
              fontSize: "14px",
              marginRight: "16px",
              display: "flex",
              alignItems: "center",
              minWidth: "80px",
            }}>
            중요도
          </div>
          <div className="col">
            <RadioBox
              list={[
                {value: "1", text: "높음"},
                {value: "2", text: "중간"},
                {value: "3", text: "낮음"},
              ]}
              setValue={setClaimImportant}
              value={claimImportant}
            />
          </div>
        </div>
        <div className={styles.answerContainer}>
          <div className={styles.content}>
            <TextAreaBox value={content} onValue={setContent} height={250} />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button
            text="저장"
            backgroundColor={styleUtils.COLOR.blueDark}
            fontColor={styleUtils.COLOR.white}
            onClick={setSave}
          />
        </div>
        <div>
          <Button text="닫기" onClick={close} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
