import {envUtil} from "@utils";
import {ZOOTOPIA_LINK_URL} from "./constant";
import CryptoJS, {AES, enc} from "crypto-js";
import {IDBDeliveryAddress} from "@data";

export function encryptQuery({query}: {query: string}) {
  const encryptQuery = encrypt(query, envUtil.ENCRYPT_KEY);
  return encryptQuery;
}

export function decryptQuery({query}: {query: string}) {
  const newQuery = query.replace(/[?]/gi, "");
  const decryptQuery = decrypt(newQuery, envUtil.ENCRYPT_KEY);
  return decryptQuery;
}

export function encryptCopyURL({query}: {query: string}) {
  const encryptQuery = encrypt(query, envUtil.ENCRYPT_KEY);
  const url = `${ZOOTOPIA_LINK_URL}/${encryptQuery}`;
  return url;
}

export function encrypt(data: string, key: string): string {
  const dataWA = enc.Utf8.parse(data);
  const keyWA = enc.Utf8.parse(key);
  const ivWA = enc.Utf8.parse(key.substring(0, 16));

  const cipher = AES.encrypt(dataWA, keyWA, {
    iv: ivWA,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const result = cipher.ciphertext.toString(enc.Base64url);
  return result;
}

export function decrypt(encData: string, key: string): string {
  const keyWA = enc.Utf8.parse(key);
  const ivWA = enc.Utf8.parse(key.substring(0, 16));

  const cipher = AES.decrypt(
    enc.Base64url.parse(encData.replace(/=/gi, "")).toString(enc.Base64),
    keyWA,
    {iv: ivWA, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC},
  );
  const result = cipher.toString(enc.Utf8);
  return result;
}

export function formatEntranceMemo({
  delivery_address,
}: {
  delivery_address?: IDBDeliveryAddress;
}) {
  let response = "";
  switch (delivery_address?.entrance_type) {
    case "password":
      response = `공동현관 비밀번호 : ${
        delivery_address.entrance_yn === "N"
          ? "없음"
          : delivery_address.entrance_memo?.replace(/\n/g, " ")
      }`;
      break;
    case "free":
      response = "공동현관 비밀번호 : 자유 출입 가능";
      break;
    case "security":
      response = "공동현관 비밀번호 : 경비실 호출";
      break;
    case "etc":
      response = `공동현관 비밀번호 : ${delivery_address.entrance_memo?.replace(
        /\n/g,
        " ",
      )}`;
      break;
  }
  return response;
}
