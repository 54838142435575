import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {styleUtils} from "@utils";
import {IDBProduct, IPostRequest} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {adminAPI} from "utils";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  prevData?: IPostRequest;
  close?: () => void;
  refresh?: () => void;
  itemList?: IDBProduct[];
}

export function PostModal({
  prevData,
  isVisible,
  close,
  refresh,
  itemList,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IPostRequest>();

  function setClose() {
    setData(undefined);
    close?.();
  }

  async function deletePost() {
    try {
      if (!data?.no) {
        return;
      }
      if (!confirm("섹션을 삭제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.post.toggleYN({
        no: data?.no,
        del_yn: "Y",
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("deletePost error", error);
    }
  }

  async function save() {
    try {
      if (!data?.title) {
        return setAlertModal({
          isVisible: true,
          title: "타이틀을 입력해주세요.",
        });
      }

      const {success, message} = data?.no
        ? await adminAPI.post.updatePost(data)
        : await adminAPI.post.createPost(data);
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    if (prevData) {
      setData(prevData);
    } else {
      setData(undefined);
    }
  }, [prevData]);

  return (
    <>
      <Modal isVisible={isVisible} width={"700px"} close={setClose}>
        <ModalTitle title="상세" isClose close={setClose} />
        <ModalContent>
          <div className="row top">
            <div className="col title">타이틀</div>
            <div className="col">
              <TextAreaBox
                value={data?.title}
                onValue={value => setData?.({...data, title: value})}
                height={50}
              />
            </div>
          </div>
          <div className="row top">
            <div className="col title">서브타이틀</div>
            <div className="col">
              <TextAreaBox
                value={data?.content}
                onValue={value => setData?.({...data, content: value})}
                height={100}
              />
            </div>
          </div>
          <div className="row top">
            <div className="col title">인스타링크</div>
            <div className="col">
              <InputBox
                value={data?.instagram_url}
                onValue={value =>
                  setData?.({
                    ...data,
                    instagram_url: (value || "").replace(/ /gi, ""),
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">상품선택</div>
            <div className="col">
              <SelectBox
                multiple
                list={(itemList || []).map(item => ({
                  value: String(item.no),
                  label: String(item.item_name || ""),
                }))}
                value={(data?.item_list || []).map(item => String(item))}
                multipleSelect={value =>
                  setData?.({
                    ...data,
                    item_list: value.map(item => Number(item)),
                  })
                }
              />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div>
            {!!data?.no && (
              <Button
                text="삭제"
                onClick={deletePost}
                backgroundColor={styleUtils.COLOR.red400}
              />
            )}
          </div>
          <div className="col">
            <div className="row">
              <Button
                text="저장"
                onClick={save}
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
              />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
