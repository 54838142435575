import {
  Button,
  Content,
  ContentLayout,
  FileUploadButton,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, excelUtil, styleUtils} from "@utils";
import {
  ResultPagingResponse,
  TFilterSelectUpDown,
  TOrderProfitSelect,
  IOrderDetail,
  IOrderSettlementResponse,
} from "@data";
import _ from "lodash";
import moment from "moment";
import {OrderDetailModal} from "pages/OrderPage/OrderDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {StoreFeeDetailModal} from "./StoreFeeDetailModal";
import styles from "./style.module.scss";
import {getPercent, strReplaceJustNumber} from "utils/utils";

const convertFilterSelect: {
  [key in TOrderProfitSelect]: string;
} = {
  minus: "수익-",
  plus: "수익+",
};
const filterList: TOrderProfitSelect[] = ["minus"];

export default function OrderProfitPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [startDate, setStartDate] = useState(
    moment().add(-1, "week").startOf("day").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD"),
  );
  const [orderNumber, setOrderNumber] = useState("");
  const [point, setPoint] = useState("");
  const [pointFilter, setPointFilter] = useState<TFilterSelectUpDown>("up");
  const [profit, setProfit] = useState("");
  const [profitFilter, setProfitFilter] = useState<TFilterSelectUpDown>("up");
  const [selectFilter, setSelectFilter] = useState<TOrderProfitSelect>();
  const [limit, setLimit] = useState(15);
  const [listData, setListData] =
    useState<ResultPagingResponse<IOrderSettlementResponse[]>>();
  const [summaryMonthData, setSummaryMonthData] =
    useState<IOrderSettlementResponse>();
  const [summaryData, setSummaryData] = useState<IOrderSettlementResponse>();
  const [orderDetailData, setOrderDetailData] = useState<IOrderDetail>();
  const [isStoreFeeModal, setIsStoreFeeModal] = useState(false);

  async function getData({page = 1}: {page?: number} = {}) {
    try {
      if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
        return setAlertModal({
          isVisible: true,
          title: "조회 기간을 확인해주세요.",
        });
      }

      getSummary({isMonth: false});
      getSummary({isMonth: true});

      const {data, success} = await adminAPI.kpi.getOrderSettlementList({
        start_date: startDate,
        end_date: endDate,
        order_number: orderNumber,
        page,
        limit,
        isMinus: selectFilter === "minus",
        point: point ? Number(point) : undefined,
        pointSelect: pointFilter,
        profit: profit ? Number(profit) : undefined,
        profitSelect: profitFilter,
      });
      if (success) {
        setListData(data);
      }
    } catch (error) {
      console.error("gatData error", error);
    }
  }

  async function getSummary({isMonth = true}: {isMonth?: boolean}) {
    try {
      if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
        return setAlertModal({
          isVisible: true,
          title: "조회 기간을 확인해주세요.",
        });
      }

      const {data, success} = await adminAPI.kpi.getOrderSettlementSummary({
        start_date: startDate,
        end_date: endDate,
        ...(!isMonth
          ? {
              order_number: orderNumber,
              isMinus: selectFilter === "minus",
              point: point ? Number(point) : undefined,
              pointSelect: pointFilter,
              profit: profit ? Number(profit) : undefined,
              profitSelect: profitFilter,
            }
          : undefined),
      });
      if (success) {
        if (isMonth) {
          setSummaryMonthData(data);
        } else {
          setSummaryData(data);
        }
      }
    } catch (error) {
      console.error("getSummary error", error);
    }
  }

  async function getDetailOrder({order_no}: {order_no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no: order_no,
      });
      if (success) {
        setOrderDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetailOrder error", error);
    }
  }

  async function downLoadExcelForm() {
    try {
      const {success, data, message} = await adminAPI.kpi.excelFormDownload();
      if (success) {
        excelUtil.excelDownload({
          excelData: data.result,
          filename: "deliveryUploadFormFile",
        });
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.log("downLoadExcelForm error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [limit, selectFilter]);

  return (
    <>
      <ContentLayout title="장바구니손익관리">
        <SearchFilter
          left={[
            <div key={0} className="row" style={{minWidth: 260}}>
              <InputBox
                type={"date"}
                placeholder="연월일 입력"
                value={startDate}
                onValue={setStartDate}
                onEnter={getData}
              />
              ~
              <InputBox
                type={"date"}
                placeholder="연월일 입력"
                value={endDate}
                onValue={setEndDate}
                onEnter={getData}
              />
            </div>,
            <InputBox
              key={1}
              placeholder="포인트입력"
              inputStyle={{width: "70px"}}
              rightContentStyle={{padding: 0, minWidth: "80px"}}
              rightContent={
                <SelectBox
                  list={[
                    {label: "이상", value: "up"},
                    {label: "미만", value: "down"},
                  ]}
                  value={pointFilter}
                  singleSelect={e => setPointFilter(e as TFilterSelectUpDown)}
                  boxStyle={{height: "28px"}}
                />
              }
              value={String(point)}
              onValue={data => setPoint(strReplaceJustNumber(data))}
              onEnter={getData}
            />,
            <InputBox
              key={2}
              placeholder="순이익입력"
              inputStyle={{width: "70px"}}
              rightContentStyle={{padding: 0, minWidth: "80px"}}
              rightContent={
                <SelectBox
                  list={[
                    {label: "이상", value: "up"},
                    {label: "미만", value: "down"},
                  ]}
                  value={profitFilter}
                  singleSelect={e => setProfitFilter(e as TFilterSelectUpDown)}
                  boxStyle={{height: "28px"}}
                />
              }
              value={String(profit)}
              onValue={data => setProfit(strReplaceJustNumber(data))}
              onEnter={getData}
            />,
            <InputBox
              key={3}
              type={"text"}
              placeholder="주문번호 입력"
              rightContent={
                <div style={{display: "flex"}} onClick={() => getData()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              value={orderNumber}
              onValue={setOrderNumber}
              onEnter={getData}
            />,
          ]}
          right={[
            <SelectBox
              key={_.uniqueId()}
              list={[
                {value: "", label: "전체"},
                ...filterList.map(key => ({
                  value: key,
                  label: convertFilterSelect[key],
                })),
              ]}
              value={selectFilter}
              singleSelect={value =>
                setSelectFilter(value as TOrderProfitSelect)
              }
              boxStyle={{minWidth: "100px"}}
            />,
            <SelectBox
              key={_.uniqueId()}
              value={String(limit)}
              list={[15, 50, 100, 200, 300, 500].map(item => ({
                value: String(item),
                label: String(item),
              }))}
              singleSelect={value => setLimit(Number(value))}
            />,
            <Button
              key={_.uniqueId()}
              text="업로드폼다운로드"
              onClick={downLoadExcelForm}
              fontSize={14}
              marginHorizontal={2}
              backgroundColor={styleUtils.COLOR.orange}
            />,
            <FileUploadButton
              key={_.uniqueId()}
              text="업로드"
              backgroundColor={styleUtils.COLOR.success}
              callback={() => getData({page: listData?.page?.cur || 1})}
              type={"excel"}
              uploadUrl={"/admin/kpi/importExcel"}
            />,
          ]}
        />
        <Content>
          <div style={{flex: 1}}>
            <table className={styles.summary}>
              <tr>
                <td className={styles.title}>
                  {startDate}~{endDate}
                </td>
                <td>
                  <div>
                    <span>순매출액</span>
                    <span className={styles.badge}>
                      {(summaryMonthData?.profit_price || 0).toLocaleString()}원
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>순이익</span>
                    <span className={styles.badge}>
                      {(
                        summaryMonthData?.final_profit_price || 0
                      ).toLocaleString()}
                      원
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>이익율</span>
                    <span className={styles.badge}>
                      {getPercent({
                        part: summaryMonthData?.final_profit_price,
                        total: summaryMonthData?.final_price,
                      })}
                      %
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>이익</span>
                    <span className={styles.badge}>
                      {(summaryMonthData?.profit_count || 0).toLocaleString()}건
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>손해</span>
                    <span className={styles.badge}>
                      {(summaryMonthData?.loss_count || 0).toLocaleString()}건
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>회수가능 비율</span>
                    <span className={styles.badge}>
                      {getPercent({
                        part: summaryData?.profit_count,
                        total: summaryData?.total_count,
                      })}
                      %
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={styles.title}>
                  조회데이터 ({(listData?.page?.maxcnt || 0).toLocaleString()}
                  건)
                </td>
                <td>
                  <div>
                    <span>순매출액</span>
                    <span className={[styles.badge, styles.success].join(" ")}>
                      {(summaryData?.profit_price || 0).toLocaleString()}원
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>순이익</span>
                    <span className={[styles.badge, styles.success].join(" ")}>
                      {(summaryData?.final_profit_price || 0).toLocaleString()}
                      원
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>이익율</span>
                    <span className={[styles.badge, styles.success].join(" ")}>
                      {getPercent({
                        part: summaryData?.final_profit_price,
                        total: summaryData?.final_price,
                      })}
                      %
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>이익</span>
                    <span className={[styles.badge, styles.success].join(" ")}>
                      {(summaryData?.profit_count || 0).toLocaleString()}건
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>손해</span>
                    <span className={[styles.badge, styles.success].join(" ")}>
                      {(summaryData?.loss_count || 0).toLocaleString()}건
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>회수가능 비율</span>
                    <span className={[styles.badge, styles.success].join(" ")}>
                      {getPercent({
                        part: summaryData?.profit_count,
                        total: summaryData?.total_count,
                      })}
                      %
                    </span>
                  </div>
                </td>
              </tr>
            </table>
            <div>
              <ListTable getList={getData} paging={listData?.page}>
                <thead>
                  <tr>
                    <th>주문번호</th>
                    <th>주문액</th>
                    <th>쿠폰할인</th>
                    <th>포인트할인</th>
                    <th>
                      배송비
                      <br />
                      (고객부담)
                    </th>
                    <th>
                      결제액
                      <br />
                      (배송비포함)
                    </th>
                    <th>매입액</th>
                    <th>평균수수료율</th>
                    <th>순매출액</th>
                    <th>카드수수료</th>
                    <th>
                      배송비
                      <br />
                      (팀프레시)
                    </th>
                    <th>
                      보관비배분
                      <br />
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="입력"
                          backgroundColor={styleUtils.COLOR.warning}
                          paddingVertical={1}
                          paddingHorizontal={6}
                          fontSize={12}
                          onClick={() => setIsStoreFeeModal(true)}
                        />
                      </div>
                    </th>
                    <th>비용합계</th>
                    <th>순이익(총이익)</th>
                  </tr>
                </thead>
                <tbody>
                  {(listData?.result || []).map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.order_number}
                        <br />
                        <div style={{display: "inline-block"}}>
                          <Button
                            text="상세"
                            backgroundColor={styleUtils.COLOR.success}
                            fontColor={styleUtils.COLOR.white}
                            paddingVertical={1}
                            paddingHorizontal={6}
                            fontSize={12}
                            onClick={() => {
                              if (item?.no) {
                                getDetailOrder({order_no: item.no});
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td className="text-right">
                        {(item.order_price || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.coupon_discount || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.point_discount || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.delivery_price || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.payment_price || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.purchase_price || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {item.avg_store_fee_percent || 0}%
                      </td>
                      <td
                        className={[
                          "text-right",
                          (item.profit_price || 0) < 0 && styles.minus,
                        ].join(" ")}>
                        {(item.profit_price || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.card_fee || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.delivery_fee || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.store_fee || 0).toLocaleString()}
                      </td>
                      <td className="text-right">
                        {(item.all_cost_price || 0).toLocaleString()}
                      </td>
                      <td
                        className={[
                          "text-right",
                          (item.final_profit_price || 0) < 0 && styles.minus,
                        ].join(" ")}>
                        {(item.final_profit_price || 0).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ListTable>
            </div>
          </div>
        </Content>
      </ContentLayout>
      {isStoreFeeModal && (
        <StoreFeeDetailModal
          isVisible={isStoreFeeModal}
          month={moment(startDate)}
          close={() => setIsStoreFeeModal(false)}
          refresh={() => getData({page: listData?.page?.cur || 1})}
        />
      )}
      {!!orderDetailData && (
        <OrderDetailModal
          isVisible={!!orderDetailData}
          close={() => setOrderDetailData(undefined)}
          data={orderDetailData}
          refresh={() => {
            if (orderDetailData?.no) {
              getDetailOrder({order_no: orderDetailData.no});
            }
          }}
        />
      )}
    </>
  );
}
