import {
  Button,
  FileUploadButton,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {IDBPostDetail, IPostDetail, IPostDetailRequest} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  data?: IPostDetail;
  post_no: number;
  close?: () => void;
  refresh?: () => void;
}

export function PostDetailModal({
  isVisible,
  data: prevData,
  post_no,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IDBPostDetail>({});
  const [photos, setPhotos] = useState<string[]>([]);

  function setClose() {
    close?.();
  }

  async function save() {
    try {
      if (!confirm("저장하시겠습니까?")) {
        return;
      }

      const params: IPostDetailRequest = {
        post_no,
        no: data.no,
        title: data.title || "",
        content: data.content || "",
        photo_list: photos,
      };

      const {success, message} = params.no
        ? await adminAPI.post.updatePostDetail(params)
        : await adminAPI.post.createPostDetail(params);
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    if (prevData) {
      setData(prevData);
      setPhotos(
        (prevData?.photos || [])
          .map(item => item.img_url)
          .filter(utils.isDefined),
      );
    } else {
      setData({});
      setPhotos([]);
    }
  }, [prevData]);

  return (
    <>
      <Modal isVisible={isVisible} width={"500px"} close={setClose}>
        <ModalTitle title="상세" isClose close={setClose} />
        <ModalContent>
          <div className="row">
            <div className="col title">타이틀</div>
            <div className="col">
              <InputBox
                value={data?.title}
                onValue={value => setData({...data, title: value})}
                height={100}
              />
            </div>
          </div>
          <div className="row top">
            <div className="col title">내용</div>
            <div className="col">
              <TextAreaBox
                value={data?.content}
                onValue={value => setData({...data, content: value})}
                height={100}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="buttonCol">
                <div>
                  이미지 <span className="text-10">(비율 335x280)</span>
                  <br />
                  <span className="text-10">꼭 저장을 눌러주세요!</span>
                </div>
                <div>
                  <FileUploadButton
                    multiple
                    text={"업로드"}
                    height={32}
                    uploadUrl={"/admin/utils/uploadPostImg"}
                    fileUpload={({files}) => setPhotos([...photos, ...files])}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.imgRow}>
            {photos.map(img_url => (
              <div key={_.uniqueId()}>
                <img src={img_url} className={styles.logoImg} />
                <div
                  className={styles.deleteBtn}
                  onClick={() =>
                    setPhotos([...photos.filter(item => item !== img_url)])
                  }>
                  X
                </div>
              </div>
            ))}
          </div>
        </ModalContent>
        <ModalFooter>
          <div></div>
          <div>
            <Button
              text="저장"
              onClick={save}
              backgroundColor={styleUtils.COLOR.success}
              fontColor={styleUtils.COLOR.white}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
