import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IDBDeliveryDate} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
  member_no: number;
  subscribe_no: number;
}

export function SubscribePassAddModal({
  isVisible,
  close,
  refresh,
  member_no,
  subscribe_no,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [subscribeDateList, setSubscribeDateList] = useState<IDBDeliveryDate[]>(
    [],
  );
  const [subscribePassDate, setSubscribePassDate] = useState<moment.Moment[]>(
    [],
  );

  async function getDeliveryDateList() {
    try {
      const {success, data} = await adminAPI.deliveryDate.getList({
        start_date: moment().format("YYYY-MM-DD"),
      });
      if (success) {
        setSubscribeDateList(data);
      }
    } catch (error) {
      console.error("getDeliveryDateList error", error);
    }
  }

  async function createSubscribePass() {
    try {
      if (!member_no || !subscribePassDate) {
        return;
      }
      const {success, message} = await adminAPI.member.createSubscribePass({
        member_no,
        subscribe_no,
        pass_date_list: subscribePassDate.map(date =>
          moment(date).format("YYYY-MM-DD"),
        ),
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        refresh?.();
        setClose();
      }
    } catch (error) {
      console.error("createSubscribePass error", error);
    }
  }

  function setClose() {
    close?.();
    setSubscribePassDate([]);
  }

  useEffect(() => {
    getDeliveryDateList();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="정기배송 건너뛰기" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>날짜선택</div>
          <div className="col">
            <SelectBox
              multiple
              list={(subscribeDateList || []).map(item => ({
                label: moment(item.delivery_datetime).format("YYYY-MM-DD"),
                value: moment(item.delivery_datetime).format("YYYY-MM-DD"),
              }))}
              value={subscribePassDate.map(date =>
                moment(date).format("YYYY-MM-DD"),
              )}
              multipleSelect={list =>
                setSubscribePassDate(list.map(date => moment(date)))
              }
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button
            text="저장"
            onClick={createSubscribePass}
            backgroundColor={styleUtils.COLOR.warning}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}
