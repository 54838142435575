import {Layout} from "components";
import {useLogin} from "@hook";
import {LoginPage} from "@pages";
import React, {useEffect} from "react";
import {AdminRouteApp, AdminPopupRoute} from "@navigation";
import {Route, Routes, useLocation} from "react-router-dom";

export default function AdminNavigation() {
  const {token, getAdminData} = useLogin();
  const location = useLocation();

  useEffect(() => {
    getAdminData();
  }, []);

  return (
    <>
      {!token ? (
        <LoginPage />
      ) : (
        <Routes>
          <Route path="/pop/*" element={<AdminPopupRoute />} />
          <Route
            path="/*"
            element={
              <Layout>
                <AdminRouteApp />
              </Layout>
            }
          />
        </Routes>
      )}
    </>
  );
}
