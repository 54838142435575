import {Calendar, Content, ContentLayout} from "@components";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {momentLocalizer, Event as TCalendarEvent} from "react-big-calendar";
import {adminAPI} from "@utils";
import {states} from "@recoils";
import {useSetRecoilState} from "recoil";
import {DeliveryDateDetailModal} from "./DeliveryDateDetailModal";
import {IDeliveryDateResponse, IDBProduct} from "@data";

export default function DeliveryDatePage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>();
  const [detailDate, setDetailDate] = useState<IDeliveryDateResponse>();
  const [focusMonth, setFocusMonth] = useState<moment.Moment>(
    moment().startOf("month"),
  );
  const [eventList, setEventList] = useState<TCalendarEvent[]>([]);
  const localizer = momentLocalizer(moment);
  const [itemList, setItemList] = useState<IDBProduct[]>();

  async function getList() {
    try {
      const {data, success, message} = await adminAPI.deliveryDate.getList({
        year: Number(focusMonth.format("YYYY")),
        month: Number(focusMonth.format("M")),
      });
      if (success) {
        setEventList(
          (data || []).map(item => ({
            start: item.delivery_datetime,
            end: item.delivery_datetime,
            title: `배송일${item.milk_yn === "Y" ? "(또또우유)" : ""}`,
          })),
        );
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail() {
    try {
      if (!selectedDate) {
        return;
      }

      const {success, data} = await adminAPI.deliveryDate.getDetail({
        date: selectedDate.format("YYYY-MM-DD"),
      });
      if (success) {
        setDetailDate(data);
      } else {
        setDetailDate({delivery_datetime: selectedDate.toDate()});
      }
      setSelectedDate(undefined);
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function getItemList() {
    try {
      const {data, success, message} = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getItemList error", error);
    }
  }

  useEffect(() => {
    getItemList();
  }, []);

  useEffect(() => {
    getList();
  }, [focusMonth]);

  useEffect(() => {
    if (selectedDate) {
      getDetail();
    }
  }, [selectedDate]);

  return (
    <>
      <ContentLayout title="정기구독배송일">
        <Content>
          <div style={{flex: 1}}>
            <Calendar
              localizer={localizer}
              defaultDate={moment().toDate()}
              onSelectEvent={event => {
                if (!selectedDate && !detailDate && !selectedDate) {
                  setSelectedDate(moment(event.start));
                }
              }}
              views={["month"]}
              startAccessor="start"
              endAccessor="start"
              allDayAccessor={() => {
                return true;
              }}
              eventList={eventList}
              onNavigate={(newDate, view, action) => {
                if (action === "DATE" && !detailDate && !selectedDate) {
                  setSelectedDate(moment(newDate));
                  return;
                } else if (
                  moment(newDate).format("YYYYMM") !==
                  focusMonth?.format("YYYYMM")
                ) {
                  setFocusMonth(moment(newDate).startOf("month"));
                }
              }}
            />
          </div>
        </Content>
      </ContentLayout>
      {!!detailDate && (
        <DeliveryDateDetailModal
          isVisible={!!detailDate?.delivery_datetime}
          close={() => setDetailDate(undefined)}
          refresh={getList}
          data={detailDate}
          itemList={itemList}
        />
      )}
    </>
  );
}
