import {Button, InputBox, ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {ResultPagingResponse, TypeYN, IMemberCart} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";
import {CartAddModal} from "./CartAddModal";

interface IMemberCartList extends IMemberCart {
  temp_count: number;
}

export function CartTab({member_no}: {member_no: number}) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<ResultPagingResponse<IMemberCartList[]>>();
  const [isAddModal, setIsAddModal] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {success, message, data} = await adminAPI.member.getCartList({
        member_no,
        page,
      });
      if (success) {
        setListData({
          page: data.page,
          result: (data.result || []).map(_item => ({
            ..._item,
            temp_count: _item.count || 0,
          })),
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function changeCartCount({
    item_no,
    subscribe_yn,
    count,
  }: {
    item_no: number;
    subscribe_yn: TypeYN;
    count: number;
  }) {
    if (!confirm("해당 상품의 수량을 변경하시겠습니까?")) {
      return;
    }
    try {
      const {success, message, data} = await adminAPI.member.changeCartCount({
        member_no,
        item_no,
        subscribe_yn,
        count,
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changeCartCount error", error);
    }
  }

  async function deleteToCart({
    item_no,
    subscribe_yn,
  }: {
    item_no: number;
    subscribe_yn: TypeYN;
  }) {
    if (!confirm("해당 상품을 장바구니에서 제거하시겠습니까?")) {
      return;
    }
    try {
      const {success, message, data} = await adminAPI.member.deleteToCart({
        member_no,
        item_no,
        subscribe_yn,
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("deleteToCart error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row">
        <div className="flex">
          <Button
            text="추가하기"
            onClick={() => setIsAddModal(true)}
            backgroundColor={styleUtils.COLOR.success}
            fontColor={styleUtils.COLOR.white}
            paddingVertical={1}
            paddingHorizontal={6}
            fontSize={12}
          />
        </div>
      </div>
      <div className="row">
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>업체명</th>
              <th>상품명</th>
              <th>개수</th>
              <th>수량변경</th>
              <th>현재재고</th>
              <th>최초담은날</th>
              <th>마지막수정일</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => (
              <tr key={index}>
                <td>{item.product?.partners?.partner_name}</td>
                <td>{item.product?.item_name}</td>
                <td className="text-bold">
                  {(item.count || 0).toLocaleString()}
                  {item.subscribe_yn === "Y" ? (
                    <>
                      <br />
                      <span className={styles.badge}>정기배송장바구니</span>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  <div style={{width: "100px"}}>
                    <InputBox
                      value={String(item.temp_count)}
                      type="number"
                      onValue={value => {
                        const temp_count = Number(value || 0);
                        setListData({
                          ...listData,
                          result: (listData?.result || []).map(
                            (_item, _index) => {
                              if (_index === index) {
                                return {..._item, temp_count};
                              } else {
                                return _item;
                              }
                            },
                          ),
                        });
                      }}
                      rightContent={
                        <Button
                          text="수량변경"
                          fontSize={10}
                          onClick={() => {
                            if (!item.item_no || !item.subscribe_yn) {
                              return;
                            }
                            if (item.temp_count > 0) {
                              changeCartCount({
                                item_no: item.item_no,
                                count: item.temp_count,
                                subscribe_yn: item.subscribe_yn,
                              });
                            } else {
                              deleteToCart({
                                item_no: item.item_no,
                                subscribe_yn: item.subscribe_yn,
                              });
                            }
                          }}
                        />
                      }
                      rightContentStyle={{padding: 0}}
                      inputStyle={{width: "100%"}}
                      boxStyle={{width: 120}}
                    />
                  </div>
                </td>
                <td>
                  {(item.product?.stock || 0).toLocaleString()}
                  {item.product?.stock_date ? (
                    <>
                      <br />
                      <span className="text-10">
                        (다음입고일{" "}
                        {moment(item.product?.stock_date.stock_date).isValid()
                          ? moment(item.product?.stock_date.stock_date).format(
                              "YYYY.MM.DD",
                            )
                          : ""}
                        )
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                <td>{moment(item.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                <td>
                  <div style={{display: "inline-block"}}>
                    <Button
                      text="제거"
                      onClick={() => {
                        if (item.item_no && item.subscribe_yn) {
                          deleteToCart({
                            item_no: item.item_no,
                            subscribe_yn: item.subscribe_yn,
                          });
                        }
                      }}
                      backgroundColor={styleUtils.COLOR.danger}
                      fontColor={styleUtils.COLOR.white}
                      paddingVertical={1}
                      paddingHorizontal={6}
                      fontSize={12}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      </div>
      <CartAddModal
        isVisible={isAddModal}
        close={() => setIsAddModal(false)}
        member_no={member_no}
        refresh={getList}
      />
    </>
  );
}
