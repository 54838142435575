import {Button, Modal, ModalContent, ModalTitle, SelectBox} from "@components";
import {IDBProduct, ISelectedDetailList} from "@data";
import React, {useState} from "react";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  save?: (data: {itemList: number[]}) => void;
  itemList: IDBProduct[];
}

export function CopyItemSelectModal({
  isVisible,
  close,
  save,
  itemList,
}: IProps) {
  const [selectItemList, setSelectItemList] = useState<number[]>([]);

  function setClose() {
    close?.();
  }

  function setSave() {
    if (selectItemList.length === 0) {
      return alert("상품을 선택해주세요.");
    }
    save?.({itemList: selectItemList});
  }

  return (
    <>
      <Modal isVisible={isVisible} width="500px" close={setClose}>
        <ModalTitle close={setClose} isClose title="제품상세추가" />
        <ModalContent>
          <div className="row">
            <div className="col">
              <SelectBox
                multiple
                list={(itemList || []).map(item => ({
                  label: String(item.item_name),
                  value: String(item.no),
                }))}
                value={selectItemList.map(item => String(item))}
                multipleSelect={list =>
                  setSelectItemList(
                    list
                      .filter(item_no => !isNaN(Number(item_no)))
                      .map(item_no => Number(item_no)),
                  )
                }
              />
            </div>
            <div>
              <Button text="저장" onClick={setSave} />
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
