import React from "react";
import styles from "./style.module.scss";

interface IProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export default function Content({children, className = ""}: IProps) {
  return (
    <div className={[styles.content, className].join(" ")}>{children}</div>
  );
}
