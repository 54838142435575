import React, {useEffect, useState} from "react";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Button,
  InputBox,
  TextAreaBox,
  SelectBox,
} from "@components";
import {IDBFaq, IDBFaqGroup} from "@data";
import {adminAPI, styleUtils} from "@utils";

interface IProps {
  isVisible?: boolean;
  data?: IDBFaq;
  setData?: (data?: IDBFaq) => void;
  save?: () => void;
  deleteFaq?: () => void;
  close?: () => void;
}

export function FaqDetailModal({
  isVisible,
  data = {},
  setData,
  save,
  deleteFaq,
  close,
}: IProps) {
  const [groupList, setGroupList] = useState<IDBFaqGroup[]>([]);

  async function getFaqGroup() {
    try {
      const {success, data} = await adminAPI.faq.apiGetFaqGroup();
      if (success) {
        setGroupList(data.result);
      }
    } catch (error) {
      console.error("getFaqGroup error", error);
    }
  }

  function setClose() {
    setData?.(undefined);
    close?.();
  }

  useEffect(() => {
    getFaqGroup();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="자주묻는 질문 상세" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">그룹</div>
          <div className="col">
            <SelectBox
              list={(groupList || []).map(item => ({
                value: String(item.no),
                label: String(item.title || ""),
              }))}
              value={String(data.group_no)}
              singleSelect={e => setData?.({...data, group_no: Number(e)})}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">제목</div>
          <div className="col">
            <InputBox
              placeholder="제목"
              value={data?.title}
              onValue={value => setData?.({...data, title: value})}
            />
          </div>
        </div>
        <div className="row top">
          <div className="col title">내용</div>
          <div className="col">
            <TextAreaBox
              placeholder="내용"
              value={data?.content}
              onValue={value => setData?.({...data, content: value})}
              height={200}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          {!!data.no && (
            <Button
              text="삭제"
              onClick={deleteFaq}
              backgroundColor={styleUtils.COLOR.red500}
            />
          )}
        </div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
