import {
  faArrowCircleDown,
  faArrowCircleUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  CheckBox,
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
} from "@components";
import {ResultPagingResponse, TypeYN, IDBEventPopup, TUpDown} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import styles from "./style.module.scss";
import {EventPopupDetailModal} from "./EventPopupDetailModal";

export default function EventPopupPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IDBEventPopup[]>>();
  const [detailData, setDetailData] = useState<IDBEventPopup>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.event.getEventPopupList({
        page,
        searchtxt: searchText,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.event.getEventPopupDetail(
        {no},
      );
      if (success) {
        setDetailData(data);
        setIsDetailOpen(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function saveEventPopup() {
    try {
      if (!detailData) {
        return;
      }
      if (!detailData.title) {
        setAlertModal({
          isVisible: true,
          title: "제목을 입력해주세요",
        });
      }
      if (!detailData.img_url) {
        setAlertModal({
          isVisible: true,
          title: "이미지를 업로드해주세요",
        });
      }

      const params = {
        ...detailData,
        openDate: moment(detailData.openDate).format("YYYY-MM-DD"),
        closeDate: moment(detailData.closeDate).format("YYYY-MM-DD"),
        hide_yn: detailData.hide_yn || "Y",
        target_no: detailData.deeplink ? detailData.target_no : undefined,
      };

      const {success, message} = await (detailData?.no
        ? adminAPI.event.updateEventPopup(params)
        : adminAPI.event.createEventPopup(params));
      if (success) {
        setDetailData(undefined);
        setIsDetailOpen(false);
        getList();
        setAlertModal({
          isVisible: true,
          title: message || "저장완료",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function toggleHideYN({no, hide_yn}: {no: number; hide_yn: TypeYN}) {
    try {
      if (!no) {
        return;
      }

      const {success, message} = await adminAPI.event.updateEventPopup({
        no,
        hide_yn,
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("toggleHideYN error", error);
    }
  }

  async function deleteEventPopup({no}: {no: number}) {
    try {
      if (!no) {
        return;
      }
      if (!confirm("팝업을 삭제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.event.deleteEventPopup({no});
      if (success) {
        setDetailData(undefined);
        setIsDetailOpen(false);
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("deleteEventPopup error", error);
    }
  }

  async function changeEventPopupOrdering({
    no,
    type,
  }: {
    no: number;
    type: TUpDown;
  }) {
    try {
      const {success, message} = await adminAPI.event.changeEventPopupOrdering({
        no,
        type,
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changeEventPopupOrdering error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="팝업관리">
        <SearchFilter
          left={[
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => setIsDetailOpen(true)}
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>번호</th>
                <th>이미지</th>
                <th>제목</th>
                <th>순서</th>
                <th>노출일</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                const isEnd =
                  moment(item.closeDate).isValid() &&
                  moment(item.closeDate).isBefore(moment());
                return (
                  <tr
                    key={index}
                    onClick={() => !!item.no && getDetail({no: item.no})}>
                    <td>{item.no}</td>
                    <td>{!!item.img_url && <img src={item.img_url} />}</td>
                    <td>{item.title}</td>
                    <td>
                      <div className="row">
                        <div className="col">
                          {(item.ordering || 0) > 1 && (
                            <FontAwesomeIcon
                              icon={faArrowCircleUp}
                              onClick={() => {
                                if (item.no) {
                                  changeEventPopupOrdering({
                                    no: item.no,
                                    type: "up",
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                        <div className="col">{item.ordering || "-"}</div>
                        <div className="col">
                          {!!item.ordering && (
                            <FontAwesomeIcon
                              icon={faArrowCircleDown}
                              onClick={() => {
                                if (item.no) {
                                  changeEventPopupOrdering({
                                    no: item.no,
                                    type: "down",
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        {!!item.openDate &&
                          moment(item.openDate).isValid() &&
                          moment(item.openDate).format("YYYY-MM-DD")}{" "}
                        ~
                        {!!item.closeDate &&
                          moment(item.closeDate).isValid() &&
                          moment(item.closeDate).format("YYYY-MM-DD")}{" "}
                      </div>
                      <div
                        className="text-10"
                        style={{
                          ...(isEnd
                            ? {color: `${styleUtils.COLOR.red400}`}
                            : {}),
                        }}>
                        {isEnd
                          ? "(종료됨)"
                          : moment(item.openDate).isValid() &&
                            moment().isBefore(moment(item.openDate))
                          ? "(시작전)"
                          : ""}
                      </div>
                    </td>
                    <td onClick={utils.handleClickBubble}>
                      <CheckBox
                        checked={item.hide_yn === "N"}
                        text={item.hide_yn === "N" ? "노출" : "숨김"}
                        isToggle
                        onChange={e => {
                          if (!item.no) {
                            return;
                          }
                          toggleHideYN({
                            no: item.no,
                            hide_yn: item.hide_yn === "N" ? "Y" : "N",
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <EventPopupDetailModal
        isVisible={isDetailOpen}
        data={detailData}
        setData={setDetailData}
        save={saveEventPopup}
        deleteEventPopup={deleteEventPopup}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
        }}
      />
    </>
  );
}
