import {
  Button,
  CheckBox,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  order_no: number;
  estimate_output_date: Date;
  close?: () => void;
  refresh?: () => void;
}

export function OrderEstimateChangeModal({
  isVisible,
  order_no,
  estimate_output_date,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [estimateOutDate, setEstimateOutDate] = useState(
    moment(estimate_output_date).format("YYYY-MM-DD"),
  );

  async function save() {
    try {
      if (
        !confirm("출고일을 변경하시겠습니까? 도착예정일도 자동으로 변경됩니다.")
      ) {
        return;
      }

      const {success, message} =
        await adminAPI.order.updateOrderEstimateOutputDate({
          order_no,
          estimate_output_date: estimateOutDate,
        });
      setAlertModal({isVisible: true, title: message});
      if (success) {
        close?.();
        refresh?.();
      }
    } catch (error) {
      setAlertModal({isVisible: true, title: "오류발생"});
    }
  }

  useEffect(() => {
    setEstimateOutDate(moment(estimate_output_date).format("YYYY-MM-DD"));
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={close}>
      <ModalTitle title="출고일변경" isClose close={close} />
      <ModalContent>
        <div className="row">
          <div className="col title">출고일</div>
          <div className="col">
            <InputBox
              value={estimateOutDate}
              type="date"
              onValue={setEstimateOutDate}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
