import {
  Button,
  CheckBox,
  Content,
  ContentLayout,
  InputBox,
  ListTable,
  SearchFilter,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, commonUtil, styleUtils, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYN,
  IDBProduct,
  IPostDetail,
  IPostRecipeItem,
  IPostRequest,
  IMemberResponse,
  IPostListResponse,
} from "@data";
import _ from "lodash";
import moment from "moment";
import {MemberDetailModal} from "pages/MemberPage/MemberDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {ZOOTOPIA_WEB_URL} from "utils/constant";
import {PostDetailListModal} from "./PostDetailListModal";
import {PostModal} from "./PostModal";
import {PostOpenDateSettingModal} from "./PostOpenDateSettingModal";
import {PostRecipeListModal} from "./PostRecipeListModal";
import styles from "./style.module.scss";

export default function PostPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [memberDetail, setMemberDetail] = useState<IMemberResponse>();
  const [listData, setListData] =
    useState<ResultPagingResponse<IPostListResponse[]>>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailData, setDetailData] = useState<IPostRequest>();
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [activePostNo, setActivePostNo] = useState<number>();
  const [postRecipe, setPostRecipe] = useState<IPostRecipeItem[]>();
  const [postDetailList, setPostDetailList] = useState<IPostDetail[]>();
  const [isOpenDateSetting, setIsOpenDateSetting] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.post.getList({
        page,
        searchtxt: searchText,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getPostRecipeList({post_no}: {post_no: number}) {
    try {
      const {data, success, message} = await adminAPI.post.getPostRecipeList({
        post_no,
      });
      if (success) {
        setPostRecipe(data);
        setActivePostNo(post_no);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPostRecipeList error", error);
    }
  }

  async function getPostDetailList({post_no}: {post_no: number}) {
    try {
      const {data, success, message} = await adminAPI.post.getPostDetailList({
        post_no,
      });
      if (success) {
        setPostDetailList(data);
        setActivePostNo(post_no);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getPostRecipeList error", error);
    }
  }

  async function getMemberDetail(no: number) {
    try {
      const {data, success, message} = await adminAPI.member.getDetail({
        member_no: no,
      });
      if (success) {
        setMemberDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function toggleHideYN({no, hide_yn}: {no: number; hide_yn: TypeYN}) {
    try {
      if (!no) {
        return;
      }

      const {success, message} = await adminAPI.post.toggleYN({
        no,
        hide_yn,
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function getItemList() {
    try {
      const {data, success} = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  useEffect(() => {
    getList();
    getItemList();
  }, []);

  return (
    <>
      <ContentLayout title="한상차림관리">
        <SearchFilter
          left={[
            <InputBox
              key={1}
              type={"text"}
              placeholder="검색"
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => {
                setDetailData(undefined);
                setIsDetailOpen(true);
              }}
            />,
          ]}
        />
        <Content>
          <div style={{flex: 1}}>
            <ListTable paging={listData?.page} getList={getList}>
              <thead>
                <tr>
                  <th>노출일</th>
                  <th>작성자</th>
                  <th>제목</th>
                  <th>숨김처리</th>
                  <th>컨텐츠</th>
                  <th>재료순서</th>
                  <th>웹</th>
                </tr>
              </thead>
              <tbody>
                {(listData?.result || []).map(item => {
                  const webLink = `${ZOOTOPIA_WEB_URL}/postDetail?k=${commonUtil.encryptQuery(
                    {
                      query: `${item.no}`,
                    },
                  )}`;
                  return (
                    <tr
                      key={_.uniqueId()}
                      onClick={() => {
                        setIsDetailOpen(true);
                        setDetailData({
                          ...item,
                          item_list: (item.recipe_item || [])
                            .map(_item => _item.item_no)
                            .filter(utils.isDefined),
                        });
                      }}>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        {moment(item.open_date).isValid() &&
                          moment(item.open_date).format("YYYY.MM.DD HH:mm")}
                        {item.hide_yn === "N" ? (
                          <>
                            <br />
                            <span className="text-12">
                              ({moment().diff(moment(item.open_date), "day")}일
                              전)
                            </span>
                          </>
                        ) : (
                          <>
                            <br />
                            <div style={{display: "inline-block"}}>
                              <Button
                                text="노출일자예약"
                                onClick={() => {
                                  setIsOpenDateSetting(true);
                                  setDetailData(item);
                                }}
                                backgroundColor={styleUtils.COLOR.baige}
                                fontSize={12}
                              />
                            </div>
                          </>
                        )}
                      </td>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        {item.member?.name} ({item.member?.nickname})
                        <br />
                        <div style={{display: "inline-block"}}>
                          <Button
                            text="상세"
                            onClick={() =>
                              !!item.member?.no &&
                              getMemberDetail(item.member?.no)
                            }
                            backgroundColor={styleUtils.COLOR.success}
                            fontColor={styleUtils.COLOR.white}
                            paddingVertical={1}
                            paddingHorizontal={6}
                            fontSize={12}
                          />
                        </div>
                      </td>
                      <td>{item.title}</td>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        <CheckBox
                          checked={item.hide_yn === "N"}
                          text={item.hide_yn === "N" ? "노출" : "숨김"}
                          isToggle
                          onChange={e => {
                            if (!item.no) {
                              return;
                            }
                            toggleHideYN({
                              no: item.no,
                              hide_yn: item.hide_yn === "N" ? "Y" : "N",
                            });
                          }}
                        />
                      </td>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        <div style={{display: "inline-block"}}>
                          <Button
                            text="상세"
                            fontSize={12}
                            onClick={() => {
                              if (item.no) {
                                getPostDetailList({post_no: item.no});
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        <div style={{display: "inline-block"}}>
                          <Button
                            text="상세"
                            fontSize={12}
                            onClick={() => {
                              if (item.no) {
                                getPostRecipeList({post_no: item.no});
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        <div style={{display: "inline-block"}}>
                          <Button
                            text="조회"
                            onClick={() => window.open(webLink)}
                            fontSize={12}
                            backgroundColor={styleUtils.COLOR.baige}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ListTable>
          </div>
        </Content>
      </ContentLayout>
      {!!isDetailOpen && (
        <PostModal
          isVisible={isDetailOpen}
          prevData={detailData}
          close={() => {
            setDetailData(undefined);
            setIsDetailOpen(false);
          }}
          itemList={itemList}
          refresh={() => getList({page: listData?.page?.cur || 1})}
        />
      )}
      <MemberDetailModal
        isVisible={!!memberDetail}
        close={() => setMemberDetail(undefined)}
        member={memberDetail}
      />
      {!!activePostNo && !!postRecipe && (
        <PostRecipeListModal
          isVisible={!!postRecipe}
          post_no={activePostNo}
          listData={postRecipe}
          close={() => {
            setPostRecipe(undefined);
            setActivePostNo(undefined);
          }}
          refresh={() => {
            getPostRecipeList({post_no: activePostNo});
          }}
        />
      )}
      {!!activePostNo && !!postDetailList && (
        <PostDetailListModal
          isVisible={!!postDetailList}
          post_no={activePostNo}
          data={postDetailList}
          close={() => {
            setPostDetailList(undefined);
            setActivePostNo(undefined);
          }}
          refresh={() => {
            getPostDetailList({post_no: activePostNo});
          }}
        />
      )}
      {!!detailData && !!isOpenDateSetting && (
        <PostOpenDateSettingModal
          isVisible={isOpenDateSetting}
          close={() => {
            setIsOpenDateSetting(false);
            setDetailData(undefined);
          }}
          post={detailData}
          refresh={() => {
            getList({page: listData?.page?.cur || 1});
          }}
        />
      )}
    </>
  );
}
