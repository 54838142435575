import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  member_no: number;
  close?: () => void;
  refresh?: () => void;
}

export function MemberPointAddModal({
  isVisible,
  member_no,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [point, setPoint] = useState(0);
  const [title, setTitle] = useState("");

  function setClose({isRefresh = false}: {isRefresh?: boolean} = {}) {
    setPoint(0);
    setTitle("");
    close?.();
    if (isRefresh) {
      refresh?.();
    }
  }

  async function save() {
    try {
      if (point === 0) {
        return alert("포인트금액을 입력해주세요.");
      }
      if (!title) {
        return alert("포인트 지급사유를 입력해주세요.");
      }
      if (
        !confirm(
          "포인트를 지급하시겠습니까? 지급 시 회원에게 푸시가 발송됩니다.",
        )
      ) {
        return;
      }

      const {success, message} = await adminAPI.member.addPointSaveMulti({
        member_arr: [member_no],
        point,
        title,
      });
      setAlertModal({isVisible: true, title: message});
      if (success) {
        setClose({isRefresh: true});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      setAlertModal({isVisible: true, title: "오류발생"});
    }
  }

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="포인트지급" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">포인트</div>
          <div className="col">
            <InputBox
              value={point}
              type="number"
              onValue={value => setPoint(Number(value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">타이틀</div>
          <div className="col">
            <InputBox
              value={title}
              onValue={setTitle}
              placeholder={"포인트 지급 사유를 입력해주세요."}
            />
          </div>
        </div>
        <div className="row">
          <div className={["col", "flex-end"].join(" ")}>
            포인트를 마이너스로 지급 시 차감됩니다.
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="지급하기" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
