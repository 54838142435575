import {Button, ListTable} from "@components";
import {
  IOrderDetail,
  IPaymentFailOrderLogResponse,
  ResultPagingResponse,
} from "@data";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import moment from "moment";
import {OrderDetailModal} from "pages/OrderPage/OrderDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  member_no: number;
}

export function PaymenyLogTab({member_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const setLoading = useSetRecoilState(states.loadingState);
  const [listData, setListData] =
    useState<ResultPagingResponse<IPaymentFailOrderLogResponse[]>>();
  const [orderDetailData, setOrderDetailData] = useState<IOrderDetail>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      setLoading(true);
      const {data, success, message} =
        await adminAPI.orderLog.getPaymentFailOrderLog({
          limit: 20,
          page,
          member_no,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("gatData error", error);
      setLoading(false);
    }
  }

  async function getOrderDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no,
      });
      if (success) {
        setOrderDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getOrderDetail error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row">
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>주문</th>
              <th>카드번호</th>
              <th>결제금액</th>
              <th>
                tid
                <br />
                moid
              </th>
              <th>상품명</th>
              <th>메시지</th>
              <th>메모</th>
              <th>결제시도일시</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => (
              <tr key={index}>
                <td>
                  {item.order_number || "-"}
                  {!!item.order_no && !isNaN(Number(item.order_no)) && (
                    <>
                      <br />
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="조회"
                          fontSize={10}
                          paddingVertical={2}
                          onClick={() => {
                            if (item.order_no) {
                              getOrderDetail({no: item.order_no});
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </td>
                <td>
                  {item.card_name} {utils.formatCardNo(item.card_no || "")}
                </td>
                <td>{Number(item.amt || 0).toLocaleString()}원</td>
                <td className="text-12">
                  {item.tid}
                  <br />
                  {item.moid}
                </td>
                <td className="text-12">{item.item_name}</td>
                <td
                  className="text-12 text-left preWrap"
                  style={{minWidth: 50, maxWidth: 200}}>
                  {(item.result_msg || "").replace(/\\n/gi, `\n`)}
                </td>
                <td
                  className="text-12 text-left preWrap"
                  style={{minWidth: 50, maxWidth: 200}}>
                  {(item.memo || "").replace(/\\n/gi, `\n`)}
                </td>
                <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      </div>
      <OrderDetailModal
        isVisible={!!orderDetailData}
        close={() => setOrderDetailData(undefined)}
        data={orderDetailData}
        refresh={() => {
          if (orderDetailData?.no) {
            getOrderDetail({no: orderDetailData.no});
          }
        }}
      />
    </>
  );
}
