import {Button, CheckBox, ListTable, TextAreaBox} from "@components";
import {states} from "@recoils";
import StarImg from "../../../assets/images/Star.png";
import StarGreenImg from "../../../assets/images/StarGreen.png";
import {adminAPI, constant, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYN,
  IReviewDetailResponse,
  IProductSubscribeMemberList,
} from "@data";
import _ from "lodash";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  item_no: number;
}

export function SubscribeMemberTab({item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<ResultPagingResponse<IProductSubscribeMemberList[]>>();
  const detailRef = useRef<HTMLDivElement>(null);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} =
        await adminAPI.item.getSubscribeMemberList({
          page,
          item_no,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  function switchType(type?: string) {
    switch (type) {
      case "subscribe":
        return "정기구독";
      case "temp":
        return "함께담기";
    }
    return "-";
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className={["row", styles.reviewTab].join(" ")}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="title">구독회원목록</div>
            <ListTable paging={listData?.page} getList={getList}>
              <thead>
                <tr>
                  <th>고객명(닉네임)</th>
                  <th>연락처</th>
                  <th>구분</th>
                  <th>갯수</th>
                </tr>
              </thead>
              <tbody>
                {(listData?.result || []).map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.name} ({item.nickname || "-"})
                    </td>
                    <td>{utils.formatPhoneNumber(item.phone_number || "")}</td>
                    <td>{switchType(item.subscribe_type)}</td>
                    <td>{(item.subscribe_count || 0).toLocaleString()}개</td>
                  </tr>
                ))}
              </tbody>
            </ListTable>
          </div>
        </div>
      </div>
    </>
  );
}
