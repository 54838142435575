import React, {
  InputHTMLAttributes,
  KeyboardEvent,
  CSSProperties,
  useState,
} from "react";
import styles from "./style.module.scss";

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  boxStyle?: CSSProperties;
  height?: number;
  onValue?: (value: string) => void;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
}

export default function TextAreaBox({
  boxStyle = {},
  height,
  value,
  onValue,
  onChange,
  ...rest
}: IProps) {
  const [isFocus, setIsFocus] = useState(false);

  function setFocus() {
    setIsFocus(true);
  }
  function setBlur() {
    setIsFocus(false);
  }

  return (
    <div
      className={[styles.box, ...[!!isFocus && styles.focus]].join(" ")}
      style={{...boxStyle, ...(!!height && {height: `${height}px`})}}>
      <textarea
        {...rest}
        onFocus={setFocus}
        onBlur={setBlur}
        value={value || ""}
        onChange={e => {
          onValue?.(e.target.value);
          onChange?.(e);
        }}></textarea>
    </div>
  );
}
