import {
  Button,
  CheckBox,
  InputBox,
  ListTable,
  SelectBox,
  TabList,
  TextAreaBox,
} from "@components";
import {
  IDBDmbList,
  IDBItemDmbList,
  IDBItemDmbTitle,
  IDBItemType,
  IDBProduct,
  IItemDmbList,
  ISaveItemDmbList,
} from "@data";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {CopyItemSelectModal} from "./CopyItemSelectModal";

interface IProps {
  item_no: number;
  item_partner_no?: number;
  dmb_list?: IDBDmbList[];
  type_list?: number[];
  itemTypeList?: IDBItemType[];
}

const defaultUnitList = ["g", "mg", "mcg", "ug", "IU", "%", "CFU", "USP"];
const 칼슘_no = 30;
const 인_no = 31;
const 칼슘_인_no = 109;

export function DmbTab({
  item_no,
  dmb_list: default_dmb_list = [],
  item_partner_no,
  type_list = [],
  itemTypeList = [],
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [selectTypeTab, setSelectTypeTab] = useState(type_list[0]);
  const [itemDmbTitleList, setItemDmbTitleList] = useState<IDBItemDmbTitle[]>(
    [],
  );
  const [itemDmbList, setItemDmbList] = useState<IItemDmbList[]>([]);
  const [tabItemDmbList, setTabItemDmbList] = useState<IItemDmbList[]>([]);
  const [selectedItemDmbList, setSelectedItemDmbList] = useState<number[]>([]);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [isSelectItemModal, setIsSelectItemModal] = useState(false);
  const [dmbTitle, setDmbTitle] = useState("");
  const [dmbContent, setDmbContent] = useState("");

  const unitList = [
    ...new Set([
      ...defaultUnitList,
      ...(default_dmb_list || [])
        .map(item => item.default_unit)
        .filter(utils.isDefined),
    ]),
  ];
  const isAllChecked = selectedItemDmbList.length === tabItemDmbList.length;

  async function getItemDmbTitleList() {
    try {
      const {data, success, message} = await adminAPI.item.getItemDmbTitleList({
        item_no,
      });
      if (success) {
        setItemDmbTitleList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getItemDmbTitleList error", error);
    }
  }

  async function getItemDmbList() {
    try {
      const {data, success, message} = await adminAPI.item.getItemDmbList({
        item_no,
      });
      if (success) {
        const tempData: IDBItemDmbList[] = (default_dmb_list || [])
          .filter(item => item.item_type_no === selectTypeTab)
          .map(dmb => {
            const item_dmb = data.find(
              _item_dmb => dmb.no === _item_dmb.dmb_no,
            );
            return {
              item_no,
              dmb_no: dmb.no,
              content: "",
              unit: dmb.default_unit,
              ordering: dmb.ordering,
              del_yn: "N",
              ...item_dmb,
            };
          });
        const tempNoList = tempData
          .map(item => item.no)
          .filter(utils.isDefined);
        (data || []).forEach(item => {
          if (!!item.no && item.detail?.no && !tempNoList.includes(item.no)) {
            tempData.push({
              item_no,
              dmb_no: item.detail.no,
              content: "",
              unit: item.detail?.default_unit,
              ordering: item.detail?.ordering || 0,
              del_yn: "N",
              ...item,
            });
          }
        });
        setItemDmbList(tempData);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getItemDmbList error", error);
    }
  }

  async function getItemList() {
    try {
      const {data, success} = await adminAPI.item.apiGetAllItems({
        partner_no: item_partner_no,
      });
      if (success) {
        setItemList((data.result || []).filter(item => item.no !== item_no));
      }
    } catch (error) {
      console.error("getItemList error", error);
    }
  }

  async function save() {
    try {
      if (!confirm("입력하신 함량을 저장하시겠습니까?")) {
        return;
      }
      const params: ISaveItemDmbList = {
        item_no,
        dmb_list: tabItemDmbList
          .filter(_item => !!_item.dmb_no && (!!_item.content || !!_item.no))
          .map(_item => ({
            dmb_no: _item.dmb_no as number,
            content: _item.content || "",
            unit: _item.unit || "",
          })),
      };
      const {success, message} = await adminAPI.item.saveItemDmb(params);
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getItemDmbList();
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function saveCopyItemDmb({itemList}: {itemList: number[]}) {
    try {
      if (itemList.length === 0) {
        return alert("상품을 선택해주세요.");
      }
      if (!selectTypeTab) {
        return alert("구분오류");
      }
      if (
        !confirm(
          `${selectedItemDmbList.length}개의 성분을 ${itemList.length}개 상품에 복사하시겠습니까?\n기존 작성한 내역이 있을 경우, 모든 [성분데이터 및 주의및 참고사항]이 삭제 후 재등록되며 복구가 불가합니다!`,
        )
      ) {
        return;
      }
      const {success, message} = await adminAPI.item.copyItemDmb({
        item_no_list: itemList,
        item_dmb_no_list: selectedItemDmbList,
        type_no: selectTypeTab,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setIsSelectItemModal(false);

        await adminAPI.item.copyItemDmbTitle({
          item_no,
          item_no_list: itemList,
          type_no: selectTypeTab,
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function saveDmbTitle() {
    try {
      if (!dmbTitle) {
        return alert("기준용량을 입력해주세요.");
      }
      const {message} = await adminAPI.item.saveItemDmbTitle({
        item_no,
        item_type_no: selectTypeTab,
        title: dmbTitle,
        content: dmbContent,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
    } catch (error) {
      console.error("saveDmbTitle error", error);
    }
  }

  function changeCalciumOrP({
    itemDmbList,
    init = false,
  }: {itemDmbList?: IItemDmbList[]; init?: boolean} = {}) {
    const prevList = itemDmbList || tabItemDmbList || [];
    const prevCalciumPRatio = prevList.find(item => item.dmb_no === 칼슘_인_no);
    const newCalciumPRatio = setCalciumPRatio({itemDmbList: prevList});
    const calciumPRatioDetail = default_dmb_list.find(
      item => item.no === 칼슘_인_no,
    );

    const newData: IItemDmbList[] = (() => {
      if (init) {
        return prevList;
      } else if (prevCalciumPRatio) {
        return prevList.map(item =>
          item.dmb_no === 칼슘_인_no
            ? {...item, content: newCalciumPRatio}
            : item,
        );
      } else {
        return [
          ...prevList,
          {
            dmb_no: 칼슘_인_no,
            content: newCalciumPRatio,
            detail: calciumPRatioDetail,
            item_no,
            unit: calciumPRatioDetail?.default_unit,
            ordering: calciumPRatioDetail?.ordering,
          },
        ].sort((a, b) => (a.ordering || 0) - (b.ordering || 0));
      }
    })();

    setTabItemDmbList(newData);
  }

  useEffect(() => {
    getItemDmbTitleList();
    getItemList();
    getItemDmbList();
  }, []);

  useEffect(() => {
    setSelectedItemDmbList([]);
  }, [selectTypeTab]);

  useEffect(() => {
    setDmbTitle(
      (itemDmbTitleList || []).find(item => item.item_type_no === selectTypeTab)
        ?.title || "",
    );
    setDmbContent(
      (itemDmbTitleList || []).find(item => item.item_type_no === selectTypeTab)
        ?.content || "",
    );
  }, [selectTypeTab, itemDmbTitleList]);

  useEffect(() => {
    const tempItemDmbList: IItemDmbList[] = (default_dmb_list || [])
      .filter(item => item.item_type_no === selectTypeTab)
      .map(item => {
        const prevData = itemDmbList.find(_item => _item.dmb_no === item.no);
        if (prevData) {
          return prevData;
        } else {
          return {detail: item, dmb_no: item.no};
        }
      })
      .filter(utils.isDefined);

    const tempDmbNoList = (default_dmb_list || [])
      .filter(item => item.item_type_no === selectTypeTab)
      .map(item => item.no)
      .filter(utils.isDefined);

    const tempTabItemDmbList = [
      ...itemDmbList
        .filter(item => item.detail?.item_type_no === selectTypeTab)
        .filter(item => !!item.dmb_no && !tempDmbNoList.includes(item.dmb_no)),
      ...tempItemDmbList,
    ].sort((a, b) => (a.ordering || 0) - (b.ordering || 0));

    changeCalciumOrP({itemDmbList: tempTabItemDmbList, init: true});
  }, [selectTypeTab, itemDmbList]);

  return (
    <>
      <TabList
        activeTab={selectTypeTab}
        setTab={setSelectTypeTab}
        tabList={itemTypeList
          .filter(item => !!item.no)
          .map(item => ({
            tab: item.no as number,
            title: item.title || "",
          }))}
      />
      <div>
        <div className="row">
          <div className="col auto">
            <div style={{width: "400px"}}>
              <InputBox
                value={dmbTitle}
                onValue={setDmbTitle}
                placeholder="1kg / 1캡슐"
                rightContentStyle={{padding: 0}}
                rightContent={<Button text="저장" onClick={saveDmbTitle} />}
              />
              <TextAreaBox
                value={dmbContent}
                onValue={setDmbContent}
                placeholder="주의 및 참고사항"
              />
            </div>
          </div>
          <div className="row flex-end" style={{alignItems: "flex-end"}}>
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.warning}
                onClick={save}
                text="일괄저장"
                fontSize={12}
              />
            </div>
            <div style={{marginLeft: "4px"}}>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="복사"
                fontSize={12}
                onClick={() => setIsSelectItemModal(true)}
              />
            </div>
          </div>
        </div>

        <ListTable>
          <thead>
            <tr>
              <th>
                <CheckBox
                  checked={isAllChecked}
                  onClick={() => {
                    if (isAllChecked) {
                      setSelectedItemDmbList([]);
                    } else {
                      const allList = (tabItemDmbList || [])
                        .map(_item => _item.no)
                        .filter(utils.isDefined);
                      setSelectedItemDmbList(allList);
                    }
                  }}
                />
              </th>
              <th>성분명</th>
              <th>영문명</th>
              <th>기호</th>
              <th>함량</th>
              <th>단위</th>
            </tr>
          </thead>
          <tbody>
            {(tabItemDmbList || []).map((item, index) => {
              const dmbData =
                (default_dmb_list || []).find(
                  _item => _item.no === item.dmb_no,
                ) || item.detail;
              const isSelected = !!selectedItemDmbList.find(
                _item => _item === item.no,
              );

              return (
                <tr key={index}>
                  <td onClick={utils.handleClickBubble}>
                    {!!item.no && (
                      <CheckBox
                        checked={isSelected}
                        onClick={() => {
                          if (isSelected) {
                            setSelectedItemDmbList([
                              ...selectedItemDmbList.filter(
                                _item => !(_item === item.no),
                              ),
                            ]);
                          } else if (item.no) {
                            setSelectedItemDmbList([
                              ...selectedItemDmbList,
                              item.no,
                            ]);
                          }
                        }}
                      />
                    )}
                  </td>
                  <td>
                    {dmbData?.require_yn === "Y" && (
                      <span style={{color: styleUtils.COLOR.red}}>* </span>
                    )}
                    {dmbData?.title}
                  </td>
                  <td>{dmbData?.title_en}</td>
                  <td>{dmbData?.dm_code}</td>
                  <td>
                    <InputBox
                      value={item.content}
                      onValue={value => {
                        const newList = (tabItemDmbList || []).map(
                          (_itemDmb, j) => {
                            if (index === j) {
                              return {..._itemDmb, content: value};
                            } else {
                              return _itemDmb;
                            }
                          },
                        );
                        if (
                          !!item.dmb_no &&
                          [칼슘_no, 인_no].includes(item.dmb_no)
                        ) {
                          changeCalciumOrP({itemDmbList: newList});
                        } else {
                          setTabItemDmbList(newList);
                        }
                      }}
                    />
                  </td>
                  <td>
                    <SelectBox
                      list={unitList.map(unit => ({label: unit, value: unit}))}
                      value={item.unit}
                      singleSelect={value => {
                        setTabItemDmbList(
                          (tabItemDmbList || []).map((_itemDmb, j) => {
                            if (index === j) {
                              return {..._itemDmb, unit: value};
                            } else {
                              return _itemDmb;
                            }
                          }),
                        );
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </div>
      {!!isSelectItemModal && (
        <CopyItemSelectModal
          isVisible={isSelectItemModal}
          itemList={itemList}
          close={() => setIsSelectItemModal(false)}
          save={saveCopyItemDmb}
        />
      )}
    </>
  );
}

function setCalciumPRatio({itemDmbList}: {itemDmbList: IItemDmbList[]}) {
  try {
    const calcium = Number(
      (itemDmbList || []).find(_item => _item.dmb_no === 칼슘_no)?.content,
    );
    const p = Number(
      (itemDmbList || []).find(_item => _item.dmb_no === 인_no)?.content,
    );
    if (isNaN(calcium) || isNaN(p)) {
      throw Error("칼슘 또는 인 데이터 없음");
    }

    const cal_p = Number(Number((calcium / p) * 10).toFixed(0)) / 10;

    if (Number(p) !== 0 && Number(cal_p) !== 0) {
      return `${cal_p}:1`;
    }
  } catch (error) {
    console.error("setCalciumPRatio error", error);
  }
  return undefined;
}
