import {Button, FileUploadButton, InputBox, TextAreaBox} from "@components";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {IProductDetailCheckpoint} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";

interface IProps {
  item_no: number;
  detailData: IProductDetailCheckpoint;
  refresh?: () => void;
}

export function CheckpointItem({detailData, refresh, item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailCheckpoint>({
    type: "checkpoint",
    data_json: {
      title: "",
      data: [],
    },
  });

  async function save() {
    try {
      const params = {
        ...data,
        data_json: {...data.data_json, title: data.data_json?.title || ""},
        item_no,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{flex: 1}}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">타이틀(앱내 미표기)</div>
          <div className="col">
            <InputBox
              value={data.data_json?.sub_title}
              onValue={value =>
                setData({
                  ...data,
                  data_json: {
                    ...{...data.data_json, title: data.data_json?.title || ""},
                    sub_title: value,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">내용</div>
          <div className="col flex-end">
            <div>
              <Button
                text="추가"
                onClick={() => {
                  if ((data.data_json?.data || []).length >= 3) {
                    return alert("최대 3개까지 등록 가능합니다.");
                  }
                  setData({
                    ...data,
                    data_json: {
                      ...{
                        ...data.data_json,
                        title: data.data_json?.title || "",
                      },
                      data: [
                        ...(data.data_json?.data || []),
                        {title: "", content: ""},
                      ],
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        {(data.data_json?.data || []).map((item, index) => {
          const img_url = item.thumbnail_img_url || item.img_url;
          return (
            <div key={index}>
              <div className="row">
                <div className="col title">
                  <div className="buttonCol">
                    이미지
                    <div>
                      <FileUploadButton
                        text={"업로드"}
                        height={32}
                        uploadUrl={"/admin/utils/uploadItemDetailImg"}
                        fileUpload={({files}) =>
                          setData({
                            ...data,
                            data_json: {
                              ...{
                                ...data.data_json,
                                title: data.data_json?.title || "",
                              },
                              data: (data.data_json?.data || []).map(
                                (_item, j) =>
                                  index === j
                                    ? {
                                        ..._item,
                                        img_url: files[0],
                                        thumbnail_img_url: undefined,
                                      }
                                    : _item,
                              ),
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className={["col", styles.imgCol].join(" ")}>
                  {!!img_url && (
                    <img src={img_url} className={styles.detailImg} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col title">타이틀(앱내 미표기)</div>
                <div className="col">
                  <InputBox
                    value={item.title}
                    onValue={value =>
                      setData({
                        ...data,
                        data_json: {
                          ...{
                            ...data.data_json,
                            title: data.data_json?.title || "",
                          },
                          data: (data.data_json?.data || []).map((_item, j) =>
                            index === j ? {..._item, title: value} : _item,
                          ),
                        },
                      })
                    }
                  />
                </div>
                <div
                  className={styles.minusIcon}
                  onClick={() => {
                    if ((data.data_json?.data || []).length <= 1) {
                      return alert("최소 1개이상 등록해야 합니다.");
                    }
                    if (!confirm("항목을 제거하시겠습니까?")) {
                      return;
                    }
                    setData({
                      ...data,
                      data_json: {
                        ...{
                          ...data.data_json,
                          title: data.data_json?.title || "",
                        },
                        data: (data.data_json?.data || []).filter(
                          (_item, j) => index !== j,
                        ),
                      },
                    });
                  }}>
                  <FontAwesomeIcon icon={faMinus} />
                </div>
              </div>
              <div className="row">
                <div className="col title">내용</div>
                <div className="col">
                  <div style={{flex: 1}}>
                    <TextAreaBox
                      value={item.content}
                      onValue={value =>
                        setData({
                          ...data,
                          data_json: {
                            ...{
                              ...data.data_json,
                              title: data.data_json?.title || "",
                            },
                            data: (data.data_json?.data || []).map((_item, j) =>
                              index === j ? {..._item, content: value} : _item,
                            ),
                          },
                        })
                      }
                      height={60}
                    />
                    <TextAreaBox
                      value={item.bold_content}
                      style={{fontWeight: "bold"}}
                      placeholder="굵은 내용"
                      onValue={value =>
                        setData({
                          ...data,
                          data_json: {
                            ...{
                              ...data.data_json,
                              title: data.data_json?.title || "",
                            },
                            data: (data.data_json?.data || []).map((_item, j) =>
                              index === j
                                ? {..._item, bold_content: value}
                                : _item,
                            ),
                          },
                        })
                      }
                      height={40}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
