import {Routes, Route, useNavigationType, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {ErrorPage} from "@pages";
import {utils} from "@utils";
import {ClaimTab, OrderTab} from "pages/MemberPage/detailModalTab";
import {PopupLayout} from "components/Layout/PopupLayout";

export default function AdminRouteApp() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const {member_no} = utils.getParameterObject({
    url: location.search,
  });
  const memberNo = isNaN(Number(member_no)) ? undefined : Number(member_no);

  function layout({
    component,
    requireData = [],
  }: {
    component: JSX.Element;
    requireData?: any[];
  }) {
    for (const require of requireData) {
      if (!require) {
        return (
          <PopupLayout>
            <ErrorPage />
          </PopupLayout>
        );
      }
    }
    return <PopupLayout>{component}</PopupLayout>;
  }

  useEffect(() => {
    if (action !== "POP") window.scrollTo(0, 0);
  }, [action, pathname]);

  return (
    <Routes>
      <Route
        path="/member-claim"
        element={layout({
          component: <ClaimTab member_no={memberNo || 0} />,
          requireData: [memberNo],
        })}
      />
      <Route
        path="/member-order"
        element={layout({
          component: <OrderTab member_no={memberNo || 0} />,
          requireData: [memberNo],
        })}
      />
      <Route
        path="*"
        element={layout({
          component: <ErrorPage />,
        })}
      />
    </Routes>
  );
}
