import {
  Button,
  FileUploadButton,
  InputBox,
  ListTable,
  Modal,
  ModalContent,
  ModalTitle,
  TabList,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {ResultPagingResponse, IIssuedCoupon} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible?: boolean;
  coupon_no: number;
  close?: () => void;
}

type TUseType = "Y" | "N" | "expired" | "";

const TAB_LIST: {tab: TUseType; title: string}[] = [
  {tab: "", title: "전체"},
  {tab: "Y", title: "사용"},
  {tab: "N", title: "미사용"},
  {tab: "expired", title: "만료"},
];

export function CouponIssuedModal({isVisible, coupon_no, close}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [issuedCouponList, setIssuedCouponList] =
    useState<ResultPagingResponse<IIssuedCoupon[]>>();
  const [searchText, setSearchText] = useState("");
  const [useType, setUseType] = useState<TUseType>("");

  function setClose() {
    setIssuedCouponList(undefined);
    close?.();
  }

  async function getIssuedCouponList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} =
        await adminAPI.coupon.getIssuedMemberList({
          coupon_no,
          page,
          searchtxt: searchText,
          use_type: useType,
        });
      if (success) {
        setIssuedCouponList(data);
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getIssuedCouponList error", error);
    }
  }

  async function deleteCoupon() {
    try {
      if (!confirm("미사용쿠폰을 일괄 삭제하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.coupon.deleteUnUsedCoupon({
        coupon_no,
      });
      alert(message);
      if (success) {
        getIssuedCouponList();
      }
    } catch (error) {
      console.error("deleteCoupon error", error);
    }
  }

  async function confirmCreateCoupon({filePath}: {filePath: string}) {
    try {
      const {success, message} = await adminAPI.coupon.confirmCreateCoupon({
        coupon_no,
        filePath,
      });
      alert(message);
      if (success) {
        getIssuedCouponList();
      }
    } catch (error) {
      console.error("deleteCoupon error", error);
    }
  }

  useEffect(() => {
    if (coupon_no) {
      getIssuedCouponList({});
    }
  }, [coupon_no, useType]);

  return (
    <Modal isVisible={isVisible} width={"700px"} close={setClose}>
      <ModalTitle title="쿠폰발행목록" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col">
            <div>
              <FileUploadButton
                text="엑셀업로드"
                callback={({
                  filePath,
                  count,
                }: {
                  filePath: string;
                  count: number;
                }) => {
                  setAlertModal({
                    isVisible: true,
                    title: `발급 대상자  ${(
                      count || 0
                    ).toLocaleString()}명에게 쿠폰이 발행됩니다.`,
                    button: [
                      {
                        text: "확인",
                        backgroundColor: styleUtils.COLOR.green,
                        fontColor: styleUtils.COLOR.white,
                        onClick: () => {
                          setAlertModal({isVisible: false});
                          confirmCreateCoupon({filePath});
                        },
                      },
                      {
                        text: "취소",
                        onClick: () => setAlertModal({isVisible: false}),
                      },
                    ],
                  });
                }}
                type={"excel"}
                uploadUrl={"/admin/coupon/importCreateCouponExcel"}
              />
            </div>
          </div>
          <div className="col" style={{justifyContent: "flex-end"}}>
            <div>
              <Button
                text="발행쿠폰삭제"
                height={30}
                fontSize={12}
                backgroundColor={styleUtils.COLOR.red400}
                onClick={deleteCoupon}
              />
            </div>
          </div>
        </div>
        <TabList tabList={TAB_LIST} activeTab={useType} setTab={setUseType} />
        <div className="row">
          <div className="col">
            총 {(issuedCouponList?.page?.maxcnt || 0).toLocaleString()}개
          </div>
          <div className="col flex-end">
            <div>
              <InputBox
                key={2}
                value={searchText}
                onValue={setSearchText}
                onEnter={getIssuedCouponList}
                rightContent={
                  <div
                    style={{display: "flex"}}
                    onClick={() => getIssuedCouponList()}>
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <ListTable
          getList={getIssuedCouponList}
          paging={issuedCouponList?.page}>
          <thead>
            <tr>
              <th>회원</th>
              <th>사용기간</th>
              <th>사용여부</th>
              <th>쿠폰코드</th>
            </tr>
          </thead>
          <tbody>
            {(issuedCouponList?.result || []).map((item, index) => (
              <tr key={index}>
                <td>
                  {item.member?.nickname}{" "}
                  {item.member?.name && `(${item.member?.name})`}
                </td>
                <td>
                  {moment(item.use_start_time).format("YYYY-MM-DD")}
                  <br />~ {moment(item.use_end_time).format("YYYY-MM-DD")}
                </td>
                <td>
                  {item.use_yn === "Y"
                    ? "사용완료"
                    : moment(item.use_end_time).isBefore(moment())
                    ? "만료"
                    : "미사용"}
                </td>
                <td>{item.coupon_code}</td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      </ModalContent>
    </Modal>
  );
}
