import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {styleUtils} from "@utils";
import React, {useEffect, useState} from "react";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  cardCancle?: () => void;
  pointCancle?: (point: number) => void;
}

export function OrderRefundModal({
  isVisible,
  close,
  cardCancle,
  pointCancle,
}: IProps) {
  const [point, setPoint] = useState<string>();

  function pointCancleHandler() {
    if (isNaN(Number(point))) {
      return alert("포인트 금액을 입력해주세요");
    }
    if (Number(point) <= 0) {
      return alert("포인트 금액을 입력해주세요");
    }
    pointCancle?.(Number(point));
  }

  useEffect(() => {
    setPoint("");
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={close}>
      <ModalTitle title="환불구분" isClose close={close} />
      <ModalContent>
        <div className="row">
          <div className="col">포인트입력(적립금취소시)</div>
        </div>
        <div className="row">
          <div className="col">
            <InputBox type="number" value={point} onValue={setPoint} />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          text="카드취소"
          onClick={cardCancle}
          backgroundColor={styleUtils.COLOR.orange}
          marginHorizontal={2}
        />
        <Button
          text="적립금취소"
          onClick={pointCancleHandler}
          backgroundColor={styleUtils.COLOR.blueDark}
          fontColor={styleUtils.COLOR.white}
          marginHorizontal={2}
        />
      </ModalFooter>
    </Modal>
  );
}
