import {Button, InputBox, SelectBox, TextAreaBox} from "@components";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {TProductDetailIconType, IProductDetailFeed} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../../style.module.scss";

interface IProps {
  item_no: number;
  detailData: IProductDetailFeed;
  refresh?: () => void;
}

const iconList: {label: string; value: TProductDetailIconType}[] = [
  {label: "체크", value: "check"},
  {label: "X", value: "close"},
];

export function FeedItem({detailData, refresh, item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductDetailFeed>({
    type: "feed",
    data_json: {
      data: [],
    },
  });

  async function save() {
    try {
      const params = {
        ...data,
        data_json: {...data.data_json},
        item_no,
      };
      const {success, message} = detailData.no
        ? await adminAPI.item.updateItemDetail({...params, no: detailData.no})
        : await adminAPI.item.createItemDetail(params);

      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    setData({...detailData});
  }, [detailData]);

  return (
    <>
      <div style={{flex: 1}}>
        <div className="row">
          <div className="col flex-end">
            <div>
              <Button
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                text="저장"
                onClick={save}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">내용</div>
          <div className="col flex-end">
            <div>
              <Button
                text="추가"
                onClick={() => {
                  setData({
                    ...data,
                    data_json: {
                      ...data.data_json,
                      data: [
                        ...(data.data_json?.data || []),
                        {icon: "check", title: "", sub_title: ""},
                      ],
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
        {(data.data_json?.data || []).map((item, index) => (
          <div key={index}>
            <div className="row">
              <div className="col title" style={{fontWeight: "400"}}>
                아이콘
              </div>
              <div className="col">
                <SelectBox
                  value={item.icon}
                  list={iconList}
                  singleSelect={value => {
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).map((_item, j) =>
                          index === j
                            ? {..._item, icon: value as TProductDetailIconType}
                            : _item,
                        ),
                      },
                    });
                  }}
                />
              </div>
              <div
                className={styles.minusIcon}
                onClick={() => {
                  if (!confirm("항목을 제거하시겠습니까?")) {
                    return;
                  }
                  setData({
                    ...data,
                    data_json: {
                      ...data.data_json,
                      data: (data.data_json?.data || []).filter(
                        (_item, j) => index !== j,
                      ),
                    },
                  });
                }}>
                <FontAwesomeIcon icon={faMinus} />
              </div>
            </div>
            <div className="row">
              <div className="col title" style={{fontWeight: "400"}}>
                타이틀
              </div>
              <div className="col">
                <InputBox
                  value={item.title}
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).map((_item, j) =>
                          index === j ? {..._item, title: value} : _item,
                        ),
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col title" style={{fontWeight: "400"}}>
                서브타이틀
              </div>
              <div className="col">
                <TextAreaBox
                  value={item.sub_title}
                  onValue={value =>
                    setData({
                      ...data,
                      data_json: {
                        ...data.data_json,
                        data: (data.data_json?.data || []).map((_item, j) =>
                          index === j ? {..._item, sub_title: value} : _item,
                        ),
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
