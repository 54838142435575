export function excelDownload({
  excelData,
  filename,
}: {
  excelData: string;
  filename: string;
}) {
  const excelUri =
    "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
  const excelUrl = excelUri + excelData;
  const link = document.createElement("a");
  link.href = excelUrl;
  link.download = filename;
  link.click();
  link.remove();
}
