import moment from "moment";
import React from "react";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  ToolbarProps,
  Event as TEvent,
  CalendarProps,
  DateLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

interface IProps extends CalendarProps<TEvent, any> {
  localizer: DateLocalizer;
  eventList?: TEvent[];
}

export default function Calendar({eventList, ...props}: IProps) {
  moment.locale("ko-KR");
  const localizer = momentLocalizer(moment);

  return (
    <BigCalendar
      {...props}
      localizer={localizer}
      events={eventList}
      style={{height: 500}}
      components={{
        toolbar: Toolbar,
      }}
    />
  );
}

function Toolbar(props: ToolbarProps) {
  const {date} = props;

  const navigate = (action: any) => {
    props.onNavigate(action);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group" style={{display: "flex", flex: 1}}>
        <div className="row" style={{justifyContent: "space-between"}}>
          <div>
            <button type="button" onClick={navigate.bind(null, "TODAY")}>
              이번달
            </button>
          </div>
          <div className="col">
            <span className="rbc-toolbar-label">{`${date.getFullYear()}년 ${
              date.getMonth() + 1
            }월`}</span>
          </div>
          <div>
            <button type="button" onClick={navigate.bind(null, "PREV")}>
              이전
            </button>
            <button type="button" onClick={navigate.bind(null, "NEXT")}>
              다음
            </button>
          </div>
        </div>
      </span>
    </div>
  );
}
