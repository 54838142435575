import React from "react";
import styles from "./style.module.scss";

interface IProps {
  title?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export default function ContentLayout({
  title,
  children,
  className = "",
}: IProps) {
  return (
    <div className={[styles.container, className].join(" ")}>
      <h1>{title}</h1>
      {children}
    </div>
  );
}
