import {Button, CheckBox, ListTable, TextAreaBox} from "@components";
import {states} from "@recoils";
import StarImg from "../../../assets/images/Star.png";
import StarGreenImg from "../../../assets/images/StarGreen.png";
import {adminAPI, constant, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYN,
  IReviewDetailResponse,
  IReviewListResponse,
} from "@data";
import _ from "lodash";
import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  item_no: number;
}

export function ReviewTab({item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<
      ResultPagingResponse<{list?: IReviewListResponse[]; allCnt?: number}>
    >();
  const [detailData, setDetailData] = useState<IReviewDetailResponse>();
  const detailRef = useRef<HTMLDivElement>(null);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.item.getReviewList({
        page,
        item_no,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.item.apiGetReviewDetail({
        no,
      });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function save({
    no,
    item_no,
    best_yn,
  }: {
    no: number;
    item_no: number;
    best_yn: TypeYN;
  }) {
    if (!confirm("수정하시겠습니까?")) {
      return;
    }

    try {
      const {success, message} = await adminAPI.item.apiUpdateRreviewBestYn({
        no,
        item_no,
        best_yn,
      });
      if (success) {
        setDetailData(undefined);
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
          button: [
            {
              text: "닫기",
              onClick: () => setAlertModal({isVisible: false}),
            },
          ],
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (detailData) {
      if (window.innerWidth <= constant.MOBILE_WIDTH) {
        detailRef.current?.scrollIntoView();
      }
    }
  }, [detailData]);

  return (
    <>
      <div className={["row", styles.reviewTab].join(" ")}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className="title">리뷰이력</div>
            <ListTable paging={listData?.page} getList={getList}>
              <thead>
                <tr>
                  <th>제품명</th>
                  <th>고객명(닉네임)</th>
                  <th>별점</th>
                  <th>베스트리뷰</th>
                  <th>작성일</th>
                </tr>
              </thead>
              <tbody>
                {(listData?.result?.list || []).map((item, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      if (!item.no) {
                        return;
                      }
                      getDetail({no: item.no});
                    }}>
                    <td className={styles.itemName}>
                      [{item.product?.partners?.partner_name}]{" "}
                      {item.product?.item_name}
                    </td>
                    <td>
                      {item.member?.name} ({item.member?.nickname || "-"})
                    </td>
                    <td>
                      <div className={styles.scoreContainer}>
                        {[...Array(5).keys()].map(starIndex => {
                          const isActive =
                            Number(item.score || 0) >= starIndex + 1;
                          return (
                            <img
                              key={starIndex}
                              src={isActive ? StarGreenImg : StarImg}
                            />
                          );
                        })}
                      </div>
                      {(item.photo_cnt || 0) > 0 && (
                        <span className="text-10">
                          (사진 {(item.photo_cnt || 0).toLocaleString()}
                          개)
                        </span>
                      )}
                    </td>
                    <td>
                      <CheckBox
                        checked={item.best_yn === "Y"}
                        text={item.best_yn === "Y" ? "O" : "X"}
                        isToggle
                        onChange={e => {
                          if (!item.no || !item.item_no) {
                            return;
                          }
                          save({
                            no: item.no,
                            item_no: item.item_no,
                            best_yn: item.best_yn === "Y" ? "N" : "Y",
                          });
                        }}
                      />
                    </td>
                    <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                  </tr>
                ))}
              </tbody>
            </ListTable>
          </div>
          <div className={styles.detailContainer}>
            <div>
              <div ref={detailRef} className={styles.detailContainer}>
                {!!detailData && (
                  <>
                    <div className={styles.member}>
                      <div>
                        <span>
                          {detailData?.member?.name} (
                          {detailData?.member?.nickname})
                        </span>
                        <div>
                          {utils.formatPhoneNumber(
                            detailData?.member?.phone_number || "",
                          )}
                        </div>
                      </div>
                      <div>
                        <div
                          className={styles.scoreContainer}
                          style={{justifyContent: "flex-end"}}>
                          {[...Array(5).keys()].map(starIndex => {
                            const isActive =
                              Number(detailData.score || 0) >= starIndex + 1;
                            return (
                              <img
                                key={starIndex}
                                src={isActive ? StarGreenImg : StarImg}
                              />
                            );
                          })}
                        </div>
                        <div>
                          {moment(detailData.createdAt).format(
                            "YYYY-MM-DD HH:mm",
                          )}
                        </div>
                      </div>
                    </div>
                    {(detailData.options || []).length > 0 && (
                      <div className={styles.optionContainer}>
                        {(detailData.options || []).map(
                          (option, optionIndex) => (
                            <>
                              {optionIndex > 0 && (
                                <span
                                  key={`dot_${optionIndex}`}
                                  className={styles.dot}
                                />
                              )}
                              <div key={option.no} className={styles.item}>
                                <span className={styles.category}>
                                  {option.category?.title}
                                </span>
                                <span className={styles.option}>
                                  {option.option?.title}
                                </span>
                              </div>
                            </>
                          ),
                        )}
                      </div>
                    )}
                    <div className={styles.contentContainer}>
                      <div className={styles.content}>
                        {detailData?.content}
                      </div>
                      <div className={styles.photoContainer}>
                        {(detailData.photos || []).map(photo => (
                          <img
                            key={photo.no}
                            src={photo.thumbnail_img_url || photo.img_url}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
