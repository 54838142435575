import {
  Button,
  CheckBox,
  Content,
  ContentLayout,
  InputBox,
  ListTable,
  SearchFilter,
  TabList,
} from "@components";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {
  ResultPagingResponse,
  TUpDown,
  TypeYN,
  IDBHomeTabList,
  IDBSection,
  IDBSectionContents,
  ISectionDetail,
  ISectionList,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {DetailModal} from "./DetailModal";
import {SectionContentModal} from "./SectionContentModal";
import {SectionDetailListModal} from "./SectionDetailListModal";

export default function SectionPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [homeTabList, setHomeTabList] = useState<IDBHomeTabList[]>([]);
  const [selectedHomeTabNo, setSelectedHomeTabNo] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<ISectionList[]>>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detailData, setDetailData] = useState<IDBSection>();
  const [activeSectionNo, setActiveSectionNo] = useState<number>();
  const [contentData, setContentData] = useState<IDBSectionContents[]>();
  const [sectionDetail, setSectionDetail] = useState<ISectionDetail[]>();

  async function getHomeTabList() {
    try {
      const {data, success} = await adminAPI.homeTab.getHomeTabList();
      if (success) {
        setHomeTabList(data);
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.section.getList({
        page,
        searchtxt: searchText,
        home_tab_no: selectedHomeTabNo,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.section.getDetail({no});
      if (success) {
        setDetailData(data);
        setIsDetailOpen(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getContentDetail({no}: {no: number}) {
    try {
      const {data, success, message} =
        await adminAPI.section.getSectionContentList({
          no,
        });
      if (success) {
        setContentData(data);
        setActiveSectionNo(no);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function getSectionDetail({no}: {no: number}) {
    try {
      const {data, success, message} =
        await adminAPI.section.getSectionDetailList({no});
      if (success) {
        setSectionDetail(data);
        setActiveSectionNo(no);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function changeSectionOrdering({
    no,
    type,
  }: {
    no: number;
    type: TUpDown;
  }) {
    try {
      if (!confirm("순서를 변경하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.section.changeSectionOrdering({
        no,
        type,
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changeSectionOrdering error", error);
    }
  }

  async function toggleHideYN({no, hide_yn}: {no: number; hide_yn: TypeYN}) {
    try {
      if (!confirm("노출여부를 변경하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.section.toggleHideYn({
        no,
        hide_yn,
      });
      if (success) {
        getList({page: listData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("toggleHideYN error", error);
    }
  }

  useEffect(() => {
    getHomeTabList();
  }, []);

  useEffect(() => {
    getList();
  }, [selectedHomeTabNo]);

  return (
    <>
      <ContentLayout title="홈섹션관리">
        <SearchFilter
          left={[
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => {
                setDetailData(undefined);
                setIsDetailOpen(true);
              }}
            />,
          ]}
        />
        <Content>
          <div className="flex" style={{flex: 1}}>
            <TabList
              tabList={[{no: undefined, name: "홈"}, ...homeTabList].map(
                item => ({
                  tab: item.no,
                  title: String(item.name),
                }),
              )}
              setTab={setSelectedHomeTabNo}
              activeTab={selectedHomeTabNo}
            />
            <div className="flex" style={{flex: 1}}>
              <ListTable paging={listData?.page} getList={getList}>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>탭구분</th>
                    <th>타이틀</th>
                    <th>서브타이틀</th>
                    <th>상품수</th>
                    <th>순서</th>
                    <th>노출일</th>
                    <th>노출여부</th>
                    <th>한상차림상세</th>
                    <th>상품순서변경</th>
                  </tr>
                </thead>
                <tbody>
                  {(listData?.result || []).map((item, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => !!item.no && getDetail({no: item.no})}>
                        <td>{item.no}</td>
                        <td>{!item.home_tab_no ? "홈" : item.homeTab?.name}</td>
                        <td>{item.title}</td>
                        <td>{item.sub_title}</td>
                        <td>{(item.item_cnt || 0).toLocaleString()}</td>
                        <td onClick={e => utils.handleClickBubble(e)}>
                          <div className="row" style={{minWidth: "50px"}}>
                            <div className="col">
                              {item.hide_yn === "N" &&
                                (item.ordering || 0) > 1 && (
                                  <FontAwesomeIcon
                                    icon={faArrowCircleUp}
                                    onClick={() => {
                                      if (item.no) {
                                        changeSectionOrdering({
                                          no: item.no,
                                          type: "up",
                                        });
                                      }
                                    }}
                                  />
                                )}
                            </div>
                            <div className="col">{item.ordering || "-"}</div>
                            <div className="col">
                              {item.hide_yn === "N" && (
                                <FontAwesomeIcon
                                  icon={faArrowCircleDown}
                                  onClick={() => {
                                    if (item.no) {
                                      changeSectionOrdering({
                                        no: item.no,
                                        type: "down",
                                      });
                                    }
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          {moment(item.openDate).isValid() &&
                            moment(item.openDate).format("YYYY-MM-DD")}{" "}
                          ~{" "}
                          {moment(item.closeDate).isValid() &&
                            moment(item.closeDate).format("YYYY-MM-DD")}
                          <br />
                          {moment(item.openDate).isAfter(moment())
                            ? "(오픈전)"
                            : moment(item.closeDate).isBefore(moment())
                            ? "(종료)"
                            : item.hide_yn === "N" && "(노출중)"}
                        </td>
                        <td onClick={e => utils.handleClickBubble(e)}>
                          <CheckBox
                            checked={item.hide_yn === "N"}
                            text={item.hide_yn === "N" ? "노출" : "숨김"}
                            isToggle
                            onChange={e => {
                              if (!item.no) {
                                return;
                              }
                              toggleHideYN({
                                no: item.no,
                                hide_yn: item.hide_yn === "N" ? "Y" : "N",
                              });
                            }}
                          />
                        </td>
                        <td onClick={e => utils.handleClickBubble(e)}>
                          <div style={{display: "inline-block"}}>
                            {item.view_type === 3 && (
                              <Button
                                text="상세"
                                fontSize={12}
                                onClick={() =>
                                  !!item.no && getContentDetail({no: item.no})
                                }
                              />
                            )}
                          </div>
                        </td>
                        <td onClick={e => utils.handleClickBubble(e)}>
                          <div style={{display: "inline-block"}}>
                            <Button
                              text="상세"
                              fontSize={12}
                              onClick={() =>
                                !!item.no && getSectionDetail({no: item.no})
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ListTable>
            </div>
          </div>
        </Content>
      </ContentLayout>
      <DetailModal
        isVisible={isDetailOpen}
        prevData={detailData}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
        }}
        refresh={() => getList({page: listData?.page?.cur || 1})}
        homeTabList={homeTabList}
      />
      {!!activeSectionNo && (
        <SectionContentModal
          isVisible={!!contentData}
          section_no={activeSectionNo}
          data={contentData}
          close={() => {
            setContentData(undefined);
            setActiveSectionNo(undefined);
          }}
          refresh={() => {
            if (activeSectionNo) {
              getContentDetail({no: activeSectionNo});
            }
          }}
        />
      )}
      {!!activeSectionNo && (
        <SectionDetailListModal
          isVisible={!!sectionDetail}
          section_no={activeSectionNo}
          listData={sectionDetail}
          close={() => {
            setSectionDetail(undefined);
            setActiveSectionNo(undefined);
          }}
          refresh={() => {
            if (activeSectionNo) {
              getSectionDetail({no: activeSectionNo});
            }
          }}
        />
      )}
    </>
  );
}
