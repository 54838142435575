import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {IDBCouponList, IMemberListResponse} from "@data";
import _ from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
}

export function MemberCouponAddMultiModal({isVisible, close, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [couponList, setCouponList] = useState<IDBCouponList[]>([]);
  const [memberList, setMemberList] = useState<IMemberListResponse[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchMemberList, setSearchMemberList] = useState<
    IMemberListResponse[]
  >([]);
  const [couponNo, setCouponNo] = useState<number>();
  const [useStartDate, setUseStartDate] = useState(
    moment().format("YYYY-MM-DD"),
  );
  const [useEndDate, setUseEndtDate] = useState(
    moment().add(14, "days").format("YYYY-MM-DD"),
  );

  function setClose() {
    setCouponNo(undefined);
    setUseStartDate(moment().format("YYYY-MM-DD"));
    setUseEndtDate(moment().add(14, "days").format("YYYY-MM-DD"));
    close?.();
  }

  async function getMemberData() {
    try {
      if (!searchText) {
        return alert("검색어를 입력해주세요.");
      }
      const {data, success, message} = await adminAPI.member.getList({
        searchtxt: searchText,
      });
      if (success) {
        setSearchMemberList(data.result.list);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getMemberData error", error);
    }
  }

  async function getCouponList() {
    try {
      const {data, success, message} =
        await adminAPI.coupon.apiGetAllCouponList();
      if (success) {
        setCouponList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getCouponList error", error);
    }
  }

  async function sendCoupon() {
    try {
      if (!useStartDate || !useEndDate) {
        return setAlertModal({
          isVisible: true,
          title: "사용기간을 입력해주세요",
        });
      }
      if (!couponNo) {
        return setAlertModal({
          isVisible: true,
          title: "쿠폰을 선택해주세요",
        });
      }
      const member_arr = [
        ...new Set(memberList.map(_item => _item.no).filter(utils.isDefined)),
      ];

      if (member_arr.length === 0) {
        return alert("회원을 선택해주세요.");
      }

      if (
        !confirm(
          `${member_arr.length}명의 회원에게 쿠폰을 지급하시겠습니까? 회원들에게 푸시가 발송됩니다.`,
        )
      ) {
        return;
      }

      const {success, message} = await adminAPI.coupon.sendCoupon({
        member: member_arr,
        coupon_no: couponNo,
        use_start_date: useStartDate,
        use_end_date: useEndDate,
      });
      setAlertModal({
        isVisible: true,
        title: message || "쿠폰발행오류",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("sendCoupon error", error);
    }
  }

  useEffect(() => {
    getCouponList();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="쿠폰발행" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">회원검색</div>
          <div className="col">
            <InputBox
              value={searchText}
              onValue={setSearchText}
              rightContent={
                <div style={{display: "flex"}} onClick={getMemberData}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              onEnter={getMemberData}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div>
              {searchMemberList.map(_item => (
                <div
                  className={styles.badge}
                  key={_.uniqueId()}
                  onClick={() =>
                    setMemberList([
                      ...memberList.filter(_member => _member.no !== _item.no),
                      _item,
                    ])
                  }>
                  {_item.name}{" "}
                  {utils.formatPhoneNumber(_item.phone_number || "")}
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col title">지급받을회원목록</div>
          <div className="col">
            <div>
              {memberList.map(_item => (
                <div className={styles.badge} key={_.uniqueId()}>
                  {_item.name}{" "}
                  {utils.formatPhoneNumber(_item.phone_number || "")}{" "}
                  <span
                    className={styles.remove}
                    onClick={() =>
                      setMemberList([
                        ...memberList.filter(
                          _member => _member.no !== _item.no,
                        ),
                      ])
                    }>
                    X
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">쿠폰선택</div>
          <div className="col">
            <SelectBox
              list={couponList.map(item => ({
                label: `[${item.coupon_num}] ${item.title}`,
                value: String(item.no),
              }))}
              value={String(couponNo)}
              singleSelect={value => setCouponNo(Number(value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">사용기간</div>
          <div className="col">
            <div className="row center">
              <div className="col">
                <InputBox
                  value={useStartDate}
                  type={"date"}
                  onValue={setUseStartDate}
                />
              </div>
              <div className="col">~</div>
              <div className="col">
                <InputBox
                  value={useEndDate}
                  type={"date"}
                  onValue={setUseEndtDate}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="발행" onClick={sendCoupon} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
