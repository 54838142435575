import {
  Button,
  ITabList,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  TabList,
} from "@components";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {IDBMember, IMemberResponse} from "@data";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";
import {
  InfoTab,
  CouponTab,
  OrderTab,
  ReviewTab,
  PointTab,
  ClaimTab,
  PaymenyLogTab,
  MemberQnaTab,
  ItemQnaTab,
} from "./detailModalTab";
import {CartTab} from "./detailModalTab/CartTab";
import GradeLogTab from "./detailModalTab/GradeLogTab";

type TTabs =
  | "main"
  | "order"
  | "cart"
  | "review"
  | "coupon"
  | "point"
  | "claim"
  | "member_qna"
  | "item_qna"
  | "paymentLog"
  | "gradeLog";
const tabs: ITabList<TTabs>[] = [
  {tab: "main", title: "고객정보"},
  {tab: "order", title: "주문내역"},
  {tab: "cart", title: "장바구니"},
  {tab: "review", title: "리뷰"},
  {tab: "coupon", title: "쿠폰내역"},
  {tab: "point", title: "포인트"},
  {tab: "claim", title: "클레임"},
  {tab: "member_qna", title: "1:1 문의"},
  {tab: "item_qna", title: "상품문의"},
  {tab: "gradeLog", title: "등급히스토리"},
  {tab: "paymentLog", title: "신용/체크결제이력"},
];

interface IProps {
  isVisible: boolean;
  close?: () => void;
  member?: IMemberResponse;
  refresh?: () => void;
}

export function MemberDetailModal({isVisible, close, member, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [tab, setTab] = useState<TTabs>("main");

  function setClose() {
    setTab(tabs[0].tab);
    close?.();
  }

  async function updateMember(params: IDBMember) {
    try {
      if (!params.no) {
        return;
      }
      const {success, message} = await adminAPI.member.updateMember(params);
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("updateMember error", error);
    }
  }

  if (!member?.no) {
    return <></>;
  }

  return (
    <>
      <Modal
        isVisible={isVisible}
        close={setClose}
        width={"90vw"}
        mobileWidth={"100vw"}
        height={"100vh"}
        positionTop={"0"}
        positionRight={"0"}
        borderRadius={0}
        animate={"right"}>
        <ModalTitle
          title={`회원상세 (${member.name} / ${
            member.nickname
          } / ${utils.formatPhoneNumber(member.phone_number || "")})`}
          close={setClose}
          isClose
        />
        <ModalContent>
          <TabList tabList={tabs} setTab={setTab} activeTab={tab} />
          <>
            {tab === "main" && (
              <InfoTab
                member={member}
                refresh={refresh}
                updateMember={updateMember}
              />
            )}
            {tab === "order" && <OrderTab member_no={member.no} />}
            {tab === "cart" && <CartTab member_no={member.no} />}
            {tab === "review" && <ReviewTab member_no={member.no} />}
            {tab === "coupon" && <CouponTab member_no={member.no} />}
            {tab === "point" && (
              <PointTab member_no={member.no} refresh={refresh} />
            )}
            {tab === "claim" && <ClaimTab member_no={member.no} />}
            {tab === "member_qna" && <MemberQnaTab member_no={member.no} />}
            {tab === "item_qna" && <ItemQnaTab member_no={member.no} />}
            {tab === "paymentLog" && <PaymenyLogTab member_no={member.no} />}
            {tab === "gradeLog" && <GradeLogTab member_no={member.no} />}
          </>
        </ModalContent>
        <ModalFooter>
          <div>
            <Button text="새로고침" onClick={refresh} />
          </div>
          <div>
            <Button text="확인" onClick={setClose} />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
