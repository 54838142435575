import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {IDBOrderDetail} from "@data";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";
import {COLOR} from "utils/styleUtils";

interface IProps {
  isVisible: boolean;
  orderDetail: IDBOrderDetail;
  close?: () => void;
  refresh?: () => void;
}

export function OrderDetailChangeModal({
  isVisible,
  orderDetail,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [count, setCount] = useState(orderDetail.count || 0);

  async function saveDetail() {
    try {
      if (!orderDetail.no || !orderDetail.item_no || !orderDetail.order_no) {
        return;
      }
      if (!confirm("수량을 변경하시겠습니까? 재고도 같이 수정됩니다.")) {
        return;
      }

      const {success, message} = await adminAPI.order.changeOrderDetailCount({
        item_no: orderDetail.item_no,
        order_detail_no: orderDetail.no,
        order_no: orderDetail.order_no,
        count,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        close?.();
        refresh?.();
      }
    } catch (error) {
      console.error("saveDetail error", error);
    }
  }

  async function deleteDetail() {
    try {
      if (!orderDetail.no || !orderDetail.item_no || !orderDetail.order_no) {
        return;
      }
      if (!confirm("상품을 제거하시겠습니까? 재고도 같이 수정됩니다.")) {
        return;
      }

      const {success, message} = await adminAPI.order.changeOrderDetailCount({
        item_no: orderDetail.item_no,
        order_detail_no: orderDetail.no,
        order_no: orderDetail.order_no,
        count: 0,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        close?.();
        refresh?.();
      }
    } catch (error) {
      console.error("deleteDetail error", error);
    }
  }

  return (
    <Modal isVisible={isVisible} width={"500px"} close={close}>
      <ModalTitle title="수량변경" isClose close={close} />
      <ModalContent>
        <div className="row">
          <div className="col title">수량</div>
          <div className="col">
            <InputBox
              value={count}
              type="number"
              onValue={value => setCount(Number(value))}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button
            text="삭제"
            onClick={deleteDetail}
            backgroundColor={COLOR.danger}
          />
        </div>
        <div>
          <Button text="저장" onClick={saveDetail} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
