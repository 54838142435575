import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IPage} from "@data";
import React from "react";
import styles from "./style.module.scss";

interface IProps {
  paging: IPage;
  limitPageCount?: number;
  getList?: ({page}: {page: number}) => void;
}

export default function Pagination({
  paging,
  limitPageCount = 5,
  getList,
}: IProps) {
  const {cur = 1, maxpage = 1} = paging || {};
  const pageArray = getPageData({cur, maxpage, limitPageCount});
  const lastPage = pageArray[pageArray.length - 1];

  function goFirst() {
    getList?.({page: 1});
  }
  function prev() {
    getList?.({page: pageArray[0] - 1});
  }
  function next() {
    getList?.({page: lastPage + 1});
  }
  function goLast() {
    getList?.({page: maxpage});
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.paging}>
        {cur > limitPageCount && (
          <>
            <FontAwesomeIcon
              icon={faAnglesLeft}
              onClick={goFirst}
              className={styles.arrowBtn}
            />
            <FontAwesomeIcon
              icon={faAngleLeft}
              onClick={prev}
              className={styles.arrowBtn}
            />
          </>
        )}
        {pageArray.map((nowPage, i) => {
          const isActive = cur === nowPage;
          return (
            <button
              key={i}
              disabled={isActive}
              className={[styles.pageItem, isActive && styles.active].join(" ")}
              onClick={() => getList?.({page: nowPage})}>
              {nowPage}
            </button>
          );
        })}
        {lastPage < maxpage && (
          <>
            <FontAwesomeIcon
              icon={faAngleRight}
              onClick={next}
              className={styles.arrowBtn}
            />
            <FontAwesomeIcon
              icon={faAnglesRight}
              onClick={goLast}
              className={styles.arrowBtn}
            />
          </>
        )}
      </div>
    </div>
  );
}

function getPageData({
  cur,
  maxpage,
  limitPageCount,
}: {
  cur: number;
  maxpage: number;
  limitPageCount: number;
}) {
  let start_page =
    Math.floor((cur - 1) / limitPageCount) * limitPageCount +
    (cur % limitPageCount > limitPageCount ? 1 : 0) +
    1;
  let end_page = start_page + (limitPageCount - 1);
  const pageArray: number[] = [];

  if (start_page < 1) {
    start_page = 1;
    end_page = limitPageCount;
  }

  if (end_page > maxpage) {
    end_page = maxpage;
  }

  if (start_page < 1) {
    start_page = 1;
  }
  if (cur < limitPageCount && end_page !== maxpage) {
    end_page = limitPageCount;
  }

  for (let i = start_page; i <= end_page; i++) {
    pageArray.push(i);
  }

  return pageArray;
}
