import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import {
  Button,
  Content,
  ContentLayout,
  ListTable,
  SearchFilter,
  TextAreaBox,
} from "@components";
import {IGradeCouponEditRequest, IGradeResponse} from "@data";
import {adminAPI} from "@utils";
import {CouponAddModal} from "./CouponAddModal";

interface IEditIGrade extends IGradeResponse {
  edit_desc?: string;
}

export default function GradePage() {
  const [gradeList, setGradeList] = useState<IEditIGrade[]>([]);
  const [addCouponModalGradeNo, setAddCouponModalGradeNo] = useState<number>();

  async function getList() {
    try {
      const {success, data} = await adminAPI.member.getGradeList();
      if (success) {
        setGradeList(
          data.map(item => ({
            ...item,
            edit_desc: item.desc,
          })),
        );
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function save() {
    try {
      if (!confirm("변경사항을 저장하시겠습니까?")) {
        return;
      }

      const data = gradeList.map(item => {
        const _data: IGradeCouponEditRequest = {
          grade_no: item.no as number,
          coupon: (item.coupon || []).map(_coupon => ({
            coupon_no: _coupon.coupon_no as number,
            count: _coupon.count || 1,
          })),
          desc: item.edit_desc || "",
        };
        return _data;
      });
      const {success, message} = await adminAPI.member.editGradeData({data});
      alert(message);
      if (success) {
        getList();
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <ContentLayout title="등급관리">
        <SearchFilter right={[<Button key={1} text="저장" onClick={save} />]} />
        <Content className={styles.wrap}>
          <div className={styles.container}>
            <ListTable className={styles.table}>
              <thead>
                <tr>
                  <th>항목</th>
                  {gradeList.map(item => (
                    <th
                      key={item.no}
                      style={{backgroundColor: item.background_color}}>
                      {item.grade_name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr style={{backgroundColor: "#FFF8E3"}}>
                  <th>누적 결제금액</th>
                  {gradeList.map((item, index) => {
                    const isLast = index === gradeList.length - 1;
                    const text = isLast
                      ? `상위 15등`
                      : item.min_price === 0
                      ? `${Number(item.max_price || 0) / 10000}만원 미만`
                      : Number(item.min_price || 0).toLocaleString();
                    return <td key={item.no}>{text}</td>;
                  })}
                </tr>
                <tr style={{backgroundColor: "#FFF8E3"}}>
                  <th>누적 결제횟수</th>
                  {gradeList.map(item => {
                    return <td key={item.no}>{item.need_order_cnt}</td>;
                  })}
                </tr>
                <tr>
                  <th>고객 수</th>
                  {gradeList.map(item => {
                    return <td key={item.no}>{item.member_cnt}</td>;
                  })}
                </tr>
                <tr>
                  <th>비율(구매자 중)</th>
                  {gradeList.map(item => {
                    return (
                      <td key={item.no}>
                        {Number(item.member_percent || 0).toFixed(2)}%
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>적립금</th>
                  {gradeList.map(item => {
                    return (
                      <td key={item.no}>
                        {Number(item.point_percent || 0).toFixed(2)}%
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>앱 내 설명</th>
                  {gradeList.map((item, i) => {
                    return (
                      <td key={item.no} style={{padding: 0}}>
                        <TextAreaBox
                          value={item.edit_desc}
                          style={{height: 100}}
                          onValue={value => {
                            setGradeList([
                              ...gradeList.map((_prevItem, _i) => {
                                if (i === _i) {
                                  return {..._prevItem, edit_desc: value};
                                }
                                return _prevItem;
                              }),
                            ]);
                          }}
                        />
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>쿠폰</th>
                  {gradeList.map((item, i) => {
                    const couponList = item.coupon || [];
                    return (
                      <td key={item.no} className={styles.couponList}>
                        <div className={styles.couponItem}>
                          <span> </span>
                          <div
                            className={styles.addBtn}
                            onClick={() => {
                              setAddCouponModalGradeNo(item.no);
                            }}>
                            +
                          </div>
                        </div>
                        {couponList.map((coupon, j) => {
                          return (
                            <div key={j} className={styles.couponItem}>
                              <span>{coupon.coupon?.title}</span>
                              <div
                                className={styles.deleteBtn}
                                onClick={() => {
                                  setGradeList([
                                    ...gradeList.map((_prevItem, _i) => {
                                      if (i === _i) {
                                        return {
                                          ..._prevItem,
                                          coupon: (
                                            _prevItem.coupon || []
                                          ).filter(
                                            (__prevCoupon, __i) =>
                                              __prevCoupon.no !== coupon.no,
                                          ),
                                        };
                                      }
                                      return _prevItem;
                                    }),
                                  ]);
                                }}>
                                X
                              </div>
                            </div>
                          );
                        })}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </ListTable>
          </div>
        </Content>
      </ContentLayout>
      <CouponAddModal
        isVisible={!!addCouponModalGradeNo}
        grade_no={addCouponModalGradeNo}
        close={() => setAddCouponModalGradeNo(undefined)}
        select={coupon => {
          setGradeList([
            ...gradeList.map(_prevItem => {
              if (addCouponModalGradeNo === _prevItem.no) {
                return {
                  ..._prevItem,
                  coupon: [
                    ...(_prevItem.coupon || []),
                    {
                      grade_no: addCouponModalGradeNo,
                      coupon_no: coupon.no,
                      count: 1,
                      limit_month: 1,
                      coupon,
                    },
                  ],
                };
              }
              return _prevItem;
            }),
          ]);
        }}
      />
    </>
  );
}
