import {ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {
  ORDER_STATUS,
  ORDER_STATUS_COLOR,
  ORDER_STATUS_TEXT_COLOR,
  ResultPagingResponse,
  IOrderDetail,
  IOrderList,
} from "@data";
import _ from "lodash";
import moment from "moment";
import {OrderDetailModal} from "pages/OrderPage/OrderDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  member_no: number;
}

export function OrderTab({member_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] = useState<
    ResultPagingResponse<{
      list?: IOrderList[];
      count?: {orderCnt?: number; cancelCnt?: number};
    }>
  >();
  const [detailData, setDetailData] = useState<IOrderDetail>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.member.getMemberOrderList(
        {
          page,
          member_no,
          limit: 15,
        },
      );
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no,
      });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row">
        <div className={styles.title}>
          총 {(listData?.page?.maxcnt || 0).toLocaleString()}개
        </div>
      </div>
      <div className={["row", styles.orderTab].join(" ")}>
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>주문번호</th>
              <th>제품명</th>
              <th>결제금액</th>
              <th>상태</th>
              <th>구매일자</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result?.list || []).map(data => {
              const statusColor = data.status
                ? ORDER_STATUS_COLOR[data.status]
                : "";
              const statusTextColor = data.status
                ? ORDER_STATUS_TEXT_COLOR[data.status]
                : "";

              return (
                <tr
                  key={_.uniqueId()}
                  onClick={() => !!data.no && getDetail({no: data.no})}>
                  <td>
                    {data.order_number}
                    {!!data.subscribe_no && (
                      <>
                        <br />
                        <span className="text-12">(정기구독)</span>
                      </>
                    )}
                  </td>
                  <td>
                    {data.detail?.[0]?.item_name}
                    {(data.detail || []).filter(
                      item => item.cancel_yn === "N" && item.refund_yn === "N",
                    ).length > 1 && ` 외 ${(data.detail || []).length - 1}건`}
                  </td>
                  <td>{(data.payment_price || 0).toLocaleString()}원</td>
                  <td>
                    <span
                      className={styles.badge}
                      style={{
                        background: statusColor,
                        color: statusTextColor,
                      }}>
                      {data.status ? ORDER_STATUS[data.status] : ""}
                    </span>
                  </td>
                  <td>{moment(data.createdAt).format("YYYY-MM-DD")}</td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </div>
      <OrderDetailModal
        isVisible={!!detailData}
        close={() => setDetailData(undefined)}
        data={detailData}
        refresh={() => {
          if (detailData?.no) {
            getDetail({no: detailData.no});
          }
        }}
      />
    </>
  );
}
