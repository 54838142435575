import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  ContentLayout,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {ResultPagingResponse, TSmsLogType, ISmsLogListResponse} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";

type TSelectType = "1" | "0" | "";
const TypeArr: {value: TSmsLogType; label: string}[] = [
  {value: "login", label: "인증번호"},
  {value: "subscribe_notice", label: "정기구독안내"},
  {value: "subscribe_fail", label: "정기구독실패"},
  {value: "first_order_survey", label: "첫구매설문안내"},
];

export default function SMSLogPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [type, setType] = useState<TSmsLogType>(TypeArr[0].value);
  const [isSuccess, setIsSuccess] = useState<TSelectType>("");
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<ISmsLogListResponse[]>>();

  async function getData({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.smsLog.getList({
        limit: 20,
        page,
        searchtxt: searchText,
        success: isSuccess,
        type,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("gatData error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [isSuccess, type]);

  return (
    <>
      <ContentLayout title="문자발송이력">
        <SearchFilter
          left={[
            <SelectBox
              key={0}
              value={type}
              list={TypeArr}
              singleSelect={value => setType(value as TSmsLogType)}
            />,
            <SelectBox
              key={1}
              value={isSuccess}
              list={
                [
                  {label: "성공여부", value: ""},
                  {label: "성공", value: "1"},
                  {label: "실패", value: "0"},
                ] as {label: string; value: TSelectType}[]
              }
              singleSelect={value => setIsSuccess(value as TSelectType)}
            />,
            <InputBox
              key={2}
              value={searchText}
              onValue={setSearchText}
              onEnter={getData}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getData()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getData}>
            <thead>
              <tr>
                <th>전화번호</th>
                <th>고객명 (닉네임)</th>
                <th>내용</th>
                <th>성공여부</th>
                <th>오류메시지</th>
                <th>발송일</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => (
                <tr key={index}>
                  <td>{utils.formatPhoneNumber(item.phoneNumber || "")}</td>
                  <td>
                    {item.name || item.nickname ? (
                      <>
                        {item.name} ({item.nickname})
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <div
                      className={["preline", "text-left"].join(" ")}
                      style={{maxWidth: "500px"}}>
                      {item.content}
                    </div>
                  </td>
                  <td>
                    {["1", "0"].includes(item.result || "") ? "성공" : "실패"}
                  </td>
                  <td>
                    {["1", "0"].includes(item.result || "") ? "" : item.message}
                  </td>
                  <td>
                    {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                </tr>
              ))}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
    </>
  );
}
