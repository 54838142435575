import _ from "lodash";
import React, {InputHTMLAttributes} from "react";
import styles from "./style.module.scss";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  checked: boolean;
  isToggle?: boolean;
}

export default function CheckBox({text, checked, isToggle, ...rest}: IProps) {
  return (
    <label
      className={[
        styles.container,
        ...(isToggle ? [styles.toggle] : []),
        ...(checked ? [styles.active] : []),
      ].join(" ")}>
      <input
        type="checkbox"
        key={_.uniqueId()}
        defaultChecked={checked}
        {...rest}
      />
      {!!text && <span>{text}</span>}
    </label>
  );
}
