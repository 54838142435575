import {ListTable, Modal, ModalContent, ModalTitle} from "@components";
import {
  faArrowCircleDown,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {TUpDown, ISectionDetail} from "@data";
import React from "react";
import {useSetRecoilState} from "recoil";
import styles from "./style.module.scss";

interface IProps {
  isVisible: boolean;
  listData?: ISectionDetail[];
  section_no: number;
  close?: () => void;
  refresh?: () => void;
}

export function SectionDetailListModal({
  listData,
  section_no,
  isVisible,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);

  function setClose() {
    close?.();
  }

  async function changeSectionDetailOrdering({
    no,
    type,
  }: {
    no: number;
    type: TUpDown;
  }) {
    try {
      const {success, message} =
        await adminAPI.section.changeSectionDetailOrdering({
          no,
          section_no,
          type,
        });
      if (success) {
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changeSectionDetailOrdering error", error);
    }
  }

  return (
    <>
      <Modal isVisible={isVisible} width={"700px"} close={setClose}>
        <ModalTitle title="홈섹션상세" isClose close={setClose} />
        <ModalContent>
          <ListTable>
            <thead>
              <tr>
                <th>이미지</th>
                <th>업체명</th>
                <th>상품명</th>
                <th>순번</th>
              </tr>
            </thead>
            <tbody>
              {(listData || []).map((item, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={item.product?.item_img}
                      className={styles.logoImg}
                    />
                  </td>
                  <td>{item.product?.partners?.partner_name}</td>
                  <td>{item.product?.item_name}</td>
                  <td>
                    <div className="row" style={{minWidth: "50px"}}>
                      <div className="col">
                        {(item.ordering || 0) > 1 && (
                          <FontAwesomeIcon
                            icon={faArrowCircleUp}
                            onClick={() => {
                              if (item.no) {
                                changeSectionDetailOrdering({
                                  no: item.no,
                                  type: "up",
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                      <div className="col">{item.ordering || "-"}</div>
                      <div className="col">
                        <FontAwesomeIcon
                          icon={faArrowCircleDown}
                          onClick={() => {
                            if (item.no) {
                              changeSectionDetailOrdering({
                                no: item.no,
                                type: "down",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </ListTable>
        </ModalContent>
      </Modal>
    </>
  );
}
