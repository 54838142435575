import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {IDBCard, IDBDeliveryAddress, ISubscribe} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
  subscribe: ISubscribe;
  deliveryAddress?: IDBDeliveryAddress[];
  card?: IDBCard[];
}

export function SubscribeEditModal({
  isVisible,
  close,
  refresh,
  subscribe,
  deliveryAddress,
  card,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [selectedAddress, setSelectedAddress] = useState<IDBDeliveryAddress>();
  const [selectedCard, setSelectedCard] = useState<number[]>([]);

  function setClose() {
    close?.();
    setSelectedAddress(undefined);
    setSelectedCard([]);
  }

  async function delSubscribe() {
    try {
      if (!subscribe.no) {
        return;
      }
      if (!confirm("정기배송을 정말 해지하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.member.delSubscribe({
        subscribe_no: subscribe.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류발생",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("delSubscribe error", error);
    }
  }

  async function editSubscribe() {
    try {
      if (!subscribe.no) {
        return;
      }
      if (selectedCard.length === 0) {
        return setAlertModal({
          isVisible: true,
          title: "카드를 선택해주세요.",
        });
      }
      if (!selectedAddress?.no) {
        return setAlertModal({
          isVisible: true,
          title: "배송지를 선택해주세요.",
        });
      }
      if (!confirm("배송지 또는 카드를 변경하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.member.editSubscribe({
        subscribe_no: subscribe.no,
        card_no: selectedCard,
        delivery_address_no: selectedAddress.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류발생",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("editSubscribe error", error);
    }
  }

  useEffect(() => {
    setSelectedAddress(subscribe.deliveryAddress);
    setSelectedCard(
      (subscribe.subscribeCard || [])
        .map(item => item.card_no)
        .filter(utils.isDefined),
    );
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="정기배송수정" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>배송지선택</div>
          <div className="col">
            <SelectBox
              list={(deliveryAddress || []).map(item => ({
                label: `${item.road_address} ${item.detail_address}`,
                value: String(item.no),
              }))}
              value={String(selectedAddress?.no)}
              singleSelect={value =>
                setSelectedAddress(
                  (deliveryAddress || []).find(
                    item => item.no === Number(value),
                  ),
                )
              }
            />
          </div>
        </div>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>카드선택</div>
          <div className="col">
            <SelectBox
              multiple
              list={(card || []).map(item => ({
                label: `${item.card_name} ${utils.formatCardNo(
                  item.card_no || "",
                )}`,
                value: String(item.no),
              }))}
              value={selectedCard.map(value => String(value))}
              multipleSelect={list =>
                setSelectedCard((list || []).map(value => Number(value)))
              }
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div>
          <Button
            text="해지"
            onClick={delSubscribe}
            backgroundColor={styleUtils.COLOR.danger}
            fontColor={styleUtils.COLOR.white}
          />
        </div>
        <div>
          <Button
            text="수정"
            onClick={editSubscribe}
            backgroundColor={styleUtils.COLOR.warning}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}
