import {Button, Modal, ModalContent, ModalTitle} from "@components";
import React from "react";
import {states} from "@recoils";
import styles from "./style.module.scss";
import {useRecoilState} from "recoil";

export default function AlertModal() {
  const [alert, setAlert] = useRecoilState(states.alertState);

  function setClose() {
    if (alert.close) {
      alert.close?.();
      close?.();
    } else {
      setAlert({isVisible: false});
    }
  }

  if (!alert.isVisible) {
    return <></>;
  }

  return (
    <Modal close={setClose} isVisible={alert.isVisible}>
      <ModalTitle isClose close={setClose} />
      <ModalContent>
        <div className={styles.container}>
          <div className={styles.title}>{alert.title}</div>
          {!!alert.content && (
            <div className={styles.content}>{alert.content}</div>
          )}
          {(alert.button || []).length > 0 && (
            <div className={styles.buttonContainer}>
              {(alert.button || []).map((item, index) => (
                <div
                  key={index}
                  style={{flex: 1, marginLeft: index === 0 ? "0" : "4px"}}>
                  <Button {...item} height={44} />
                </div>
              ))}
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
