import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {IDBCouponList} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  member_no: number;
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
}

export function CouponAddModal({member_no, isVisible, close, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [couponList, setCouponList] = useState<IDBCouponList[]>([]);
  const [couponNo, setCouponNo] = useState<number>();
  const [useStartDate, setUseStartDate] = useState(
    moment().format("YYYY-MM-DD"),
  );
  const [useEndDate, setUseEndtDate] = useState(
    moment().add(14, "days").format("YYYY-MM-DD"),
  );

  function setClose() {
    setCouponNo(undefined);
    setUseStartDate(moment().format("YYYY-MM-DD"));
    setUseEndtDate(moment().add(14, "days").format("YYYY-MM-DD"));
    close?.();
  }

  async function getCouponList() {
    try {
      const {data, success, message} =
        await adminAPI.coupon.apiGetAllCouponList();
      if (success) {
        setCouponList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getCouponList error", error);
    }
  }

  async function sendCoupon() {
    try {
      if (!useStartDate || !useEndDate) {
        return setAlertModal({
          isVisible: true,
          title: "사용기간을 입력해주세요",
        });
      }
      if (!couponNo) {
        return setAlertModal({
          isVisible: true,
          title: "쿠폰을 선택해주세요",
        });
      }
      if (
        !confirm("쿠폰을 지급하시겠습니까? 지급 시 회원에게 푸시가 발송됩니다.")
      ) {
        return;
      }
      const {success, message} = await adminAPI.coupon.sendCoupon({
        member: [member_no],
        coupon_no: couponNo,
        use_start_date: useStartDate,
        use_end_date: useEndDate,
      });
      setAlertModal({
        isVisible: true,
        title: message || "쿠폰발행오류",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("sendCoupon error", error);
    }
  }

  useEffect(() => {
    getCouponList();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="쿠폰발행" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">쿠폰선택</div>
          <div className="col">
            <SelectBox
              list={couponList.map(item => ({
                label: `[${item.coupon_num}] ${item.title}`,
                value: String(item.no),
              }))}
              value={String(couponNo)}
              singleSelect={value => setCouponNo(Number(value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">사용기간</div>
          <div className="col">
            <div className="row center">
              <div className="col">
                <InputBox
                  value={useStartDate}
                  type={"date"}
                  onValue={setUseStartDate}
                />
              </div>
              <div className="col">~</div>
              <div className="col">
                <InputBox
                  value={useEndDate}
                  type={"date"}
                  onValue={setUseEndtDate}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="발행" onClick={sendCoupon} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
