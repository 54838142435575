import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {
  Button,
  CheckBox,
  Content,
  ContentLayout,
  FileUploadButton,
  InputBox,
  ListTable,
  SearchFilter,
} from "@components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {COLOR} from "utils/styleUtils";
import {
  IDBItemStockLog,
  IEditTeamfreshStockExcel,
  IEditTeamfreshStockExcelRequest,
  ResultPagingResponse,
} from "@data";
import {adminAPI, utils} from "@utils";
import {StockDetailModal} from "pages/ItemPage/detailModalTab/StockDetailModal";

export default function StockExcelImportPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchDate, setSearchDate] = useState(moment().format("YYYY-MM-DD"));
  const [listData, setListData] =
    useState<ResultPagingResponse<IEditTeamfreshStockExcel[]>>();
  const [selectNoList, setSelectNoList] = useState<number[]>([]);
  const [selectStockLog, setSelectStockLog] = useState<IDBItemStockLog>();

  const isAllSelected =
    (listData?.result || []).filter(
      item => !!item.no && !item.stock_log && !selectNoList.includes(item.no),
    ).length === 0;

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {success, message, data} =
        await adminAPI.item.getStockExcelTeamFresh({
          searchDate,
          page,
          limit: 30,
        });
      if (success) {
        setListData(data);
        setSelectNoList(
          (data.result || []).map(_item => _item.no).filter(utils.isDefined),
        );
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function setCreateStockLog() {
    try {
      const filteredSaveList = (listData?.result || []).filter(item => {
        if (!item.no || !selectNoList.includes(item.no)) {
          return false;
        }
        return true;
      });
      const validationList = filteredSaveList.filter(item => {
        const insert_date = getValidDate({
          date: item.insert_date,
          edit_date: item.edit_insert_date,
        });
        const limit_date = getValidDate({
          date: item.limit_date,
          edit_date: item.edit_limit_date,
        });
        const made_date = getValidDate({
          date: item.made_date,
          edit_date: item.edit_made_date,
        });
        if (!insert_date || !limit_date || !made_date) {
          return false;
        }
        if (!moment(insert_date).isValid()) {
          return false;
        }
        if (!moment(limit_date).isValid()) {
          return false;
        }
        if (!moment(made_date).isValid()) {
          return false;
        }
        if (!insert_date && !limit_date && !made_date) {
          return false;
        }
        return true;
      });
      if (filteredSaveList.length !== validationList.length) {
        const message = "필수값을 정확히 확인해주세요.";
        setAlertModal({
          isVisible: true,
          title: message,
        });
        throw Error(message);
      }

      const list: IEditTeamfreshStockExcelRequest[] = validationList
        .filter(item => !!item.no)
        .map(item => ({
          no: item.no as number,
          insert_date: getValidDate({
            date: item.insert_date,
            edit_date: item.edit_insert_date,
          }),
          made_date: getValidDate({
            date: item.made_date,
            edit_date: item.edit_made_date,
          }),
          limit_date: getValidDate({
            date: item.limit_date,
            edit_date: item.edit_limit_date,
          }),
        }));
      const {success, message} = await adminAPI.item.createStockExcelTeamFresh({
        list,
      });
      if (success) {
        setAlertModal({
          isVisible: true,
          title: message || "일괄 재고 입력 완료",
        });
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("setCreateStockLog error", error);
    }
  }

  useEffect(() => {
    getList();
  }, [searchDate]);

  return (
    <>
      <ContentLayout title="팀프레시 입고 엑셀업로드">
        <SearchFilter
          left={[
            <InputBox
              key={0}
              type={"date"}
              placeholder="일자 입력"
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              value={searchDate}
              onValue={setSearchDate}
              onEnter={getList}
            />,
          ]}
          right={[
            <FileUploadButton
              key={1}
              text="엑셀업로드"
              callback={() => getList({page: listData?.page?.cur || 1})}
              type={"excel"}
              uploadUrl={"/admin/item/importStockExcelTeamFresh"}
            />,
          ]}
        />
        <Content>
          <div style={{flex: 1}}>
            <div className={styles.contentBtnDiv}>
              <div>
                <Button
                  text="입고등록하기"
                  onClick={setCreateStockLog}
                  backgroundColor={COLOR.success}
                />
              </div>
            </div>
            <div className={styles.gridContainer}>
              <ListTable paging={listData?.page} getList={getList}>
                <thead>
                  <tr>
                    <th>
                      <CheckBox
                        checked={isAllSelected}
                        text=""
                        onChange={() => {
                          if (isAllSelected) {
                            setSelectNoList([]);
                          } else {
                            setSelectNoList(
                              (listData?.result || [])
                                .map(data => data.no)
                                .filter(utils.isDefined),
                            );
                          }
                        }}
                      />
                    </th>
                    <th>제품명(엑셀)</th>
                    <th>제품명(DB)</th>
                    <th>입고수량</th>
                    <th>입고일자</th>
                    <th>제조일자</th>
                    <th>소비기한</th>
                    <th>바코드</th>
                    <th>입고처리여부</th>
                  </tr>
                </thead>
                <tbody>
                  {(listData?.result || []).map((item, index) => {
                    const checked =
                      selectNoList.filter(no => item.no === no).length > 0;

                    return (
                      <tr key={index}>
                        <td onClick={e => utils.handleClickBubble(e)}>
                          {!item.stock_log && (
                            <CheckBox
                              checked={checked}
                              text=""
                              onChange={e => {
                                if (checked) {
                                  setSelectNoList([
                                    ...selectNoList.filter(
                                      no => no !== item.no,
                                    ),
                                  ]);
                                } else if (item.no) {
                                  setSelectNoList([...selectNoList, item.no]);
                                }
                              }}
                            />
                          )}
                        </td>
                        <td>{item.item_name}</td>
                        <td>{item.product?.item_name}</td>
                        <td>{(item.count || 0).toLocaleString()}</td>
                        <td>
                          {moment(item.insert_date).isValid() ? (
                            moment(item.insert_date).format("YYYY.MM.DD")
                          ) : (
                            <InputBox
                              value={item.edit_insert_date}
                              onValue={value =>
                                setListData({
                                  ...listData,
                                  result: [
                                    ...(listData?.result || []).map(
                                      (_temp, j) => {
                                        if (index === j) {
                                          return {
                                            ..._temp,
                                            edit_insert_date: value,
                                          };
                                        } else {
                                          return _temp;
                                        }
                                      },
                                    ),
                                  ],
                                })
                              }
                            />
                          )}
                        </td>
                        <td>
                          {moment(item.made_date).isValid() ? (
                            moment(item.made_date).format("YYYY.MM.DD")
                          ) : (
                            <InputBox
                              value={item.edit_made_date}
                              onValue={value =>
                                setListData({
                                  ...listData,
                                  result: [
                                    ...(listData?.result || []).map(
                                      (_temp, j) => {
                                        if (index === j) {
                                          return {
                                            ..._temp,
                                            edit_made_date: value,
                                          };
                                        } else {
                                          return _temp;
                                        }
                                      },
                                    ),
                                  ],
                                })
                              }
                            />
                          )}
                        </td>
                        <td>
                          {moment(item.limit_date).isValid() ? (
                            moment(item.limit_date).format("YYYY.MM.DD")
                          ) : (
                            <InputBox
                              value={item.edit_limit_date}
                              onValue={value =>
                                setListData({
                                  ...listData,
                                  result: [
                                    ...(listData?.result || []).map(
                                      (_temp, j) => {
                                        if (index === j) {
                                          return {
                                            ..._temp,
                                            edit_limit_date: value,
                                          };
                                        } else {
                                          return _temp;
                                        }
                                      },
                                    ),
                                  ],
                                })
                              }
                            />
                          )}
                        </td>
                        <td>{item.barcode}</td>
                        <td>
                          {item.stock_log ? (
                            <Button
                              text="조회"
                              backgroundColor={COLOR.success}
                              onClick={() => setSelectStockLog(item.stock_log)}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ListTable>
            </div>
          </div>
        </Content>
      </ContentLayout>
      {!!selectStockLog && !!selectStockLog?.item_no && (
        <StockDetailModal
          isVisible={!!selectStockLog}
          item_no={selectStockLog?.item_no}
          data={selectStockLog}
          refresh={getList}
          close={() => setSelectStockLog(undefined)}
        />
      )}
    </>
  );
}

function getValidDate({date, edit_date}: {date?: string; edit_date?: string}) {
  if (date && moment(date).isValid()) {
    return moment(date).format("YYYY.MM.DD");
  } else if (edit_date && moment(edit_date).isValid()) {
    return moment(edit_date).format("YYYY.MM.DD");
  } else {
    return undefined;
  }
}
