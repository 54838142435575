import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  month: moment.Moment;
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
}

export function StoreFeeDetailModal({
  month,
  isVisible,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [storageFee, setStorageFee] = useState(0);

  async function getStorageFee() {
    try {
      const {success, data} = await adminAPI.kpi.getOrderSettlement({
        year: month.format("YYYY"),
        month: month.format("MM"),
      });
      if (success) {
        setStorageFee(data.storage_fee || 0);
      }
    } catch (error) {
      console.error("getStorageFee error", error);
    }
  }

  async function save() {
    try {
      if (storageFee <= 0) {
        return setAlertModal({
          isVisible: true,
          title: "보관비를 입력해주세요.",
        });
      }
      if (!confirm("보관비를 입력하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.kpi.saveOrderSettlement({
        year: month.format("YYYY"),
        month: month.format("MM"),
        storage_fee: storageFee,
      });
      setAlertModal({isVisible: true, title: message});
      if (success) {
        close?.();
        refresh?.();
      }
    } catch (error) {
      setAlertModal({isVisible: true, title: "오류발생"});
    }
  }

  useEffect(() => {
    if (isVisible) {
      getStorageFee();
    }
  }, [isVisible]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={close}>
      <ModalTitle title="보관비입력" isClose close={close} />
      <ModalContent>
        <div className="row">
          <div className="col title flex">
            {month.format("YYYY년 M월")} 보관비
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InputBox
              value={String(storageFee)}
              type="number"
              onValue={value => setStorageFee(Number(value))}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="저장" onClick={save} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
