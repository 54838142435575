import {Button, ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {POINT_STATUS, ResultPagingResponse, IPoint} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {MemberPointAddModal} from "./MemberPointAddModal";
import styles from "../style.module.scss";

interface IProps {
  member_no: number;
  refresh?: () => void;
}

export function PointTab({member_no, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [isAddPoint, setIsAddPoint] = useState(false);
  const [listData, setListData] = useState<ResultPagingResponse<IPoint[]>>();

  function setRefresh() {
    getList({page: listData?.page?.cur || 1});
    refresh?.();
  }

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {success, message, data} = await adminAPI.member.getMemberPointList(
        {
          member_no,
          page,
        },
      );
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row">
        <div className={[styles.title, "row"].join(" ")}>
          <div>포인트이력</div>
          <div className={["col", "flex-end"].join(" ")} style={{flex: "auto"}}>
            <div>
              <Button
                text="포인트지급"
                onClick={() => setIsAddPoint(true)}
                marginHorizontal={2}
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                fontSize={12}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>구분</th>
              <th>타이틀</th>
              <th>포인트</th>
              <th>잔액</th>
              <th>일자</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => {
              const baggeClass = item.status
                ? ["1", "2"].includes(item.status)
                  ? styles.out
                  : styles.purchase
                : "";
              return (
                <tr key={index}>
                  <td>
                    <span className={[styles.badge, baggeClass].join(" ")}>
                      {!!item.status && POINT_STATUS[item.status]}
                    </span>
                  </td>
                  <td>{item.title}</td>
                  <td>{(item.point || 0).toLocaleString()}</td>
                  <td>{(item.cur_point || 0).toLocaleString()}</td>
                  <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </div>
      <MemberPointAddModal
        member_no={member_no}
        isVisible={isAddPoint}
        close={() => setIsAddPoint(false)}
        refresh={setRefresh}
      />
    </>
  );
}
