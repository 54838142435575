import {
  Button,
  Content,
  ContentLayout,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYNWithEmpty,
  IEventAttendanceList,
  IMemberResponse,
} from "@data";
import moment from "moment";
import {MemberDetailModal} from "pages/MemberPage/MemberDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

export default function AttendancePage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [searchText, setSearchText] = useState("");
  const [successYn, setSuccessYn] = useState<TypeYNWithEmpty>("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IEventAttendanceList[]>>();
  const [memberDetail, setMemberDetail] = useState<IMemberResponse>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} =
        await adminAPI.event.getEventAttendanceList({
          page,
          searchtxt: searchText,
          successYn,
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getMemberDetail(no: number) {
    try {
      const {data, success, message} = await adminAPI.member.getDetail({
        member_no: no,
      });
      if (success) {
        setMemberDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  useEffect(() => {
    getList();
  }, [successYn]);

  return (
    <>
      <ContentLayout title="출석이벤트">
        <SearchFilter
          left={[
            <SelectBox
              key={0}
              value={successYn}
              placeholder="성공여부"
              list={
                [
                  {label: "전체", value: ""},
                  {label: "성공", value: "Y"},
                  {label: "실패", value: "N"},
                ] as {label: string; value: TypeYNWithEmpty}[]
              }
              singleSelect={value => setSuccessYn(value as TypeYNWithEmpty)}
            />,
            <InputBox
              key={1}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
        />
        <Content>
          <ListTable paging={listData?.page} getList={getList}>
            <thead>
              <tr>
                <th>회원정보</th>
                <th>출석</th>
                <th>응모상품</th>
                <th>구매횟수</th>
              </tr>
            </thead>
            <tbody>
              {(listData?.result || []).map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.name} ({item.nickname})<br />
                      {utils.formatPhoneNumber(item.phone_number || "")}
                      <br />
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="상세"
                          onClick={() => !!item.no && getMemberDetail(item.no)}
                          backgroundColor={styleUtils.COLOR.success}
                          fontColor={styleUtils.COLOR.white}
                          paddingVertical={1}
                          paddingHorizontal={6}
                          fontSize={12}
                        />
                      </div>
                    </td>
                    <td>
                      {(item.attendance_apply || []).map((apply, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              borderTop:
                                index === 0
                                  ? "none"
                                  : "1px solid var(--color-gray7)",
                            }}>
                            <div className="row">
                              <div className="col title">출석일</div>
                              <div
                                className="col preWrap"
                                style={{maxWidth: "300px"}}>
                                {(apply.apply || [])
                                  .map(_apply =>
                                    moment(_apply.createdAt).format(
                                      "YYYY-MM-DD",
                                    ),
                                  )
                                  .join(" / ")}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col title">성공여부</div>
                              <div className="col">
                                {apply.success ? "성공" : "실패"}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {(item.item_application_apply || [])
                        .map(apply =>
                          (apply.apply || []).map(_apply =>
                            _apply.product
                              ? `[${_apply.product?.partners?.partner_name}] ${_apply.product?.item_name}`
                              : apply.detail?.item_name,
                          ),
                        )
                        .join(", ")}
                    </td>
                    <td>{(item.order_count || 0).toLocaleString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
      <MemberDetailModal
        isVisible={!!memberDetail}
        close={() => setMemberDetail(undefined)}
        member={memberDetail}
      />
    </>
  );
}
