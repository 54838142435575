import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {TInOut, IDBItemStockLog} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  data?: IDBItemStockLog;
  close?: () => void;
  refresh?: () => void;
  item_no: number;
}

const selectList: {label: string; value: TInOut}[] = [
  {label: "입고", value: "input"},
  {label: "출고", value: "output"},
];

export function StockDetailModal({
  isVisible,
  close,
  refresh,
  data,
  item_no,
}: IProps) {
  const adminData = useRecoilValue(states.adminDataState);
  const setAlertModal = useSetRecoilState(states.alertState);
  const [type, setType] = useState<TInOut>("input");
  const [count, setCount] = useState(0);
  const [insertDate, setInsertDate] = useState(moment().format("YYYY-MM-DD"));
  const [limitDate, setLimitDate] = useState("");
  const [madeDate, setMadeDate] = useState("");
  const [memo, setMemo] = useState("");

  const admin_name = adminData?.name || adminData?.nickname || "";

  function setClose() {
    close?.();
    setType("input");
    setCount(0);
    setInsertDate(moment().format("YYYY-MM-DD"));
    setLimitDate("");
    setMadeDate("");
    setMemo("");
  }

  async function save() {
    try {
      if (count <= 0) {
        return setAlertModal({
          isVisible: true,
          title: "재고를 정확히 입력해주세요.",
        });
      }
      if (!confirm(`${data?.no ? "수정" : "등록"}하시겠습니까?`)) {
        return;
      }

      const params = {
        item_no,
        type,
        count,
        insert_date: moment(insertDate).isValid()
          ? moment(insertDate).format("YYYY.MM.DD")
          : "",
        limit_date: moment(limitDate).isValid()
          ? moment(limitDate).format("YYYY.MM.DD")
          : "",
        made_date: moment(madeDate).isValid()
          ? moment(madeDate).format("YYYY.MM.DD")
          : "",
        admin_name,
        memo: memo,
      };

      const {success, message} = data?.no
        ? await adminAPI.item.updateItemStock({no: data?.no, ...params})
        : await adminAPI.item.createItemStock(params);
      if (success) {
        setClose();
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function setDelete() {
    try {
      if (!data?.no) {
        return;
      }
      if (!confirm("정말로 재고를 삭제하시겠어요?")) {
        return;
      }
      const {success, message} = await adminAPI.item.deleteItemStock({
        no: data.no,
        item_no,
      });
      if (success) {
        setClose();
        refresh?.();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("setDelete error", error);
    }
  }

  useEffect(() => {
    setType(data?.type || "input");
    setCount(data?.count || 0);
    setInsertDate(moment(data?.insert_date).format("YYYY-MM-DD"));
    setLimitDate(data?.limit_date || "");
    setMadeDate(data?.made_date || "");
    setMemo(data?.memo || "");
  }, [data]);

  return (
    <>
      <Modal isVisible={isVisible} width="500px" close={setClose}>
        <ModalTitle close={setClose} isClose title="재고입력" />
        <ModalContent>
          <div className="row">
            <div className="col title">구분</div>
            <div className="col">
              <SelectBox
                list={selectList}
                value={type}
                singleSelect={value => setType(value as TInOut)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">수량</div>
            <div className="col">
              <InputBox
                type="number"
                value={String(count)}
                onValue={value => setCount(Number(value))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">입고일자</div>
            <div className="col">
              <InputBox
                type="date"
                value={moment(insertDate).format("YYYY-MM-DD")}
                onValue={setInsertDate}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">제조일자</div>
            <div className="col">
              <InputBox
                type="date"
                value={moment(madeDate).format("YYYY-MM-DD")}
                onValue={setMadeDate}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">소비기한</div>
            <div className="col">
              <InputBox
                type="date"
                value={moment(limitDate).format("YYYY-MM-DD")}
                onValue={setLimitDate}
              />
            </div>
          </div>
          <div className="row">
            <div className="col title">입고처리자</div>
            <div className="col">
              <InputBox disabled value={admin_name} />
            </div>
          </div>
          <div className="row top">
            <div className="col title">메모</div>
            <div className="col">
              <TextAreaBox value={memo} onValue={setMemo} height={150} />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div>
            {!!data && !!data.no && data.admin_yn === "Y" && (
              <Button
                backgroundColor={styleUtils.COLOR.danger}
                fontColor={styleUtils.COLOR.white}
                text="삭제"
                onClick={setDelete}
              />
            )}
          </div>
          <div>
            <Button
              backgroundColor={styleUtils.COLOR.success}
              fontColor={styleUtils.COLOR.white}
              text="저장"
              onClick={save}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
