import {Button, ListTable, TextAreaBox} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {
  ResultPagingResponse,
  IDBItemStockDate,
  IDBItemStockLog,
  IOrderDetail,
  IStockList,
} from "@data";
import _ from "lodash";
import moment from "moment";
import {OrderDetailModal} from "pages/OrderPage/OrderDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";
import {StockDateDetailModal} from "./StockDateDetailModal";
import {StockDetailModal} from "./StockDetailModal";

interface IProps {
  item_no: number;
}

export function StockTab({item_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [stockList, setStockList] =
    useState<ResultPagingResponse<IStockList[]>>();
  const [stockDateList, setStockDateList] =
    useState<ResultPagingResponse<IDBItemStockDate[]>>();
  const [memo, setMemo] = useState("");
  const [notOutStock, setNotOutStock] = useState(0);
  const [isStockModal, setIsStockModal] = useState(false);
  const [stockDetail, setStockDetail] = useState<IDBItemStockLog>();
  const [isStockDateModal, setIsStockDateModal] = useState(false);
  const [stockDateDetail, setStockDateDetail] = useState<IDBItemStockDate>();
  const [orderDetailData, setOrderDetailData] = useState<IOrderDetail>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.item.getItemStockList({
        item_no,
        page,
      });
      if (success) {
        setStockList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDateList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.item.getItemStockDateList(
        {
          item_no,
          page,
        },
      );
      if (success) {
        setStockDateList(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getDateList error", error);
    }
  }

  async function deleteStockDate({no}: {no: number}) {
    try {
      if (!confirm("입고일을 삭제하시겠습니까?")) {
        return;
      }

      const {success, message} = await adminAPI.item.deleteItemStockDate({
        item_no,
        no,
      });
      if (success) {
        getDateList({page: stockDateList?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("deleteStockDate error", error);
    }
  }

  async function getDetail() {
    try {
      const {data, success, message} = await adminAPI.item.getDetail({
        no: item_no,
      });
      if (success) {
        setMemo(data.stock_memo || "");
        setNotOutStock(data.not_out_stock || 0);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function stockMemoSave() {
    try {
      if (!memo) {
        return setAlertModal({
          isVisible: true,
          title: "메모를 입력해주세요.",
        });
      }

      const {data, success, message} = await adminAPI.item.updateStockMemo({
        item_no,
        stock_memo: memo,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다.",
      });
      if (success) {
        getDetail();
      }
    } catch (error) {
      console.error("stockMemoSave error", error);
    }
  }

  async function getOrderDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no,
      });
      if (success) {
        setOrderDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  useEffect(() => {
    getList();
    getDateList();
    getDetail();
  }, []);

  return (
    <>
      <div className="row top">
        <div className="col" style={{padding: "0 5px"}}>
          <div style={{flex: 1}}>
            <div className="row">
              <div className="col auto title">
                재고이력{" "}
                {notOutStock > 0 && (
                  <span
                    className={["text-12"].join(" ")}
                    style={{fontWeight: "normal", marginLeft: "6px"}}>
                    (미출고 : {notOutStock.toLocaleString()})
                  </span>
                )}
              </div>
              <div className="col flex-end">
                <div>
                  <Button
                    backgroundColor={styleUtils.COLOR.success}
                    fontColor={styleUtils.COLOR.white}
                    text="추가"
                    onClick={() => setIsStockModal(true)}
                  />
                </div>
              </div>
            </div>
            <ListTable paging={stockList?.page} getList={getList}>
              <thead>
                <tr>
                  <th>구분</th>
                  <th>메모</th>
                  <th>수량</th>
                  <th>재고</th>
                  <th>처리일시</th>
                  <th>기타</th>
                </tr>
              </thead>
              <tbody>
                {(stockList?.result || []).map(item => {
                  const isInput = item.type === "input";
                  const countColor =
                    Number(item.count || 0) > 0
                      ? styleUtils.COLOR.black
                      : styleUtils.COLOR.red;
                  const stockColor =
                    Number(item.stock || 0) > 0
                      ? styleUtils.COLOR.black
                      : styleUtils.COLOR.red;
                  return (
                    <tr key={_.uniqueId()}>
                      <td>
                        <span
                          className={[
                            styles.badge,
                            !isInput && styles.danger,
                          ].join(" ")}>
                          {isInput ? "입고" : "출고"}
                        </span>
                      </td>
                      <td>
                        {!!item.admin_name && (
                          <>
                            처리자: {item.admin_name}
                            <br />
                          </>
                        )}
                        <span className="preWrap" style={{maxWidth: "150px"}}>
                          {item.memo}
                        </span>
                        {!!item.order_data?.order_number && (
                          <>
                            <br />
                            <span className="text-10">
                              (주문번호: {item.order_data?.order_number}{" "}
                              {!!item.order_data?.status &&
                                ["1"].includes(item.order_data?.status) && (
                                  <span
                                    className={[
                                      styles.badge,
                                      styles.badgeCount,
                                    ].join(" ")}>
                                    미출고
                                  </span>
                                )}{" "}
                              <div style={{display: "inline-block"}}>
                                <Button
                                  text="상세"
                                  fontSize={10}
                                  paddingVertical={1}
                                  paddingHorizontal={4}
                                  backgroundColor={styleUtils.COLOR.baige}
                                  onClick={() => {
                                    if (item.order_no) {
                                      getOrderDetail({no: item.order_no});
                                    }
                                  }}
                                />
                              </div>
                              )
                            </span>
                            <br />
                          </>
                        )}
                      </td>
                      <td style={{color: countColor}}>
                        {Number(item.count || 0).toLocaleString()}
                      </td>
                      <td style={{color: stockColor}}>
                        {Number(item.stock || 0).toLocaleString()}
                      </td>
                      <td>
                        {moment(item.createdAt).format("YYYY-MM-DD")}
                        <br />
                        {moment(item.createdAt).format("HH:mm")}
                        {item.admin_yn === "Y" && (
                          <>
                            <div
                              style={{
                                display: "inline-block",
                                marginLeft: "4px",
                              }}>
                              <Button
                                text="수정"
                                fontSize={12}
                                paddingVertical={2}
                                paddingHorizontal={6}
                                backgroundColor={styleUtils.COLOR.warning}
                                fontColor={styleUtils.COLOR.white}
                                onClick={() => {
                                  setIsStockModal(true);
                                  setStockDetail(item);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </td>
                      <td>
                        {moment(item.insert_date).isValid()
                          ? `입고일자: ${moment(item.insert_date).format(
                              "YYYY.MM.DD",
                            )}`
                          : ""}
                        <br />
                        {item.made_date ? `제조일자: ${item.made_date}` : ""}
                        <br />
                        {item.limit_date ? `소비기한: ${item.limit_date}` : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ListTable>
          </div>
        </div>
        <div className="col" style={{padding: "0 5px"}}>
          <div style={{flex: 1}}>
            <div>
              <div className="row">
                <div className="col auto title">입고일자</div>
                <div className="col flex-end">
                  <div>
                    <Button
                      backgroundColor={styleUtils.COLOR.success}
                      fontColor={styleUtils.COLOR.white}
                      text="입고일추가"
                      onClick={() => setIsStockDateModal(true)}
                    />
                  </div>
                </div>
              </div>
              <ListTable>
                <thead>
                  <tr>
                    <th>입고일</th>
                    <th>입고수량</th>
                    <th>메모</th>
                    <th>-</th>
                  </tr>
                </thead>
                <tbody>
                  {(stockDateList?.result || []).map(item => (
                    <tr key={_.uniqueId()}>
                      <td>{moment(item.stock_date).format("YYYY-MM-DD")}</td>
                      <td>{Number(item.stock_count || 0).toLocaleString()}</td>
                      <td>
                        {!!item.admin_name && (
                          <>
                            처리자: {item.admin_name}
                            <br />
                          </>
                        )}
                        <span className="preWrap">{item.memo}</span>
                      </td>
                      <td onClick={utils.handleClickBubbleAndCapture}>
                        <div style={{display: "inline-block"}}>
                          <Button
                            text="수정"
                            fontSize={12}
                            paddingVertical={2}
                            paddingHorizontal={6}
                            backgroundColor={styleUtils.COLOR.warning}
                            fontColor={styleUtils.COLOR.white}
                            onClick={() => {
                              setIsStockDateModal(true);
                              setStockDateDetail(item);
                            }}
                          />
                          <Button
                            text="삭제"
                            fontSize={12}
                            paddingVertical={2}
                            paddingHorizontal={6}
                            backgroundColor={styleUtils.COLOR.danger}
                            fontColor={styleUtils.COLOR.white}
                            onClick={() => {
                              if (item.no) {
                                deleteStockDate({no: item.no});
                              }
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ListTable>
            </div>
            <div style={{marginTop: "24px"}}>
              <div className="row">
                <div className="col auto title">재고메모</div>
                <div className="col flex-end">
                  <div>
                    <Button
                      backgroundColor={styleUtils.COLOR.success}
                      fontColor={styleUtils.COLOR.white}
                      text="메모저장"
                      onClick={stockMemoSave}
                    />
                  </div>
                </div>
              </div>
              <div>
                <TextAreaBox value={memo} onValue={setMemo} height={200} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <StockDetailModal
        isVisible={isStockModal}
        data={stockDetail}
        close={() => {
          setIsStockModal(false);
          setStockDetail(undefined);
        }}
        refresh={getList}
        item_no={item_no}
      />
      <StockDateDetailModal
        isVisible={isStockDateModal}
        data={stockDateDetail}
        close={() => {
          setIsStockDateModal(false);
          setStockDateDetail(undefined);
        }}
        refresh={getDateList}
        item_no={item_no}
      />
      <OrderDetailModal
        isVisible={!!orderDetailData}
        close={() => setOrderDetailData(undefined)}
        data={orderDetailData}
        refresh={() => {
          if (orderDetailData?.no) {
            getOrderDetail({no: orderDetailData.no});
          }
        }}
      />
    </>
  );
}
