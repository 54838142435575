import {
  Button,
  CheckBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {IDBProduct} from "@data";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  member_no: number;
  isVisible: boolean;
  close?: () => void;
  refresh?: () => void;
}

interface IItem {
  item_no: number;
  count: number;
}

export function CartAddModal({member_no, isVisible, close, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [selectedItemList, setSelectedItemList] = useState<IItem[]>([]);
  const [isSubscribe, setIsSubscribe] = useState(false);

  function setClose() {
    setSelectedItemList([]);
    setIsSubscribe(false);
    close?.();
  }

  async function addCart() {
    try {
      const item_list = selectedItemList.filter(
        ({item_no, count}) => Number(count) > 0 && !isNaN(item_no),
      );
      if (item_list.length === 0) {
        return setAlertModal({
          isVisible: true,
          title: "상품 및 수량을 선택해주세요.",
        });
      }
      const {success, message} = await adminAPI.member.addCartSave({
        member_no,
        item_list,
        subscribe_yn: isSubscribe ? "Y" : "N",
      });
      setAlertModal({
        isVisible: true,
        title: message || "카드등록 오류",
      });
      if (success) {
        setClose();
        refresh?.();
      }
    } catch (error) {
      console.error("addCart error", error);
    }
  }

  async function getItemList() {
    try {
      const {data, success} = await adminAPI.item.apiGetAllItems({});
      if (success) {
        setItemList(data.result);
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  useEffect(() => {
    getItemList();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="장바구니추가" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">정기구독</div>
          <div className="col">
            <div style={{display: "inline-block"}}>
              <CheckBox
                checked={isSubscribe}
                text={isSubscribe ? "Yes" : "No"}
                isToggle
                onChange={e => setIsSubscribe(!isSubscribe)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">상품</div>
          <div className="col">
            <SelectBox
              multiple
              list={(itemList || []).map(item => ({
                value: String(item.no),
                label: String(item.item_name || ""),
              }))}
              value={selectedItemList.map(item => String(item.item_no))}
              multipleSelect={list => {
                const itemNoList = list
                  .map(item_no => Number(item_no))
                  .filter(item_no => !isNaN(item_no));
                const prevItemNoList = selectedItemList.map(
                  item => item.item_no,
                );
                const newItemNoList = itemNoList.filter(
                  item_no => !prevItemNoList.includes(item_no),
                );
                setSelectedItemList([
                  ...selectedItemList.filter(item =>
                    itemNoList.includes(item.item_no),
                  ),
                  ...newItemNoList.map(item_no => ({item_no, count: 1})),
                ]);
              }}
            />
          </div>
        </div>
        <div className="text-12">
          {selectedItemList.map(({item_no, count}, index) => {
            const itemData = itemList.find(_item => _item.no === item_no);
            return (
              <div key={index} className={styles.cartAddItem}>
                <div>{itemData?.item_name}</div>
                <div>
                  <div
                    className={styles.minusIcon}
                    style={{marginRight: "6px"}}
                    onClick={() => {
                      if (count === 1) {
                        setSelectedItemList([
                          ...selectedItemList.filter(
                            _item => _item.item_no !== item_no,
                          ),
                        ]);
                      } else {
                        setSelectedItemList([
                          ...selectedItemList.map(_item => {
                            if (_item.item_no === item_no) {
                              return {..._item, count: _item.count - 1};
                            } else {
                              return _item;
                            }
                          }),
                        ]);
                      }
                    }}>
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                  {count}
                  <div
                    className={styles.plusIcon}
                    style={{marginLeft: "6px"}}
                    onClick={() => {
                      setSelectedItemList([
                        ...selectedItemList.map(_item => {
                          if (_item.item_no === item_no) {
                            return {..._item, count: _item.count + 1};
                          } else {
                            return _item;
                          }
                        }),
                      ]);
                    }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="등록" onClick={addCart} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
