import {
  Button,
  Content,
  ContentLayout,
  InputBox,
  ITabList,
  ListTable,
  SearchFilter,
  SelectBox,
  TabList,
} from "@components";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import {
  ResultPagingResponse,
  TypeYNWithEmpty,
  IDBCouponList,
  ICouponList,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {CouponCodeDetailModal} from "./CouponCodeDetailModal";
import {CouponDetailModal} from "./CouponDetailModal";
import {CouponIssuedModal} from "./CouponIssuedModal";

const tabs: ITabList<TypeYNWithEmpty>[] = [
  {tab: "", title: "전체"},
  {tab: "Y", title: "활성"},
  {tab: "N", title: "비활성"},
];

export default function CouponPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<ResultPagingResponse<ICouponList[]>>();
  const [detailData, setDetailData] = useState<IDBCouponList>();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [couponCodeModalNo, setCouponCodeModalNo] = useState<number>();
  const [issuedCouponModalNo, setIssuedCouponModalNo] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const [searchUseableYn, setSearchUseableYn] = useState<TypeYNWithEmpty>("");

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {data, success, message} = await adminAPI.coupon.getList({
        page,
        searchtxt: searchText,
        useable_yn: searchUseableYn,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.coupon.getDetail({no});
      if (success) {
        setDetailData(data);
        setIsDetailOpen(true);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  useEffect(() => {
    getList();
  }, [searchUseableYn]);

  return (
    <>
      <ContentLayout title="쿠폰관리">
        <SearchFilter
          left={[
            <InputBox
              key={2}
              value={searchText}
              onValue={setSearchText}
              onEnter={getList}
              rightContent={
                <div style={{display: "flex"}} onClick={() => getList()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
            />,
          ]}
          right={[
            <Button
              key={0}
              text="신규등록"
              onClick={() => {
                setDetailData(undefined);
                setIsDetailOpen(true);
              }}
            />,
          ]}
        />
        <Content>
          <div style={{flex: 1}}>
            <TabList
              tabList={tabs}
              setTab={setSearchUseableYn}
              activeTab={searchUseableYn}
            />
            <ListTable paging={listData?.page} getList={getList}>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>쿠폰명</th>
                  <th>쿠폰번호</th>
                  <th>구분</th>
                  <th>발급구분</th>
                  <th>발행수량/사용수량</th>
                  <th>활성여부</th>
                  <th>쿠폰사용기간</th>
                  <th>쿠폰코드</th>
                </tr>
              </thead>
              <tbody>
                {(listData?.result || []).map((item, index) => {
                  const couponAddType = [
                    ...(item.issuance_type === "birthday"
                      ? ["생일쿠폰"]
                      : item.issuance_type === "signup"
                      ? ["회원가입"]
                      : item.issuance_type === "addPetInfo"
                      ? ["아이정보입력"]
                      : []),
                    ...(item.scene_yn === "Y"
                      ? ["현장사용쿠폰"]
                      : item.addable_yn === "Y"
                      ? ["고객등록"]
                      : []),
                  ];

                  return (
                    <tr
                      key={index}
                      onClick={() => !!item.no && getDetail({no: item.no})}>
                      <td>{item.no}</td>
                      <td>{item.title}</td>
                      <td>{item.coupon_num}</td>
                      <td>
                        {item.type === "price"
                          ? "금액"
                          : item.type === "delivery"
                          ? "배송비"
                          : item.type === "percent"
                          ? "퍼센트"
                          : ""}
                      </td>
                      <td>{couponAddType.join(", ")}</td>
                      <td
                        onClick={e => {
                          utils.handleClickBubble(e);
                          setIssuedCouponModalNo(item.no);
                        }}>
                        {Number(item.send_cnt || 0).toLocaleString()} /{" "}
                        {Number(item.use_cnt || 0).toLocaleString()}
                        <br />
                        <span className="text-12">
                          (
                          {(
                            (Number(item.use_cnt || 0) /
                              Number(item.send_cnt || 1)) *
                            100
                          ).toFixed(1)}
                          %)
                        </span>
                      </td>
                      <td>
                        {item.useable_yn === "Y"
                          ? moment(item.use_end_date).isValid() &&
                            moment(item.use_end_date).isBefore(moment())
                            ? "종료됨"
                            : moment(item.use_start_date).isValid() &&
                              moment().isBefore(moment(item.use_start_date))
                            ? "시작전"
                            : "활성"
                          : "비활성"}
                      </td>
                      <td>
                        {moment(item.use_start_date).isValid() && (
                          <>
                            {moment(item.use_start_date).format("YYYY-MM-DD")}
                            <br />
                          </>
                        )}
                        ~
                        {moment(item.use_end_date).isValid() &&
                          moment(item.use_end_date).format("YYYY-MM-DD")}
                      </td>
                      <td onClick={e => utils.handleClickBubble(e)}>
                        {item.multiple_yn === "Y" && (
                          <Button
                            text="조회"
                            onClick={() => setCouponCodeModalNo(item.no)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ListTable>
          </div>
        </Content>
      </ContentLayout>
      <CouponDetailModal
        isVisible={isDetailOpen}
        data={detailData}
        setData={setDetailData}
        refresh={() => getList({page: listData?.page?.cur || 1})}
        close={() => {
          setDetailData(undefined);
          setIsDetailOpen(false);
        }}
      />
      {!!couponCodeModalNo && (
        <CouponCodeDetailModal
          isVisible={!!couponCodeModalNo}
          coupon_no={couponCodeModalNo}
          close={() => setCouponCodeModalNo(undefined)}
        />
      )}
      {!!issuedCouponModalNo && (
        <CouponIssuedModal
          isVisible={!!issuedCouponModalNo}
          coupon_no={issuedCouponModalNo}
          close={() => setIssuedCouponModalNo(undefined)}
        />
      )}
    </>
  );
}
