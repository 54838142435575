import logoImg from "../../assets/images/logo.png";
import {useLogin} from "@hook";
import React, {KeyboardEvent, useEffect, useState} from "react";
import styles from "./style.module.scss";
import {InputBox, Button, CheckBox} from "@components";
import {cookieUtil, styleUtils} from "@utils";

const REMEMBER_ID_KEY = "REMEMBER_ID_KEY";

export default function LoginPage() {
  const {login} = useLogin();
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [remember, setRemember] = useState(false);

  async function submit() {
    try {
      if (!id || !pw) {
        setErrMsg(`${!id ? "아이디" : "비밀번호"}를 입력해주세요.`);
        return;
      }

      const {success, message} = await login({id, pw});
      if (!success) {
        setErrMsg(message);

        if (remember) {
          cookieUtil.setCookie({
            name: REMEMBER_ID_KEY,
            value: id,
            expire: 7,
          });
        } else {
          cookieUtil.deleteCookie({name: REMEMBER_ID_KEY});
        }
      }
    } catch (error) {
      console.error("submit error", error);
    }
  }

  function pressEnter(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      submit();
    }
  }

  useEffect(() => {
    setErrMsg("");
  }, [id, pw]);

  useEffect(() => {
    const rememberId = cookieUtil.getCookie({name: REMEMBER_ID_KEY});
    if (rememberId) {
      setId(rememberId);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={logoImg} />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>Zootopia Admin Login</div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>ID</div>
            <div>
              <InputBox value={id} onValue={setId} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowTitle}>Password</div>
            <div>
              <InputBox
                type="password"
                value={pw}
                onValue={setPw}
                onKeyDownCapture={pressEnter}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className={styles.alert}>{errMsg}</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <CheckBox
                text="Remember Me"
                checked={remember}
                onChange={e => setRemember(e.target.checked)}
              />
            </div>
          </div>
          <div className="row" style={{marginTop: "25px"}}>
            <div className="col">
              <Button
                text="Login"
                onClick={submit}
                backgroundColor={styleUtils.COLOR.green}
                fontColor={styleUtils.COLOR.white}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
