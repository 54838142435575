import {Button, CheckBox, ListTable, TextAreaBox} from "@components";
import {states} from "@recoils";
import {adminAPI, constant, styleUtils, utils} from "@utils";
import {
  IDBCard,
  IDBDeliveryAddress,
  IDBMember,
  IMemberResponse,
  IOrderDetail,
  ISubscribe,
  ISubscribeItem,
} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {MemberPointAddModal} from "./MemberPointAddModal";
import styles from "../style.module.scss";
import {AddressDetailModal} from "./AddressDetailModal";
import {CardAddModal} from "./CardAddModal";
import {SubscribeEditModal} from "./SubscribeEditModal";
import {SubscribeItemEditModal} from "./SubscribeItemEditModal";
import {OrderDetailModal} from "pages/OrderPage/OrderDetailModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {SubscribePassAddModal} from "./SubscribePassAddModal";
import {formatEntranceMemo} from "utils/commonUtil";

interface IProps {
  member: IMemberResponse;
  updateMember?: (data: IDBMember) => void;
  refresh?: () => void;
}

export function InfoTab({member, updateMember, refresh}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [isAddPoint, setIsAddPoint] = useState(false);
  const [memo, setMemo] = useState<string>();
  const [isCardAddVisible, setIsCardAddVisible] = useState(false);
  const [isDeliveryAddVisible, setIsDeliveryAddVisible] = useState(false);
  const [editDeliveryAddress, setEditDeliveryAddress] =
    useState<IDBDeliveryAddress>();
  const [detailData, setDetailData] = useState<IOrderDetail>();

  const grade_name = constant.GRADE_LIST.find(
    _item => Number(_item.value) === Number(member?.grade_no),
  )?.label;

  function toggleGiftYn() {
    if (!confirm("제품체험여부를 변경하시겠습니까?")) {
      return;
    }
    updateMember?.({
      no: member.no,
      gift_apply_yn: member.gift_apply_yn === "Y" ? "N" : "Y",
    });
  }

  function saveMemberMemo() {
    updateMember?.({
      no: member.no,
      memo,
    });
  }

  async function delDeliveryAddress({no}: {no: number}) {
    try {
      if (!member.no) {
        return;
      }
      if (!confirm("배송지를 정말로 삭제하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.member.deleteDeliveryAddress({
        no,
        member_no: member.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류발생",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("delDeliveryAddress error", error);
    }
  }

  async function setMainDeliveryAddress({no}: {no: number}) {
    try {
      if (!member.no) {
        return;
      }
      if (!confirm("배송지를 주배송지로 변경하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.member.setMainDeliveryAddress({
        no,
        member_no: member.no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류발생",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("delDeliveryAddress error", error);
    }
  }

  async function createOrder({
    delivery_address_no,
  }: {
    delivery_address_no: number;
  }) {
    try {
      if (!member.no) {
        return;
      }
      if (!confirm("빈 주문건을 생성하시겠습니까?")) {
        return;
      }
      const {success, data, message} = await adminAPI.order.createOrder({
        member_no: member.no,
        delivery_address_no,
      });
      if (success) {
        getDetailOrder({order_no: data.order_no});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류발생",
        });
      }
    } catch (error) {
      console.error("createOrder error", error);
    }
  }

  async function getDetailOrder({order_no}: {order_no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no: order_no,
      });
      if (success) {
        setDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetailOrder error", error);
    }
  }

  useEffect(() => {
    setMemo(member.memo);
  }, [member]);

  if (!member?.no) {
    return <></>;
  }

  return (
    <>
      <div className={["row", styles.infoTab].join(" ")}>
        <div className={styles.modalCol}>
          <div className={styles.item}>
            <div className="row">
              <div className={styles.title}>고객정보</div>
            </div>
            <div className="row">
              <div className={["col", styles.itemTitle].join(" ")}>
                전화번호
              </div>
              <div className="col">
                {utils.formatPhoneNumber(member.phone_number || "")}
              </div>
            </div>
            <div className="row">
              <div className={["col", styles.itemTitle].join(" ")}>이름</div>
              <div className="col">{member.name}</div>
            </div>
            <div className="row">
              <div className={["col", styles.itemTitle].join(" ")}>닉네임</div>
              <div className="col">{member.nickname}</div>
            </div>
            <div className="row">
              <div className={["col", styles.itemTitle].join(" ")}>
                제품체험혜택
              </div>
              <div className="col">
                <div>
                  <CheckBox
                    checked={member.gift_apply_yn === "Y"}
                    isToggle
                    text={member.gift_apply_yn === "Y" ? "신청" : "X"}
                    onChange={toggleGiftYn}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className={["col", styles.itemTitle].join(" ")}>포인트</div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    {(member.point?.totalPoint || 0).toLocaleString()} P
                    {(member.point?.disappearPoint || 0) > 0 && (
                      <span>
                        {" "}
                        (한달이내 소멸포인트:{" "}
                        {(
                          member.point?.disappearPoint || 0
                        ).toLocaleString()}{" "}
                        P)
                      </span>
                    )}
                  </div>
                  <div
                    className={["col", "flex-end"].join(" ")}
                    style={{flex: "auto"}}>
                    <div>
                      <Button
                        text="포인트지급"
                        onClick={() => setIsAddPoint(true)}
                        marginHorizontal={2}
                        backgroundColor={styleUtils.COLOR.success}
                        fontColor={styleUtils.COLOR.white}
                        fontSize={12}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={["col", styles.itemTitle].join(" ")}>등급</div>
              <div className="col">{grade_name}</div>
            </div>
            <div className="row">
              <div className={["col", styles.itemTitle].join(" ")}>
                누적 결제금액
              </div>
              <div className="col">
                {(member.gradeData?.price || 0).toLocaleString()}원 /{" "}
                {(member.gradeData?.count || 0).toLocaleString()}건(최근 6개월)
              </div>
            </div>
            {member.del_yn === "Y" && (
              <>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    탈퇴일
                  </div>
                  <div className="col">
                    {moment(member.out_date).format("YYYY-MM-DD HH:mm")}
                  </div>
                </div>
                <div className="row">
                  <div className={["col", styles.itemTitle].join(" ")}>
                    탈퇴사유
                  </div>
                  <div className="col">
                    {(member.member_out_reason || []).map((item, i) => (
                      <div key={i}>{item.title}</div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.item}>
            <div className="row">
              <div className={styles.title}>배송지</div>
            </div>
            <div className="row">
              <ListTable>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>주소</th>
                    <th>
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="신규등록"
                          backgroundColor={styleUtils.COLOR.success}
                          fontColor={styleUtils.COLOR.white}
                          fontSize={12}
                          height={26}
                          onClick={() => setIsDeliveryAddVisible(true)}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(member.deliveryAddress || []).map((item, index) => {
                    const isDawn = item.dawn_delivery_yn === "Y";
                    const isDay = item.day_delivery_yn === "Y";
                    const isPlus = item.plus_delivery_yn === "Y";
                    const badgeTitle = isDawn
                      ? "새벽"
                      : isDay
                      ? "당일"
                      : isPlus
                      ? "도서산간"
                      : "일반";
                    const badgeClass = isDawn
                      ? styles.dawn
                      : isDay
                      ? styles.day
                      : isPlus
                      ? styles.plus
                      : undefined;

                    return (
                      <tr key={index}>
                        <td>
                          {item.main_address_yn === "Y" && (
                            <>
                              <span
                                className={[styles.badge, styles.main].join(
                                  " ",
                                )}>
                                주배송지
                              </span>
                              <br />
                            </>
                          )}
                          <span
                            className={[styles.badge, badgeClass].join(" ")}>
                            {badgeTitle}
                          </span>
                        </td>
                        <td>
                          {item.road_address}, {item.detail_address}
                          <br />({item.jibun_address})
                          <div
                            className="text-12"
                            style={{whiteSpace: "normal"}}>
                            {formatEntranceMemo({delivery_address: item})}
                          </div>
                        </td>
                        <td>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}>
                              <div style={{marginBottom: "6px"}}>
                                {item.main_address_yn === "N" && (
                                  <Button
                                    text="주배송지설정"
                                    backgroundColor={styleUtils.COLOR.gray1}
                                    fontColor={styleUtils.COLOR.white}
                                    fontSize={12}
                                    height={18}
                                    onClick={() => {
                                      if (item.no) {
                                        setMainDeliveryAddress({no: item.no});
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}>
                              <div style={{marginBottom: "6px"}}>
                                <Button
                                  text="주문건생성"
                                  backgroundColor={styleUtils.COLOR.warning}
                                  fontColor={styleUtils.COLOR.black}
                                  fontSize={12}
                                  height={18}
                                  onClick={() => {
                                    if (item.no) {
                                      createOrder({
                                        delivery_address_no: item.no,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}>
                              <div>
                                <Button
                                  text="수정"
                                  backgroundColor={styleUtils.COLOR.primary}
                                  fontColor={styleUtils.COLOR.white}
                                  fontSize={12}
                                  height={18}
                                  onClick={() => {
                                    setIsDeliveryAddVisible(true);
                                    setEditDeliveryAddress(item);
                                  }}
                                />
                              </div>
                              <div style={{width: "6px"}}></div>
                              <div>
                                <Button
                                  text="삭제"
                                  backgroundColor={styleUtils.COLOR.danger}
                                  fontColor={styleUtils.COLOR.white}
                                  fontSize={12}
                                  height={18}
                                  onClick={() => {
                                    if (item.no) {
                                      delDeliveryAddress({no: item.no});
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ListTable>
            </div>
          </div>
          <div className={styles.item}>
            <div className="row">
              <div className={styles.title}>카드</div>
            </div>
            <div className="row">
              <ListTable>
                <thead>
                  <tr>
                    <th>카드사</th>
                    <th>카드번호</th>
                    <th>
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="신규등록"
                          backgroundColor={styleUtils.COLOR.success}
                          fontColor={styleUtils.COLOR.white}
                          fontSize={12}
                          height={26}
                          onClick={() => setIsCardAddVisible(true)}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(member.card || []).map((item, index) => (
                    <tr key={index}>
                      <td>{item.card_name}</td>
                      <td>{utils.formatCardNo(item.card_no || "")}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </ListTable>
            </div>
          </div>
        </div>
        <div className={styles.modalCol}>
          <div className={styles.item}>
            <div className="row">
              <div className={styles.title}>정기구독</div>
            </div>
            <div className="row">
              {(member.subscribe || []).length > 0 ? (
                <>
                  {(member.subscribe || []).map((subscribe, index) => (
                    <SubscribeItem
                      key={index}
                      subscribe={subscribe}
                      card={member.card}
                      deliveryAddress={member.deliveryAddress}
                      refresh={refresh}
                      member_no={member.no}
                    />
                  ))}
                </>
              ) : (
                <div className="col">정기구독건 없음</div>
              )}
            </div>
          </div>
          <div className={styles.item}>
            <div className="row">
              <div className={styles.title}>아이정보</div>
            </div>
            <div className="row">
              <ListTable>
                <thead>
                  <tr>
                    <th>아이명</th>
                    <th>종류</th>
                    <th>무게</th>
                    <th>생일</th>
                    <th>질환</th>
                    <th>알러지</th>
                  </tr>
                </thead>
                <tbody>
                  {(member.pet || []).map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.name}
                        <br />
                        <span className="text-12">
                          ({item.sex}
                          {item.neutered_yn === "Y" && "/중성화"})
                        </span>
                      </td>
                      <td>
                        {item.kind?.kind_name}
                        <br />
                        {item.type?.type_name}
                      </td>
                      <td>{item.weight} kg</td>
                      <td>{moment(item.birthday).format("YYYY-MM-DD")}</td>
                      <td>
                        {(item.diseaseList || []).map((disease, j) => (
                          <span
                            key={j}
                            className={[styles.badge].join(" ")}
                            style={{marginRight: "2px"}}>
                            {disease.disease?.title}
                          </span>
                        ))}
                      </td>
                      <td>
                        {(item.allergyList || []).map((allergy, j) => (
                          <span
                            key={j}
                            className={[styles.badge].join(" ")}
                            style={{marginRight: "2px"}}>
                            {allergy.allergy?.title}
                          </span>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ListTable>
            </div>
          </div>
          <div className={styles.item}>
            <div className="row">
              <div className={[styles.title, "row"].join(" ")}>
                <div className="col">고객메모</div>
                <div className={["col", "flex-end"].join(" ")}>
                  <div>
                    <Button
                      text="저장"
                      onClick={saveMemberMemo}
                      backgroundColor={styleUtils.COLOR.success}
                      fontColor={styleUtils.COLOR.white}
                      fontSize={12}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <TextAreaBox value={memo} onValue={setMemo} height={200} />
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
      <MemberPointAddModal
        member_no={member.no}
        isVisible={isAddPoint}
        close={() => setIsAddPoint(false)}
        refresh={refresh}
      />
      <CardAddModal
        isVisible={isCardAddVisible}
        member_no={member.no}
        close={() => setIsCardAddVisible(false)}
        refresh={refresh}
      />
      <AddressDetailModal
        isVisible={isDeliveryAddVisible}
        data={editDeliveryAddress}
        member_no={member.no}
        close={() => {
          setIsDeliveryAddVisible(false);
          setEditDeliveryAddress(undefined);
        }}
        refresh={refresh}
      />
      {!!detailData && (
        <OrderDetailModal
          isVisible={!!detailData}
          close={() => setDetailData(undefined)}
          data={detailData}
          refresh={() => {
            if (detailData?.no) {
              getDetailOrder({order_no: detailData.no});
            }
          }}
        />
      )}
    </>
  );
}

function SubscribeItem({
  member_no,
  subscribe,
  deliveryAddress,
  card,
  refresh,
}: {
  member_no?: number;
  subscribe: ISubscribe;
  deliveryAddress?: IDBDeliveryAddress[];
  card?: IDBCard[];
  refresh?: () => void;
}) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [subscribeItem, setSubscribeItem] = useState<ISubscribeItem>();
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isSubscribePass, setIsSubscribePass] = useState(false);

  async function removeSubscribePass({
    no,
    subscribe_no,
  }: {
    no: number;
    subscribe_no: number;
  }) {
    try {
      if (!member_no) {
        return;
      }
      if (!confirm("건너뛰기를 제거하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.member.removeSubscribePass({
        member_no,
        no,
        subscribe_no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("removeSubscribePass error", error);
    }
  }

  async function removeSubscribeItem({
    item_no,
    subscribe_no,
  }: {
    item_no: number;
    subscribe_no: number;
  }) {
    try {
      if (!member_no) {
        return;
      }
      if (!confirm("해당 제품을 정기구독에서 제거하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.member.removeSubscribeItem({
        member_no,
        item_no,
        subscribe_no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        refresh?.();
      }
    } catch (error) {
      console.error("removeSubscribeItem error", error);
    }
  }

  async function rePaymentSubscribe() {
    try {
      if (!member_no) {
        return;
      }
      if (!confirm("정기구독 결제를 재시도하시겠습니까?")) {
        return;
      }
      const {message} = await adminAPI.order.makeSubscribeOrder({
        member_no,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
    } catch (error) {
      console.error("rePaymentSubscribe error", error);
    }
  }

  return (
    <>
      <div className={styles.subscribeItem}>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>구독번호</div>
          <div className="col">
            <div className="row">
              <div className="col">{subscribe.subscribe_number}</div>
              <div className={["col", "flex-end"].join(" ")}>
                <div>
                  <Button text="수정" onClick={() => setIsEditVisible(true)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>구독시작일</div>
          <div className="col">
            {moment(subscribe.start_date).format("YYYY-MM-DD")}
          </div>
        </div>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>자동할인</div>
          <div className="col">
            쿠폰 :{" "}
            {subscribe.auto_coupon_yn === "Y" ? "항상 자동 적용" : "적용 안함"}
            <br />
            포인트 :{" "}
            {subscribe.auto_point_yn === "Y" ? "항상 전액 사용" : "적용 안함"}
          </div>
        </div>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>
            건너뛰기
            <div
              className={styles.plusIcon}
              style={{marginLeft: "6px"}}
              onClick={() => setIsSubscribePass(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
          <div className="col">
            <ul>
              {(subscribe.subscribePass || [])
                .sort((a, b) =>
                  moment(a.pass_date).isAfter(moment(b.pass_date)) ? 1 : -1,
                )
                .map((item, index) => (
                  <li key={index} style={{display: "flex"}}>
                    {moment(item.pass_date).isValid()
                      ? moment(item.pass_date).format("YYYY-MM-DD")
                      : ""}
                    <div
                      className={styles.minusIcon}
                      style={{marginLeft: "6px"}}
                      onClick={() => {
                        if (!item.no || !item.subscribe_no) {
                          return;
                        }
                        removeSubscribePass({
                          no: item.no,
                          subscribe_no: item.subscribe_no,
                        });
                      }}>
                      <FontAwesomeIcon icon={faMinus} />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>배송지</div>
          <div className="col">
            {subscribe.deliveryAddress?.road_address},{" "}
            {subscribe.deliveryAddress?.detail_address}
            <br />
            {formatEntranceMemo({delivery_address: subscribe.deliveryAddress})}
          </div>
        </div>
        {!!subscribe.memo && (
          <div className="row">
            <div className={["col", styles.itemTitle].join(" ")}>배송메모</div>
            <div className="col">{subscribe.memo}</div>
          </div>
        )}
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>결제카드</div>
          <div className="col">
            <div>
              {(subscribe.subscribeCard || []).map((item, index) => (
                <div key={index}>
                  {item.main_yn === "Y" ? `(주결제)` : `(부결제)`}{" "}
                  {utils.formatCardNo(item.card?.card_no || "")}
                </div>
              ))}
            </div>
            <div className={["col", "flex-end"].join(" ")}>
              <div>
                <Button
                  text="재결제"
                  fontSize={12}
                  onClick={rePaymentSubscribe}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={["col", styles.itemTitle].join(" ")}>선택옵션</div>
          <div className={["col", styles.itemContent].join(" ")}>
            <ul>
              {(subscribe.subscribeItem || []).map((item, index) => (
                <li key={index}>
                  <div className="row">
                    <div className="col">
                      ({item.release_cycle}주) [
                      {item.product?.partners?.partner_name}]{" "}
                      {item.product?.item_name} (
                      {(
                        item.product?.subscribe_price ||
                        item.product?.price ||
                        0
                      ).toLocaleString()}
                      원) {item.count || 0}개
                    </div>
                    <div>
                      <Button
                        text="수량변경"
                        fontSize={12}
                        onClick={() => setSubscribeItem(item)}
                      />
                    </div>
                    <div
                      className={styles.minusIcon}
                      style={{marginLeft: "6px"}}
                      onClick={() => {
                        if (!item.product?.no || !item.subscribe_no) {
                          return;
                        }
                        removeSubscribeItem({
                          item_no: item.product?.no,
                          subscribe_no: item.subscribe_no,
                        });
                      }}>
                      <FontAwesomeIcon icon={faMinus} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {(subscribe.subscribe_temp || []).length > 0 && (
          <div className="row">
            <div className={["col", styles.itemTitle].join(" ")}>함께담기</div>
            <div className="col">
              <ul>
                {(subscribe.subscribe_temp || []).map((item, index) => (
                  <li key={index}>
                    [{item.product?.partners?.partner_name}]{" "}
                    {item.product?.item_name} (
                    {(item.product?.price || 0).toLocaleString()}
                    원) {item.count || 0}개
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <SubscribeEditModal
        subscribe={subscribe}
        isVisible={isEditVisible}
        card={card}
        deliveryAddress={deliveryAddress}
        close={() => setIsEditVisible(false)}
        refresh={refresh}
      />
      {!!subscribeItem && (
        <SubscribeItemEditModal
          isVisible={!!subscribeItem}
          subscribeItem={subscribeItem}
          close={() => setSubscribeItem(undefined)}
          refresh={refresh}
        />
      )}
      {!!isSubscribePass && !!member_no && !!subscribe.no && (
        <SubscribePassAddModal
          isVisible={isSubscribePass}
          member_no={member_no}
          subscribe_no={subscribe.no}
          close={() => setIsSubscribePass(false)}
          refresh={refresh}
        />
      )}
    </>
  );
}
