import {Button, ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {ResultPagingResponse, ICoupon} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";
import {CouponAddModal} from "./CouponAddModal";

interface IProps {
  member_no: number;
}

export function CouponTab({member_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [couponListData, setCouponListData] =
    useState<ResultPagingResponse<ICoupon[]>>();
  const [isCouponAddModal, setIsCouponAddModal] = useState(false);

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {success, message, data} = await adminAPI.member.getCouponList({
        member_no,
        page,
      });
      if (success) {
        setCouponListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function deleteCouponOne({
    no,
    coupon_no,
  }: {
    no: number;
    coupon_no: number;
  }) {
    try {
      if (!confirm("쿠폰을 삭제하시겠습니까? 삭제 후 복구가 불가능합니다.")) {
        return;
      }

      const {success, message} = await adminAPI.member.deleteCouponOne({
        member_no,
        coupon_no,
        no,
      });
      if (success) {
        setAlertModal({
          isVisible: true,
          title: "삭제완료",
        });
        getList({page: couponListData?.page?.cur || 1});
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("deleteCouponOne error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row">
        <div className={[styles.title, "row"].join(" ")}>
          <div className="col">
            총 {(couponListData?.page?.maxcnt || 0).toLocaleString()}개
          </div>
          <div className={["col", "flex-end"].join(" ")}>
            <div>
              <Button
                text="쿠폰등록"
                onClick={setIsCouponAddModal}
                backgroundColor={styleUtils.COLOR.success}
                fontColor={styleUtils.COLOR.white}
                fontSize={12}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <ListTable paging={couponListData?.page} getList={getList}>
          <thead>
            <tr>
              <th>쿠폰명</th>
              <th>쿠폰설명</th>
              <th>쿠폰코드</th>
              <th>쿠폰금액</th>
              <th>사용유무</th>
              <th>쿠폰활성화</th>
              <th>유효기간</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            {(couponListData?.result || []).map((item, index) => (
              <tr key={index}>
                <td>{item.couponDetail?.title}</td>
                <td>{item.couponDetail?.coupon_desc}</td>
                <td>{item.couponDetail?.coupon_num}</td>
                <td>
                  {(item.couponDetail?.price || 0).toLocaleString()}
                  {item.couponDetail?.type === "percent" ? "% 할인" : "원"}
                </td>
                <td>
                  {item.use_yn === "Y" ? (
                    <span className={[styles.badge, styles.warning].join(" ")}>
                      사용
                    </span>
                  ) : moment(item.use_end_time).isBefore(moment()) ? (
                    <span className={[styles.badge, styles.out].join(" ")}>
                      만료
                    </span>
                  ) : (
                    <span className={[styles.badge, styles.success].join(" ")}>
                      미사용
                    </span>
                  )}
                </td>
                <td>
                  {item.couponDetail?.useable_yn === "Y" ? (
                    moment(item.use_end_time).isValid() &&
                    moment(item.use_end_time).isBefore(moment()) ? (
                      <>
                        <span
                          className={[styles.badge, styles.warning].join(" ")}>
                          종료
                        </span>
                        <div className="text-10">(앱 내 미노출)</div>
                      </>
                    ) : moment(item.use_start_time).isValid() &&
                      moment().isBefore(moment(item.use_start_time)) ? (
                      <>
                        <span
                          className={[styles.badge, styles.warning].join(" ")}>
                          시작전
                        </span>
                        <div className="text-10">(앱 내 미노출)</div>
                      </>
                    ) : (
                      <span
                        className={[styles.badge, styles.success].join(" ")}>
                        활성
                      </span>
                    )
                  ) : (
                    <>
                      <span className={[styles.badge, styles.danger].join(" ")}>
                        비활성
                      </span>
                      <div className="text-10">(앱 내 미노출)</div>
                    </>
                  )}
                </td>
                <td>
                  {moment(item.use_start_time).format("YYYY-MM-DD")} ~
                  <br />
                  {moment(item.use_end_time).format("YYYY-MM-DD")}
                </td>
                <td>
                  <div style={{display: "inline-block"}}>
                    <Button
                      text="삭제"
                      backgroundColor={styleUtils.COLOR.danger}
                      fontColor={styleUtils.COLOR.white}
                      paddingVertical={2}
                      paddingHorizontal={6}
                      fontSize={12}
                      onClick={() => {
                        if (item.no && item.coupon_no) {
                          deleteCouponOne({
                            no: item.no,
                            coupon_no: item.coupon_no,
                          });
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      </div>
      <CouponAddModal
        isVisible={isCouponAddModal}
        member_no={member_no}
        close={() => setIsCouponAddModal(false)}
        refresh={() => getList({page: couponListData?.page?.cur || 1})}
      />
    </>
  );
}
