import {
  Button,
  InputBox,
  ListTable,
  Modal,
  ModalContent,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {ResultPagingResponse, IDBCouponCodeList} from "@data";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible?: boolean;
  coupon_no: number;
  close?: () => void;
}

export function CouponCodeDetailModal({isVisible, coupon_no, close}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [couponCodeList, setCouponCodeList] =
    useState<ResultPagingResponse<IDBCouponCodeList[]>>();
  const [newCouponCode, setNewCouponCode] = useState("");

  function setClose() {
    setCouponCodeList(undefined);
    setNewCouponCode("");
    close?.();
  }

  async function getCouponCodeList({page = 1}: {page?: number}) {
    try {
      const {data, success, message} = await adminAPI.coupon.getCouponCodeList({
        coupon_no,
        page,
      });
      if (success) {
        setCouponCodeList(data);
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getCouponCodeList error", error);
    }
  }

  async function createCoupon() {
    try {
      if (!coupon_no || !newCouponCode) {
        return;
      }

      const {data, success, message} = await adminAPI.coupon.createCouponCode({
        coupon_no,
        coupon_code: newCouponCode,
      });
      if (success) {
        setNewCouponCode("");
        getCouponCodeList({});
      } else {
        return setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("createCoupon error", error);
    }
  }

  useEffect(() => {
    if (coupon_no) {
      getCouponCodeList({});
    }
  }, [coupon_no]);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="쿠폰코드목록" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col">
            총 {(couponCodeList?.page?.maxcnt || 0).toLocaleString()}개
          </div>
          <div className="col flex-end">
            <div>
              <InputBox
                value={newCouponCode}
                onValue={setNewCouponCode}
                placeholder={"쿠폰코드입력"}
              />
            </div>
            <div>
              <Button
                text="등록"
                backgroundColor={styleUtils.COLOR.green}
                fontColor={styleUtils.COLOR.white}
                onClick={createCoupon}
              />
            </div>
          </div>
        </div>
        <ListTable getList={getCouponCodeList} paging={couponCodeList?.page}>
          <thead>
            <tr>
              <th>쿠폰코드</th>
              <th>발행여부</th>
            </tr>
          </thead>
          <tbody>
            {(couponCodeList?.result || []).map((item, index) => (
              <tr key={index}>
                <td>{item.coupon_code}</td>
                <td>{item.issued_yn === "Y" ? "발행완료" : "미발행"}</td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      </ModalContent>
    </Modal>
  );
}
