import * as FSRegularIcon from "@fortawesome/free-regular-svg-icons";
import * as FSSolidIcon from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {utils} from "@utils";
import styles from "./style.module.scss";
import {ScreenId} from "@assets";
import {useLogin} from "@hook";

interface IProps {
  itemQnaCount?: number;
  memberQnaCount?: number;
  frequnecyApplyCount?: number;
  memberOutRequestCount?: number;
}

type TRightCountType =
  | "qnaTotal"
  | "itemQna"
  | "memberQna"
  | "eventTotal"
  | "frequency"
  | "member_out_request";

interface IMenuOrigin {
  title: string;
  rightCount?: TRightCountType;
  icon?: any;
  link?: ScreenId;
  outLink?: string;
  isOpen?: boolean;
  level: number[];
}
interface IMenu extends IMenuOrigin {
  detail?: IMenuOrigin[];
}

export const initMenu: IMenu[] = [
  {
    title: "지표관리",
    icon: FSSolidIcon.faChartLine,
    detail: [
      {
        title: "Dashboard",
        icon: FSSolidIcon.faChartPie,
        link: ScreenId.Dashboard,
        level: [1],
      },
      {
        title: "KPI",
        icon: FSSolidIcon.faFileExcel,
        link: ScreenId.Kpi,
        level: [1],
      },
      {
        title: "판매량조회",
        icon: FSSolidIcon.faChartLine,
        link: ScreenId.OrderLog,
        level: [1],
      },
      {
        title: "장바구니손익관리",
        icon: FSSolidIcon.faChartLine,
        link: ScreenId.OrderProfit,
        level: [1],
      },
      {
        title: "일자별 재구매현황",
        icon: FSSolidIcon.faNotesMedical,
        link: ScreenId.ReOrderCountPage,
        level: [1],
      },
      {
        title: "코호트분석",
        icon: FSSolidIcon.faNotesMedical,
        link: ScreenId.CohortMonthly,
        level: [1],
      },
    ],
    level: [1],
  },
  {
    title: "홈 관리",
    icon: FSSolidIcon.faHome,
    detail: [
      {
        title: "홈탭관리",
        icon: FSSolidIcon.faTabletScreenButton,
        link: ScreenId.HomeTab,
        level: [1],
      },
      {
        title: "홈섹션관리",
        icon: FSSolidIcon.faJarWheat,
        link: ScreenId.Section,
        level: [1],
      },
      {
        title: "배너관리",
        icon: FSSolidIcon.faBurst,
        link: ScreenId.Banner,
        level: [1],
      },
      {
        title: "이벤트팝업관리",
        icon: FSSolidIcon.faBurst,
        link: ScreenId.EventPopup,
        level: [1],
      },
    ],
    level: [1],
  },
  {
    title: "제품관리",
    icon: FSSolidIcon.faBoxOpen,
    detail: [
      {
        title: "업체관리",
        icon: FSRegularIcon.faBuilding,
        link: ScreenId.Partner,
        level: [1],
      },
      {
        title: "상품관리",
        icon: FSSolidIcon.faBox,
        link: ScreenId.Item,
        level: [1, 2],
      },
      {
        title: "팀프레시 일괄입고",
        icon: FSSolidIcon.faFileExcel,
        link: ScreenId.StockExcelImport,
        level: [1],
      },
      {
        title: "상품가격관리",
        icon: FSSolidIcon.faCoins,
        link: ScreenId.ItemPriceChange,
        level: [1],
      },
      {
        title: "리뷰관리",
        icon: FSSolidIcon.faPencil,
        link: ScreenId.Review,
        level: [1],
      },
    ],
    level: [1, 2],
  },
  {
    title: "고객관리",
    icon: FSSolidIcon.faUsersLine,
    rightCount: "member_out_request",
    detail: [
      {
        title: "고객관리",
        icon: FSSolidIcon.faUsersLine,
        link: ScreenId.Member,
        level: [1],
      },
      {
        title: "등급관리",
        icon: FSSolidIcon.faUsersLine,
        link: ScreenId.Grade,
        level: [1],
      },
      {
        title: "계정삭제요청",
        icon: FSSolidIcon.faSignOut,
        link: ScreenId.MemberOutRequest,
        rightCount: "member_out_request",
        level: [1],
      },
    ],
    level: [1],
  },
  {
    title: "주문관리",
    icon: FSSolidIcon.faNotesMedical,
    link: ScreenId.Order,
    level: [1],
  },
  {
    title: "이벤트관리",
    icon: FSSolidIcon.faBoxOpen,
    rightCount: "eventTotal",
    detail: [
      {
        title: "출석이벤트",
        icon: FSRegularIcon.faBuilding,
        link: ScreenId.Attendance,
        level: [1],
      },
      {
        title: "프리퀀시",
        icon: FSSolidIcon.faCoins,
        link: ScreenId.Frequency,
        rightCount: "frequency",
        level: [1],
      },
    ],
    level: [1],
  },
  {
    title: "한상차림관리",
    icon: FSSolidIcon.faGrinStars,
    link: ScreenId.Post,
    level: [1, 2],
  },
  {
    title: "공지사항",
    icon: FSSolidIcon.faNoteSticky,
    link: ScreenId.Notice,
    level: [1],
  },
  {
    title: "문의관리",
    rightCount: "qnaTotal",
    detail: [
      {
        title: "자주묻는 질문",
        icon: FSSolidIcon.faQuestionCircle,
        link: ScreenId.Faq,
        level: [1],
      },
      {
        title: "1:1 문의",
        rightCount: "memberQna",
        icon: FSSolidIcon.faPersonWalkingWithCane,
        link: ScreenId.MemberQnA,
        level: [1],
      },
      {
        title: "상품문의관리",
        rightCount: "itemQna",
        icon: FSSolidIcon.faBoxTissue,
        link: ScreenId.ItemQnA,
        level: [1],
      },
    ],
    icon: FSSolidIcon.faQuestion,
    level: [1],
  },
  {
    title: "쿠폰관리",
    icon: FSSolidIcon.faCouch,
    link: ScreenId.Coupon,
    level: [1],
  },
  {
    title: "배송지조회",
    icon: FSSolidIcon.faCar,
    outLink:
      "https://tms.teamfresh.co.kr/popup/deliveryAreaSearchPopupForCustomer",
    level: [1],
  },
  {
    title: "정기구독배송일지정",
    icon: FSSolidIcon.faCalendarXmark,
    link: ScreenId.DeliveryDate,
    level: [1],
  },
  {
    title: "마케팅알림",
    icon: FSSolidIcon.faMessage,
    link: ScreenId.Marketing,
    level: [1],
  },
  {
    title: "문자발송이력",
    icon: FSSolidIcon.faMenorah,
    link: ScreenId.SmsLog,
    level: [1],
  },
  {
    title: "결제이력",
    icon: FSSolidIcon.faMenorah,
    link: ScreenId.PaymentLog,
    level: [1],
  },
];

export function Sidebar({
  itemQnaCount,
  memberQnaCount,
  frequnecyApplyCount,
  memberOutRequestCount,
}: IProps) {
  const {adminData} = useLogin();
  const navigate = useNavigate();
  const location = useLocation();
  const nowPath = (location.pathname || "").slice(
    1,
    (location.pathname || "").length,
  );
  const [menu, setMenu] = useState<IMenu[]>([]);

  function gotoLink({link}: {link: string}) {
    if (nowPath === link) {
      window.location.replace(link);
    } else {
      navigate(link);
    }
  }
  function gotoOutLink({link}: {link: string}) {
    window.open(link, "_blank");
  }

  useEffect(() => {
    setMenu(
      initMenu.filter(
        _menu => !!adminData?.level && _menu.level.includes(adminData?.level),
      ),
    );
  }, [adminData]);

  return (
    <ul className={styles.sidebarMenu}>
      {menu.map((item, i) => {
        const isActive = nowPath === item.link;
        const rightCount = getRightCount({
          right: item.rightCount,
          itemQnaCount,
          memberQnaCount,
          frequnecyApplyCount,
          memberOutRequestCount,
        });
        const detailMenu = (item.detail || []).filter(
          _menu => !!adminData?.level && _menu.level.includes(adminData?.level),
        );

        return (
          <li
            key={i}
            className={[
              ...[isActive ? styles.active : undefined],
              ...[
                (item.detail || []).length > 0
                  ? styles.menuGroup
                  : styles.menuItem,
              ],
              ...[item.isOpen ? styles.open : undefined],
            ].join(" ")}
            onClick={() => {
              if (item.link) {
                gotoLink({link: item.link});
              } else if (item.outLink) {
                gotoOutLink({link: item.outLink});
              } else if ((item.detail || []).length > 0) {
                setMenu([
                  ...menu.map((_item, _index) =>
                    _index === i ? {..._item, isOpen: !_item.isOpen} : _item,
                  ),
                ]);
              }
            }}>
            <div className={styles.groupDiv}>
              {!!item.icon && (
                <FontAwesomeIcon icon={item.icon} className={styles.icon} />
              )}
              <span className={styles.submenuTitle}>
                {item.title}
                {rightCount > 0 && (
                  <span className={styles.menuCount}>
                    ({rightCount.toLocaleString()})
                  </span>
                )}
              </span>
            </div>
            {detailMenu.length > 0 && (
              <ul className={[styles.subMenu].join(" ")}>
                {detailMenu.map((subMenu, j) => {
                  const isSubActive = nowPath === subMenu.link;
                  const subRightCount = getRightCount({
                    right: subMenu.rightCount,
                    itemQnaCount,
                    memberQnaCount,
                    frequnecyApplyCount,
                    memberOutRequestCount,
                  });

                  return (
                    <li
                      key={j}
                      className={[
                        styles.subMenuItem,
                        ...[isSubActive ? styles.active : undefined],
                      ].join(" ")}
                      onClick={e => {
                        utils.handleClickBubble(e);
                        if (subMenu.link) {
                          gotoLink({link: subMenu.link});
                        } else if (subMenu.outLink) {
                          gotoOutLink({link: subMenu.outLink});
                        }
                      }}>
                      {!!subMenu.icon && (
                        <FontAwesomeIcon
                          icon={subMenu.icon}
                          className={styles.icon}
                        />
                      )}
                      <span className={styles.submenuTitle}>
                        {subMenu.title}
                      </span>
                      {subRightCount > 0 && (
                        <span className={styles.menuCount}>
                          ({subRightCount.toLocaleString()})
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
}

function getRightCount({
  right,
  itemQnaCount = 0,
  memberQnaCount = 0,
  frequnecyApplyCount = 0,
  memberOutRequestCount = 0,
}: {
  right?: TRightCountType;
  itemQnaCount?: number;
  memberQnaCount?: number;
  frequnecyApplyCount?: number;
  memberOutRequestCount?: number;
}) {
  let count = 0;
  if (right === "qnaTotal") {
    count = (itemQnaCount || 0) + (memberQnaCount || 0);
  } else if (right === "itemQna") {
    count = itemQnaCount;
  } else if (right === "memberQna") {
    count = memberQnaCount;
  } else if (right === "eventTotal") {
    count = frequnecyApplyCount;
  } else if (right === "frequency") {
    count = frequnecyApplyCount;
  } else if (right === "member_out_request") {
    count = memberOutRequestCount;
  }
  return count;
}
