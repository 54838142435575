import React from "react";
import {Chart, registerables} from "chart.js";
import {RootNavigation} from "@navigation";
import {AlertModal, Loader} from "@components";
import {useRecoilValue} from "recoil";
import {states} from "@recoils";

Chart.register(...registerables);

export default function App() {
  const loading = useRecoilValue(states.loadingState);

  return (
    <>
      <RootNavigation />
      <AlertModal />
      <Loader loading={loading} />
    </>
  );
}
