import {SelectBox} from "components";
import React, {useEffect, useState} from "react";
import styles from "./style.module.scss";

interface IProps {
  value?: string;
  setValue?: (value: string) => void;
  step?: number;
}

const timeRegex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;

export default function TimePicker({value, setValue, step}: IProps) {
  const {hours, minutes} = getOptions({step});

  const [hour, setHour] = useState<string>();
  const [minute, setMinute] = useState<string>();

  useEffect(() => {
    if (timeRegex.test(String(value || ""))) {
      const H = (value || "").split(":")[0];
      const M = (value || "").split(":")[1];
      setHour(H);
      setMinute(M);
    } else {
      setHour(undefined);
      setMinute(undefined);
    }
  }, [value]);

  useEffect(() => {
    if (hour && minute) {
      const data = `${hour}:${minute}`;
      if (data !== value) {
        setValue?.(data);
      }
    }
  }, [hour, minute]);

  return (
    <div className={styles.container}>
      <SelectBox list={hours} value={hour} singleSelect={setHour} />
      <SelectBox list={minutes} value={minute} singleSelect={setMinute} />
    </div>
  );
}

function getOptions({step = 10}: {step?: number}) {
  const hours = [{label: "시간선택", value: ""}];
  const minutes = [{label: "분선택", value: ""}];

  for (let hour = 0; hour < 24; hour++) {
    const data = String(hour).padStart(2, "0");
    hours.push({label: data, value: data});
  }
  for (let minute = 0; minute < 60; minute += step) {
    const data = String(minute).padStart(2, "0");
    minutes.push({label: data, value: data});
  }

  return {hours, minutes};
}
