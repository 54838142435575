import _ from "lodash";
import React, {CSSProperties} from "react";
import styles from "./style.module.scss";

interface IProps {
  list: {
    text?: string;
    value: string;
  }[];
  value: string;
  setValue?: (value: string) => void;
  containerStyle?: CSSProperties;
}

export default function RadioBox({
  list,
  value,
  setValue,
  containerStyle,
}: IProps) {
  const radioName = _.uniqueId();

  return (
    <div className={styles.container} style={containerStyle}>
      {list.map(item => (
        <label key={_.uniqueId()} className={styles.item}>
          <input
            type="radio"
            name={radioName}
            value={item.value}
            defaultChecked={item.value === value}
            onClick={() => setValue?.(item.value)}
          />
          {item.text || item.value}
        </label>
      ))}
    </div>
  );
}
