import {Button, ListTable} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {ResultPagingResponse, IMemberClaim, IOrderDetail} from "@data";
import moment from "moment";
import {OrderDetailModal} from "pages/OrderPage/OrderDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";
import {ClaimDetailModal} from "./ClaimDetailModal";

interface IProps {
  member_no: number;
}

const importantList: {[key: number]: string} = {
  1: "높음",
  2: "중간",
  3: "낮음",
};

export function ClaimTab({member_no}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [listData, setListData] =
    useState<ResultPagingResponse<IMemberClaim[]>>();
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [detailData, setDetailData] = useState<IMemberClaim>();
  const [orderData, setOrderData] = useState<IOrderDetail>();

  async function getList({page = 1}: {page?: number} = {}) {
    try {
      const {success, message, data} = await adminAPI.member.getClaimList({
        member_no,
        page,
      });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no,
      });
      if (success) {
        setOrderData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getDetail error", error);
    }
  }

  async function saveDetailData({
    content,
    important,
    type,
  }: {
    content: string;
    important: number;
    type: string;
  }) {
    try {
      const {data, success, message} = await adminAPI.order.apiSaveOrderClaim({
        no: detailData?.no,
        member_no,
        content,
        important,
        type,
      });
      if (success) {
        setIsDetailVisible(false);
        setDetailData(undefined);
        if (detailData) {
          getList({page: listData?.page?.cur || 1});
        } else {
          getList();
        }
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("saveDetailData error", error);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="row" style={{justifyContent: "space-between"}}>
        <div className={styles.title}>
          총 {(listData?.page?.maxcnt || 0).toLocaleString()}건
        </div>
        <div>
          <Button
            text="추가"
            onClick={() => setIsDetailVisible(true)}
            backgroundColor={styleUtils.COLOR.success}
          />
        </div>
      </div>
      <div className="row">
        <ListTable paging={listData?.page} getList={getList}>
          <thead>
            <tr>
              <th>구분</th>
              <th>주문번호</th>
              <th>내용</th>
              <th>작성자</th>
              <th>중요도</th>
              <th>작성일</th>
              <th>상세</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.type}</td>
                  <td>
                    {item.order?.order_number}
                    {!!item.order && (
                      <>
                        <br />
                        <div style={{display: "inline-block"}}>
                          <Button
                            text="주문서조회"
                            fontSize={10}
                            paddingVertical={1}
                            backgroundColor={styleUtils.COLOR.baige}
                            onClick={() => {
                              if (item.order_no) {
                                getDetail({no: item.order_no});
                              }
                            }}
                          />
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    <div
                      className={["preline", "text-left"].join(" ")}
                      style={{maxWidth: "500px"}}>
                      {item.content}
                    </div>
                  </td>
                  <td>{item.admin?.name}</td>
                  <td>{!!item.important && importantList[item.important]}</td>
                  <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                  <td>
                    <div style={{display: "inline-block"}}>
                      <Button
                        text="상세조회"
                        fontSize={10}
                        paddingVertical={1}
                        backgroundColor={styleUtils.COLOR.baige}
                        onClick={() => {
                          setIsDetailVisible(true);
                          setDetailData(item);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </div>
      <OrderDetailModal
        isVisible={!!orderData}
        close={() => setOrderData(undefined)}
        data={orderData}
        refresh={() => {
          if (orderData?.no) {
            getDetail({no: orderData.no});
          }
        }}
      />
      <ClaimDetailModal
        visible={!!isDetailVisible}
        close={() => {
          setIsDetailVisible(false);
          setDetailData(undefined);
        }}
        save={saveDetailData}
        data={detailData}
      />
    </>
  );
}
