import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  SelectBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {IDBCouponList} from "@data";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  grade_no?: number;
  isVisible: boolean;
  close?: () => void;
  select?: (coupon: IDBCouponList) => void;
}

export function CouponAddModal({grade_no, isVisible, close, select}: IProps) {
  const [couponList, setCouponList] = useState<IDBCouponList[]>([]);
  const [couponNo, setCouponNo] = useState<number>();

  function setClose() {
    setCouponNo(undefined);
    close?.();
  }

  async function getCouponList() {
    try {
      const {data, success} = await adminAPI.coupon.apiGetAllCouponList();
      if (success) {
        setCouponList(data);
      }
    } catch (error) {
      console.error("getCouponList error", error);
    }
  }

  async function addCoupon() {
    try {
      const coupon = couponList.find(item => item.no === couponNo);
      if (!coupon) {
        return alert("쿠폰을 선택해주세요");
      }
      select?.(coupon);
      setClose();
    } catch (error) {
      console.error("addCoupon error", error);
    }
  }

  useEffect(() => {
    getCouponList();
  }, []);

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="쿠폰발행" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">쿠폰선택</div>
          <div className="col">
            <SelectBox
              list={couponList.map(item => ({
                label: `[${item.coupon_num}] ${item.title}`,
                value: String(item.no),
              }))}
              value={String(couponNo)}
              singleSelect={value => setCouponNo(Number(value))}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button text="추가" onClick={addCoupon} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
