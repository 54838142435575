import {Button, CheckBox, InputBox, ListTable} from "@components";
import {
  faArrowCircleDown,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, styleUtils, utils} from "@utils";
import {
  TUpDown,
  TypeYN,
  IDBMaterialList,
  IMaterialList,
  IDBProduct,
} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {MaterialAddModal} from "./MaterialAddModal";
import {CopyItemSelectModal} from "./CopyItemSelectModal";

interface IProps {
  item_no: number;
  item_partner_no?: number;
  itemMaterialList?: IDBMaterialList[];
  getItemMaterialList?: () => void;
}

interface IUpdateMaterialList extends IMaterialList {
  new_content_percent: number;
}

export function MaterialTab({
  item_no,
  item_partner_no,
  itemMaterialList,
  getItemMaterialList,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [list, setList] = useState<IUpdateMaterialList[]>([]);
  const [isAddModal, setIsAddModal] = useState(false);
  const [itemList, setItemList] = useState<IDBProduct[]>([]);
  const [selectedMaterialList, setSelectedMaterialList] = useState<number[]>(
    [],
  );
  const [isSelectItemModal, setIsSelectItemModal] = useState(false);

  const isAllChecked = selectedMaterialList.length === list.length;

  async function getList() {
    try {
      const {data, success, message} = await adminAPI.item.getItemMaterialList({
        item_no,
      });
      if (success) {
        setList(
          (data.result || []).map(item => ({
            ...item,
            new_content_percent: item.content_percent || 0,
          })),
        );
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("getList error", error);
    }
  }

  async function getItemList() {
    try {
      const {data, success} = await adminAPI.item.apiGetAllItems({
        partner_no: item_partner_no,
      });
      if (success) {
        setItemList((data.result || []).filter(item => item.no !== item_no));
      }
    } catch (error) {
      console.log("getItemList error", error);
    }
  }

  async function toggleMaterialYn({
    no,
    main_yn,
    organic_yn,
    antibiotic_free_yn,
    natural_yn,
  }: {
    no: number;
    main_yn?: TypeYN;
    organic_yn?: TypeYN;
    antibiotic_free_yn?: TypeYN;
    natural_yn?: TypeYN;
  }) {
    try {
      const {success, message} = await adminAPI.item.toggleMaterialYn({
        no,
        item_no,
        main_yn,
        organic_yn,
        antibiotic_free_yn,
        natural_yn,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다.",
      });
      if (success) {
        getList();
      }
    } catch (error) {
      console.error("toggleMaterialYn error", error);
    }
  }

  async function changeMaterialOrdering({
    no,
    type,
  }: {
    no: number;
    type: TUpDown;
  }) {
    try {
      const {success, message} = await adminAPI.item.changeMaterialOrdering({
        no,
        item_no,
        type,
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("changeMaterialOrdering error", error);
    }
  }

  async function deleteItemMaterial({no}: {no: number}) {
    try {
      if (!confirm("원재료를 삭제하시겠습니까?")) {
        return;
      }
      const {success, message} = await adminAPI.item.deleteItemMaterial({
        no,
        item_no,
      });
      if (success) {
        getList();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("deleteItemMaterial error", error);
    }
  }

  async function saveContentPercent({
    isAll = true,
    material_no,
  }: {
    isAll?: boolean;
    material_no?: number;
  }) {
    try {
      const updatedList = isAll
        ? list.filter(
            item =>
              (item.content_percent || 0) !== (item.new_content_percent || 0) &&
              utils.isDefined(item.no) &&
              utils.isDefined(item.item_no),
          )
        : list.filter(
            item =>
              (item.content_percent || 0) !== (item.new_content_percent || 0) &&
              item.no === material_no &&
              utils.isDefined(item.item_no),
          );
      const convertList = updatedList.map(item => ({
        no: item.no || 0,
        item_no: item.item_no || 0,
        content_percent: item.new_content_percent,
      }));
      if (convertList.length === 0) {
        setAlertModal({
          isVisible: true,
          title: "수정된 내역이 없습니다.",
        });
        return;
      }

      const {success, message} =
        await adminAPI.item.updateItemMaterialContentPercentMulti({
          list: convertList,
        });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        getList();
      }
    } catch (error) {
      console.error("saveContentPercent error", error);
    }
  }

  async function saveCopyItemMaterial({itemList}: {itemList: number[]}) {
    try {
      if (itemList.length === 0 || selectedMaterialList.length === 0) {
        return alert("상품을 선택해주세요.");
      }
      if (
        !confirm(
          `${selectedMaterialList.length}개의 원재료를 ${itemList.length}개 상품에 복사하시겠습니까?\n기존 작성한 내역이 있을 경우, 모든 원재료가 삭제 후 재등록되며 복구가 불가합니다!`,
        )
      ) {
        return;
      }
      const {success, message} = await adminAPI.item.copyItemMaterial({
        item_no_list: itemList,
        material_no_list: selectedMaterialList,
      });
      setAlertModal({
        isVisible: true,
        title: message || "오류가 발생했습니다",
      });
      if (success) {
        setIsSelectItemModal(false);
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  useEffect(() => {
    getItemList();
    getList();
  }, []);

  return (
    <>
      <div>
        <div className="row flex-end">
          <div>
            <Button
              backgroundColor={styleUtils.COLOR.warning}
              onClick={() => saveContentPercent({isAll: true})}
              text="함량일괄저장"
              fontSize={12}
            />
          </div>
          <div style={{marginLeft: "4px"}}>
            <Button
              backgroundColor={styleUtils.COLOR.warning}
              onClick={() => setIsAddModal(true)}
              text="추가"
              fontSize={12}
            />
          </div>
          <div style={{marginLeft: "4px"}}>
            <Button
              backgroundColor={styleUtils.COLOR.success}
              fontColor={styleUtils.COLOR.white}
              text="복사"
              fontSize={12}
              onClick={() => {
                if (selectedMaterialList.length === 0) {
                  return alert("복사할 상세를 먼저 체크해주세요.");
                } else {
                  setIsSelectItemModal(true);
                }
              }}
            />
          </div>
        </div>

        <ListTable getList={getList}>
          <thead>
            <tr>
              <th>
                <CheckBox
                  checked={isAllChecked}
                  onClick={() => {
                    if (isAllChecked) {
                      setSelectedMaterialList([]);
                    } else {
                      const allList = list
                        .map(_item => _item.no)
                        .filter(utils.isDefined);
                      setSelectedMaterialList(allList);
                    }
                  }}
                />
              </th>
              <th>
                원재료명
                <br />
                (바깥표기)
              </th>
              <th>
                원재료명
                <br />
                (상세페이지)
              </th>
              <th>
                국내/국외
                <br />
                (원산지)
              </th>
              <th>유기농</th>
              <th>무항생제</th>
              <th>자연산</th>
              <th>핵심원재료</th>
              <th>순번</th>
              <th>함량(%)</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => {
              const isSelected = !!selectedMaterialList.find(
                _item => _item === item.no,
              );

              return (
                <tr key={index}>
                  <td onClick={utils.handleClickBubble}>
                    <CheckBox
                      checked={isSelected}
                      onClick={() => {
                        if (isSelected) {
                          setSelectedMaterialList([
                            ...selectedMaterialList.filter(
                              _item => !(_item === item.no),
                            ),
                          ]);
                        } else if (item.no) {
                          setSelectedMaterialList([
                            ...selectedMaterialList,
                            item.no,
                          ]);
                        }
                      }}
                    />
                  </td>
                  <td>{item.materialInfo?.out_title}</td>
                  <td>{item.materialInfo?.title}</td>
                  <td>
                    {item.domestic_yn === "Y" ? "국내산" : "국외산"}
                    {!!item.from_country && `(${item.from_country})`}
                  </td>
                  <td>
                    <div>
                      <CheckBox
                        isToggle
                        checked={item.organic_yn === "Y"}
                        text={item.organic_yn === "Y" ? "O" : "X"}
                        onChange={e => {
                          if (item.no) {
                            toggleMaterialYn({
                              no: item.no,
                              organic_yn: item.organic_yn === "Y" ? "N" : "Y",
                            });
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <CheckBox
                        isToggle
                        checked={item.antibiotic_free_yn === "Y"}
                        text={item.antibiotic_free_yn === "Y" ? "O" : "X"}
                        onChange={e => {
                          if (item.no) {
                            toggleMaterialYn({
                              no: item.no,
                              antibiotic_free_yn:
                                item.antibiotic_free_yn === "Y" ? "N" : "Y",
                            });
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <CheckBox
                        isToggle
                        checked={item.natural_yn === "Y"}
                        text={item.natural_yn === "Y" ? "O" : "X"}
                        onChange={e => {
                          if (item.no) {
                            toggleMaterialYn({
                              no: item.no,
                              natural_yn: item.natural_yn === "Y" ? "N" : "Y",
                            });
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <CheckBox
                        isToggle
                        checked={item.main_yn === "Y"}
                        text={item.main_yn === "Y" ? "O" : "X"}
                        onChange={e => {
                          if (item.no) {
                            toggleMaterialYn({
                              no: item.no,
                              main_yn: item.main_yn === "Y" ? "N" : "Y",
                            });
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="row" style={{minWidth: "50px"}}>
                      <div className="col">
                        {(item.ordering || 0) > 1 && (
                          <FontAwesomeIcon
                            icon={faArrowCircleUp}
                            onClick={() => {
                              if (item.no) {
                                changeMaterialOrdering({
                                  no: item.no,
                                  type: "up",
                                });
                              }
                            }}
                          />
                        )}
                      </div>
                      <div className="col">{item.ordering || "-"}</div>
                      <div className="col">
                        <FontAwesomeIcon
                          icon={faArrowCircleDown}
                          onClick={() => {
                            if (item.no) {
                              changeMaterialOrdering({
                                no: item.no,
                                type: "down",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </td>
                  <td width={"50px"}>
                    <InputBox
                      value={String(item.new_content_percent)}
                      type="number"
                      onValue={value => {
                        setList(
                          list.map(_item => {
                            if (_item.no === item.no) {
                              return {
                                ..._item,
                                new_content_percent: !isNaN(Number(value))
                                  ? Number(value)
                                  : item.new_content_percent,
                              };
                            } else {
                              return _item;
                            }
                          }),
                        );
                      }}
                      rightContent={
                        <Button
                          text="저장"
                          fontSize={12}
                          paddingVertical={2}
                          onClick={() =>
                            saveContentPercent({material_no: item.no})
                          }
                        />
                      }
                    />
                  </td>
                  <td>
                    <Button
                      text="삭제"
                      backgroundColor={styleUtils.COLOR.danger}
                      fontColor={styleUtils.COLOR.white}
                      onClick={() => {
                        if (item.no) {
                          deleteItemMaterial({no: item.no});
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </ListTable>
      </div>
      <MaterialAddModal
        item_no={item_no}
        isVisible={isAddModal}
        close={() => setIsAddModal(false)}
        refresh={getList}
        itemMaterialList={itemMaterialList}
        getItemMaterialList={getItemMaterialList}
      />
      {!!isSelectItemModal && (
        <CopyItemSelectModal
          isVisible={isSelectItemModal}
          itemList={itemList}
          close={() => setIsSelectItemModal(false)}
          save={saveCopyItemMaterial}
        />
      )}
    </>
  );
}
