import {ContentLayout, ITabList, TabList} from "@components";
import React, {useState} from "react";
import {PaymentFailLog} from "./PaymentFailLog";
import {PaymentNotOrderLog} from "./PaymentNotOrderLog";

type TTabs = "payment_fail" | "not_order";
const tabs: ITabList<TTabs>[] = [
  {tab: "payment_fail", title: "결제실패"},
  {tab: "not_order", title: "신용/체크카드"},
];

export default function PaymentLogPage() {
  const [tab, setTab] = useState<TTabs>(tabs[0].tab);

  return (
    <>
      <ContentLayout title="결제이력">
        <TabList tabList={tabs} setTab={setTab} activeTab={tab} />
        <>{tab === "payment_fail" && <PaymentFailLog />}</>
        <>{tab === "not_order" && <PaymentNotOrderLog />}</>
      </ContentLayout>
    </>
  );
}
