import {Button, Modal, ModalContent, ModalTitle, SelectBox} from "@components";
import {states} from "@recoils";
import {adminAPI} from "@utils";
import {TProductDetailType} from "@data";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  item_no: number;
  close?: () => void;
  refresh?: () => void;
  categorys?: {text: string; value: TProductDetailType}[];
}

export function DetailItemAddModal({
  isVisible,
  item_no,
  close,
  refresh,
  categorys,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [selectedCategory, setSelectedCategory] =
    useState<TProductDetailType>();

  function setClose() {
    close?.();
    setSelectedCategory((categorys || [])[0]?.value);
  }

  async function save() {
    try {
      if (!selectedCategory) {
        return;
      }

      const {success, message} = await adminAPI.item.createItemDetail({
        item_no,
        data_json: {},
        type: selectedCategory,
      });
      if (success) {
        refresh?.();
        setClose();
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다.",
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  return (
    <>
      <Modal isVisible={isVisible} width="500px" close={setClose}>
        <ModalTitle close={setClose} isClose title="제품상세추가" />
        <ModalContent>
          <div className="row">
            <div className="col">
              <SelectBox
                list={(categorys || []).map(item => ({
                  label: item.text,
                  value: item.value,
                }))}
                value={selectedCategory}
                singleSelect={value =>
                  setSelectedCategory(value as TProductDetailType)
                }
              />
            </div>
            <div>
              <Button text="추가" onClick={save} />
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
