import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  ContentLayout,
  InputBox,
  SearchFilter,
  ListTable,
  Content,
} from "@components";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import moment from "moment";
import {IKPIResponse} from "@data";

const monthColumn = Object.freeze(
  [...Array(12).keys()].map(index => String(index + 1).padStart(2, "0")),
);
type Tmonth = (typeof monthColumn)[number];
interface IViewData {
  member: {
    accu_count: number;
    member_count: number;
  };
  order: {
    new_order_count: number;
    order_count: number;
    prev_order_count: number;
  };
  order_member: {
    member_count: number;
    new_member_count: number;
    prev_member_count: number;
  };
  order_partner: {
    partner_name: string;
    item_count: number;
    order_count: number;
  }[];
  re_order_count: {
    re_order_count: number;
  };
  subscribe_order_count: {
    new_order_count: number;
    order_count: number;
    prev_order_count: number;
  };
}

export default function KpiPage() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [year, setYear] = useState(moment().get("year").toString());
  const [kpiData, setKpiData] = useState<IKPIResponse>();
  const [viewData, setViewData] = useState<{
    [key: Tmonth]: IViewData;
  }>({});

  async function getData() {
    try {
      const {success, data, message} = await adminAPI.kpi.apiGetKpiData({
        year,
      });
      if (success) {
        setKpiData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("KpiPage getData error", error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const monthData: Record<Tmonth, IViewData> = {};

    monthColumn.map(month => {
      const memberCount = (kpiData?.member_count || []).find(
        item => item.month === month,
      );
      const orderCount = (kpiData?.order_count || []).find(
        item => item.month === month,
      );
      const orderMemberCount = (kpiData?.order_member_count || []).find(
        item => item.month === month,
      );
      const orderPartners = kpiData?.order_partner
        .map(arr => {
          const data = arr.filter(item => item.month === month)?.[0];
          if (!data || !data.partner_name) {
            return null;
          }
          return {
            partner_name: data.partner_name,
            item_count: data.item_count || 0,
            order_count: data.order_count || 0,
          };
        })
        .filter(utils.isDefined);
      const reOrderCount = (kpiData?.re_order_count || []).find(
        item => item.month === month,
      );
      const subscribeOrderCount = (kpiData?.subscribe_order_count || []).find(
        item => item.month === month,
      );

      monthData[month] = {
        member: {
          accu_count: memberCount?.accu_count || 0,
          member_count: memberCount?.member_count || 0,
        },
        order: {
          new_order_count: orderCount?.new_order_count || 0,
          order_count: orderCount?.order_count || 0,
          prev_order_count: orderCount?.prev_order_count || 0,
        },
        order_member: {
          member_count: orderMemberCount?.member_count || 0,
          new_member_count: orderMemberCount?.new_member_count || 0,
          prev_member_count: orderMemberCount?.prev_member_count || 0,
        },
        order_partner: orderPartners || [],
        re_order_count: {
          re_order_count: reOrderCount?.re_order_count || 0,
        },
        subscribe_order_count: {
          new_order_count: subscribeOrderCount?.new_order_count || 0,
          order_count: subscribeOrderCount?.order_count || 0,
          prev_order_count: subscribeOrderCount?.prev_order_count || 0,
        },
      };
    });

    setViewData(monthData);
  }, [kpiData]);

  return (
    <>
      <ContentLayout title="KPI">
        <SearchFilter
          left={[
            <InputBox
              key={0}
              type={"number"}
              placeholder="연도 입력"
              rightContent={
                <div style={{display: "flex"}} onClick={getData}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              }
              value={year}
              onValue={setYear}
              onEnter={getData}
            />,
          ]}
        />
        <Content>
          <ListTable>
            <thead>
              <tr>
                <th colSpan={2}>구분</th>
                {monthColumn.map(month => (
                  <th key={month}>{Number(month)}월</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowSpan={2}>회원유치</th>
                <td className="title">누적 회원수</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.member.accu_count.toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="title">월별 신규 회원수</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.member.member_count.toLocaleString()}
                  </td>
                ))}
              </tr>

              <tr>
                <th rowSpan={6}>구매건수</th>
                <td className="title">기존 회원(구매횟수)</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.order.prev_order_count.toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="title">신규 회원(구매횟수)</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.order.new_order_count.toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr className="summary">
                <td className="title">구매횟수 계</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.order.order_count.toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="title">기존회원(회원수)</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.order_member.prev_member_count.toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td className="title">신규회원(회원수)</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.order_member.new_member_count.toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr className="summary">
                <td className="title">구매회원 계</td>
                {monthColumn.map(month => (
                  <td key={month} className="content">
                    {(viewData || {})[
                      month
                    ]?.order_member.member_count.toLocaleString()}
                  </td>
                ))}
              </tr>

              <tr>
                <th rowSpan={2}>구매율</th>
                <td className="title">기존 회원(구매율)</td>
                {monthColumn.map(month => {
                  const memberCount =
                    (viewData || {})[month]?.member.accu_count || 1;
                  const buyCount = (viewData || {})[month]?.order_member
                    .prev_member_count;
                  return (
                    <td key={month} className="content">
                      {Number((buyCount / memberCount) * 100).toFixed(1)}%
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td className="title">신규 회원(구매율)</td>
                {monthColumn.map(month => {
                  const memberCount =
                    (viewData || {})[month]?.member.member_count || 1;
                  const buyCount = (viewData || {})[month]?.order_member
                    .new_member_count;
                  return (
                    <td key={month} className="content">
                      {Number((buyCount / memberCount) * 100).toFixed(1)}%
                    </td>
                  );
                })}
              </tr>

              <tr>
                <th>구매건수</th>
                <td className="title">일평균거래수</td>
                {monthColumn.map(month => {
                  const orderCount =
                    (viewData || {})[month]?.order.order_count || 1;
                  const days = moment(`${year}-${month}-01`).daysInMonth();
                  return (
                    <td key={month} className="content">
                      {Number(orderCount / days).toFixed(1)}
                    </td>
                  );
                })}
              </tr>

              <tr>
                <th rowSpan={2}>정기구독</th>
                <td className="title">구매횟수</td>
                {monthColumn.map(month => {
                  return (
                    <td key={month} className="content">
                      {(viewData || {})[
                        month
                      ]?.subscribe_order_count.order_count.toLocaleString()}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td className="title">구매율</td>
                {monthColumn.map(month => {
                  const orderCount =
                    (viewData || {})[month]?.subscribe_order_count
                      .order_count || 0;
                  const buyCount =
                    (viewData || {})[month]?.order.order_count || 1;
                  return (
                    <td key={month} className="content">
                      {Number((orderCount / buyCount) * 100).toFixed(1)}%
                    </td>
                  );
                })}
              </tr>

              <tr>
                <th colSpan={2}>3개월이내 재구매율</th>
                {monthColumn.map(month => {
                  const reOrderCount =
                    (viewData || {})[month]?.re_order_count.re_order_count || 0;
                  const buyCount =
                    (viewData || {})[month]?.order.order_count || 1;
                  return (
                    <td key={month} className="content">
                      {Number((reOrderCount / buyCount) * 100).toFixed(1)}%
                    </td>
                  );
                })}
              </tr>

              <tr className="titleContent">
                <td colSpan={2}></td>
                <td colSpan={monthColumn.length}>주문제품수량</td>
              </tr>
              {(kpiData?.order_partner || []).map((parentArr, index) => {
                const td: JSX.Element[] = [];

                td.push(
                  <td key={"title"} className="content" colSpan={2}>
                    {parentArr?.[0]?.partner_name}
                  </td>,
                );

                monthColumn.map(month => {
                  const data = parentArr.filter(
                    item => item.month === month,
                  )?.[0];
                  td.push(
                    <td key={month} className="content">
                      {(data?.item_count || 0).toLocaleString()}
                    </td>,
                  );
                });

                return <tr key={index}>{td}</tr>;
              })}

              <tr className="titleContent">
                <td colSpan={2}></td>
                <td colSpan={monthColumn.length}>주문횟수</td>
              </tr>
              {(kpiData?.order_partner || []).map((parentArr, index) => {
                const td: JSX.Element[] = [];

                td.push(
                  <td key={"title"} className="content" colSpan={2}>
                    {parentArr?.[0]?.partner_name}
                  </td>,
                );

                monthColumn.map(month => {
                  const data = parentArr.filter(
                    item => item.month === month,
                  )?.[0];
                  td.push(
                    <td key={month} className="content">
                      {(data?.order_count || 0).toLocaleString()}
                    </td>,
                  );
                });

                return <tr key={index}>{td}</tr>;
              })}
            </tbody>
          </ListTable>
        </Content>
      </ContentLayout>
    </>
  );
}
