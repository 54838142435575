import {IAlertModal, IDBAdmin} from "@data";
import {atom} from "recoil";
import stateKeys from "./stateKeys";

const states = {
  alertState: atom<IAlertModal>({
    key: stateKeys.alertModalKey,
    default: {},
  }),
  adminDataState: atom<IDBAdmin | undefined>({
    key: stateKeys.adminDataKey,
    default: undefined,
  }),
  loadingState: atom<boolean>({
    key: stateKeys.loadingKey,
    default: false,
  }),
};

export default states;
