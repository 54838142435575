import {
  Button,
  InputBox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import React, {useState} from "react";
import {useSetRecoilState} from "recoil";

interface IProps {
  isVisible: boolean;
  order_no: number;
  payment_price: number;
  final_price: number;
  close?: () => void;
  refresh?: () => void;
}

export function OrderFinalPriceModal({
  isVisible,
  order_no,
  payment_price,
  final_price,
  close,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [minusPrice, setMinusPrice] = useState(payment_price - final_price);
  const newFinalPrice = payment_price - minusPrice;

  function setClose() {
    close?.();
    setMinusPrice(payment_price - final_price);
  }

  async function save() {
    try {
      if (newFinalPrice < 0) {
        return setAlertModal({
          isVisible: true,
          title: "최종금액이 0원보다 작을 수 없습니다.",
        });
      }

      const {success, message} = await adminAPI.order.updateOrderFinalPrice({
        order_no,
        final_price: newFinalPrice,
      });
      if (success) {
        setClose?.();
        refresh?.();
      } else {
        setAlertModal({isVisible: true, title: message});
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  return (
    <Modal isVisible={isVisible} width={"500px"} close={setClose}>
      <ModalTitle title="최종금액 수정" isClose close={setClose} />
      <ModalContent>
        <div className="row">
          <div className="col title">주문금액</div>
          <div className="col">{payment_price.toLocaleString()}</div>
        </div>
        <div className="row">
          <div className="col title">차감금액</div>
          <div className="col">
            <InputBox
              type="number"
              value={String(minusPrice)}
              onValue={value => setMinusPrice(Number(value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col title">최종금액</div>
          <div className="col">{newFinalPrice.toLocaleString()}</div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button
            text="저장"
            onClick={save}
            backgroundColor={styleUtils.COLOR.success}
            fontColor={styleUtils.COLOR.white}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}
