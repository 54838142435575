import React, {useEffect, useRef, useState} from "react";
import {utils} from "@utils";
import styles from "./style.module.scss";
import {useRecoilState} from "recoil";
import {states} from "@recoils";
import _ from "lodash";
import {MOBILE_WIDTH} from "utils/constant";

interface IProps {
  isVisible?: boolean;
  children?: JSX.Element | JSX.Element[];
  close?: () => void;
  width?: string;
  mobileWidth?: string;
  height?: string;
  positionTop?: string;
  positionBottom?: string;
  positionLeft?: string;
  positionRight?: string;
  borderRadius?: number;
  animate?: "top" | "bottom" | "left" | "right";
  className?: string;
}

export default function Modal({
  isVisible,
  children,
  close,
  width = "300px",
  mobileWidth,
  height,
  positionTop,
  positionBottom,
  positionLeft,
  positionRight,
  borderRadius,
  animate,
  className,
}: IProps) {
  const [isEsc, setIsEsc] = useState(false);
  const modalId = useRef(`modal_${_.uniqueId()}`).current;
  const isMobile = window.innerWidth < MOBILE_WIDTH;

  useEffect(() => {
    const escKeyModalClose = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsEsc(true);
      }
    };
    window.addEventListener("keydown", escKeyModalClose);
    return () => {
      window.removeEventListener("keydown", escKeyModalClose);
    };
  }, []);

  useEffect(() => {
    if (isEsc && isVisible) {
      const activeModalList = document.querySelectorAll(
        `.${styles.container}.${styles.show}`,
      );
      const activeModalIndex = activeModalList.length - 1;
      let thisModalIndex = -1;
      activeModalList.forEach((element, index) => {
        if (element.id === modalId) {
          thisModalIndex = index;
          return false;
        }
      });
      const isLastModal = activeModalIndex === thisModalIndex;
      if (isLastModal) {
        close?.();
      }
    }
    setIsEsc(false);
  }, [isEsc]);

  return (
    <div
      id={modalId}
      className={[
        styles.container,
        className,
        isVisible ? styles.show : styles.hide,
        isMobile ? styles.mobile : styles.pc,
        ...(animate ? [styles.animate, styles[animate]] : []),
      ].join(" ")}>
      <div className={styles.modalContainer}>
        <div
          className={styles.modal}
          style={{
            width: `min(${isMobile ? mobileWidth ?? width : width}, 100vw)`,
            borderRadius,
            ...(height ? {height, maxHeight: "100vh"} : undefined),
            ...(positionTop ? {top: positionTop} : undefined),
            ...(positionBottom ? {bottom: positionBottom} : undefined),
            ...(positionLeft ? {left: positionLeft} : undefined),
            ...(positionRight ? {right: positionRight} : undefined),
          }}
          onClick={utils.handleClickBubble}>
          {children}
        </div>
      </div>
    </div>
  );
}
