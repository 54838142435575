import {
  Button,
  Content,
  InputBox,
  ListTable,
  SearchFilter,
  SelectBox,
} from "@components";
import {
  IMemberResponse,
  IOrderDetail,
  IPaymentFailOrderLogResponse,
  ResultPagingResponse,
  TStrBoolean,
} from "@data";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {states} from "@recoils";
import {adminAPI, utils} from "@utils";
import moment from "moment";
import {MemberDetailModal} from "pages/MemberPage/MemberDetailModal";
import {OrderDetailModal} from "pages/OrderPage/OrderDetailModal";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";

const successSelectList: {value: TStrBoolean; label: string}[] = [
  {value: "", label: "전체"},
  {value: "TRUE", label: "결제성공"},
  {value: "FALSE", label: "결제실패"},
];

export function PaymentNotOrderLog() {
  const setAlertModal = useSetRecoilState(states.alertState);
  const setLoading = useSetRecoilState(states.loadingState);
  const [isSuccess, setIsSuccess] = useState<TStrBoolean>("FALSE");
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] =
    useState<ResultPagingResponse<IPaymentFailOrderLogResponse[]>>();
  const [memberDetail, setMemberDetail] = useState<IMemberResponse>();
  const [orderDetailData, setOrderDetailData] = useState<IOrderDetail>();
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().add(-3, "days"),
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  async function getData({page = 1}: {page?: number} = {}) {
    try {
      setLoading(true);
      const {data, success, message} =
        await adminAPI.orderLog.getPaymentFailOrderLog({
          limit: 20,
          page,
          searchtxt: searchText,
          isSuccess,
          start_date: startDate.format("YYYY-MM-DD"),
          end_date: endDate.format("YYYY-MM-DD"),
        });
      if (success) {
        setListData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("gatData error", error);
      setLoading(false);
    }
  }

  async function getMemberDetail(no: number) {
    try {
      const {data, success, message} = await adminAPI.member.getDetail({
        member_no: no,
      });
      if (success) {
        setMemberDetail(data.result);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getMemberDetail error", error);
    }
  }

  async function getOrderDetail({no}: {no: number}) {
    try {
      const {data, success, message} = await adminAPI.order.apiGetOrderDetail({
        no,
      });
      if (success) {
        setOrderDetailData(data);
      } else {
        setAlertModal({
          isVisible: true,
          title: message || "오류가 발생했습니다",
        });
      }
    } catch (error) {
      console.error("getOrderDetail error", error);
    }
  }

  useEffect(() => {
    getData();
  }, [isSuccess]);

  return (
    <>
      <SearchFilter
        left={[
          <SelectBox
            key={0}
            value={isSuccess}
            list={successSelectList}
            singleSelect={value => setIsSuccess(value as TStrBoolean)}
          />,
          <div key={1} className="row">
            <InputBox
              type="date"
              value={startDate.format("YYYY-MM-DD")}
              onValue={data => setStartDate(moment(data))}
            />
            ~
            <InputBox
              type="date"
              value={endDate.format("YYYY-MM-DD")}
              onValue={data => setEndDate(moment(data))}
            />
          </div>,
          <InputBox
            key={2}
            value={searchText}
            onValue={setSearchText}
            onEnter={getData}
            rightContent={
              <div style={{display: "flex"}} onClick={() => getData()}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            }
          />,
        ]}
      />
      <Content>
        <ListTable paging={listData?.page} getList={getData}>
          <thead>
            <tr>
              <th>전화번호</th>
              <th>고객명 (닉네임)</th>
              <th>주문</th>
              <th>카드번호</th>
              <th>결제금액</th>
              <th>
                tid
                <br />
                moid
              </th>
              <th>상품명</th>
              <th>메시지</th>
              <th>메모</th>
              <th>결제시도일시</th>
            </tr>
          </thead>
          <tbody>
            {(listData?.result || []).map((item, index) => (
              <tr key={index}>
                <td>{utils.formatPhoneNumber(item.phone_number || "")}</td>
                <td>
                  {item.name || item.nickname ? (
                    <>
                      {item.name} ({item.nickname})
                    </>
                  ) : (
                    "-"
                  )}
                  {!isNaN(Number(item.member_no)) && (
                    <>
                      <br />
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="조회"
                          fontSize={10}
                          paddingVertical={2}
                          onClick={() => {
                            if (item.member_no) {
                              getMemberDetail(item.member_no);
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </td>
                <td>
                  {item.order_number || "-"}
                  {!!item.order_no && !isNaN(Number(item.order_no)) && (
                    <>
                      <br />
                      <div style={{display: "inline-block"}}>
                        <Button
                          text="조회"
                          fontSize={10}
                          paddingVertical={2}
                          onClick={() => {
                            if (item.order_no) {
                              getOrderDetail({no: item.order_no});
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </td>
                <td>
                  {item.card_name} {utils.formatCardNo(item.card_no || "")}
                </td>
                <td>{Number(item.amt || 0).toLocaleString()}원</td>
                <td className="text-12">
                  {item.tid}
                  <br />
                  {item.moid}
                </td>
                <td className="text-12">{item.item_name}</td>
                <td
                  className="text-12 text-left preWrap"
                  style={{minWidth: 50, maxWidth: 200}}>
                  {(item.result_msg || "").replace(/\\n/gi, `\n`)}
                </td>
                <td
                  className="text-12 text-left preWrap"
                  style={{minWidth: 50, maxWidth: 200}}>
                  {(item.memo || "").replace(/\\n/gi, `\n`)}
                </td>
                <td>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
              </tr>
            ))}
          </tbody>
        </ListTable>
      </Content>
      <MemberDetailModal
        isVisible={!!memberDetail}
        close={() => setMemberDetail(undefined)}
        member={memberDetail}
        refresh={() => !!memberDetail?.no && getMemberDetail(memberDetail.no)}
      />
      <OrderDetailModal
        isVisible={!!orderDetailData}
        close={() => setOrderDetailData(undefined)}
        data={orderDetailData}
        refresh={() => {
          if (orderDetailData?.no) {
            getOrderDetail({no: orderDetailData.no});
          }
        }}
      />
    </>
  );
}
