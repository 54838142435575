import {
  Button,
  CheckBox,
  FileUploadButton,
  InputBox,
  SelectBox,
  TextAreaBox,
} from "@components";
import {states} from "@recoils";
import {adminAPI, styleUtils} from "@utils";
import {
  IDBBadgeList,
  IDBItemCategory,
  IDBItemType,
  IDBPartner,
  IDBProduct,
  IProductRequest,
  IItemDetail,
  IProductStockItemList,
} from "@data";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import styles from "../style.module.scss";

interface IProps {
  detailData?: IItemDetail;
  partnerList: IDBPartner[];
  itemTypeList: IDBItemType[];
  itemCategoryList: IDBItemCategory[];
  itemBadgeList: IDBBadgeList[];
  refresh?: (item_no?: number) => void;
}

export function InfoTab({
  detailData,
  partnerList,
  itemTypeList,
  itemCategoryList,
  itemBadgeList,
  refresh,
}: IProps) {
  const setAlertModal = useSetRecoilState(states.alertState);
  const [data, setData] = useState<IProductRequest>({});
  const [isItemNameCheck, setIsItemNameCheck] = useState(false);
  const [partnerItemNameList, setPartnerItemNameList] = useState<IDBProduct[]>(
    [],
  );

  async function itemNameCheck() {
    try {
      if (!data.partner_no) {
        return setAlertModal({
          isVisible: true,
          title: "업체를 먼저 선택해주세요.",
        });
      }
      if (!data.item_name) {
        return setAlertModal({
          isVisible: true,
          title: "상품명을 입력해주세요.",
        });
      }
      const {success} = await adminAPI.item.checkItemName({
        no: data.no,
        item_partner_no: data.partner_no,
        item_name: data.item_name,
      });
      setIsItemNameCheck(success);
      setAlertModal({
        isVisible: true,
        title: success
          ? "사용할 수 있는 제품명입니다."
          : "이미 사용중인 제품명입니다.",
      });
    } catch (error) {
      console.error("itemNameCheck error", error);
    }
  }

  async function save() {
    try {
      if (!data.partner_no) {
        return setAlertModal({
          isVisible: true,
          title: "업체를 선택해주세요.",
        });
      }
      if (!data.item_name) {
        return setAlertModal({
          isVisible: true,
          title: "상품명을 입력해주세요.",
        });
      }
      if (
        !isItemNameCheck &&
        (detailData?.item_name ? data.item_name !== detailData.item_name : true)
      ) {
        return setAlertModal({
          isVisible: true,
          title: "제품명 중복체크를 해주세요.",
        });
      }
      if (data.no) {
        const {success, message} = await adminAPI.item.updateItem(data);
        if (success) {
          refresh?.();
        }
        setAlertModal({
          isVisible: true,
          title: message,
        });
      } else {
        const {
          success,
          message,
          data: apiData,
        } = await adminAPI.item.createItem(data);
        if (success) {
          refresh?.(apiData.result.no);
        }
        setAlertModal({
          isVisible: true,
          title: message,
        });
      }
    } catch (error) {
      console.error("save error", error);
    }
  }

  async function getPartnerItenNameList() {
    try {
      if (!data.partner_no) {
        return;
      }
      await setPartnerItemNameList([]);

      const {success, data: apiData} = await adminAPI.item.apiGetAllItems({
        partner_no: data.partner_no,
      });
      if (success) {
        setPartnerItemNameList(
          (apiData.result || []).filter(_item => _item.no !== data.no),
        );
      }
    } catch (error) {
      console.error("getPartnerItenNameList error", error);
    }
  }

  useEffect(() => {
    const item_main_badge: number[] = [];
    const item_badge: number[] = [];
    (detailData?.badge || []).forEach(badge => {
      if (!badge.badge_no) {
        return;
      }
      const isMain =
        itemBadgeList.find(item => item.no === badge.badge_no)?.main_yn === "Y";
      if (isMain) {
        item_main_badge.push(badge.badge_no);
      } else {
        item_badge.push(badge.badge_no);
      }
    });
    setData({
      release_cycle: 0,
      item_rate: 0,
      item_order_limit: 100,
      price: 0,
      view_price: 0,
      subscribe_price: 0,
      ...detailData,
      partner_no: detailData?.item_partner_no,
      item_main_badge,
      item_badge,
    });
  }, [detailData]);

  useEffect(() => {
    getPartnerItenNameList();
  }, [data.partner_no]);

  return (
    <div className={["row", styles.modalContainer].join(" ")}>
      <div className="col">
        <div style={{flex: 1}}>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">
              <div className="buttonCol">
                이미지
                <div>
                  <FileUploadButton
                    text={"업로드"}
                    height={32}
                    uploadUrl={"/admin/utils/uploadItemImg"}
                    isThumbnail
                    fileUpload={({files, thumbList}) =>
                      setData?.({
                        ...data,
                        item_img: files?.[0],
                        item_thumbnail_img: thumbList?.[0],
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col">
              {!!data?.item_img && (
                <img src={data?.item_img} className={styles.logoImg} />
              )}
            </div>
          </div>
          {!!detailData?.barcode && (
            <>
              <div className={["row", styles.infoRow].join(" ")}>
                <div className="col title">바코드</div>
                <div className="col">{detailData?.barcode}</div>
              </div>
              <div className={["row", styles.infoRow].join(" ")}>
                <div className="col title">nosnos 코드</div>
                <div className="col">
                  <InputBox
                    value={data?.nosnos_code}
                    onValue={value => setData?.({...data, nosnos_code: value})}
                  />
                </div>
              </div>
              <div className={["row", styles.infoRow].join(" ")}>
                <div className="col title">팀프레시 코드</div>
                <div className="col">
                  <InputBox
                    value={data?.teamfresh_code}
                    onValue={value =>
                      setData?.({...data, teamfresh_code: value})
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">업체</div>
            <div className="col">
              <SelectBox
                disabled={!!data.no}
                value={String(data?.partner_no)}
                list={(partnerList || []).map(item => ({
                  value: String(item.no),
                  label: String(item.partner_name || ""),
                }))}
                singleSelect={value => {
                  setIsItemNameCheck(false);
                  setData?.({...data, partner_no: Number(value)});
                }}
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">카테고리</div>
            <div className="col">
              <SelectBox
                value={(data?.type_list || []).map(type_no => String(type_no))}
                multiple
                list={(itemTypeList || []).map(item => ({
                  value: String(item.no),
                  label: String(item.title || ""),
                }))}
                multipleSelect={selectedList =>
                  setData?.({
                    ...data,
                    type_list: selectedList
                      .map(type_no => Number(type_no))
                      .filter(type_no => !isNaN(type_no)),
                    category_list: (data.category_list || []).filter(
                      category_no => {
                        const type_no = (itemCategoryList || []).filter(
                          item => item.no === category_no,
                        )?.[0]?.type_no;
                        const isType = selectedList.includes(String(type_no));
                        return isType;
                      },
                    ),
                  })
                }
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">세부 카테고리</div>
            <div className="col">
              <SelectBox
                value={(data?.category_list || []).map(category_no =>
                  String(category_no),
                )}
                multiple
                list={(itemCategoryList || [])
                  .filter(
                    item =>
                      !!item.type_no &&
                      (data.type_list || []).includes(item.type_no),
                  )
                  .map(item => {
                    const typeData = itemTypeList.find(
                      _type => _type.no === item.type_no,
                    );
                    return {
                      value: String(item.no),
                      label: `[${typeData?.title}] ${item.category}`,
                    };
                  })}
                multipleSelect={selectedList =>
                  setData?.({
                    ...data,
                    category_list: selectedList
                      .map(category_no => Number(category_no))
                      .filter(category_no => !isNaN(category_no)),
                  })
                }
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">제품명</div>
            <div className="col">
              <InputBox
                value={data?.item_name}
                onValue={value => {
                  setIsItemNameCheck(false);
                  setData?.({...data, item_name: value});
                }}
                rightContent={
                  isItemNameCheck ? undefined : (
                    <Button onClick={itemNameCheck} text="중복체크" />
                  )
                }
                onBlurCapture={() => {
                  setData?.({
                    ...data,
                    short_name: data.short_name || data.item_name,
                  });
                }}
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">
              상세페이지 레시피 타이틀
              <br />
              (짧은제품명)
            </div>
            <div className="col">
              <InputBox
                value={data?.short_name}
                onValue={value => {
                  setData?.({...data, short_name: value});
                }}
              />
            </div>
          </div>
          <div className={["row", "top", styles.infoRow].join(" ")}>
            <div className="col title">뱃지1</div>
            <div className="col">
              <div style={{flex: 1}}>
                <div>
                  <SelectBox
                    multiple
                    value={data?.item_main_badge?.map(item => String(item))}
                    list={(itemBadgeList || [])
                      .filter(item => item.main_yn === "Y")
                      .map(item => ({
                        value: String(item.no),
                        label: String(item.title || ""),
                      }))}
                    multipleSelect={arr =>
                      setData?.({
                        ...data,
                        item_main_badge: [
                          ...new Set([
                            ...(data?.item_main_badge || []).filter(
                              item => !!arr.includes(String(item)),
                            ),
                            ...arr.map(item => Number(item)),
                          ]),
                        ],
                      })
                    }
                  />
                </div>
                <div className={styles.badgeList}>
                  {data?.item_main_badge?.map(badge_no => {
                    const img_url = itemBadgeList.find(
                      item => item.no === badge_no,
                    )?.img_url;
                    return (
                      <img
                        key={_.uniqueId()}
                        src={img_url}
                        className={styles.badgeImg}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={["row", "top", styles.infoRow].join(" ")}>
            <div className="col title">뱃지2</div>
            <div className="col">
              <div style={{flex: 1}}>
                <div>
                  <SelectBox
                    multiple
                    value={data?.item_badge?.map(item => String(item))}
                    list={(itemBadgeList || [])
                      .filter(item => item.main_yn !== "Y")
                      .map(item => ({
                        value: String(item.no),
                        label: String(item.title || ""),
                      }))}
                    multipleSelect={arr =>
                      setData?.({
                        ...data,
                        item_badge: [
                          ...new Set([
                            ...(data?.item_badge || []).filter(
                              item => !!arr.includes(String(item)),
                            ),
                            ...arr.map(item => Number(item)),
                          ]),
                        ],
                      })
                    }
                  />
                </div>
                <div className={styles.badgeList}>
                  {data?.item_badge?.map(badge_no => {
                    const badge = itemBadgeList.find(
                      item => item.no === badge_no,
                    );
                    return (
                      <div
                        key={_.uniqueId()}
                        className={styles.badge}
                        style={{
                          backgroundColor: badge?.backgroundColor,
                          color: badge?.textColor,
                        }}>
                        {badge?.title}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">표시가격(실구매가)</div>
            <div className="col">
              <InputBox
                type="number"
                value={String(data?.price)}
                onValue={value => {
                  setData?.({...data, price: Number(value)});
                }}
                onBlurCapture={() => {
                  setData?.({
                    ...data,
                    view_price: data.view_price || data.price,
                    subscribe_price: data.subscribe_price || data.price,
                  });
                }}
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">정상가격</div>
            <div className="col">
              <InputBox
                type="number"
                value={String(data?.view_price)}
                onValue={value => {
                  setData?.({...data, view_price: Number(value)});
                }}
                style={{textDecoration: "line-through"}}
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">정기구독가격</div>
            <div className="col">
              <InputBox
                type="number"
                value={String(data?.subscribe_price)}
                onValue={value =>
                  setData?.({...data, subscribe_price: Number(value)})
                }
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">입고가격</div>
            <div className="col">
              <InputBox
                type="number"
                value={String(data?.input_price)}
                onValue={value =>
                  setData?.({...data, input_price: Number(value)})
                }
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">프로모션가격</div>
            <div className="col">
              <InputBox
                value={String(data?.promotion_price || "")}
                onValue={value => {
                  const new_value = (value || "").replace(/[^0-9]/g, "");
                  const promotion_price =
                    !!new_value && !isNaN(Number(new_value))
                      ? Number(new_value)
                      : null;

                  setData?.({
                    ...data,
                    promotion_price,
                  });
                }}
              />
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">노출여부</div>
            <div className="col">
              <div>
                <CheckBox
                  isToggle
                  checked={data?.view_yn === "Y"}
                  text={data?.view_yn === "Y" ? "노출" : "X"}
                  onChange={e =>
                    setData?.({
                      ...data,
                      view_yn: data?.view_yn === "Y" ? "N" : "Y",
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">냉장상품 체크</div>
            <div className="col">
              <div>
                <CheckBox
                  isToggle
                  checked={data?.item_fresh === "Y"}
                  text={data?.item_fresh === "Y" ? "냉장" : "냉동"}
                  onChange={e =>
                    setData?.({
                      ...data,
                      item_fresh: data?.item_fresh === "Y" ? "N" : "Y",
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">쿠폰사용여부</div>
            <div className="col">
              <div>
                <CheckBox
                  isToggle
                  checked={data?.coupon_useable === "Y"}
                  text={data?.coupon_useable === "Y" ? "가능" : "불가"}
                  onChange={e =>
                    setData?.({
                      ...data,
                      coupon_useable: data?.coupon_useable === "Y" ? "N" : "Y",
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={["row", styles.infoRow].join(" ")}>
            <div className="col title">주문수량한도</div>
            <div className="col">
              <InputBox
                type="number"
                value={String(data?.item_order_limit)}
                onValue={value =>
                  setData?.({...data, item_order_limit: Number(value)})
                }
              />
            </div>
          </div>
          <div className={["row", "top", styles.infoRow].join(" ")}>
            <div className="col title">주문서출력상품</div>
            <div className={["col", "flexColumn"].join(" ")}>
              <div style={{width: "100%"}}>
                <SelectBox
                  key={1}
                  list={[
                    {no: "", item_name: "상품선택"},
                    ...partnerItemNameList,
                  ].map(item => ({
                    value: String(item.no),
                    label: String(item.item_name || ""),
                  }))}
                  multiple
                  value={(data.order_sheet_item_list || []).map(_item =>
                    String(_item),
                  )}
                  multipleSelect={select_list => {
                    setData({
                      ...data,
                      order_sheet_item_list: select_list
                        .filter(_item => !isNaN(Number(_item)))
                        .map(_item => Number(_item)),
                    });
                  }}
                  boxStyle={{minWidth: "150px"}}
                />
              </div>
              <div>
                <span className="text-12 preWrap">
                  (샘플팩과 같이 묶음판매하나, nosnos코드가 묶음으로 생성될 수
                  없는 경우 사용하시면 됩니다.{"\n"}상품목록이 선택되어있으며
                  nosnos코드가 공란일 경우, 주문서 엑셀 출력 시 해당상품은
                  리스트에서 나오지 않으며, 선택한 상품목록이 치환되어
                  나옵니다.)
                </span>
              </div>
            </div>
          </div>
          <div className={["row", "top", styles.infoRow].join(" ")}>
            <div className="col title">재고연동</div>
            <div className={["col", "flexColumn"].join(" ")}>
              <div style={{width: "100%"}}>
                <SelectBox
                  key={1}
                  list={[
                    {no: "", item_name: "상품선택"},
                    ...partnerItemNameList,
                  ].map(item => ({
                    value: String(item.no),
                    label: String(item.item_name || ""),
                  }))}
                  multiple
                  value={(data.stock_item_list || []).map(_item =>
                    String(_item.item_no),
                  )}
                  multipleSelect={select_list => {
                    const newSelectList: IProductStockItemList[] = select_list
                      .filter(
                        _selectItemNoStr => !isNaN(Number(_selectItemNoStr)),
                      )
                      .map(_selectItemNoStr => {
                        const _selectItemNo = Number(_selectItemNoStr);
                        const prevStockItem = (data.stock_item_list || []).find(
                          _stockItem => _stockItem.item_no === _selectItemNo,
                        );
                        if (prevStockItem) {
                          return {...prevStockItem, item_no: _selectItemNo};
                        } else {
                          return {item_no: _selectItemNo, count: 1};
                        }
                      });
                    setData({
                      ...data,
                      stock_item_list: newSelectList,
                    });
                  }}
                  boxStyle={{minWidth: "150px"}}
                />
              </div>
              <div style={{width: "100%"}}>
                {(data.stock_item_list || []).map(({item_no, count}) => {
                  const itemData = partnerItemNameList.find(
                    partnerItem => partnerItem.no === item_no,
                  );
                  return (
                    <div
                      key={item_no}
                      className={["row", styles.infoRow].join(" ")}
                      style={{width: "100%"}}>
                      <div className="col" style={{flex: 2}}>
                        {itemData?.item_name}
                      </div>
                      <div className="col" style={{flex: 1}}>
                        <InputBox
                          value={String(count)}
                          type="number"
                          rightContent={<>개</>}
                          inputStyle={{width: 80}}
                          onValue={value => {
                            if (isNaN(Number(value))) {
                              return;
                            }
                            const newSelectList: IProductStockItemList[] = (
                              data.stock_item_list || []
                            ).map(prevData => {
                              if (prevData.item_no === item_no) {
                                return {...prevData, count: Number(value)};
                              } else {
                                return prevData;
                              }
                            });
                            setData({
                              ...data,
                              stock_item_list: newSelectList,
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <span className="text-12 preWrap">
                  (연동재고상품 설정 시, 재고관리는 현재상품으로 되지 않고
                  연동상품으로 관리가 됩니다. 저장 시 즉시반영됩니다.)
                </span>
              </div>
            </div>
          </div>
          <div className={["row", "top", styles.infoRow].join(" ")}>
            <div className="col title">메모</div>
            <div className="col">
              <TextAreaBox
                value={data?.item_memo}
                onValue={value => setData?.({...data, item_memo: value})}
                height={100}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Button
          text="저장"
          backgroundColor={styleUtils.COLOR.success}
          fontColor={styleUtils.COLOR.white}
          onClick={save}
        />
      </div>
    </div>
  );
}
