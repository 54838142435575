import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./style.module.scss";

interface IProps {
  title?: string;
  isClose?: boolean;
  close?: () => void;
}

export default function ModalTitle({title, isClose, close}: IProps) {
  return (
    <div className={styles.title}>
      <span>{title}</span>
      {isClose && <FontAwesomeIcon icon={faClose} onClick={close} />}
    </div>
  );
}
